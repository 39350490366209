"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: Points
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVMBase");
var PointsTable = require("./PointsTable");
var PointsForm = require("./PointsForm");
var PointsRouter = require("./PointsRouter");
var Points = /** @class */ (function (_super) {
    __extends(Points, _super);
    function Points(data) {
        var _this = 
        //call base class constructor 
        _super.call(this, data, PointsForm, PointsTable, PointsRouter) || this;
        _this.data = data;
        _this.UserClubPoints = ko.computed(function () {
            return _this.filterPointsByType(_this.GlobalNavigation.UserPoints.ClubPoints(), _this.ItemDetail.PointSummary.CurrentSelectedYear());
        });
        _this.UserBhcPoints = ko.computed(function () {
            return _this.filterPointsByType(_this.GlobalNavigation.UserPoints.BhcPoints(), _this.ItemDetail.PointSummary.CurrentSelectedYear());
        });
        _this.UserClubPointsPreRebalance = ko.computed(function () {
            return _this.filterPointsByType(_this.ItemDetail.PointSummary.PointsBuckets.ClubPointsPreRebalance(), _this.ItemDetail.PointSummary.CurrentSelectedYear());
        });
        _this.UserBhcPointsPreRebalance = ko.computed(function () {
            return _this.filterPointsByType(_this.ItemDetail.PointSummary.PointsBuckets.BhcPointsPreRebalance(), _this.ItemDetail.PointSummary.CurrentSelectedYear());
        });
        _this.UserClubPointsPostRebalance = ko.computed(function () {
            return _this.filterPointsByType(_this.ItemDetail.PointSummary.PointsBuckets.ClubPointsPostRebalance(), _this.ItemDetail.PointSummary.CurrentSelectedYear());
        });
        _this.UserBhcPointsPostRebalance = ko.computed(function () {
            return _this.filterPointsByType(_this.ItemDetail.PointSummary.PointsBuckets.BhcPointsPostRebalance(), _this.ItemDetail.PointSummary.CurrentSelectedYear());
        });
        _this.TotalBhcPoints = ko.computed(function () {
            var bhcpoints = _this.filterPointsByType(_this.GlobalNavigation.UserPoints.BhcPoints(), _this.getAllText());
            var selectedAvailBhc = ko.utils.arrayMap(bhcpoints, function (pointsYear) {
                return pointsYear.Points();
            });
            var bhcSum = _.reduce(selectedAvailBhc, function (bhcSum, points) {
                return bhcSum + points;
            });
            return bhcSum;
        });
        _this.TotalClubPoints = ko.computed(function () {
            var clubpoints = _this.filterPointsByType(_this.GlobalNavigation.UserPoints.ClubPoints(), _this.getAllText());
            var selectedAvailBhc = ko.utils.arrayMap(clubpoints, function (pointsYear) {
                return pointsYear.Points();
            });
            var clubSum = _.reduce(selectedAvailBhc, function (clubSum, points) {
                return clubSum + points;
            });
            return clubSum;
        });
        _this.UserTotalAvailablePoints = ko.computed(function () {
            var selectedAvailClub = ko.utils.arrayMap(_this.UserClubPoints(), function (pointsYear) {
                return pointsYear.Points();
            });
            var selectedAvailBhc = ko.utils.arrayMap(_this.UserBhcPoints(), function (pointsYear) {
                return pointsYear.Points();
            });
            var clubSum = _.reduce(selectedAvailClub, function (clubSum, points) {
                return clubSum + points;
            });
            var bhcSum = _.reduce(selectedAvailBhc, function (bhcSum, points) {
                return bhcSum + points;
            });
            if (clubSum === undefined) {
                clubSum = 0;
            }
            if (bhcSum === undefined) {
                bhcSum = 0;
            }
            return clubSum + bhcSum;
        });
        _this.UserTotalAvailablePointsPreRebalance = ko.computed(function () {
            var selectedAvailClub = ko.utils.arrayMap(_this.UserClubPointsPreRebalance(), function (pointsYear) {
                return pointsYear.Points();
            });
            var selectedAvailBhc = ko.utils.arrayMap(_this.UserBhcPointsPreRebalance(), function (pointsYear) {
                return pointsYear.Points();
            });
            var clubSum = _.reduce(selectedAvailClub, function (clubSum, points) {
                return clubSum + points;
            });
            var bhcSum = _.reduce(selectedAvailBhc, function (bhcSum, points) {
                return bhcSum + points;
            });
            if (clubSum === undefined) {
                clubSum = 0;
            }
            if (bhcSum === undefined) {
                bhcSum = 0;
            }
            return clubSum + bhcSum;
        });
        _this.UserTotalAvailablePointsPostRebalance = ko.computed(function () {
            var selectedAvailClub = ko.utils.arrayMap(_this.UserClubPointsPostRebalance(), function (pointsYear) {
                return pointsYear.Points();
            });
            var selectedAvailBhc = ko.utils.arrayMap(_this.UserBhcPointsPostRebalance(), function (pointsYear) {
                return pointsYear.Points();
            });
            var clubSum = _.reduce(selectedAvailClub, function (clubSum, points) {
                return clubSum + points;
            });
            var bhcSum = _.reduce(selectedAvailBhc, function (bhcSum, points) {
                return bhcSum + points;
            });
            if (clubSum === undefined) {
                clubSum = 0;
            }
            if (bhcSum === undefined) {
                bhcSum = 0;
            }
            return clubSum + bhcSum;
        });
        ko.applyBindings(_this);
        _this.picker.setupCalendarBindings(_this, 'TopDatePicker');
        // Sammy is disabled; Must call load manually
        _this.form.cancel();
        $('.hideUnbound').removeClass('hideUnbound');
        return _this;
    }
    return Points;
}(base.ResortSearchVmBase));
module.exports = Points;

"use strict";
var ko = require("knockout");
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var upload = (typeof window !== "undefined" ? window['$']['fileupload'] : typeof global !== "undefined" ? global['$']['fileupload'] : null);
upload;
var KoBindingFileUpload = /** @class */ (function () {
    function KoBindingFileUpload() {
    }
    KoBindingFileUpload.prototype.init = function () {
        ko.bindingHandlers.fileUpload = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var valueBinding = valueAccessor();
                valueAccessor();
                function generateUploaderId(id) {
                    var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); return v.toString(16); });
                    return id + '_' + guid;
                }
                ;
                var currentId = $(element).attr('id');
                $(element).attr('id', generateUploaderId(currentId));
                //get options from bindings
                var propName = allBindings.get('fileUploadPropertyName');
                var propIsCollection = allBindings.get('fileUploadPropertyIsCollection');
                var url = allBindings.get('fileUploadEndpointUrl');
                var height = allBindings.get('fileUploadRecommendedHeight');
                var width = allBindings.get('fileUploadRecommendedWidth');
                var callback = allBindings.get('fileUploadCallback');
                var setProperties = true;
                var callbackFunction;
                if (callback) {
                    setProperties = false;
                    callbackFunction = bindingContext.$root.form[callback];
                }
                var setResult = function (message, resultClass) {
                    var $messageContainer = $('#' + element.id).siblings('.file-upload-result');
                    if (message == null) {
                        $messageContainer.hide();
                    }
                    else {
                        var html = '<span class="{{CLASS}}">{{MESSAGE}}</span>';
                        html = html.replace("{{CLASS}}", resultClass).replace('{{MESSAGE}}', message);
                        $messageContainer.html(html).show();
                    }
                };
                var setProgress = function (progress) {
                    var $progress = $('#' + element.id).siblings('.file-upload-progress');
                    var $processing = $progress.find(".p-bar-processing");
                    if (progress === 0) {
                        $progress.hide();
                        $processing.hide();
                    }
                    else {
                        $progress.show();
                        if (progress >= 100)
                            $processing.show();
                    }
                    $progress.children('.p-bar').css('width', progress + '%');
                };
                var resetUpload = function () {
                    setProgress(0);
                    setResult(null, null);
                    $('.kendo-image-width').val('100%');
                };
                // define callback functions
                var addFunction = function (event, data) {
                    resetUpload();
                    if (data.files && data.files[0]) {
                        data.submit();
                    }
                };
                var doneFunction = function (event, data) {
                    if (data.result.success === true) {
                        if (setProperties) {
                            if (propIsCollection === true) {
                                bindingContext.$data[propName].push(data.result.dto);
                            }
                            else {
                                bindingContext.$data[propName](data.result.dto);
                            }
                        }
                        var successMessage = "File <strong>{{FILE}}</strong> uploaded successfully.";
                        setProgress(0);
                        setResult(successMessage.replace("{{FILE}}", data.result.dto.FileName), "success");
                        if (!setProperties) {
                            setTimeout(resetUpload, 5000, 0);
                            var isImageGallery = typeof (bindingContext.$data.Type) == "function" && bindingContext.$data.Type() === "image-gallery";
                            callbackFunction.call(bindingContext.$root.form, element, data.result.dto, isImageGallery);
                        }
                    }
                    else {
                        setProgress(0);
                        setResult(data.result.error.message, "error");
                    }
                };
                var failFunction = function (event, data) {
                    if (data.response().jqXHR.status === 404) {
                        setResult("File size is too large. Try re-sizing to under 4MB.", "error");
                    }
                    else {
                        setResult("There was an error uploading the file.", "error");
                    }
                };
                var progressFunction = function (event, data) {
                    var progress = (data.loaded / data.total) * 100;
                    setProgress(progress);
                };
                $(element).fileupload({
                    url: url,
                    dataType: 'json',
                    autoUpload: true,
                    previewCrop: true,
                    done: doneFunction,
                    add: addFunction,
                    fail: failFunction,
                    progressall: progressFunction
                });
            }
        };
    };
    return KoBindingFileUpload;
}());
module.exports = KoBindingFileUpload;

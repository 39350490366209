"use strict";
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var Sammy = require("sammy");
var _ = require("lodash");
var Cookies = require("js-cookie");
var Router = /** @class */ (function () {
    function Router(data, vm, alternateroutes) {
        this.data = data;
        this.vm = vm;
        if (alternateroutes) {
            this.routes = alternateroutes;
        }
        else {
            this.routes = this.getRoutes();
        }
        this.setupBindings();
        this.initRoutes();
        this.DEEP_LINK_LOCAL_ROUTE_COOKIE_NAME = "deepLinkLocalRoute";
    }
    Router.prototype.getRoutes = function () {
        var self = this;
        if (self.data.DisableSammyRoutes)
            return [];
        var route = self.data.FrontEndRoute !== null ? self.data.FrontEndRoute : self.data.Title;
        return [
            {
                // Returned as RegExp to expand options for what this method returns in overriding versions
                name: new RegExp(self.getArea() + "\/" + route + "#\/list"),
                func: function () {
                    self.vm.form.cancel();
                    self.vm.table.load();
                }
            },
            {
                name: self.getArea() + "/" + route + "#/new",
                func: function () {
                    self.vm.form.add();
                    $('.new-content-page-modal').modal('hide');
                }
            },
            {
                name: self.getArea() + "/" + route + "#/view/:id",
                func: function () {
                    var id = this.params['id'];
                    self.vm.form.getDetail(id);
                }
            },
            {
                name: self.getArea() + "/" + self.data.Title + "#/index",
                func: function () {
                    self.vm.form.getDetail(null);
                }
            },
            {
                name: self.getArea() + "/" + self.data.Title + "#/delete/:id",
                func: function () {
                    var id = this.params['id'];
                    self.vm.form.delete(id);
                }
            }
        ];
    };
    Router.prototype.setupBindings = function () {
        var _this = this;
        var self = this;
        //#add show call new route
        $('#admin-button-container').on('click', '#add', function () {
            _this.add();
            return false;
        });
        //#cancel show back
        $('.main-modal-form').on('click', '.dialog-close', function () {
            _this.goBack();
            return false;
        });
    };
    Router.prototype.initRoutes = function () {
        var _this = this;
        var app = Sammy('body', function (sammy) {
            //pass error 
            sammy.raise_errors = true;
            //register all routes (this allows us to add to them from derived class)
            _.each(_this.routes, function (v) {
                sammy.get(v.name, v.func);
            });
            //keep track of last route
            sammy.before(function (route) {
                _this.lastRoute = _this.currentRoute;
                _this.setHashFromDeepLink(route.path);
                _this.currentRoute = route;
                _this.vm.WindowLocation(window.location.pathname + window.location.hash);
                // Before switching routes, scroll to top
                $("html, body").animate({ scrollTop: 0 }, 500);
            });
            sammy.notFound = function (verb, route) {
                // try again with deepLink route stored in cookie
                // hack to enable deep linking for pages that dont have routes setup for deep links
                if (!_this.setHashFromDeepLink(route)) {
                    var defaultErrorRoute = '#/' + _this.vm.DefaultSammyRoute();
                    window.location.hash = defaultErrorRoute;
                    if (!_this.pathIsLoginPage(route)) {
                        console.error(route + ": route not found. Redirecting to default route.");
                    }
                }
            };
            sammy.after(function (route) {
                _this.vm.RouteDisplayed(true);
            });
        });
        $(function () {
            if (_this.data.DisableSammyRoutes)
                return;
            var location = app.getLocation();
            _this.setDeepLinkLocalRouteCookie(location);
            var hasLocalRoute = location.indexOf('#') > 0;
            if (!hasLocalRoute) {
                //go to default route if no route in request
                var indexOfQuestionMark = location.indexOf("?");
                var queryString = indexOfQuestionMark >= 0
                    ? location.substring(indexOfQuestionMark)
                    : "";
                location =
                    '/' + _this.getUrlBase() +
                        '/' + _this.vm.FrontEndRoute() +
                        queryString +
                        '#/' + _this.vm.DefaultSammyRoute();
            }
            app.run(location);
        });
    };
    Router.prototype.pathIsLoginPage = function (url) {
        function nthIndex(str, pattern, n) {
            var L = str.length, i = -1;
            while (n-- && i++ < L) {
                i = str.indexOf(pattern, i);
                if (i < 0)
                    break;
            }
            return i;
        }
        var indexOfSecondSlash = nthIndex(url, "/", 2);
        if (indexOfSecondSlash < 0)
            return false;
        var subString = url.substring(indexOfSecondSlash);
        return subString.lastIndexOf("/login", 0) === 0; // startsWith
    };
    Router.prototype.setDeepLinkLocalRouteCookie = function (route) {
        var isRedirectToLoginPage = this.pathIsLoginPage(route);
        var localRouteIndex = route.indexOf("#");
        var hasLocalRoute = localRouteIndex >= 0;
        if (isRedirectToLoginPage && hasLocalRoute) {
            var localRoute = route.substring(localRouteIndex);
            var threeMinsFromNow = new Date();
            threeMinsFromNow.setMinutes(threeMinsFromNow.getMinutes() + 3);
            Cookies.set(this.DEEP_LINK_LOCAL_ROUTE_COOKIE_NAME, localRoute, { expires: threeMinsFromNow });
        }
    };
    Router.prototype.pathIsSelectMembershipOrInviteUser = function (url) {
        return url.includes('select-membership') || url.includes('user-info');
    };
    Router.prototype.setHashFromDeepLink = function (path) {
        var isRedirectToLoginPage = this.pathIsLoginPage(path);
        var isSelectMembershipPage = this.pathIsSelectMembershipOrInviteUser(path);
        var deepLinkLocalRoute = Cookies.get(this.DEEP_LINK_LOCAL_ROUTE_COOKIE_NAME);
        ;
        if (isSelectMembershipPage || isRedirectToLoginPage || deepLinkLocalRoute === undefined)
            return false;
        window.location.hash = deepLinkLocalRoute;
        var host = window.location.hostname.split('.').reverse();
        var domain = (host[1] ? (host[1] + '.') : '') + host[0];
        Cookies.remove(this.DEEP_LINK_LOCAL_ROUTE_COOKIE_NAME, { path: '/', domain: domain });
        return true;
    };
    Router.prototype.getUrlBase = function () {
        return this.getArea().replace('.*', this.vm.CurrentLanguageCode());
    };
    Router.prototype.getArea = function () {
        var area = '';
        if (this.vm.Area()) {
            area = '/' + this.vm.Area();
        }
        else {
            area = '.*';
        }
        return area;
    };
    //go to previous route or default route if undefined
    Router.prototype.goBack = function () {
        window.location.hash = "#/list";
    };
    Router.prototype.add = function () {
        window.location.hash = '#/new';
    };
    Router.prototype.view = function (id) {
        window.location.hash = '#/view/' + id;
    };
    return Router;
}());
module.exports = Router;

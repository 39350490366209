"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: MortgagePayment
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVmBase");
var MortgagePaymentTable = require("./MortgagePaymentTable");
var MortgagePaymentRouter = require("./MortgagePaymentRouter");
var MortgagePaymentForm = require("./MortgagePaymentForm");
var ClientPagination = require("../Base/ClientSidePagination");
var MortgagePayment = /** @class */ (function (_super) {
    __extends(MortgagePayment, _super);
    function MortgagePayment(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, MortgagePaymentForm, MortgagePaymentTable, MortgagePaymentRouter) || this;
        _this.data = data;
        _this.TotalAmountDue = ko.observable(0);
        _this.IsLoanPayoff = ko.observable(false);
        _this.AmountDue = ko.observable(0);
        _this.AdditionalPayment = ko.observable(0);
        _this.LateCharge = ko.observable(0);
        _this.PayOffAmount = ko.observable(0);
        _this.initHtsTokenizer();
        console.log("Japan property ", _this.IsJapanProperty);
        ko.applyBindings(_this);
        $('.hideUnbound').removeClass('hideUnbound');
        var checkNumber = function (input, ko) {
            if (input < 0) {
                ko(0);
            }
            else if (isNaN(input)) {
                ko(0);
            }
            else if (input === "") {
                ko(0);
            }
        };
        _this.AmountDue(_this.ItemDetail.AmountDue());
        _this.LateCharge(_this.ItemDetail.LateCharge());
        _this.PayOffAmount(0);
        _this.TotalAmountDue(_this.ItemDetail.AmountDue()
            + _this.ItemDetail.LateCharge()
            + _this.ItemDetail.AdditionalPayment()
            + _this.ItemDetail.AdditionalPrincipal());
        _this.ItemDetail.CreditCardForm.Amount(_this.AmountDue());
        _this.ItemDetail.TotalPayment(_this.TotalAmountDue());
        _this.AdditionalPayment(_this.ItemDetail.AdditionalPayment());
        _this.ItemDetail.AdditionalPayment.subscribe(function (newValue) {
            checkNumber(newValue, _this.ItemDetail.AdditionalPayment);
        });
        _this.ItemDetail.AdditionalPrincipal.subscribe(function (newValue) {
            checkNumber(newValue, _this.ItemDetail.AdditionalPrincipal);
        });
        _this.IsJapanProperty(true);
        _this.IsLoanPayoff.subscribe(function (newValue) {
            var self = _this.ItemDetail;
            var tobePaid;
            if (newValue) {
                _this.AmountDue(0);
                _this.AdditionalPayment(0);
                _this.LateCharge(0);
                _this.PayOffAmount(_this.ItemDetail.PayOffAmount());
                $('#txtAdditionalPayment').attr('disabled', 'disabled');
                tobePaid = 0;
                tobePaid += parseFloat(_this.AmountDue().toString());
                tobePaid += parseFloat(_this.AdditionalPayment().toString());
                tobePaid += parseFloat(_this.LateCharge().toString());
                tobePaid += parseFloat(_this.PayOffAmount().toString());
                _this.TotalAmountDue(tobePaid);
            }
            else {
                _this.AmountDue(self.AmountDue());
                _this.AdditionalPayment(self.AdditionalPayment());
                _this.LateCharge(self.LateCharge());
                _this.PayOffAmount(0);
                $('#txtAdditionalPayment').removeAttr('disabled');
                tobePaid = 0;
                tobePaid += parseFloat(_this.AmountDue().toString());
                tobePaid += parseFloat(_this.AdditionalPayment().toString());
                tobePaid += parseFloat(_this.LateCharge().toString());
                tobePaid += parseFloat(self.AdditionalPrincipal().toString());
                tobePaid += parseFloat(_this.PayOffAmount().toString());
                _this.TotalAmountDue(tobePaid);
            }
        });
        _this.AdditionalPayment.subscribe(function (newValue) {
            if (newValue.toString() === "") {
                newValue = 0;
            }
            else if (newValue < 0) {
                newValue = 0;
            }
            else if (isNaN(newValue)) {
                newValue = 0;
            }
            if (!isNaN(newValue)) {
                var totalAmount = parseFloat(_this.AmountDue().toString());
                totalAmount += parseFloat(newValue.toString());
                _this.TotalAmountDue(totalAmount);
            }
        });
        _this.TotalAmountDue.subscribe(function (newValue) {
            _this.ItemDetail.CreditCardForm.Amount(newValue);
            _this.ItemDetail.TotalPayment(newValue);
        });
        _this.PaginatedLoanTransactions = new ClientPagination(_this.ItemDetail.LoanTransactions, 10, '.paged-loan-transactions');
        return _this;
    }
    return MortgagePayment;
}(base.ResortSearchVmBase));
module.exports = MortgagePayment;

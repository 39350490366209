"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var SignUpForm = /** @class */ (function (_super) {
    __extends(SignUpForm, _super);
    function SignUpForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    SignUpForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        $('#sign-up-wrapper').on('click', '#create-account', function (e) {
            e.preventDefault();
            var promise = _this.post();
            promise.done(function (data) {
                _this.vm.DataLayer.push({
                    'event': 'accountCreation'
                });
                _this.vm.EmailSent(data.EmailSent);
                _this.vm.MemberAlreadyExists(data.MemberAlreadyExists);
            });
            promise.fail(function (data) {
                _this.vm.IsLoading(false);
                _this.vm.AccountNotFound(data.responseJSON.AccountNotFound);
                _this.vm.AccountEmailMismatch(data.responseJSON.AccountEmailMismatch);
            });
            return false;
        });
        $('#add-email-wrapper').on('click', '#add-email', function (e) {
            e.preventDefault();
            var promise = _this.post();
            promise.done(function (data) {
                _this.vm.EmailSent(data.EmailSent);
                _this.vm.MemberAlreadyExists(data.MemberAlreadyExists);
                _this.vm.AccountEmailAlreadySet(data.EmailAlreadySet);
                _this.vm.AddingEmailAddress(data.AddingEmailAddress);
            });
            promise.fail(function (data) {
                _this.vm.IsLoading(false);
                _this.vm.AccountNotFound(data.responseJSON.AccountNotFound);
                _this.vm.AccountEmailMismatch(data.responseJSON.AccountEmailMismatch);
                _this.vm.AccountEmailAlreadySet(data.responseJSON.AccountEmailAlreadySet);
                _this.vm.AddingEmailAddress(data.responseJSON.AddingEmailAddress);
                _this.vm.MemberAlreadyExists(data.responseJSON.MemberAlreadyExists);
            });
            return false;
        });
    };
    SignUpForm.prototype.postPromiseDone = function (data) {
        this.mapPostData(data);
        if (!this.vm.LeaveOpenOnAjaxSuccess()) {
            this.resetDetail();
        }
        this.vm.IsLoading(false);
    };
    SignUpForm.prototype.getPromiseDone = function (data, id) {
        this.vm.ItemDetailIsNew(false);
        this.komappingfromdata(data, id);
        ;
        this.vm.IsLoading(false);
        this.vm.FormVisible(true);
    };
    SignUpForm.prototype.missingDates = function () { };
    SignUpForm.prototype.refreshResortData = function () { };
    return SignUpForm;
}(FrontEndForm));
module.exports = SignUpForm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var SetupProfileForm = /** @class */ (function (_super) {
    __extends(SetupProfileForm, _super);
    function SetupProfileForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    SetupProfileForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('#setup-profile-wrapper').on('click', '#continue', function (e) {
            e.preventDefault();
            var promise = self.post();
            promise.done(function (data) {
                $('#loading').height($(window).height());
                self.vm.IsLoading(true);
                self.vm.IsLoadingTransaction(true);
                window.location.href = data.ReturnUrl;
            });
            promise.fail(function () {
                self.vm.IsLoading(false);
            });
            return false;
        });
        $('#edit-profile-picture, #add-profile-picture').on('click', function (e) {
            e.preventDefault();
            $("input[type=file]:hidden").trigger('click'); //trigger the click of the hidden input element
        });
        $('#remove-profile-picture').on('click', function (e) {
            e.preventDefault();
            _this.vm.ItemDetail.ImageUpload(null);
        });
        $('body').on('keyup', '.input-confirm-password', function (e) {
            if (e.keyCode === 13) {
                $('#continue').click();
            }
        });
    };
    SetupProfileForm.prototype.saveMemberProfilePicture = function (element, result) {
        //update the image to the new result
        var url = result.ImageSizes[0].Url;
        this.vm.ItemDetail.ImageUpload(url);
        this.vm.ItemDetail.ProfilePictureId(result.Id);
    };
    SetupProfileForm.prototype.postPromiseDone = function (data) {
        this.mapPostData(data);
        this.vm.IsLoading(false);
    };
    SetupProfileForm.prototype.getPromiseDone = function (data, id) {
        this.vm.ItemDetailIsNew(false);
        this.komappingfromdata(data, id);
        ;
        this.vm.IsLoading(false);
        this.vm.FormVisible(true);
    };
    return SetupProfileForm;
}(FrontEndForm));
module.exports = SetupProfileForm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Table = require("../Base/Table");
var ResortReviewFrontEndTable = /** @class */ (function (_super) {
    __extends(ResortReviewFrontEndTable, _super);
    function ResortReviewFrontEndTable(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        _this.skipPageSubscriptions = ko.observable(false);
        return _this;
    }
    Object.defineProperty(ResortReviewFrontEndTable.prototype, "tableSelector", {
        get: function () { return '.reviews-list-container'; },
        enumerable: false,
        configurable: true
    });
    ResortReviewFrontEndTable.prototype.setupSubscriptions = function () {
        var _this = this;
        //page number
        this.vm.PageInfo.PageNumber.subscribe(function () {
            if (!_this.skipPageSubscriptions())
                _this.load();
        });
        //page size
        this.vm.PageInfo.PageSize.subscribe(function (newValue) {
            if (!_this.skipPageSubscriptions())
                _this.somethingChanged();
        });
    };
    ResortReviewFrontEndTable.prototype.load = function () {
        //var project = ko.unwrap(this.vm.ProjectId());
        var project = this.vm.ProjectIds();
        if (project == null) {
            return null;
        }
        var lang = ko.unwrap(this.vm.CurrentLanguageCode());
        this.vm.Filters.ProjectNumbers(project);
        this.vm.Filters.Language(lang);
        this.orderby = "CreatedDate desc";
        return _super.prototype.load.call(this);
    };
    return ResortReviewFrontEndTable;
}(Table));
module.exports = ResortReviewFrontEndTable;

"use strict";
var ValidationConstants = /** @class */ (function () {
    function ValidationConstants() {
    }
    Object.defineProperty(ValidationConstants, "generalCCValidationError", {
        get: function () { return "Cannot process payment at this time. Ensure credit card info is correct."; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "ccNumberCountValidationError", {
        get: function () { return "Invalid credit card number"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "ccNumberValidationErrorKey", {
        get: function () { return "ItemDetail.CreditCardForm.Number"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "ipAddressValidationError", {
        get: function () { return "Unable to process payment at this time"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "visaTypeId", {
        get: function () { return "VS"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "jcbTypeId", {
        get: function () { return "JC"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "mastercardTypeId", {
        get: function () { return "MC"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "americanExpressTypeId", {
        get: function () { return "AX"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "discoverTypeId", {
        get: function () { return "DS"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "visa", {
        get: function () { return "visa"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "jcb", {
        get: function () { return "jcb"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "mastercard", {
        get: function () { return "mastercard"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "americanExpress", {
        get: function () { return "american-express"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "discover", {
        get: function () { return "discover"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "dinersclubTypeId", {
        get: function () { return "DC"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "dinersclub", {
        get: function () { return "diners-club"; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ValidationConstants, "allCardTypes", {
        get: function () {
            return ["visa", "jcb", "mastercard", "american-express", "discover", "diners-club"];
        },
        enumerable: false,
        configurable: true
    });
    return ValidationConstants;
}());
module.exports = ValidationConstants;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Router = require("../Base/Router");
var PurchaseRequestRouter = /** @class */ (function (_super) {
    __extends(PurchaseRequestRouter, _super);
    function PurchaseRequestRouter(data, vm, alternateroutes) {
        var _this = _super.call(this, data, vm, alternateroutes) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    PurchaseRequestRouter.prototype.getRoutes = function () {
        var self;
        self = this;
        if (self.data.DisableSammyRoutes)
            return [];
        return [
            {
                // No ID, default
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/index",
                func: function () {
                    //self.vm.form.getDetail(null);
                    self.vm.form.add();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/confirmation",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                }
            }
        ];
    };
    return PurchaseRequestRouter;
}(Router));
module.exports = PurchaseRequestRouter;

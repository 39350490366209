"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Form = require("./Form");
var Promise = require("bluebird");
var ccValidator = require("../Validation/CCValidator");
var ccTypeValidator = require("../Validation/CCTypeValidator");
var FrontEndForm = /** @class */ (function (_super) {
    __extends(FrontEndForm, _super);
    function FrontEndForm(data, vm, editAction) {
        if (editAction === void 0) { editAction = 'Edit'; }
        var _this = _super.call(this, data, vm, editAction) || this;
        _this.data = data;
        _this.vm = vm;
        _this.editAction = editAction;
        _this.getUrl = _this.area + "/" + _this.vm.CurrentLanguageCode() + "/" + data.FrontEndRoute + "/Item";
        return _this;
    }
    FrontEndForm.prototype.setupBindings = function () {
        _super.prototype.setupBindings.call(this);
        var self = this;
        // Check whether links are external:
        $.extend($.expr[':'], {
            external: function (a, i, m) {
                if (!a.href) {
                    return false;
                } // Only works with elements that have href
                if (a.hostname && a.hostname !== window.location.hostname) {
                    var fixedHostname = a.hostname.replace(/^\s+|\s+$/g, '').toLowerCase().replace('qa.', '').replace('stage.', '').replace('www.', '');
                    if (jQuery.inArray(fixedHostname, self.vm.ExternalLinkWhitelist()) >= 0)
                        return false;
                    if (fixedHostname.indexOf('javascript') > 0 || fixedHostname.indexOf('(') > 0)
                        return false;
                    if (a.hostname.substr(0, a.hostname.indexOf(':')) === window.location.hostname)
                        return false;
                    else
                        return true;
                }
                else
                    return false;
            }
        });
        $('a:external').attr('target', '_blank').click(function (e) {
            e.preventDefault();
            //alert($(this).attr('href'));
            self.vm.DataLayer.push({
                'event': 'outboundURL',
                'outboundURL': $(this).attr('href')
            });
            $('#leaving-site-modal-ok').attr('href', $(this).attr('href'));
            HGV.Modal['leaving-site-modal-trigger'].open();
        });
        $("#btnPrev").click(function () {
            var leftPos = $('#myCarousel').scrollLeft();
            $("#myCarousel").animate({ scrollLeft: leftPos - 200 }, 800);
        });
        $("#btnNext").click(function () {
            var leftPos = $('#myCarousel').scrollLeft();
            $("#myCarousel").animate({ scrollLeft: leftPos + 200 }, 800);
        });
        //$('#carouselExample').on('slide.bs.carousel', function (e) {
        //    var $e = $(e.relatedTarget);
        //    var idx = $e.index();
        //    var itemsPerSlide = 4;
        //    var totalItems = $('.carousel-item').length;
        //    if (idx >= totalItems - (itemsPerSlide - 1)) {
        //        var it = itemsPerSlide - (totalItems - idx);
        //        for (var i = 0; i < it; i++) {
        //            // append slides to end
        //            if (e.direction == "left") {
        //                $('.carousel-item').eq(i).appendTo('.carousel-inner');
        //            }
        //            else {
        //                $('.carousel-item').eq(0).appendTo('.carousel-inner');
        //            }
        //        }
        //    }
        //});
        $('#leaving-site-modal-ok').click(function (e) {
            HGV.Modal['leaving-site-modal-trigger'].close();
        });
        //Needed to toggle ADA for the availability view
        $('body').on('change', '.checkbox--ada', function (e) {
            e.stopImmediatePropagation();
            if ($(e.target).hasClass('is-disabled'))
                return false;
            var selected = $(this).is(':checked');
            self.vm.Filters.ADAOnly(selected);
        });
        $('body').on('change', '.checkbox-selectall', function (e) {
            var roomSizes = [];
            var val = e.target.value;
            if ($(e.target).hasClass('is-disabled'))
                return false;
            var selected = $(this).is(':checked');
            if (selected) {
                $('.checkbox--roomsizes').each(function () {
                    this.checked = selected;
                    var id = $(this)[0].dataset["id"];
                    if (id && !roomSizes.includes(id)) {
                        roomSizes.push(id);
                    }
                });
                roomSizes.push(val);
            }
            else if (!selected) {
                $('.checkbox--roomsizes').each(function () {
                    this.checked = selected;
                });
                roomSizes.length = 0;
            }
            if (self.vm.Filters.hasOwnProperty('RoomSizes')) {
                self.vm.Filters.RoomSizes(roomSizes);
            }
        });
        //Needed to collect up room sizes
        $('body').on('change', '.checkbox--roomsizes', function (e) {
            e.stopImmediatePropagation();
            if ($(e.target).hasClass('is-disabled'))
                return false;
            var selected = $(this).is(':checked');
            var id = $(this)[0].dataset["id"];
            if (id !== undefined) {
                var roomSizes = ko.unwrap(self.vm.Filters.RoomSizes());
                var beginLength = roomSizes.length;
                if (selected) {
                    if (!roomSizes.includes(id)) {
                        roomSizes.push(id);
                    }
                }
                else {
                    if (id && roomSizes.includes(id)) {
                        var idx = roomSizes.indexOf(id);
                        roomSizes.splice(idx, 1);
                    }
                    $('.checkbox-selectall').each(function () {
                        this.checked = selected;
                    });
                    if (roomSizes.includes("5")) {
                        var selectAllidx = roomSizes.indexOf("5");
                        roomSizes.splice(selectAllidx, 1);
                    }
                }
                if (roomSizes.length != beginLength) {
                    self.vm.Filters.RoomSizes(roomSizes);
                }
            }
        });
        // Called from bookingJS upon changes to the roomSizes array
        $(document).on('adjustRoomTypes', null, function (e, arr) {
            e.stopImmediatePropagation();
            self.vm.Filters.RoomSizes(arr);
        });
    };
    FrontEndForm.prototype.validateCC = function (vm) {
        var result = false;
        if (vm.DisplayNumber() != null) {
            if (ccValidator.validate(vm.CardType(), vm.DisplayNumber())) {
                if ((vm.Month() + vm.Year()).length >= 3) {
                    result = true;
                }
            }
        }
        if (result == false) {
            this.postCCError();
        }
        return result;
    };
    FrontEndForm.prototype.validateJapanCC = function (vm) {
        var result = true;
        if (vm.LastFourCardNumber.length == 0) {
            result = false;
        }
        if (result == false) {
            this.postCCError();
        }
        return result;
    };
    FrontEndForm.prototype.postCCError = function () {
        this.scrollTop();
        this.vm.AddAlert(this.vm.getGlobalContentBlock("GeneralCreditCardErrorMessage"), 'error', true);
        this.vm.IsLoading(false);
    };
    //postOSCAmountError(message: string) {
    //    this.vm.AddAlert(message, 'error', true);
    //    this.vm.IsLoading(false);
    //}
    FrontEndForm.prototype.getCardType = function (number) {
        var cctype = ccTypeValidator.validate(number);
        return cctype;
    };
    FrontEndForm.prototype.GetFuseboxToken = function (vm, currentUrl) {
        var self = this;
        var cctype = ccTypeValidator.validate(vm.DisplayNumber());
        if (cctype === '') {
            this.postCCError();
            return;
        }
        vm.CardType(cctype);
        var fuseData = {
            CHAINCODE: self.vm.ChainCode(),
            LOCATION_ID: self.vm.LocationId(),
            TERMINAL_ID: self.vm.TerminalId(),
            Cardholder_Account_Number: vm.DisplayNumber(),
            EXPDATE: vm.Month() + vm.Year(),
            CARDTYPE: vm.CardType()
        };
        return new Promise(function (resolve, reject) {
            self.vm.IsLoading(true);
            $.ajax({
                url: self.vm.FuseBoxRootUrl(),
                dataType: 'json',
                data: fuseData,
                type: 'GET',
                cache: false,
            }).then(function (tokenData) {
                if (tokenData.TOKEN.length > 0) {
                    vm.LastFourCardNumber(tokenData.TOKEN.substr(tokenData.TOKEN.length - 4));
                    vm.CardToken(tokenData.TOKEN);
                    vm.Number(tokenData.TOKEN);
                    resolve(vm);
                }
                else {
                    self.postCCError();
                    self.logFuseError(tokenData.STATUS + JSON.stringify(tokenData) + " " + currentUrl);
                    reject(tokenData.STATUS);
                }
            }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
                if (XMLHttpRequest.readyState == 4) {
                    self.logFuseError(errorThrown);
                }
                else if (XMLHttpRequest.readyState == 0) {
                    self.logFuseError("network refusal- calling alternate url ");
                    return new Promise(function () {
                        self.vm.IsLoading(true);
                        $.ajax({
                            url: self.vm.FuseboxBaseUrlAlternate(),
                            dataType: 'json',
                            data: fuseData,
                            type: 'GET',
                            cache: false
                        }).then(function (tokenData) {
                            if (tokenData.TOKEN.length > 0) {
                                self.logFuseError("calling alternate url success -" + JSON.stringify(tokenData) + " " + currentUrl);
                                vm.LastFourCardNumber(tokenData.TOKEN.substr(tokenData.TOKEN.length - 4));
                                vm.CardToken(tokenData.TOKEN);
                                vm.Number(tokenData.TOKEN);
                                resolve(vm);
                            }
                            else {
                                self.postCCError();
                                self.logFuseError(tokenData.STATUS + JSON.stringify(tokenData) + " " + currentUrl);
                                reject(tokenData.STATUS);
                            }
                        }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
                            self.vm.IsLoading(false);
                            self.logFuseError(errorThrown);
                            reject(errorThrown);
                        });
                    });
                }
                else {
                    reject(errorThrown);
                    self.logFuseError(errorThrown);
                }
            });
        });
    };
    FrontEndForm.prototype.logFuseError = function (error) {
        var url = "/" + this.vm.CurrentLanguageCode() + "/" + 'error/fuseerror';
        error += " For User Lead: " + this.vm.User.LeadId() + " Email: " + this.vm.User.Email();
        $.ajax({
            type: 'POST',
            url: url,
            data: JSON.stringify({ "error": error }),
            contentType: "application/json; charset=utf-8",
            dataType: 'json',
        });
    };
    FrontEndForm.prototype.getLeadId = function () {
        return this.vm.User.LeadId();
    };
    FrontEndForm.prototype.formInputAddError = function (forminput, key, message) {
        //select2 need to use parent element
        if (forminput.hasClass('select2-offscreen')) {
            forminput = $('.select2-container', forminput.parent());
        }
        var isSpan = forminput.is('span');
        var isCheckbox = forminput.is(':checkbox');
        var formElementFound = true;
        if (forminput.length > 0) {
            var elementParent = forminput.parents('div').not('.dropdown').first();
            elementParent.addClass('is-error');
            if (!isCheckbox) { //skip adding the extra tag
                if (!isSpan) {
                    $('.form-control-error', elementParent).remove();
                    elementParent.append('<div class="form-control-error">' + message + '</div>');
                }
                else {
                    forminput.text(message);
                    forminput.addClass('label label-danger');
                }
            }
        }
        else {
            formElementFound = false;
        }
        if (formElementFound) {
            _super.prototype.setupClearValidation.call(this, key);
        }
        if (!this.vm.HasScrolledToError()) {
            var errorElement = '#' + key.replace(/\./g, '_');
            var sideModalParent = $(errorElement).parents('.side-modal');
            var isInModal = sideModalParent.length > 0;
            if (isInModal) {
                sideModalParent.animate({ scrollTop: $(errorElement).offset().top }, 500); //smoothscroll doesn't work correctly in modals
            }
            else {
                //$.smoothScroll({ scrollTarget: errorElement });
                this.scrollCenter(errorElement);
            }
            this.vm.HasScrolledToError(true);
        }
    };
    Object.defineProperty(FrontEndForm.prototype, "postUrl", {
        get: function () {
            return this.area + "/" + this.vm.CurrentLanguageCode() + "/" + this.data.FrontEndRoute + "/" + this.editAction;
        },
        enumerable: false,
        configurable: true
    });
    FrontEndForm.prototype.scrollTop = function (elementToScroll, scrollToElement) {
        if (elementToScroll == undefined || scrollToElement == undefined)
            $.smoothScroll();
        else
            $(elementToScroll).animate({ scrollTop: $(scrollToElement).offset().top }, 500); //smoothscroll doesn't work correctly in modals
    };
    FrontEndForm.prototype.scrollCenter = function (selector) {
        var $el = $(selector);
        var elOffset = $el.offset().top;
        var elHeight = $el.height();
        var windowHeight = $(window).height();
        var offset;
        if (elHeight < windowHeight)
            offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
        else
            offset = elOffset;
        $.smoothScroll({ speed: 700 }, offset);
    };
    FrontEndForm.prototype.removeError = function (property) {
        var _this = this;
        setTimeout(function () {
            var full = property.replace(/\./g, '_');
            var forminputs = $('#' + full + ', .' + full);
            //property id has _ instead of .
            //var forminput = $('#' + property.replace(/\./g, '_'));
            _.each(forminputs, function (f) {
                var forminput = $(f);
                //select2 uses parent
                if (forminput.hasClass('select2-offscreen')) {
                    forminput = $('.select2-container', forminput.parent());
                }
                //remove error class to parent element
                var formGroup = forminput.closest('div');
                formGroup.removeClass('is-error');
                //remove popover element
                $('.form-control-error', formGroup).remove();
                //refresh tabs
                _super.prototype.refreshTabValidationBadges.call(_this);
                //handle span differently
                var isSpan = forminput.is('span');
                if (isSpan) {
                    forminput.text('');
                    forminput.removeClass('label label-danger');
                }
            });
        }, 500);
    };
    FrontEndForm.prototype.getVmProperty = function (property) {
        var propertyParts = property.split('.');
        var vmProperty = this.vm;
        for (var i = 0; i < propertyParts.length; i++) {
            vmProperty = vmProperty[propertyParts[i]];
        }
        return vmProperty;
    };
    FrontEndForm.prototype.formInputRemoveError = function (forminput, key) {
        if (forminput.hasClass('select2-offscreen')) {
            forminput = $('.select2-container', forminput.parent());
        }
        //remove error class to parent element
        var formGroup = forminput.closest('div');
        formGroup.removeClass('is-error');
        //remove popover element
        $('.form-control-error', formGroup).remove();
        //refresh tabs
        this.refreshTabValidationBadges();
        //handle span differently
        var isSpan = forminput.is('span');
        if (isSpan) {
            forminput.text('');
            forminput.removeClass('label label-danger');
        }
    };
    FrontEndForm.prototype.refreshStickySidebar = function (sidebarId, shouldReset, shouldDisable) {
        var stickySideBarDisabled = HGV.StickySidebar === undefined;
        if (stickySideBarDisabled)
            return;
        var sidebar = HGV.StickySidebar[sidebarId];
        if (shouldReset) {
            this.disableAllStickySidebars();
            sidebar.reset(shouldDisable);
        }
        else {
            sidebar.refresh();
        }
    };
    FrontEndForm.prototype.disableAllStickySidebars = function () {
        for (var key in HGV.StickySidebar) {
            if (HGV.StickySidebar.hasOwnProperty((key))) {
                HGV.StickySidebar[key].isDisabled = true;
            }
        }
    };
    FrontEndForm.prototype.bindingsApplied = function () {
        // This was initially thought to be a way to have an event called after knockout applied bindings.  Not reliable and different ways were used
        // to work around toolkit limitations
    };
    return FrontEndForm;
}(Form));
module.exports = FrontEndForm;

"use strict";
var PasswordComplexity = /** @class */ (function () {
    function PasswordComplexity() {
    }
    PasswordComplexity.MeetsMinimumLength = function (password) {
        return password !== null && password !== undefined && password.length >= 8;
    };
    PasswordComplexity.MeetsMaximumLength = function (password) {
        return password !== null && password !== undefined && password.length <= 64;
    };
    PasswordComplexity.HasUppercaseLetter = function (password) {
        return password !== null && password !== undefined && /[A-Z]/.test(password);
    };
    PasswordComplexity.HasNumberOrSpecialChar = function (password) {
        return (/[0-9]/.test(password)) || /[~`!#@$%\^&*+=\-\[\]\\';,\/{}|\\":<>\?]/.test(password); //special chars to test
    };
    return PasswordComplexity;
}());
module.exports = PasswordComplexity;

"use strict";
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var komapping = require("knockout.mapping");
ko.mapping = komapping;
var _ = require("lodash");
var mapper = require("./Mapper");
var Table = /** @class */ (function () {
    function Table(data, vm, pageInfoOverride, titleOverride) {
        if (pageInfoOverride === void 0) { pageInfoOverride = undefined; }
        if (titleOverride === void 0) { titleOverride = undefined; }
        this.data = data;
        this.vm = vm;
        this.pageInfoOverride = pageInfoOverride;
        this.titleOverride = titleOverride;
        this.pageInfo = this.pageInfoOverride || this.vm.PageInfo; // fix this before calling other function
        this.title = this.titleOverride || data.Title;
        this.oDataUrl = "/api/" + this.title;
        this.defaultSort = 'Id desc';
        this.orderby = this.defaultSort;
        this.sortBy = new Array();
        this.setupSubscriptions();
        this.setupBindings();
        this.jqXHR = null;
    }
    Object.defineProperty(Table.prototype, "skip", {
        get: function () {
            return (this.pageInfo.PageNumber() - 1) * this.top;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "top", {
        get: function () {
            return (this.pageInfo.PageSize());
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "tableSelector", {
        get: function () {
            return '.oo-table-' + this.title;
        },
        enumerable: false,
        configurable: true
    });
    //loads ajax data into table
    Table.prototype.loadResponse = function (responsedata) {
        var _this = this;
        //clear the list
        this.vm.List.removeAll();
        //add the new items
        _.each(responsedata.List, function (v) {
            _this.pushToList(v);
        });
        this.reloadPageInfo(responsedata);
        this.vm.IsLoading(false);
    };
    Table.prototype.reloadPageInfo = function (responsedata) {
        mapper.fromJsExisting(responsedata.PageInfo, this.pageInfo);
    };
    Table.prototype.pushToList = function (v) {
        this.vm.List.push(v);
    };
    //subscribes to observables for filtering
    Table.prototype.setupSubscriptions = function () {
        var _this = this;
        //page number
        this.pageInfo.PageNumber.subscribe(function () {
            _this.load();
        });
        //page size
        this.pageInfo.PageSize.subscribe(function (newValue) {
            _this.somethingChanged();
        });
        if (this.vm.Filters !== undefined && this.vm.Filters !== null && this.vm.Filters.SearchTerm !== undefined)
            this.vm.Filters.SearchTerm.extend({ rateLimit: 400, method: "notifyWhenChangesStop" });
        //filters values
        _.each(this.vm.Filters, function (v) {
            v.subscribe(function () {
                _this.somethingChanged();
            });
        });
    };
    Table.prototype.somethingChanged = function () {
        if (this.pageInfo.PageNumber() === 1)
            this.load();
        this.pageInfo.PageNumber(1);
    };
    Table.prototype.setupBindings = function () {
        //add click handlers to the column-headings
        var self = this;
        // click - column headers
        $(this.tableSelector).on('click', '.column-heading', function () {
            var data = ko.dataFor(this); //'this' is local
            var isUp = data.IsSortedUp();
            var isDown = data.IsSortedDown();
            //neither -> up
            if (!isUp && !isDown) {
                data.IsSortedUp(true);
                //add column asc to array + remove other variants of this column
                self.sortBy.push(data.SortColumn() + ' asc');
                self.sortBy = _.without(self.sortBy, data.SortColumn() + ' desc');
                self.sortBy = _.without(self.sortBy, self.defaultSort);
            }
            //up -> down
            else if (isUp) {
                data.IsSortedUp(false);
                data.IsSortedDown(true);
                //add column desc to array + remove other variants of this column
                self.sortBy.push(data.SortColumn() + ' desc');
                self.sortBy = _.without(self.sortBy, data.SortColumn() + ' asc');
                self.sortBy = _.without(self.sortBy, self.defaultSort);
            }
            //down -> neither
            else {
                data.IsSortedUp(false);
                data.IsSortedDown(false);
                //remove all variants of this column
                self.sortBy = _.without(self.sortBy, data.SortColumn() + ' desc');
                self.sortBy = _.without(self.sortBy, data.SortColumn() + ' asc');
                self.sortBy = _.without(self.sortBy, self.defaultSort);
            }
            //if no sort add default
            if (self.sortBy.length === 0)
                self.sortBy.push(self.defaultSort);
            self.orderby = self.sortBy.join(', ');
            self.load();
            return false;
        });
        // click - paging controls
        $(this.tableSelector).on('click', '.pagination a', function () {
            var $aLink = $(this);
            if ($aLink.parent().hasClass('disabled') === false) {
                var aText = $aLink.text().toLowerCase();
                var pageNumber = self.pageInfo.PageNumber();
                if (aText.indexOf('first') > -1) {
                    pageNumber = 1;
                }
                else if (aText.indexOf('prev') > -1 || $aLink.hasClass('page-prev')) {
                    pageNumber = pageNumber - 1;
                }
                else if (aText.indexOf('next') > -1 || $aLink.hasClass('page-next')) {
                    pageNumber = pageNumber + 1;
                }
                else if (aText.indexOf('last') > -1) {
                    pageNumber = self.pageInfo.PageCount();
                }
                else { //numbers
                    pageNumber = parseInt(aText);
                }
                self.pageInfo.PageNumber(pageNumber);
            }
            //self.load();
            return false;
        });
        // Search controls
        $("#search-list").keydown(function (event) {
            if (event.keyCode == 13) { //prevent enter key from submitting form
                event.preventDefault();
            }
        });
    };
    //clears sorts on all columns except ones named
    Table.prototype.clearSortColumns = function (dotNotClearColumnName) {
        _.each(this.vm.Columns(), function (v) {
            if (v.ColumnName() !== dotNotClearColumnName) {
                v.IsSortedUp(false);
                v.IsSortedDown(false);
            }
        });
    };
    //sends ajax call for data 
    Table.prototype.load = function () {
        var _this = this;
        this.vm.IsLoading(true);
        //cancel previous pending request
        if (this.jqXHR && this.jqXHR.state() === 'pending') {
            //Calls any error / fail callbacks of jqXHR
            this.jqXHR.abort();
        }
        //format the request
        var requestData = {
            $inlinecount: 'allpages',
            $orderby: this.orderby,
            $top: this.top,
            $skip: this.skip
        };
        //add in filters
        $.extend(requestData, ko.toJS(this.vm.Filters));
        //make the request
        var response = $.ajax({
            url: this.oDataUrl,
            dataType: 'json',
            cache: false,
            data: requestData
        });
        this.jqXHR = response;
        //handle successful response
        response.done(function (responsedata) {
            _this.loadResponse(responsedata);
        });
        return response;
    };
    return Table;
}());
module.exports = Table;

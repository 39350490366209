"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _ = require("lodash");
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var Cookies = require("js-cookie");
var WeatherApi = require("../Base/WeatherApi");
var DashboardForm = /** @class */ (function (_super) {
    __extends(DashboardForm, _super);
    function DashboardForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    DashboardForm.prototype.setupBindings = function () {
        _super.prototype.setupBindings.call(this);
        var self = this;
        //
        $('body').on('click', '.loan-hide', function () {
            var cookieKey = 'loan-closed-alerts';
            var data = ko.dataFor(this);
            _.each(self.vm.ItemDetail.Loans(), function (v) {
                if (v.ContractNo() === data.ContractNo()) {
                    v.ShowAsTopMessage(false);
                    //Set cookie
                    var name = cookieKey + '-' + v.ContractNo();
                    Cookies.set(name, 'Disable true', { expires: 31 }); //Cookie will expire in 31 days
                }
            });
        });
        $('body').on('click', '.review-hide', function () {
            var cookieKey = 'reservation-closed-alerts';
            var data = ko.dataFor(this);
            if (data.PendingResortReview === undefined) {
                self.vm.ShowReview(false);
                return;
            }
            if (data.PendingResortReview.ReservationNumber() === self.vm.PendingResortReview.ReservationNumber()) {
                self.vm.ShowReview(false);
                var name = cookieKey + '-' + self.vm.PendingResortReview.ReservationNumber();
                Cookies.set(name, 'Disable review reservation for ' + self.vm.PendingResortReview.ResortName(), { expires: 120 }); //Lets set expire so it disappears
            }
        });
        $('#add-profile-picture').on('click', function (e) {
            e.preventDefault();
            $("input[type=file]:hidden").trigger('click'); //trigger the click of the hidden input element
        });
    };
    DashboardForm.prototype.saveMemberProfilePicture = function (element, result) {
        //update the image to the new result
        var url = result.ImageSizes[0].Url;
        //save the new dto back to the server to save it for the current user
        this.editAction = "UpdateProfilePicture";
        var postData = mapper.toJs(result);
        var promise = this.post(postData);
        promise.done(function (data) {
            //refresh the details with updated information;
            $('.dashboard-photo').attr("src", url);
            $('#GlobalProfilePicture').attr("src", url);
        });
    };
    DashboardForm.prototype.getUpcomingVacationTemperature = function () {
        var _this = this;
        if (this.vm.ItemDetail.UpcomingReservation == null)
            return;
        var zipcode = this.vm.ItemDetail.UpcomingReservation.ResortZipCode();
        var countryCode = this.vm.ItemDetail.UpcomingReservation.ResortShortCountry();
        var weatherApi = new WeatherApi(this.vm.ItemDetail.GetWeatherUrl());
        var promise = weatherApi.getCurrentTemperature(zipcode, countryCode);
        promise.done(function (data) {
            if (data) {
                _this.vm.ItemDetail.VacationCurrentTemperature(data.Fahrenheit.toString());
            }
        });
    };
    DashboardForm.prototype.komappingfromdata = function (data, id) {
        mapper.fromJsToModel(data.ItemDetail.Loans, this.vm.ItemDetail.Loans);
        mapper.fromJsToModel(data.ItemDetail.FeesTaxes, this.vm.ItemDetail.FeesTaxes);
        mapper.fromJsToModel(data.ItemDetail.IsLocked, this.vm.ItemDetail.IsLocked);
        mapper.fromJsToModel(data.ItemDetail.HomeWeeks, this.vm.ItemDetail.HomeWeeks);
        mapper.fromJsToModel(data.ItemDetail.HomeResorts, this.vm.ItemDetail.HomeResorts);
        //Check if we need to show the warning for any loans!!
        var cookieKey = 'loan-closed-alerts';
        _.each(this.vm.ItemDetail.Loans(), function (v) {
            var name = cookieKey + '-' + v.ContractNo();
            var alertsCookie = Cookies.get(name);
            if (alertsCookie !== undefined) {
                v.ShowAsTopMessage(false);
            }
        });
    };
    DashboardForm.prototype.getPromiseDone = function (data, id) {
        this.komappingfromdata(data, id);
        this.vm.IsLoading(false);
        this.vm.FormVisible(true);
        this.vm.OwnershipLoading(false);
        /*
        if (this.vm.HelpOverlaysEnabled) {
            this.vm.initOverlay();
            this.vm.DisplayHelpOverlays();
        }
        */
    };
    DashboardForm.prototype.getDetail = function () {
        var _this = this;
        this.resetDetail();
        //$('#loading').height($(window).height());
        this.vm.OwnershipLoading(true);
        $('body').addClass('form-shown');
        var promise = $.ajax({
            url: this.getUrl,
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.fail(function (error) { return _this.getPromiseFail(error, null); });
        promise.done(function (data) { return _this.getPromiseDone(data, null); });
        return promise;
    };
    DashboardForm.prototype.missingDates = function () { };
    DashboardForm.prototype.refreshResortData = function () { };
    return DashboardForm;
}(FrontEndForm));
module.exports = DashboardForm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Router = require("../Base/Router");
var OwnershipRouter = /** @class */ (function (_super) {
    __extends(OwnershipRouter, _super);
    function OwnershipRouter(data, vm, alternateroutes) {
        var _this = _super.call(this, data, vm, alternateroutes) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    OwnershipRouter.prototype.getRoutes = function () {
        var self;
        self = this;
        return [
            {
                // No ID, default
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/index",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(false);
                    self.vm.NewForm("none");
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/hoa",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    //Set form
                    self.vm.NewForm("hoa");
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/loans",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("loans");
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/financial-services",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("financial-services");
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/financial-services/view-statements",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("view-statements");
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/financial-services/view-statements/maintenancefee-statements",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("maintenancefee-statements");
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/financial-services/view-statements/monthlyloan-statements",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("monthlyloan-statements");
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/financial-services/view-statements/yearendtax-statements",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("yearendtax-statements");
                    window.HGV.init();
                }
            }
        ];
    };
    return OwnershipRouter;
}(Router));
module.exports = OwnershipRouter;

"use strict";
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var _ = require("lodash");
var moment = require("moment");
var DayDto = /** @class */ (function () {
    function DayDto(date, calendarNumber, squareWeek, squareMonthNumber) {
        this.IsCheckOutOnly = ko.observable(false);
        this.IsSelected = ko.observable(false);
        this.Date = date;
        this.CalendarNumber = calendarNumber;
        this.WeekNumber = squareWeek;
        this.SquareMonthNumber = squareMonthNumber % 12;
        this.DayNumber = date.date();
        this.MonthNumber = date.month();
        var yesterday = moment().add(-1, 'day');
        this.IsExpired = date < yesterday;
        this.IsPlaceHolder = this.MonthNumber !== this.SquareMonthNumber;
        var momentDate = moment(this.Date);
        this.DateKey = momentDate.format('YYMMDD');
        this.YesterdayKey = momentDate.add(-1, 'day').format('YYMMDD');
    }
    return DayDto;
}());
var HgvDatePicker = /** @class */ (function () {
    function HgvDatePicker(_vm) {
        var _this = this;
        this.monthOffSet = ko.observable(0);
        this.checkInSelected = ko.observable(false);
        this.checkOutSelected = ko.observable(false);
        this.dateFormat = "MM/DD/YYYY";
        this.firstMonth = ko.computed(function () {
            return _this.getAllSquareMonthDates(_this.monthOffSet());
        });
        this.secondMonth = ko.computed(function () {
            return _this.getAllSquareMonthDates(_this.monthOffSet() + 1, 2);
        });
        this.closeDelay = 250;
        this.vm = _vm;
        this.addCalendarWeek(this.vm, 1, 1);
        this.addCalendarWeek(this.vm, 1, 2);
        this.addCalendarWeek(this.vm, 1, 3);
        this.addCalendarWeek(this.vm, 1, 4);
        this.addCalendarWeek(this.vm, 1, 5);
        this.addCalendarWeek(this.vm, 1, 6);
        this.addCalendarWeek(this.vm, 2, 1);
        this.addCalendarWeek(this.vm, 2, 2);
        this.addCalendarWeek(this.vm, 2, 3);
        this.addCalendarWeek(this.vm, 2, 4);
        this.addCalendarWeek(this.vm, 2, 5);
        this.addCalendarWeek(this.vm, 2, 6);
        //only show the 6th week if needed
        this.vm.ShowWeek6 = ko.computed(function () {
            return _.some(_this.vm['CalendarWeek6'](), function (day) {
                return day.MonthNumber !== day.SquareMonthNumber;
            });
            ;
        });
        //only show the 12th week if needed
        this.vm.ShowWeek12 = ko.computed(function () {
            return _.some(_this.vm['CalendarWeek12'](), function (day) {
                return day.MonthNumber !== day.SquareMonthNumber;
            });
            ;
        });
        this.HoverDate = ko.observable(null);
        this.Calendar1Display = ko.computed(function () {
            var startOfMonth = moment().startOf('month');
            var offsetMonth = startOfMonth.add(_this.monthOffSet(), 'months');
            return _this.getMonthString(_this.vm, offsetMonth.month()) + ' ' + offsetMonth.year();
        });
        this.Calendar2Display = ko.computed(function () {
            var startOfMonth = moment().startOf('month');
            var offsetMonth = startOfMonth.add(_this.monthOffSet() + 1, 'months');
            return _this.getMonthString(_this.vm, offsetMonth.month()) + ' ' + offsetMonth.year();
        });
    }
    HgvDatePicker.prototype.setMonthOffset = function (date, shouldRefreshUnits) {
        var startOfSelectedMonth = moment.tz(date, moment.tz.guess());
        var startOfCurrentMonth = moment().startOf('month');
        var offset = startOfSelectedMonth.diff(startOfCurrentMonth, 'months');
        this.monthOffSet(offset);
        this.refreshSelectedDate(this.vm, shouldRefreshUnits);
    };
    HgvDatePicker.prototype.getMonthString = function (vm, value) {
        var month = new Array();
        month[0] = vm.getGlobalContentBlock("January", "Months");
        month[1] = vm.getGlobalContentBlock("February", "Months");
        month[2] = vm.getGlobalContentBlock("March", "Months");
        month[3] = vm.getGlobalContentBlock("April", "Months");
        month[4] = vm.getGlobalContentBlock("May", "Months");
        month[5] = vm.getGlobalContentBlock("June", "Months");
        month[6] = vm.getGlobalContentBlock("July", "Months");
        month[7] = vm.getGlobalContentBlock("August", "Months");
        month[8] = vm.getGlobalContentBlock("September", "Months");
        month[9] = vm.getGlobalContentBlock("October", "Months");
        month[10] = vm.getGlobalContentBlock("November", "Months");
        month[11] = vm.getGlobalContentBlock("December", "Months");
        return month[value];
    };
    //this will get the first and last days of a month needed to fit squarely on a calendar
    //offset gets added to current month
    HgvDatePicker.prototype.getSquareMonthStartEndDates = function (monthOffset) {
        if (monthOffset === void 0) { monthOffset = 0; }
        var adjustedMonth = moment().add(monthOffset, 'months');
        //get the first to use making sure it is a sunday
        var firstDateOfMonth = adjustedMonth.clone().startOf('month');
        var firstSquareDayOfMonth = firstDateOfMonth.startOf('week');
        //get the last to use making sure it is a saturday
        var lastDateOfMonth = adjustedMonth.clone().endOf('month');
        var lastSquareDayOfMonth = lastDateOfMonth.endOf('week');
        //gives the days in number between 2 dates including end date
        var numberOfDaysTotal = lastSquareDayOfMonth.diff(firstSquareDayOfMonth, 'days') + 1;
        if (numberOfDaysTotal < 42) {
            lastSquareDayOfMonth = lastSquareDayOfMonth.add(42 - numberOfDaysTotal, 'days');
        }
        return { Start: firstSquareDayOfMonth, End: lastSquareDayOfMonth };
    };
    HgvDatePicker.prototype.getAllSquareMonthDates = function (offset, calendarNumber) {
        if (offset === void 0) { offset = 0; }
        if (calendarNumber === void 0) { calendarNumber = 1; }
        var month1Dates = this.getSquareMonthStartEndDates(offset);
        var listOfDates = new Array();
        var dateCounter = month1Dates.Start;
        var week1DayCounter = 0;
        var week = 1;
        while (dateCounter <= month1Dates.End) {
            //calculate week
            if (week1DayCounter < 7) {
                week1DayCounter = week1DayCounter + 1;
            }
            else {
                week1DayCounter = 1;
                week = week + 1;
            }
            //new up the DayDto passing in date, calendar, week and current month
            var thisMonth = moment().month() + offset;
            var dateDto = new DayDto(dateCounter, calendarNumber, week, thisMonth);
            var container = { self: this, date: dateDto };
            dateDto.IsAvailable = ko.pureComputed(function () {
                this.self.vm.AvailabilityCacheUpdate();
                var result = false;
                // The following logic is only relevant to the 'Resort' page yet this picker is used (now) on all pages.
                if (this.self.vm !== undefined) {
                    var selectedUnit = this.self.vm.SelectedUnit();
                    if (selectedUnit !== null) {
                        //check if its in the cache
                        var keyWithoutDate = selectedUnit.AvailabilityProjectId() + '-' + selectedUnit.UnitId() + '-';
                        var key = this.self.vm.addUnitNumberToCacheKey(keyWithoutDate + this.date.DateKey, selectedUnit);
                        result = this.self.vm.getAvailabilityCache(key) !== undefined;
                        //if not check if yesterday was available 
                        //if it is then it is checkout only
                        if (result === false) {
                            var yesterdayKey = this.self.vm.addUnitNumberToCacheKey(keyWithoutDate + this.date.YesterdayKey, selectedUnit);
                            this.date.IsCheckOutOnly(this.self.vm.getAvailabilityCache(yesterdayKey) !== undefined);
                        }
                        else {
                            this.date.IsCheckOutOnly(false);
                        }
                    }
                    else {
                        var keyWithoutDate2 = this.self.vm.ItemDetail.ProjectNumber() + '-';
                        var key2 = keyWithoutDate2 + this.date.DateKey;
                        result = this.self.vm.getAvailabilityCache(key2) !== undefined;
                        if (result === false) {
                            var yesterdayKey2 = keyWithoutDate2 + this.date.YesterdayKey;
                            this.date.IsCheckOutOnly(this.self.vm.getAvailabilityCache(yesterdayKey2) !== undefined);
                        }
                        else {
                            this.date.IsCheckOutOnly(false);
                        }
                    }
                }
                return result;
            }, container);
            dateDto.IsBetween = ko.pureComputed(function () {
                //Disabled code allows stretching the selection into the past as well, but it looks goofy
                //var startDate = (this.self.checkInSelected() && this.self.HoverDate())
                //    ? this.self.HoverDate()
                //    : moment(this.self.vm.Filters.CheckIn(), this.self.vm.dateFormat);
                var startDate = moment(this.self.vm.Filters.CheckIn(), this.self.vm.dateFormat);
                var endDate = (this.self.checkOutSelected() && this.self.HoverDate())
                    ? this.self.HoverDate()
                    : moment(this.self.vm.Filters.CheckOut(), this.self.vm.dateFormat);
                var result = (!this.date.IsPlaceHolder && !this.date.IsExpired && this.date.Date >= startDate && this.date.Date <= endDate);
                return result;
            }, container);
            dateDto.IsInvalid = ko.pureComputed(function () {
                if (!this.date.IsBetween())
                    return false;
                //Disabled code allows stretching the selection into the past as well, but it looks goofy
                //var startDate = (this.self.checkInSelected() && this.self.HoverDate())
                //    ? this.self.HoverDate()
                //    : moment(this.self.vm.Filters.CheckIn(), this.self.vm.dateFormat);
                var startDate = moment(this.self.vm.Filters.CheckIn(), this.self.vm.dateFormat);
                var endDate = (this.self.checkOutSelected() && this.self.HoverDate())
                    ? this.self.HoverDate()
                    : moment(this.self.vm.Filters.CheckOut(), this.self.vm.dateFormat);
                var days = this.self.firstMonth().concat(this.self.secondMonth());
                var daysInRange = days.filter(function (x) { return !x.IsPlaceholder && startDate != null && endDate != null && x.Date >= startDate && x.Date <= endDate; });
                var isSelectedRangeValid = !this.self.vm.UnitSelected() || daysInRange.length > 0 && daysInRange.every(function (x) { return x.IsAvailable() || (x.IsCheckOutOnly() && x.Date.isSame(endDate)); });
                //console.log(moment().format("h:mm:ss:SSS"), this.date.CalendarNumber, this.date.DateKey, isSelectedRangeValid);
                var result = !isSelectedRangeValid;
                return result;
            }, container);
            listOfDates.push(dateDto);
            //increment counter by 1 day
            dateCounter = dateCounter.clone().add(1, 'days');
        }
        return listOfDates;
    };
    //adds the computed weeks to bind to
    HgvDatePicker.prototype.addCalendarWeek = function (vm, calendar, week) {
        //calendar is 1 for the first month shown and 2 for the 2nd month shown
        var propertyName = "CalendarWeek" + (week + ((calendar - 1) * 6)).toString();
        var datesToCheck = calendar === 1 ? this.firstMonth : this.secondMonth;
        vm[propertyName] = ko.computed(function () {
            //filter to be only the days in the week we care about
            return _.filter(datesToCheck(), function (date) {
                return date.CalendarNumber === calendar
                    && (date.WeekNumber === week);
            });
        });
    };
    HgvDatePicker.prototype.setupCalendarBindings = function (vm, formId) {
        var _this = this;
        var self = this;
        $("#checkInDate").mask("99/99/9999", { placeholder: this.dateFormat });
        $("#checkOutDate").mask("99/99/9999", { placeholder: this.dateFormat });
        $(".datepicker-control").mask("99/99/9999", { placeholder: this.dateFormat });
        //focus a checkin / checkout
        $('body').on('focus', '#' + formId + ' #checkInDate, #' + formId + ' #checkOutDate', function (e) {
            if (window.navigator.msMaxTouchPoints || 'ontouchstart' in document) {
                $(this).blur(); //if they are on a mobile device blur so that the keyboard doesn't pop up
            }
            vm[formId + 'Shown'](true);
        });
        //clicking a checkin / checkout input to show calendar
        $('body').on('click', '#' + formId + ' #checkInDate, #' + formId + ' #checkOutDate', function (e) {
            if (window.navigator.msMaxTouchPoints || 'ontouchstart' in document) {
                $(this).blur(); //if they are on a mobile device blur so that the keyboard doesn't pop up
            }
            else {
                $(this).select();
            }
            vm[formId + 'Shown'](true);
        });
        //bluring a checkin / checkout
        $('body').on('blur', '#' + formId + ' #checkInDate, #' + formId + ' #checkOutDate', function (e) {
            var myFormId = formId;
            // Delay processing of the blur so that ClearDates has enough time to be noticed before potentially closing the form
            setTimeout(function () {
                var activeFocus = $(':focus');
                if (activeFocus.hasClass('clear-dates') || activeFocus.hasClass('calendar-prev') || activeFocus.hasClass('calendar-next'))
                    return;
                var $selectedElement = $(e.target);
                var selectedValue = $selectedElement.val();
                var isCheckIn = $selectedElement.attr('id') === 'checkInDate';
                //This does work, need to figure out how to reset and set  date.
                var yesterday = moment().add(-1, 'day');
                var maxDate = moment().add(1, 'year');
                var date = moment(selectedValue, self.dateFormat);
                //window.console.log(date);
                var validDate = (date.isValid() && date.isAfter(yesterday) && date.isBefore(maxDate));
                if (validDate) {
                    if (isCheckIn) {
                        self.checkinDateDisplay = date.format(self.dateFormat);
                        //Set right month in datepicker.
                        var month = ((date.year() - moment().year()) * 12) + date.month() - moment().month();
                        //window.console.log(self.monthOffSet() + ' --- ' + month);
                        if (self.monthOffSet() !== month && (self.monthOffSet() + 1) !== month && month >= 0) {
                            self.monthOffSet(month);
                        }
                        if (self.checkoutDateDisplay != null && date.isAfter(self.checkoutDateDisplay)) {
                            self.vm.Filters.CheckOut(null);
                            self.checkoutDateDisplay = null;
                        }
                    }
                    else {
                        if (self.checkinDateDisplay != null && date.isBefore(self.checkinDateDisplay)) {
                            validDate = false;
                        }
                        else {
                            self.checkoutDateDisplay = date.format(self.dateFormat);
                            if ($selectedElement.attr('Id') !== 'checkInDate' && $selectedElement.attr('Id') !== 'checkOutDate' && !$selectedElement.hasClass('calendar-prev') && !$selectedElement.hasClass('calendar-next')) {
                                vm[myFormId + 'Shown'](false);
                            }
                        }
                    }
                }
                else {
                    if (selectedValue === self.dateFormat || selectedValue === "") {
                        if (isCheckIn) {
                            self.vm.Filters.CheckIn(null);
                            self.checkinDateDisplay = null;
                        }
                        else {
                            self.vm.Filters.CheckOut(null);
                            self.checkoutDateDisplay = null;
                        }
                        //Its a reset of date.
                        validDate = true;
                    }
                }
                if (!validDate) {
                    if (isCheckIn) {
                        self.vm.Filters.CheckIn(self.checkinDateDisplay != null ? self.checkinDateDisplay : null);
                    }
                    else {
                        self.vm.Filters.CheckOut(self.checkoutDateDisplay != null ? self.checkoutDateDisplay : null);
                    }
                }
                else {
                    self.refreshSelectedDate(vm);
                    self.vm.refreshAllUnits();
                }
                //if (pickerShown && !targetingOtherDate) {
                //    $(this).focus();
                //}
            }, 100);
        });
        //clicking clear dates
        $('#' + formId).on('click', '.clear-dates', function (e) {
            e.preventDefault();
            var myFormId = formId;
            if (myFormId == "SubmitRequestDatePicker") {
                console.log($('#' + myFormId + ' #checkInDate'));
                $('#' + myFormId + ' #checkInDate').val(null);
                $('#' + myFormId + ' #checkOutDate').val(null);
                $('#checkOutDateBooking').val(null);
                $('#checkInDateBooking').val(null);
            }
            vm.Filters.CheckIn(null);
            vm.Filters.CheckOut(null);
            self.setMonthOffset(moment().format(self.dateFormat));
            self.refreshSelectedDate(vm);
            vm.refreshAllUnits();
            $('#' + myFormId + ' #checkInDate').focus();
            return false;
        });
        //Because of firefox there is no good way to check which one was selected when they click on the datepicker day
        $('#checkInDate, #checkOutDate').focus(function (e) {
            var isCheckIn = this.id === 'checkInDate';
            self.checkInSelected(isCheckIn);
            self.checkOutSelected(!isCheckIn);
        });
        //clicking a day
        $('body').on('mousedown', '#' + formId + ' .datepicker__day', function (e) {
            e.preventDefault();
            var myFormId = formId;
            var data = ko.dataFor(this); //local this
            //the sticky date picker cannot have unavailable days selected
            //(ie you can't select a day if there is no availability)
            var restricted = myFormId === 'StickyDatePicker' && self.vm.UnitSelected();
            var date = moment(data.Date).format(self.dateFormat);
            var checkInDateSet = self.vm.Filters.CheckIn() !== null;
            var checkOutDateControl = $('#' + formId + ' #checkOutDate');
            //if they havent picked a check in date or the are focussed on it
            //and it is available or not restricted
            if ((self.checkInSelected() || !checkInDateSet) && !data.IsExpired && (data.IsAvailable() || !restricted)) {
                self.vm.Filters.CheckIn(date);
                self.checkinDateDisplay = date;
                //What if checkout date is before checkin date!
                var checkOut = self.vm.Filters.CheckOut() ? moment(self.vm.Filters.CheckOut(), self.vm.dateFormat) : null;
                if (checkOut == null || checkOut < data.Date) {
                    self.vm.Filters.CheckOut(null);
                    self.checkoutDateDisplay = null;
                    checkOutDateControl.val(self.dateFormat);
                }
                checkOutDateControl.focus();
            }
            //else we are choosing the checkout date
            //we can also choose checkoutonly dates (ie the day earlier was available for checkin so it is a valid checkout date)
            else if (!data.IsExpired && (data.IsAvailable() || data.IsCheckOutOnly() || !restricted)) {
                self.vm.Filters.CheckOut(date);
                self.checkoutDateDisplay = date;
                //What if checkout date is before checkin date!
                var checkIn = self.vm.Filters.CheckIn() ? moment(self.vm.Filters.CheckIn(), self.vm.dateFormat) : null;
                if (checkIn == null || checkIn >= data.Date) {
                    self.vm.Filters.CheckIn(date);
                    self.vm.Filters.CheckOut(null);
                    self.checkoutDateDisplay = null;
                    checkOutDateControl.val(self.dateFormat);
                }
                else {
                    setTimeout(function () { self.vm[myFormId + 'Shown'](false); }, self.closeDelay);
                }
            }
            self.refreshSelectedDate(vm);
            self.vm.refreshAllUnits();
        });
        $('body').on('mouseenter', '#' + formId + ' .datepicker__day', function (e) {
            e.preventDefault();
            var date = ko.dataFor(this).Date;
            if (!date.IsPlaceHolder && !date.IsExpired) {
                //console.log("day mouseEnter", date.format("YYMMDD"));
                self.HoverDate(date);
            }
        });
        $('body').on('mouseleave', '#' + formId + '.datepicker__day', function () {
            //console.log("day mouseLeave", ko.dataFor(this).Date.format("YYMMDD"));
            self.HoverDate(null);
        });
        //clicking next month
        $('body').on('click', '#' + formId + ' .calendar-next', function (e) {
            _this.monthOffSet(_this.monthOffSet() + 1);
            self.refreshSelectedDate(vm);
        });
        //clicking previous month
        $('body').on('click', '#' + formId + ' .calendar-prev', function (e) {
            _this.monthOffSet(_this.monthOffSet() - 1);
            self.refreshSelectedDate(vm);
        });
        //clicking outside the cal
        $('body').on('click', function (e) {
            var myFormId = formId;
            if (_this.vm.IsLoading() === false) {
                if ($(e.target).closest('.date-range-picker').length === 0 && $(e.target).hasClass('browse-dates') === false) {
                    vm[myFormId + 'Shown'](false);
                }
            }
        });
    };
    HgvDatePicker.prototype.resetPickerDates = function (vm) {
        this.monthOffSet(0);
        vm.Filters.CheckIn(null);
        vm.Filters.CheckOut(null);
        this.refreshSelectedDate(vm);
        vm.refreshAllUnits();
    };
    HgvDatePicker.prototype.setupHomeWeekCalendarBindings = function (vm, formId) {
        var _this = this;
        var self = this;
        //clicking clear dates
        $('#' + formId).on('click', '.clear-dates', function (e) {
            e.preventDefault();
            var myFormId = formId;
            $('#' + myFormId + ' #checkInDate').focus();
            self.resetPickerDates(vm);
            return false;
        });
        //clicking a day
        $('#' + formId).on('click', '.datepicker__day', function (e) {
            var data = ko.dataFor(this); //local this
            if (!data.IsAvailable() && !data.IsCheckOutOnly())
                return;
            var homeWeekStartDayWeekIndex = self.vm.ItemDetail.HomeWeekStartDayIndex();
            var numberOfNights = self.vm.ItemDetail.HomeWeekNumberOfNights();
            var selectedDay = moment(data.Date);
            var selectedWeekDayIndex = selectedDay.day();
            var isCheckOutOnly = data.IsCheckOutOnly();
            var checkinDate;
            if (selectedWeekDayIndex >= homeWeekStartDayWeekIndex && !isCheckOutOnly) { //if is a checkout only day get the prior week instead
                checkinDate = moment(selectedDay).day(homeWeekStartDayWeekIndex);
            }
            else {
                checkinDate = moment(selectedDay).day(homeWeekStartDayWeekIndex - 7); //get the selected dayindex from the prior week
            }
            var checkoutDate = checkinDate.clone().add(numberOfNights, 'days');
            vm.refreshHomeWeekDateSelection(checkinDate, checkoutDate);
        });
        //clicking next month
        $('#' + formId).on('click', '.calendar-next', function (e) {
            _this.monthOffSet(_this.monthOffSet() + 1);
            self.refreshSelectedDate(vm);
        });
        //clicking previous month
        $('#' + formId).on('click', '.calendar-prev', function (e) {
            _this.monthOffSet(_this.monthOffSet() - 1);
            self.refreshSelectedDate(vm);
        });
    };
    //dates checkin, checkout and in between should show as selected
    HgvDatePicker.prototype.refreshSelectedDate = function (vm, shouldRefreshUnits) {
        var _this = this;
        if (this.vm.IsDetail() || this.vm.IsHomeWeek()) {
            var needsRefresh = this.vm.form.missingDates();
            if (needsRefresh) {
                var promise = this.vm.getMoreDates([this.vm.ItemDetail.VersionId()]);
                if (shouldRefreshUnits && promise) {
                    promise.done(function () {
                        _this.vm.refreshAllUnits(true);
                    });
                }
            }
            else if (shouldRefreshUnits) {
                this.vm.refreshAllUnits(true);
            }
        }
        else {
            var missingIds = this.vm.table.getIdsMissingDates();
            var listNeedsRefresh = missingIds.length > 0;
            if (listNeedsRefresh) {
                this.vm.getMoreDates(missingIds);
            }
        }
        if (this.secondMonth()[41])
            _.each(this.firstMonth(), function (date) {
                _this.updateDate(date, vm);
            });
        _.each(this.secondMonth(), function (date) {
            _this.updateDate(date, vm);
        });
        this.refreshArrows(vm);
    };
    HgvDatePicker.prototype.refreshArrows = function (vm) {
        var secondMonthVisible = $('#month2').is(':visible');
        var dates = secondMonthVisible
            ? this.secondMonth().filter(function (x) { return x.IsPlaceHolder === false; })
            : this.firstMonth().filter(function (x) { return x.IsPlaceHolder === false; });
        var lastDateShown = dates[dates.length - 1].Date;
        var lastDateToShow = moment().add(1, 'years');
        if (lastDateShown > lastDateToShow) {
            $('.calendar-next').hide();
        }
        else {
            $('.calendar-next').show();
        }
        if (this.monthOffSet() <= -1) {
            $('.calendar-prev').hide();
        }
        else {
            $('.calendar-prev').show();
        }
    };
    HgvDatePicker.prototype.updateDate = function (date, vm) {
        var checkIn = vm.Filters.CheckIn() ? moment(vm.Filters.CheckIn(), vm.dateFormat) : null;
        var checkOut = vm.Filters.CheckOut() ? moment(vm.Filters.CheckOut(), vm.dateFormat) : null;
        var dateTime = date.Date;
        var selected = false;
        if (checkOut == null && checkIn == null) {
            selected = false;
        }
        else if (checkOut != null && checkIn == null) {
            selected = Number(dateTime) === Number(checkOut);
        }
        else if (checkOut == null) {
            selected = Number(dateTime) === Number(checkIn);
        }
        else {
            selected = dateTime >= checkIn && dateTime <= checkOut;
        }
        date.IsSelected(selected);
    };
    return HgvDatePicker;
}());
module.exports = HgvDatePicker;

"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: Dashboard
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var Cookies = require("js-cookie");
var base = require("../Base/ResortSearchVMBase");
var DashboardForm = require("./DashboardForm");
var DashboardRouter = require("./DashboardRouter");
var DashboardTable = require("./DashboardTable");
var clientPagination = require("../Base/ClientSidePagination");
var Dashboard = /** @class */ (function (_super) {
    __extends(Dashboard, _super);
    function Dashboard(data) {
        var _a;
        var _this = 
        //call base class constructor
        _super.call(this, data, DashboardForm, DashboardTable, DashboardRouter) || this;
        _this.data = data;
        _this.UserClubPoints = ko.computed(function () {
            return _this.filterPointsByType(_this.GlobalNavigation.UserPoints.ClubPoints(), _this.ItemDetail.PointSummary.CurrentSelectedYear());
        });
        _this.UserBhcPoints = ko.computed(function () {
            return _this.filterPointsByType(_this.GlobalNavigation.UserPoints.BhcPoints(), _this.ItemDetail.PointSummary.CurrentSelectedYear());
        });
        _this.TotalBhcPoints = ko.computed(function () {
            var bhcpoints = _this.filterPointsByType(_this.GlobalNavigation.UserPoints.BhcPoints(), _this.getAllText());
            var selectedAvailBhc = ko.utils.arrayMap(bhcpoints, function (pointsYear) {
                return pointsYear.Points();
            });
            var bhcSum = _.reduce(selectedAvailBhc, function (bhcSum, points) {
                return bhcSum + points;
            });
            return bhcSum;
        });
        _this.TotalClubPoints = ko.computed(function () {
            var clubpoints = _this.filterPointsByType(_this.GlobalNavigation.UserPoints.ClubPoints(), _this.getAllText());
            var selectedAvailBhc = ko.utils.arrayMap(clubpoints, function (pointsYear) {
                return pointsYear.Points();
            });
            var clubSum = _.reduce(selectedAvailBhc, function (clubSum, points) {
                return clubSum + points;
            });
            return clubSum;
        });
        _this.UserTotalAvailablePoints = ko.computed(function () {
            var selectedAvailClub = ko.utils.arrayMap(_this.UserClubPoints(), function (pointsYear) {
                return pointsYear.Points();
            });
            var selectedAvailBhc = ko.utils.arrayMap(_this.UserBhcPoints(), function (pointsYear) {
                return pointsYear.Points();
            });
            var clubSum = _.reduce(selectedAvailClub, function (clubSum, points) {
                return clubSum + points;
            });
            var bhcSum = _.reduce(selectedAvailBhc, function (bhcSum, points) {
                return bhcSum + points;
            });
            if (clubSum === undefined) {
                clubSum = 0;
            }
            if (bhcSum === undefined) {
                bhcSum = 0;
            }
            return clubSum + bhcSum;
        });
        if (!(window.navigator.msMaxTouchPoints) && !('ontouchstart' in document)) {
            $('html').toggleClass('is-searching', true);
        }
        _this.ItemDetail.ShowFeesTaxes = ko.computed(function () {
            if (_this.ItemDetail.FeesTaxes().length > 0) {
                return ko.unwrap(_this.ItemDetail.FeesTaxes).reduce(function (accum, curr) {
                    return accum + (ko.unwrap(curr.AmountDue) || 0);
                }, 0) > 0;
            }
            return false;
        });
        _this.FirstFeesTaxesWithPayment = ko.computed(function () {
            var firstFee = null;
            if (_this.ItemDetail.FeesTaxes().length > 0) {
                _this.ItemDetail.FeesTaxes().some(function (element) {
                    if (element.AmountDue() > 0) {
                        firstFee = element;
                        return element;
                    }
                });
            }
            return firstFee;
        });
        _this.FirstLoanWithPayment = ko.computed(function () {
            var firstLoan = null;
            if (_this.ItemDetail.Loans().length > 0) {
                _this.ItemDetail.Loans().some(function (element) {
                    if (element.AmountDue() > 0) {
                        firstLoan = element;
                        return element;
                    }
                });
            }
            return firstLoan;
        });
        _this.ItemDetail.VacationCurrentTemperature = ko.observable(null);
        _this.PaginatedHomeWeeks = new clientPagination(_this.ItemDetail.HomeWeeks, 10, '.paged-home-weeks');
        _this.PaginatedHomeResorts = new clientPagination(_this.ItemDetail.HomeResorts, 10, '.paged-home-resorts');
        ko.applyBindings(_this);
        $('.hideUnbound').removeClass('hideUnbound');
        _super.prototype.initOverlayValues.call(_this);
        _this.getBrowserVersion();
        _this.picker.setupCalendarBindings(_this, 'TopDatePicker');
        _this.form.getDetail();
        _this.form.getUpcomingVacationTemperature();
        _this.UnitSelected = ko.computed(function () { return _this.SelectedUnit() != null; });
        var postlogincookie = Cookies.get("post-login-dashboard-indentify");
        if (postlogincookie === 'true') {
            var postTo = "/" + _this.CurrentLanguageCode() + "/address/GetAddressInfoByLeadId?LeadID=" + ((_a = _this.User) === null || _a === void 0 ? void 0 : _a.ULeadId());
            var promise = $.ajax({
                url: postTo,
                type: 'GET',
                cache: false,
                contentType: 'application/json;charset=utf-8',
            });
            promise.done(function (data) {
                if (data != null) {
                    _this.MembershipAddress = JSON.stringify(data);
                }
                _this.identifyPostlogin();
            });
            promise.fail(function (error) {
                console.log(error.statusText);
            });
        }
        $('#home-week-link').click(function () {
            _this.scrollToHomeWeeks();
            return false;
        });
        return _this;
    }
    Dashboard.prototype.identifyPostlogin = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28;
        var memeberId = (_b = (_a = this.User) === null || _a === void 0 ? void 0 : _a.MemberId()) !== null && _b !== void 0 ? _b : "";
        var memberShipId = (_d = (_c = this.User) === null || _c === void 0 ? void 0 : _c.TrustCustomerNumber()) !== null && _d !== void 0 ? _d : "";
        var databaseArea = (_f = (_e = this.User) === null || _e === void 0 ? void 0 : _e.DatabaseArea()) !== null && _f !== void 0 ? _f : "";
        var leadNumber = (_h = (_g = this.User) === null || _g === void 0 ? void 0 : _g.LeadNumber()) !== null && _h !== void 0 ? _h : "";
        var first_Name1 = (_k = (_j = this.User) === null || _j === void 0 ? void 0 : _j.FirstName()) !== null && _k !== void 0 ? _k : "";
        var last_Name1 = (_m = (_l = this.User) === null || _l === void 0 ? void 0 : _l.LastName()) !== null && _m !== void 0 ? _m : "";
        var userName = (_p = (_o = this.User) === null || _o === void 0 ? void 0 : _o.UserName()) !== null && _p !== void 0 ? _p : "";
        var email = (_r = (_q = this.User) === null || _q === void 0 ? void 0 : _q.Email()) !== null && _r !== void 0 ? _r : "";
        var clubMemberType = (_t = (_s = this.User) === null || _s === void 0 ? void 0 : _s.ClubMemberType()) !== null && _t !== void 0 ? _t : "";
        var clubMemberTypeId = (_v = (_u = this.User) === null || _u === void 0 ? void 0 : _u.ClubMemberTypeId()) !== null && _v !== void 0 ? _v : "";
        var trustMemberContextId = "";
        var doubleLeadArea = "";
        var doubleLeadId = "";
        var secondary_email = "";
        var first_Name2 = "";
        var last_Name2 = "";
        var address = JSON.parse(this.MembershipAddress);
        var honors_number = "";
        var hilton_honors_tier = "";
        var hilton_honors_points = "";
        var ClubPointsObject = {};
        var clubPoints = this.GlobalNavigation.UserPoints.ClubPoints();
        if (this.GlobalNavigation.UserPoints.HasClubPointBuckets && clubPoints.length > 0) {
            for (var i = 0; i < clubPoints.length; i++) {
                if (clubPoints[i].IsRescuePoints() || clubPoints[i].IsRemainingPoints()) {
                    if (clubPoints[i].Year() !== 0) {
                        var key = 'Points_' + clubPoints[i].Year() + '_Year';
                        if (ClubPointsObject.hasOwnProperty(key)) {
                            ClubPointsObject[key] = clubPoints[i].Points() + ClubPointsObject[key];
                        }
                        else {
                            ClubPointsObject[key] = clubPoints[i].Points();
                        }
                    }
                }
            }
        }
        if (typeof this.User.TrustMemberContext == "object") {
            trustMemberContextId = (_y = (_x = (_w = this.User) === null || _w === void 0 ? void 0 : _w.TrustMemberContext) === null || _x === void 0 ? void 0 : _x.LeadId()) !== null && _y !== void 0 ? _y : "";
            doubleLeadArea = (_1 = (_0 = (_z = this.User) === null || _z === void 0 ? void 0 : _z.TrustMemberContext) === null || _0 === void 0 ? void 0 : _0.DoubleLeadArea()) !== null && _1 !== void 0 ? _1 : "";
            doubleLeadId = (_4 = (_3 = (_2 = this.User) === null || _2 === void 0 ? void 0 : _2.TrustMemberContext) === null || _3 === void 0 ? void 0 : _3.DoubleLeadId()) !== null && _4 !== void 0 ? _4 : "";
            secondary_email = (_7 = (_6 = (_5 = this.User) === null || _5 === void 0 ? void 0 : _5.TrustMemberContext) === null || _6 === void 0 ? void 0 : _6.SecondaryEmailAddress()) !== null && _7 !== void 0 ? _7 : "";
            first_Name2 = (_10 = (_9 = (_8 = this.User) === null || _8 === void 0 ? void 0 : _8.TrustMemberContext) === null || _9 === void 0 ? void 0 : _9.SecondFirstName()) !== null && _10 !== void 0 ? _10 : "";
            last_Name2 = (_13 = (_12 = (_11 = this.User) === null || _11 === void 0 ? void 0 : _11.TrustMemberContext) === null || _12 === void 0 ? void 0 : _12.SecondLastName()) !== null && _13 !== void 0 ? _13 : "";
            honors_number = (_16 = (_15 = (_14 = this.User) === null || _14 === void 0 ? void 0 : _14.TrustMemberContext) === null || _15 === void 0 ? void 0 : _15.HonorsNumber()) !== null && _16 !== void 0 ? _16 : "";
        }
        //this.GlobalNavigation.UserPoints
        if (typeof this.HHonorsInfo == "object") {
            hilton_honors_tier = (_18 = (_17 = this.HHonorsInfo) === null || _17 === void 0 ? void 0 : _17.Tier()) !== null && _18 !== void 0 ? _18 : "";
            hilton_honors_points = (_20 = (_19 = this.HHonorsInfo) === null || _19 === void 0 ? void 0 : _19.PointBalance()) !== null && _20 !== void 0 ? _20 : "";
        }
        this.Analytics.identify({
            u_lead_id: (_22 = (_21 = this.User) === null || _21 === void 0 ? void 0 : _21.ULeadId()) !== null && _22 !== void 0 ? _22 : "",
            lead_id: (_24 = (_23 = this.User) === null || _23 === void 0 ? void 0 : _23.LeadId()) !== null && _24 !== void 0 ? _24 : "",
            trust_member_context_lead_id: trustMemberContextId,
            double_lead_area: doubleLeadArea,
            double_lead_id: doubleLeadId,
            member_id: memeberId,
            membership_id: memberShipId,
            database_area: databaseArea,
            lead_number: leadNumber,
            first_name_1: first_Name1,
            last_name_1: last_Name1,
            first_name_2: first_Name2,
            last_name_2: last_Name2,
            username: userName,
            email: email,
            secondary_email: secondary_email,
            address: {
                street_address: (_25 = address === null || address === void 0 ? void 0 : address.street_address) !== null && _25 !== void 0 ? _25 : "",
                city: (_26 = address === null || address === void 0 ? void 0 : address.city) !== null && _26 !== void 0 ? _26 : "",
                state: (_27 = address === null || address === void 0 ? void 0 : address.state) !== null && _27 !== void 0 ? _27 : "",
                postalCode: (_28 = address === null || address === void 0 ? void 0 : address.postalCode) !== null && _28 !== void 0 ? _28 : ""
            },
            Points: ClubPointsObject,
            honors_number: honors_number,
            hilton_honors_tier: hilton_honors_tier,
            hilton_honors_points: hilton_honors_points,
            clubMemberType: clubMemberType,
            clubMemberTypeId: clubMemberTypeId
        });
        Cookies.remove("post-login-dashboard-indentify");
    };
    Dashboard.prototype.stepsJson = function () {
        var overlay = window['hgv']['Dashboard'].HelpOverlay;
        return JSON.parse(ko.unwrap(overlay.Content));
    };
    Dashboard.prototype.getBrowserVersion = function () {
        console.log(this.BrowserName().toUpperCase());
        if (this.BrowserName().toUpperCase() === 'INTERNETEXPLORER'
            || this.BrowserName().toUpperCase() === 'IE') {
            this.AddAlert(this.getGlobalContentBlock("GeneralBestBrowserMessage"), 'info', true);
        }
    };
    Dashboard.prototype.overlayClosed = function () {
        var dashboard = window['hgv']['Dashboard'];
        dashboard.UpdateClosedOverlays(dashboard.Title());
    };
    Dashboard.prototype.scrollToHomeWeeks = function () {
        $('html, body').animate({
            scrollTop: $('#home-week-section').position().top
        });
    };
    //TODO: Implement these
    Dashboard.prototype.refreshAllUnits = function (initialLoad) { };
    Dashboard.prototype.getMoreDates = function (ids) { };
    Dashboard.prototype.refreshHomeWeekDateSelection = function (checkinDate, checkoutDate) { };
    return Dashboard;
}(base.ResortSearchVmBase));
module.exports = Dashboard;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVMBase");
var ReservationTable = require("./ReservationTable");
var ReservationRouter = require("./ReservationRouter");
var ReservationForm = require("./ReservationForm");
var numeral = require("numeral");
var Reservation = /** @class */ (function (_super) {
    __extends(Reservation, _super);
    function Reservation(data) {
        var _this = _super.call(this, data, ReservationForm, ReservationTable, ReservationRouter) || this;
        _this.data = data;
        _this.ReceiptVisible = ko.observable(false);
        _this.AcceptOrRejectReservationVisible = ko.observable(false);
        _this.AcceptOrRejectResProcessing = ko.observable(false);
        _this.NotAbletoAcceptOrRejectReservation = ko.observable(false);
        _this.AvailErrorVisible = ko.observable(false);
        _this.UpcomingComplete = ko.observable(false);
        _this.HistoryComplete = ko.observable(false);
        _this.IsOpenSeasonCredit = ko.observable(false);
        _this.selectedPaymentCard = ko.observable();
        _this.isGuestFormVisible = ko.observable(false);
        _this.isGuestButtonVisible = ko.observable(false);
        _this.isOwnerVisible = ko.observable(true);
        _this.isPrimayGuestDataEntered = ko.observable(false);
        _this.initHtsTokenizer();
        var frame = $('#jpnCC');
        frame.hide();
        var self = _this;
        _this.table.orderby = "ArrivalDate asc";
        _this.PageInfo.PageNumber.subscribe(function () {
            _this.UpcomingComplete(false);
        });
        //hacking in history here, we assume the normal page into is for upcoming
        var filter = _this.Filters;
        _this.Filters = null;
        // just need this ReservationTable to ignore the filters so it doesn't randomly query
        _this.HistoryReservationTable = new ReservationTable(data, _this, false, _this.HistoryPageInfo, "ReservationHistory");
        _this.HistoryReservationTable.orderby = "ArrivalDate desc";
        _this.HistoryPageInfo.PageNumber.subscribe(function () {
            _this.HistoryComplete(false);
        });
        _this.Filters = filter;
        // this.ChangePrimaryGuest =ko.mapping.toJS(data.ChangePrimaryGuest);
        //this.initiatePrimaryGuest();
        _this.ShowBonusPointsSummary = ko.computed(function () {
            return (_this.ItemDetail.PaymentMethod() === 3);
        });
        // Array of points used objects (either bonus, normal or normal when rescuing)
        _this.ItemDetail.ViewingPointsUsed = ko.computed(function () {
            if (self.ShowBonusPointsSummary())
                return self.ItemDetail.BonusPointsUsed();
            else
                return self.ItemDetail.PointsUsed();
        });
        _this.HasDiscountPromo = ko.computed(function () {
            return self.ItemDetail.UnitType.HasDiscountPromo() || false;
        });
        _this.ItemDetail.HasPrimaryGuestChanged = ko.observable(false);
        _this.ShowPaymentOptions = ko.computed(function () {
            return (_this.AvailablePaymentMethods() && _this.AvailablePaymentMethods().length > 1);
        });
        var paymentUrl = _this.ItemDetail.YenPaymentLink();
        if (paymentUrl) {
            _this.paymentUrl = ko.observable(_this.ItemDetail.YenPaymentLink().replace(/['"]+/g, ''));
        }
        if (_this.ItemDetail.RequiresBorrowing()) {
            _this.setupBorrowingComputedProperties();
        }
        _this.ShowCoverRemainingBalance = ko.computed(function () {
            return _this.ItemDetail.RequiresBorrowing() && _this.ItemDetail.PaymentMethod() === 2;
        });
        _this.ItemDetail.UnitType.UnitDescription = ko.pureComputed(function () {
            return _this.ItemDetail.UnitType.Name() + " (" + _this.ItemDetail.UnitType.UnitId() + ")";
        });
        _this.ItemDetail.IsJapanProperty = ko.computed(function () {
            return _this.ItemDetail.IsYenAccepted() && _this.ItemDetail.PaymentMethod() === 1; //Yen is accepted only for cash payment
        });
        _this.ShowPointsSummary = ko.computed(function () {
            return ((_this.ItemDetail.IsBooking() && _this.ItemDetail.PaymentMethod() !== 1) // Not cash
                //|| this.ItemDetail.IsUpgrading()
                || ((_this.ItemDetail.IsChanging() && _this.ItemDetail.AllowChangeableReservation()) && _this.ItemDetail.IsUpgrading())
                || _this.ItemDetail.IsPointsReceipt())
                && _this.ItemDetail.ViewingPointsUsed().length > 0;
        });
        _this.ShowPaymentSummary = ko.computed(function () {
            return (_this.ItemDetail.PaymentLineItems().length > 0);
        });
        _this.ShowPaidWith = ko.computed(function () {
            if (_this.ItemDetail.PaymentLineItems().length > 0) {
                return ko.unwrap(self.ItemDetail.PaymentLineItems).reduce(function (accum, curr) {
                    return accum + (ko.unwrap(curr.Amount) || 0);
                }, 0) > 0;
            }
            return false;
        });
        _this.ShowPointsTotals = ko.computed(function () {
            if (!_this.ShowPointsSummary())
                return false;
            var items = ko.utils.arrayFilter(_this.ItemDetail.ViewingPointsUsed(), function (item) {
                return item.UsedAmount() > 0;
            });
            var count = items.length;
            return count > 1;
        });
        _this.ShowPointsInSticky = ko.computed(function () {
            return ((_this.ShowPointsSummary() || _this.ItemDetail.IsBooking() === false) || _this.ItemDetail.IsPointsReceipt());
        });
        _this.ShowChangeNegative = ko.computed(function () {
            return ((_this.ItemDetail.IsBooking() === false && _this.ItemDetail.PointsDifference() < 0)
                || (_this.ItemDetail.CancellationNumber() > 0 && _this.ItemDetail.ReservationStatus() != 'Reserved')
                || (_this.ItemDetail.IsChanging() && _this.ItemDetail.PointsDifference() < 0));
        });
        _this.PointsToBook = ko.computed(function () {
            return (_this.ItemDetail.IsBooking() && !_this.ItemDetail.IsChanging()) || _this.ItemDetail.IsPointsReceipt()
                ? _this.ItemDetail.UnitType.TotalPointsCost()
                : Math.abs(_this.ItemDetail.PointsDifference());
        });
        _this.TotalPointsToBook = ko.computed(function () {
            return _this.ItemDetail.UnitType.TotalPointsCost();
        });
        _this.ShowGuestCert = ko.computed(function () {
            return (_this.IsHomeWeekBooking() || _this.ItemDetail.PaymentMethod() !== 1);
        });
        _this.ShowCancellationReceipt = ko.computed(function () {
            if ((_this.ItemDetail.CancellationNumber() > 0) && (_this.ItemDetail.ReservationStatus() != 'Reserved')) {
                return true;
            }
            return false;
        });
        _this.ShowGuestCertReceipt = ko.computed(function () {
            return (_this.ItemDetail.IsChanging() && !_this.ItemDetail.AllowChangeableReservation());
        });
        // Only show cancel protection option for club points bookings
        _this.ShowCancelProt = ko.computed(function () {
            return _this.ItemDetail.PaymentMethod() === 2 && _this.IsEligibleForCancelProt();
        });
        _this.ItemDetail.SectionNumberForPaymentMethod = ko.computed(function () {
            var sectionNumber = 1;
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForRoomTypeSummary = ko.computed(function () {
            var sectionNumber = 2;
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForCoverRemainingBalance = ko.computed(function () {
            var sectionNumber = 2;
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForPointsAndRoomTypeSummary = ko.computed(function () {
            var sectionNumber = 3;
            if (self.ItemDetail.BorrowSummary != null) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForNonHiltonDisclosure = ko.computed(function () {
            var sectionNumber = 4;
            // When modifying guest certificates
            if (self.ItemDetail.PaymentMethod() === 0) {
                sectionNumber = sectionNumber - 1;
            }
            if (self.ItemDetail.BorrowSummary != null) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForGuestContactInformation = ko.computed(function () {
            var sectionNumber = 5;
            // When modifying guest certificates
            if (self.ItemDetail.PaymentMethod() === 0) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ItemDetail.IsNonHilton() || self.ItemDetail.PaymentMethod() === 0) {
                sectionNumber = sectionNumber - 1;
            }
            if (self.ItemDetail.BorrowSummary != null) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForCancellationAndChangePolicy = ko.computed(function () {
            var sectionNumber = 6;
            if (self.ItemDetail.BorrowSummary != null) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ItemDetail.IsNonHilton()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForCancellationProtection = ko.computed(function () {
            var sectionNumber = 7;
            if (self.ItemDetail.BorrowSummary != null) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ItemDetail.AllowChangeableReservation()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ItemDetail.IsNonHilton()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForPromotion = ko.computed(function () {
            var sectionNumber = 8;
            if (self.ItemDetail.BorrowSummary != null) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowCancelProt()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ItemDetail.AllowChangeableReservation()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ItemDetail.IsNonHilton()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.SectionNumberForPayment = ko.computed(function () {
            var sectionNumber = 9;
            if (self.ItemDetail.BorrowSummary != null) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowCancelProt()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ItemDetail.AllowChangeableReservation()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowPaymentOptions()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ShowPromotion()) {
                sectionNumber = sectionNumber - 1;
            }
            if (!self.ItemDetail.IsNonHilton()) {
                sectionNumber = sectionNumber - 1;
            }
            return sectionNumber;
        });
        _this.ItemDetail.IsAdaAccessible = function () {
            return (self.ItemDetail.AccessibleRoomIds() != null && self.ItemDetail.AccessibleRoomIds() !== '') || (self.ItemDetail.UnitType && self.ItemDetail.UnitType.IsAdaAccessible());
            //            return self.ItemDetail.UnitType && self.ItemDetail.UnitType.IsAdaAccessible();
        };
        _this.ItemDetail.UsedPointsTotal = ko.computed(function () {
            //return ko.unwrap<any[]>(self.ItemDetail.PointsUsed).reduce(function (accum, curr) {
            return ko.unwrap(self.ItemDetail.ViewingPointsUsed).reduce(function (accum, curr) {
                return accum + (ko.unwrap(curr.UsedAmount) || 0);
            }, 0);
        });
        _this.ItemDetail.UsedPointsTotalString = ko.computed(function () {
            return numeral(self.ItemDetail.UsedPointsTotal() || 0).format('(0,0)');
        });
        _this.ItemDetail.PreviousPointsTotal = ko.computed(function () {
            //only use point bucket for total if it is used
            var used = _.filter(self.ItemDetail.ViewingPointsUsed(), function (x) { return (x.FormattedDifferenceAmount() !== ''); });
            return used.reduce(function (accum, curr) { return (accum + (ko.unwrap(curr.PreviousAmount) || 0)); }, 0);
        });
        _this.ItemDetail.PreviousPointsTotalString = ko.computed(function () {
            var val = self.ItemDetail.PreviousPointsTotal();
            return numeral(val || 0).format('(0,0)');
        });
        _this.ItemDetail.RemainingPointsTotal = ko.computed(function () {
            //only use point bucket for total if it is used
            var used = _.filter(self.ItemDetail.ViewingPointsUsed(), function (x) { return (x.FormattedDifferenceAmount() !== ''); });
            return used.reduce(function (accum, curr) { return (accum + (ko.unwrap(curr.RemainingAmount) || 0)); }, 0);
        });
        _this.ItemDetail.TotalPayment = ko.computed(function () {
            // Calculate the total payment by summing up PaymentLineItems amounts
            var totalPayment = self.ItemDetail.PaymentLineItems().reduce(function (accum, curr) {
                return accum + (ko.unwrap(curr.Amount) || 0);
            }, 0);
            // Subtract OSCAmount from totalPayment
            if (!isNaN(self.OSCAmount())) {
                totalPayment -= self.OSCAmount();
            }
            //used in receipt
            if (self.ItemDetail.OpenSeasonCredit() > 0 && self.OSCAmount() == 0)
                totalPayment -= self.ItemDetail.OpenSeasonCredit();
            return totalPayment;
        });
        _this.TotalCost = ko.computed(function () {
            // Calculate the total payment by summing up PaymentLineItems amounts
            var totalPayment = self.ItemDetail.PaymentLineItems().reduce(function (accum, curr) {
                return accum + (ko.unwrap(curr.Amount) || 0);
            }, 0);
            return totalPayment;
        });
        _this.ItemDetail.RemainingPointsTotalString = ko.computed(function () {
            return numeral(ko.unwrap(self.ItemDetail.RemainingPointsTotal) || 0).format('(0,0)');
        });
        // Users can remove certain points - so the booking should only be possible when there is enough total used points, booking with cash or booking a home week (or a change)
        _this.ItemDetail.BookingEnabled = ko.computed(function () {
            var bookingWithPoints = self.ItemDetail.PaymentMethod() === 2;
            var requiresBorrowing = self.ItemDetail.BorrowSummary.RunningBalance() > 0;
            if (bookingWithPoints && requiresBorrowing) //prevent booking if they require selecting points to borrow and they haven't selected any yet
                return false;
            return self.IsHomeWeekBooking() || self.ShowPointsSummary() === false || !self.ItemDetail.IsBooking() || (_this.ItemDetail.IsChanging() && _this.ItemDetail.AllowChangeableReservation())
                ? true
                : (ko.unwrap(self.ItemDetail.UsedPointsTotal) >= ko.unwrap(self.ItemDetail.UnitType.TotalPointsCost));
        });
        _this.ItemDetail.CommitChangeGuestEnabled = ko.computed(function () {
            return self.ItemDetail.HasPrimaryGuestChanged();
        });
        // Attempt to fetch a localized description for a payment line item
        _this.ItemDetail.PaymentDescription = function (paymentDto) {
            var block = ko.utils.arrayFirst(self.FrontEndContentBlocks(), function (item) {
                if (item)
                    return item.Slug() === paymentDto.Description();
            });
            return block
                ? block.Content()
                : paymentDto.Description();
        };
        _this.ItemDetail.ResortSortedImages = ko.computed(function () {
            return _.sortBy(_this.ItemDetail.ResortImages(), function (img) {
                return img.Ordinal();
            });
        });
        _this.ItemDetail.ResortImageGallery = ko.computed(function () {
            var gallery = new Array();
            _.each(_this.ItemDetail.ResortSortedImages(), function (img) {
                var size = _.find(img.ImageSizes(), function (sz) {
                    return ko.unwrap(sz.Size) === '1440x900';
                });
                if (size !== null) {
                    gallery.push(ko.unwrap(size.Url));
                }
            });
            return gallery;
        });
        _this.ReservationToChange = ko.observable(null);
        _this.ResortChangeBaseUrl = ko.observable(null);
        _this.CancelReservation = ko.observable(false);
        _this.CancelReservationReceipt = ko.observable(false);
        _this.ReservationNotFound = ko.observable(false);
        _this.ReservationResortNotFound = ko.observable(false);
        _this.ReservationRequestIsInValid = ko.observable(false);
        _this.ReservationToCancel = ko.observable(null);
        //this.ErrorReason = ko.observable(0);    // 0 = No error, 1 = availability, 2 = CashBookOverCancelRange
        _this.ActualGuestFirstName = ko.computed(function () {
            var firstName = _this.ChangePrimaryGuest.FirstName();
            var coownerVal = _this.ItemDetail.IsCoownerDropdownChanged != null ? _this.ItemDetail.IsCoownerDropdownChanged() : false;
            return (_this.ItemDetail.HasPrimaryGuestChanged() || coownerVal) && (firstName)
                ? "" + firstName
                : _this.ItemDetail.GuestFirstName();
        });
        _this.ActualGuestLastName = ko.computed(function () {
            var lastName = _this.ChangePrimaryGuest.LastName();
            var coownerVal = _this.ItemDetail.IsCoownerDropdownChanged != null ? _this.ItemDetail.IsCoownerDropdownChanged() : false;
            return (_this.ItemDetail.HasPrimaryGuestChanged() || coownerVal) && (lastName)
                ? "" + lastName
                : _this.ItemDetail.GuestLastName();
        });
        _this.ActualGuestFullName = ko.computed(function () {
            var firstName = _this.ActualGuestFirstName();
            var lastName = _this.ActualGuestLastName();
            return firstName + " " + lastName;
        });
        _this.ActualOwnerName = ko.computed(function () {
            var firstName = _this.ChangePrimaryGuest.FirstName();
            var lastName = _this.ChangePrimaryGuest.LastName();
            return firstName + " " + lastName;
        });
        _this.ActualGuestEmail = ko.computed(function () {
            var email = _this.ChangePrimaryGuest.Email();
            var coownerVal = _this.ItemDetail.IsCoownerDropdownChanged != null ? _this.ItemDetail.IsCoownerDropdownChanged() : false;
            return (_this.ItemDetail.HasPrimaryGuestChanged() || coownerVal) && email
                ? _this.ChangePrimaryGuest.Email()
                : _this.ItemDetail.GuestEmail();
        });
        _this.ActualGuestPhone = ko.computed(function () {
            var phone = _this.ChangePrimaryGuest.Phone();
            var coownerVal = _this.ItemDetail.IsCoownerDropdownChanged != null ? _this.ItemDetail.IsCoownerDropdownChanged() : false;
            return (_this.ItemDetail.HasPrimaryGuestChanged() || coownerVal) && phone
                ? _this.ChangePrimaryGuest.Phone()
                : _this.ItemDetail.GuestPhone();
        });
        _this.ItemDetail.PaymentLineItems.subscribe(function (newValue) {
            var cost = 0;
            _.each(_this.ItemDetail.PaymentLineItems(), function (x) { cost = cost + x.Amount(); });
            if (!isNaN(self.OSCAmount())) {
                cost -= self.OSCAmount();
            }
            _this.TotalAmountDue(cost);
            _this.ItemDetail.CreditCardForm.Amount(cost);
        });
        _this.OSCMaxAmount = ko.computed(function () {
            //var eligibleOSCAmnt = 0;
            var eligibleOSCAmnt = parseFloat(_this.Year1OpenSeasonCredit());
            //if (this.ItemDetail.DateCheckIn().toString().includes(this.Year1()) && this.ItemDetail.DateCheckOut().toString().includes(this.Year1()))
            //    eligibleOSCAmnt = parseFloat(this.Year1OpenSeasonCredit());
            //else if (this.ItemDetail.DateCheckIn().toString().includes(this.Year2()) && this.ItemDetail.DateCheckOut().toString().includes(this.Year2()))
            //    eligibleOSCAmnt = parseFloat(this.Year2OpenSeasonCredit());
            //else if (this.ItemDetail.DateCheckIn().toString().includes(this.Year1()) && this.ItemDetail.DateCheckOut().toString().includes(this.Year2()))
            //    eligibleOSCAmnt = parseFloat(this.Year1OpenSeasonCredit()) + parseFloat(this.Year2OpenSeasonCredit());
            if (eligibleOSCAmnt <= _this.TotalCost())
                return parseFloat(eligibleOSCAmnt.toFixed(2));
            else
                return parseFloat(_this.TotalCost().toFixed(2));
        });
        _this.OSCAmount.subscribe(function (newValue) {
            var osc = _this.OSCAmount;
            if (_this.ItemDetail.IsJapanProperty()) {
                _this.applyValidRange(_this.OSCMaxAmount(), newValue, osc);
            }
            else {
                _this.applyValidRange(_this.OSCMaxAmount(), newValue, osc, true);
            }
            var cost = _this.TotalCost();
            if (!isNaN(self.OSCAmount())) {
                cost -= self.OSCAmount();
            }
            _this.TotalAmountDue(cost);
            _this.ItemDetail.CreditCardForm.Amount(cost);
        });
        _this.selectedPaymentCard.subscribe(function (newValue) {
            if (newValue != undefined) {
                self.ItemDetail.CreditCardForm.CardToken = newValue['ProviderToken']();
                self.ItemDetail.CreditCardForm.LastFourCardNumber = newValue['MaskedCardNo']();
                self.ItemDetail.CreditCardForm.NameOnCard = newValue['HolderName']();
                self.ItemDetail.CreditCardForm.CardType = ko.observable("0"); //since there is no card type for GMO defaulting it to 0
                self.ItemDetail.CreditCardForm.Number = newValue['MaskedCardNo']();
                var month = newValue['Expiration']();
                var mo = month.substring(0, 2);
                self.ItemDetail.CreditCardForm.Month = mo;
                var year = month.substring(2, 4);
                self.ItemDetail.CreditCardForm.Year = year;
                /*
                console.log('month ', mo);
                console.log('CC token: ', self.ItemDetail.CreditCardForm.CardToken);
                console.log('LastFourCardNumber: ', self.ItemDetail.CreditCardForm.LastFourCardNumber);
                console.log('NameOnCard: ', self.ItemDetail.CreditCardForm.NameOnCard);
                */
            }
            else {
                InitializeCard();
            }
        });
        _this.ItemDetail.ResortDetailsUrl = ko.computed(function () {
            if (self.ItemDetail.HomeWeekContract() != null) {
                return '/' + self.CurrentLanguageCode() + '/resort/homeweek/' + self.ItemDetail.HomeWeekContract() + "/" + self.ItemDetail.HomeWeekInterval();
            }
            return '/' + self.CurrentLanguageCode() + '/resort/' + self.ItemDetail.ResortBaseUrl();
        });
        // Animation callbacks
        _this.showElement = function (elem) { if (elem.nodeType === 1)
            $(elem).hide().slideDown(); };
        _this.hideElement = function (elem) { if (elem.nodeType === 1)
            $(elem).slideUp('slow', (function () { $(elem).remove(); })); };
        _this.IsChangingGuest = ko.observable(false);
        $("#discard-change-reservation").click(function (e) {
            e.stopPropagation();
        });
        $("#change-reservation-change-resort").click(function (e) {
            e.stopPropagation();
        });
        ko.applyBindings(_this);
        _this.picker.setupCalendarBindings(_this, 'TopDatePicker');
        $('.hideUnbound').removeClass('hideUnbound');
        $("#ChangePrimaryGuest_FirstName").bind("keyup keypress focusout", function (event) {
            $("#ChangePrimaryGuest_FirstName").val(self.getEngOnly($("#ChangePrimaryGuest_FirstName").val()));
        });
        $("#ChangePrimaryGuest_LastName").bind("keyup keypress focusout", function (event) {
            $("#ChangePrimaryGuest_LastName").val(self.getEngOnly($("#ChangePrimaryGuest_LastName").val()));
        });
        $("#ChangePrimaryGuest_SecondaryFirstName").bind("keyup keypress focusout", function (event) {
            $("#ChangePrimaryGuest_SecondaryFirstName").val(self.getEngOnly($("#ChangePrimaryGuest_SecondaryFirstName").val()));
        });
        $("#ChangePrimaryGuest_SecondaryLastName").bind("keyup keypress focusout", function (event) {
            $("#ChangePrimaryGuest_SecondaryLastName").val(self.getEngOnly($("#ChangePrimaryGuest_SecondaryLastName").val()));
        });
        $("#ChangePrimaryGuest_Address").bind("keyup keypress focusout", function (event) {
            $("#ChangePrimaryGuest_Address").val(self.getEngOnly($("#ChangePrimaryGuest_Address").val()));
        });
        $("#ChangePrimaryGuest_City").bind("keyup keypress focusout", function (event) {
            $("#ChangePrimaryGuest_City").val(self.getEngOnly($("#ChangePrimaryGuest_City").val()));
        });
        $("#ChangePrimaryGuest_State").bind("keyup keypress focusout", function (event) {
            $("#ChangePrimaryGuest_State").val(self.getEngOnly($("#ChangePrimaryGuest_State").val()));
        });
        $("#ChangePrimaryGuest_Zip").bind("keyup keypress focusout", function (event) {
            $("#ChangePrimaryGuest_Zip").val(self.getEngOnly($("#ChangePrimaryGuest_Zip").val()));
        });
        self.form.bindingsApplied();
        function InitializeCard() {
            self.ItemDetail.CreditCardForm.CardToken = ko.observable("");
            self.ItemDetail.CreditCardForm.LastFourCardNumber = ko.observable("");
            self.ItemDetail.CreditCardForm.NameOnCard = ko.observable("");
            self.ItemDetail.CreditCardForm.Month = ko.observable("");
            self.ItemDetail.CreditCardForm.Year = ko.observable("");
        }
        _this.showPanel = function () {
            var frame = $('#jpnCC');
            frame.toggle();
            console.log('Payment URL: ', _this.paymentUrl());
            frame.attr('src', _this.paymentUrl());
            InitializeCard();
            $('#selectCard').hide();
            $('#populateCard').show();
        };
        var reservationStarted = sessionStorage.getItem("ReservationStarted");
        if (reservationStarted !== null || reservationStarted !== undefined) {
            _this.pushSegmentReservationStartedAnalyticsEvent();
        }
        return _this;
    }
    Reservation.prototype.pushSegmentReservationStartedAnalyticsEvent = function () {
        this.Analytics.track("Reservation Started", {
            event: "reservation_started",
            lead_id: this.User.LeadId(),
            u_lead_id: this.User.ULeadId(),
            ecommerce: {
                items: [{
                        unit_id: this.ItemDetail.UnitType.UnitId(),
                        item_name: this.ItemDetail.UnitType.Name(),
                        price: this.OSCAmount(),
                    }],
                points_used: [{
                        previous_amount: this.ItemDetail.PreviousPointsTotal(),
                        used_amount: this.ItemDetail.UsedPointsTotal(),
                        remaining_amount: this.ItemDetail.RemainingPointsTotal()
                    }]
            },
            date_check_in: (new Date(this.ItemDetail.DateCheckIn())).toLocaleDateString(),
            date_check_out: (new Date(this.ItemDetail.DateCheckOut())).toLocaleDateString(),
            number_of_nights: this.ItemDetail.NumberOfNights(),
            number_of_guests: this.ItemDetail.NumberOfGuests(),
            resort_name: this.ItemDetail.ResortName(),
            resort_display_name: this.ItemDetail.ResortDisplayName(),
            resort_city_state_zip: this.ItemDetail.ResortCityStateZip().trim(),
            room_size: this.ItemDetail.UnitType.Size(),
            occupancy: this.ItemDetail.UnitType.Occupancy(),
            payment_method: this.ItemDetail.PaymentMethod(),
            guest_first_name: this.ItemDetail.GuestFirstName(),
            guest_last_name: this.ItemDetail.GuestLastName(),
            guest_email: this.ItemDetail.GuestEmail(),
            guest_phone: this.ItemDetail.GuestPhone(),
            total_points_cost: this.ItemDetail.UsedPointsTotal()
        });
        sessionStorage.removeItem("reservationStarted");
    };
    //  Val[i].charCodeAt(i) == 64
    // || Val[i].charCodeAt(i) == 46 || Val[i].charCodeAt(i) == 45
    // || Val[i].charCodeAt(i) >= 48 && Val[i].charCodeAt(i) <= 57
    Reservation.prototype.getEngOnly = function (Val) {
        var valNewText = ""; //64   @  46  45        48 to 57 
        var tempStrAllowed = "1234567890@.-,?.&%$#!+";
        for (var i = 0; i < Val.length; i++) {
            if ((Val[i].match(/^[a-zA-Z0-9_ ]*$/) != null) || (tempStrAllowed.indexOf(Val[i]) != -1) || (Val[i].charCodeAt(0) == 32)) {
                valNewText += Val[i];
            }
        }
        return valNewText;
    };
    Reservation.prototype.getAdaContains = function (list, value) {
        if (list === undefined) {
            return false;
        }
        return _.some(list(), function (a) { return a === value; });
    };
    Reservation.prototype.setupBorrowingComputedProperties = function () {
        var _this = this;
        var borrowSummary = this.ItemDetail.BorrowSummary;
        //Only setup the subscriptions if they are actually used on the page
        if (borrowSummary.RequiresBorrowSelection()) {
            borrowSummary.SelectedClubPoints.subscribe(function (newValue) {
                _this.refreshBorrowPointsSelected(newValue, borrowSummary.ClubPointsMax(), borrowSummary.SelectedClubPoints, borrowSummary.SelectedBhcPoints);
            });
            borrowSummary.SelectedBhcPoints.subscribe(function (newValue) {
                _this.refreshBorrowPointsSelected(newValue, borrowSummary.BhcPointsMax(), borrowSummary.SelectedBhcPoints, borrowSummary.SelectedClubPoints);
            });
        }
    };
    //Will calculate the points and any overages will reduce the other point bucket
    Reservation.prototype.refreshBorrowPointsSelected = function (selectedPointsInput, maxSelectedPointsBucket, selectedPointsBucket, otherPointBucket) {
        var selectedPoints = parseInt(selectedPointsInput);
        if (selectedPoints < 0 || isNaN(selectedPoints)) {
            selectedPointsBucket("0");
            return;
        }
        if (selectedPoints > maxSelectedPointsBucket) {
            selectedPointsBucket(maxSelectedPointsBucket.toString()); //reset to the max available points bucket
            return;
        }
        var otherBucketSelected = parseInt(otherPointBucket());
        var totalPointsSelected = selectedPoints + otherBucketSelected;
        var pointsOverage = totalPointsSelected - this.ItemDetail.BorrowSummary.PointsNeeded();
        if (pointsOverage > 0) {
            otherPointBucket((otherBucketSelected - pointsOverage).toString()); //reduce the other bucket by the overage
            return;
        }
        var runningBalance = this.ItemDetail.BorrowSummary.PointsNeeded() - totalPointsSelected;
        this.ItemDetail.BorrowSummary.RunningBalance(runningBalance);
        this.updateBorrowPointCostLineItems();
        this.refreshToolkitRangeSlider();
    };
    //Updates the borrow points cost line items in real time as they update the sliders
    Reservation.prototype.updateBorrowPointCostLineItems = function () {
        var borrowSummary = this.ItemDetail.BorrowSummary;
        var bhcLineItem = ko.utils.arrayFirst(this.ItemDetail.PointsUsed(), function (item) {
            return item.PointsBucketType() === borrowSummary.BhcPointsType() &&
                item.Type() === borrowSummary.BorrowPointsType();
        });
        var clubLineItem = ko.utils.arrayFirst(this.ItemDetail.PointsUsed(), function (item) {
            return item.PointsBucketType() === borrowSummary.ClubPointsType() &&
                item.Type() === borrowSummary.BorrowPointsType();
        });
        if (bhcLineItem != null) {
            this.updateBorrowLineItemCosts(bhcLineItem, borrowSummary.SelectedBhcPoints(), borrowSummary.BhcAvailablePoints());
        }
        if (clubLineItem != null) {
            this.updateBorrowLineItemCosts(clubLineItem, borrowSummary.SelectedClubPoints(), borrowSummary.ClubAvailablePoints());
        }
    };
    Reservation.prototype.updateBorrowLineItemCosts = function (borrowLineItem, usedPointsInput, availablePoints) {
        var usedPoints = parseInt(usedPointsInput);
        var remainingClub = availablePoints - usedPoints;
        var formattedDifference = numeral(usedPoints).format("0,0");
        borrowLineItem.UsedAmount(usedPoints);
        borrowLineItem.UsedAmountString(formattedDifference);
        borrowLineItem.FormattedDifferenceAmount(formattedDifference);
        borrowLineItem.LostPoints(usedPoints > 0);
        borrowLineItem.RemainingAmount(remainingClub);
        borrowLineItem.RemainingAmountString(numeral(remainingClub).format("0,0"));
    };
    Reservation.prototype.refreshToolkitRangeSlider = function () {
        setTimeout(function () { $('input[type=range]').trigger('change.toolkit'); }, 0);
    };
    Reservation.prototype.initiatePrimaryGuest = function () {
        this.ChangePrimaryGuest.Address(null);
        this.ChangePrimaryGuest.AgreeGuestsAre18(null);
        this.ChangePrimaryGuest.AgreeResponsible(null);
        this.ChangePrimaryGuest.AgreeAuthorizedGuest(null);
        this.ChangePrimaryGuest.City(null);
        this.ChangePrimaryGuest.CountryId(null);
        this.ChangePrimaryGuest.CountryName(null);
        this.ChangePrimaryGuest.Email(null);
        //this.ChangePrimaryGuest.Fee(null);
        this.ChangePrimaryGuest.FirstName(null);
        this.ChangePrimaryGuest.LastName(null);
        this.ChangePrimaryGuest.Phone(null);
        this.ChangePrimaryGuest.SecondaryEmail(null);
        this.ChangePrimaryGuest.SecondaryFirstName(null);
        this.ChangePrimaryGuest.SecondaryLastName(null);
        this.ChangePrimaryGuest.SecondaryPhone(null);
        this.ChangePrimaryGuest.State(null);
        this.ChangePrimaryGuest.Zip(null);
    };
    return Reservation;
}(base.ResortSearchVmBase));
module.exports = Reservation;

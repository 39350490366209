"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Router = require("../Base/Router");
var LoginRouter = /** @class */ (function (_super) {
    __extends(LoginRouter, _super);
    function LoginRouter(data, vm, alternateroutes) {
        var _this = _super.call(this, data, vm, alternateroutes) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    LoginRouter.prototype.initRoutes = function () {
        var self;
        self = this;
        if (!self.vm.DisableRoutes()) {
            _super.prototype.initRoutes.call(this);
        }
    };
    LoginRouter.prototype.getRoutes = function () {
        var self;
        self = this;
        return [
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/forgot-password",
                func: function () {
                    self.vm.ShowForgotPassword(true);
                    self.vm.ForgotPasswordDetail.EmailAddress("");
                    self.vm.ForgotPasswordDetail.ResetEmailSent(false);
                    self.vm.ForgotPasswordDetail.ResetEmailSentMessage("");
                    self.vm.ForgotPasswordDetail.RequireIdentifyMembership(false);
                    self.vm.ForgotPasswordDetail.NoAccountFoundError(false);
                    self.vm.ForgotPasswordDetail.EmailRequiredError(false);
                    self.vm.ShowForgotEmail(false); //hide the other screens 
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/forgot-email",
                func: function () {
                    self.vm.ShowForgotEmail(true);
                    self.vm.ForgotEmailDetail.AccountIdentifier("");
                    self.vm.ForgotEmailDetail.AccountFound(false);
                    self.vm.ForgotEmailDetail.EmailAssociated(false);
                    self.vm.ForgotEmailDetail.AccountFoundMessage("");
                    self.vm.ForgotEmailDetail.NoAccountFoundError(false);
                    self.vm.ForgotEmailDetail.AccountIdentifierRequiredError(false);
                    self.vm.ShowForgotPassword(false); //hide the other screens 
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/two-factor",
                func: function () {
                    if (this.params.email !== undefined
                        && this.params.email !== null
                        && this.params.email !== ''
                        && this.params.email !== 'null') {
                        self.vm.ItemDetail.EmailAddress(this.params.email);
                        self.vm.ShowTwoFactorAuth(true);
                        self.vm.ShowForgotPassword(false);
                        self.vm.ShowForgotEmail(false);
                    }
                    else {
                        window.history.pushState("", "", '#/index');
                        self.vm.ShowTwoFactorAuth(false);
                        self.vm.ShowForgotPassword(false);
                        self.vm.ShowForgotEmail(false); //hide the other screens
                    }
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/index",
                func: function () {
                    if (this.params.email !== undefined && this.params.email !== null && this.params.email !== '' && this.params.email !== 'null') {
                        self.vm.ItemDetail.EmailAddress(this.params.email);
                    }
                    if (this.params.updateEmailToken !== undefined) {
                        self.vm.ItemDetail.UpdateEmailToken(this.params.updateEmailToken);
                    }
                    if (this.params.error == 'RecaptchaNotValidError') {
                        self.vm.ItemDetail.RecaptchaNotValidError(true);
                    }
                    else if (this.params.error == 'TwoFactorServiceIssue') {
                        self.vm.ItemDetail.TwoFactorServiceIssue(true);
                    }
                    else if (this.params.error !== undefined) {
                        self.vm.ItemDetail.IncorrectEmailOrPasswordError(true);
                    }
                    self.vm.ShowTwoFactorAuth(false);
                    self.vm.ShowForgotPassword(false);
                    self.vm.ShowForgotEmail(false);
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/rci",
                func: function () {
                    self.vm.Area("RCI");
                    self.vm.ShowTwoFactorAuth(false);
                    self.vm.ShowForgotPassword(false);
                    self.vm.ShowForgotEmail(false);
                }
            }
        ];
    };
    return LoginRouter;
}(Router));
module.exports = LoginRouter;

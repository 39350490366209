"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VmBase = void 0;
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var koextensions = require("./KoBindingExtensions");
var koBindingDoughnutChart = require("./KoBindingDoughnutChart");
new koBindingDoughnutChart().init();
new koextensions().register();
var mapper = require("./Mapper");
var _ = require("lodash");
var Cookies = require("js-cookie");
var moment = require("moment");
window['ko'] = ko; //for console debugging
window['$'] = $;
window['_'] = _;
var VmBase = /** @class */ (function () {
    function VmBase(data, formType, tableType, routerType) {
        var _this = this;
        this.data = data;
        this.applyValidRange = function (max, input, ko, allowDecimal) {
            if (allowDecimal === void 0) { allowDecimal = false; }
            if (input > max) {
                ko(max);
            }
            else if (input < 0) {
                ko(0);
                return;
            }
            else if (isNaN(input)) {
                ko(0);
            }
            else if (input === "") {
                ko(0);
            }
            else {
                //Make sure it is always a number
                if (!allowDecimal)
                    var value = parseInt(input.toString());
                else
                    var value = parseFloat(input.toString());
                ko(value);
            }
        };
        this.data.KOMapping.ignore = ["DisplayNumber"];
        this.mapInitData(data);
        this.setupBindings();
        this.UserTimeZone = moment.tz.guess();
        //save copy of what a empty item looks like for creating after editing
        this.setNewItem();
        this.form = new formType(data, this);
        this.table = new tableType(data, this);
        if (routerType)
            this.routes = new routerType(data, this);
        this.WindowLocation(window.location.pathname + window.location.hash);
        this.setupSecondaryNavigation();
        this.HeaderClasses = ko.computed({
            owner: this,
            read: function () {
                var classes = "header ";
                if (_this.HeaderClass() && _this.HeaderClass() != '')
                    classes += _this.HeaderClass();
                return classes.trim();
            }
        });
        this.DataLayer = this.getDataLayer();
        this.Analytics = this.getAnalytics();
        this.AnalyticsStartTime = this.getAnalyticsSessionTime();
        //Begin the Timer for Session. 
        if (this.User.IsAuthenticated != undefined && this.User.IsAuthenticated()) {
            this.beginSessionTimeOut();
        }
    }
    VmBase.prototype.UserCurrentTime = function () {
        return moment.tz(moment(), this.UserTimeZone);
    };
    VmBase.prototype.ClickLoader = function (trigger) {
        if (trigger === void 0) { trigger = true; }
        if (trigger) {
            this.IsLoading(true);
        }
    };
    VmBase.prototype.mapDictionaryToArray = function (cardInfoDictionary) {
        var result = [];
        for (var key in cardInfoDictionary) {
            if (cardInfoDictionary.hasOwnProperty(key)) {
                result.push({ cardNumber: key, token: cardInfoDictionary[key] });
            }
        }
        return result;
    };
    VmBase.prototype.initHtsTokenizer = function (itemDetailDto) {
        var _this = this;
        if (this.HTSIsEnabled()) {
            $(window).on('message', function (e) {
                var tokenInfo = e.originalEvent.data;
                var itemDetail = itemDetailDto == undefined ? _this.ItemDetail : itemDetailDto;
                var form = itemDetail.CreditCardForm;
                form.CardToken(tokenInfo.Token);
                form.CardType(tokenInfo.CardType);
                form.NameOnCard(tokenInfo.Name);
                form.LastFourCardNumber(tokenInfo.LastFour);
                form.ExpDate(tokenInfo.ExpDate);
                _this.CreditCardIsMissing(false);
            });
        }
    };
    VmBase.prototype.creditCardValid = function () {
        var itemDetail = this.ItemDetail;
        var form = itemDetail.CreditCardForm;
        var allFieldsEntered = false;
        if (form.CardToken()) {
            if (form.CardType()) {
                if (form.ExpDate()) {
                    if (form.NameOnCard()) {
                        allFieldsEntered = true;
                    }
                }
            }
        }
        return allFieldsEntered;
    };
    VmBase.prototype.projectIsBlocked = function (object) {
        return this.BlockedProjectNumbers.indexOf(object) > -1;
    };
    VmBase.prototype.getSize = function (object) {
        var objectList = [];
        var stack = [object];
        var bytes = 0;
        while (stack.length) {
            var value = stack.pop();
            if (typeof value === 'boolean') {
                bytes += 4;
            }
            else if (typeof value === 'string') {
                bytes += value.length * 2;
            }
            else if (typeof value === 'number') {
                bytes += 8;
            }
            else if (typeof value === 'object'
                && objectList.indexOf(value) === -1) {
                objectList.push(value);
                for (var i in value) {
                    stack.push(value[i]);
                }
            }
        }
        return bytes;
    };
    VmBase.prototype.filterPointsByType = function (pointsBuckets, selectedOption) {
        var isAll = this.getAllText() === selectedOption;
        var isBonus = this.getBonusText() === selectedOption;
        var maxYear = _.max(_.map(ko.toJS(pointsBuckets), "Year"));
        var filteredBhc = _.filter(pointsBuckets, function (x) {
            return (x.Year().toString() === selectedOption && !x.IsBonusPoints())
                || ((isAll && !x.IsBonusPoints() && x.Year() <= maxYear)
                    || isBonus && x.IsBonusPoints());
        });
        return filteredBhc;
    };
    VmBase.prototype.handleIFrameLoadedEvent = function (location) {
        window.location = location;
        //return function (e) {
        //    this.IframeLoaded(e, location);
        //};
    };
    VmBase.prototype.IframeLoaded = function (e, signoutLocation) {
        window.location = signoutLocation;
    };
    VmBase.prototype.addLogoutIframe = function (id, load) {
        var embedUrl = this.EmbedLogoutUrl();
        var signoutIFrame = document.createElement('iframe');
        signoutIFrame.id = id;
        signoutIFrame.onload = load;
        signoutIFrame.src = embedUrl;
        signoutIFrame.style.cssText = 'display: none;';
        document.body.appendChild(signoutIFrame);
    };
    VmBase.prototype.removeLogoutIframe = function (id) {
        if (id === void 0) { id = 'logout-frame'; }
        var e = document.getElementById(id);
        e.remove();
    };
    VmBase.prototype.setupBindings = function () {
        var _this = this;
        var headerLanguageSelectorMobile = document.getElementById('headerMobileLanguage');
        var footerLanguageSelectorMobile = document.getElementById('footerMobileLanguage');
        headerLanguageSelectorMobile.addEventListener('touchstart', function (event) {
            pushMenuClickEventLog($(event.target).text().trim(), 'Language Menu', 'Utility Bar');
        });
        footerLanguageSelectorMobile.addEventListener('touchstart', function (event) {
            pushMenuClickEventLog($(event.target).text().trim(), 'Language Menu', 'Utility Bar');
        });
        var self = this;
        $('.alerts-holder').on('click', '.close', function () {
            var data = ko.dataFor(this); //local this
            self.Alerts.remove(data);
        });
        var UserType;
        (function (UserType) {
            UserType[UserType["None"] = 0] = "None";
            UserType[UserType["Deeded"] = 1] = "Deeded";
            UserType[UserType["Trust"] = 2] = "Trust";
        })(UserType || (UserType = {}));
        $(window).on('message', function (e) {
            if (e.originalEvent.data != undefined && e.originalEvent.data == 'begin-Session-TimeOut') {
                _this.beginSessionTimeOut();
            }
            //chat bubble click in embed website
            if (e.originalEvent.data.type == 'beginChatSession') {
                var dataLayer = self.getDataLayer();
                dataLayer.push({
                    event: 'live_chat',
                    interaction_type: 'click',
                    chat_type: 'chat bubble'
                });
            }
            if (e.originalEvent.data.actionType === 'MESSAGE_RECEIVED') {
                //the purpose of the flag is to prevent multiple pushes to Google Analytics with every message sent to chat
                var isLiveChatActivatedFlag = Cookies.get('live_chat_activated') === 'true';
                if (!isLiveChatActivatedFlag) {
                    var dataLayer = self.getDataLayer();
                    dataLayer.push({
                        event: 'live_chat',
                        interaction_type: 'click',
                        chat_type: 'live chat'
                    });
                    Cookies.set('live_chat_activated', 'true');
                }
            }
        });
        $('body').on('click', '.chat_window.chat_online', function () {
            var dataLayer = self.getDataLayer();
            dataLayer.push({
                event: 'live_chat',
                interaction_type: 'click',
                chat_type: 'live chat'
            });
        });
        $('body').on('click', '.side-menu__body a', function () {
            // Get the clicked link's text
            var linkText = $(this).text();
            var headerText = $('.side-menu__header h3').text().trim() || 'None';
            // Get the parent and grandparent anchor texts
            var parentName = $(this).closest('ul').closest('li').children('a').text() || 'None';
            var grandParentName = $(this).closest('ul').closest('li').closest('ul').closest('li').children('a').text() || 'None';
            var parent_menu = '';
            if (parentName !== 'None' && grandParentName !== 'None') {
                parent_menu = grandParentName + ' > ' + parentName;
            }
            else if (parentName !== 'None') {
                parent_menu = parentName;
            }
            else {
                parent_menu = '';
            }
            if (headerText !== 'None' && parent_menu.length > 0) {
                parent_menu = headerText + ' > ' + parent_menu;
            }
            else if (headerText !== 'None') {
                parent_menu = headerText;
            }
            pushMenuClickEventLog(linkText, parent_menu, 'Side Menu');
            var sideMenuClicked = $(this).text();
            var dataLayer = self.getDataLayer();
            dataLayer.push({
                event: sideMenuClicked,
                interaction_type: 'click',
            });
        });
        $('body').on('click', '.chat_window.chat_offline', function () {
            var dataLayer = self.getDataLayer();
            dataLayer.push({
                event: 'live_chat',
                interaction_type: 'click',
                chat_type: 'message'
            });
        });
        $(document).on('hide-header-alert', function (e, id) {
            var parentElem = $(this).parent();
            var markAsReadUrl = '/api/notification/' + id;
            var promise = $.ajax({
                url: markAsReadUrl,
                dataType: 'json',
                contentType: 'application/json',
                type: 'POST',
                cache: false,
            });
            promise.done(function (data) {
                parentElem.slideUp();
            });
            promise.fail(function (error) {
                // what should I do about errors?
            });
            /*
            var cookieKey = 'user-read-alerts';
            var cookieValue = '';
            var parentElem = $(this).parent();

            var cookieUpdated = false;
            
            if (id !== undefined) {
                var alertsCookie = Cookies.getJSON(cookieKey);

                if (alertsCookie !== undefined) {
                    cookieValue = alertsCookie;
                }
                if (cookieValue.indexOf(id) === -1) {
                    cookieValue += ((cookieValue.length > 0 ? '|' : '') + id);
                    cookieUpdated = true;
                }
                if (cookieUpdated) {
                    Cookies.set(cookieKey, cookieValue, { domain: window.location.hostname, path: '/' });
                    parentElem.slideUp();
                }
            }
            */
        });
        $(document).on('sendInboxCookie', function (e, id) {
            e.stopImmediatePropagation();
            var cookieKey = 'user-read-alerts';
            var cookieValue = '';
            var parentElem = $("[data-inboxID='" + id + "']")[0].parentElement;
            var cookieUpdated = false;
            //if the alert does not have an id just ignore it
            if (id !== undefined) {
                //if it has an id keep track of it so that the server knows
                //which alerts have been read                                   
                setTimeout(function () {
                    parentElem.classList.remove('unread');
                    var elem = $("[data-inboxID='" + id + "']");
                    if ($(elem).hasClass('unread')) {
                        var markAsReadUrl = '/api/notification/' + id;
                        console.log(id);
                        var promise = $.ajax({
                            url: markAsReadUrl,
                            dataType: 'json',
                            contentType: 'application/json',
                            type: 'POST',
                            cache: false,
                        });
                    }
                    $(elem).removeClass('unread');
                    var countElem = document.getElementById('alert-count');
                    var countElemData = ko.dataFor(countElem);
                    var unread = ko.unwrap(countElemData.UserAlertUnreadCount);
                    unread = (--unread < 0) ? 0 : unread;
                    countElemData.UserAlertUnreadCount(unread);
                }, 2500);
            }
        });
        $('body').on('click', '.skip', function (e) {
            e.preventDefault();
            // strip the leading hash and declare
            // the content we're skipping to
            var skipTo = "." + this.href.split('#')[1];
            // Setting 'tabindex' to -1 takes an element out of normal 
            // tab flow but allows it to be focused via javascript
            $(skipTo + ':visible:first').attr('tabindex', -1).on('blur focusout', function () {
                // when focus leaves this element, 
                // remove the tabindex attribute
                $(this).removeAttr('tabindex');
            }).focus(); // focus on the content container
            return false;
        });
        $('#createAccount').on('click', function (e) {
            var url = "/" + _this.CurrentLanguageCode() + "/sign-up";
            _this.DataLayer.push({
                event: "create_account",
                interaction_type: "click",
                link_text: "create_account",
                link_url: url
            });
            window.location.href = url;
        });
        $('.promotionButton').click(function (event) {
            event.preventDefault();
            var url = $(this).data('url');
            var btnText = $(event.target).text();
            var cardTitle = $(this).data('subheading');
            self.DataLayer.push({
                event: "Cards_Interaction",
                link_text: btnText,
                card_title: cardTitle,
                interaction_type: "click"
            });
            if (url != '' && url != null) {
                window.open(url, '_blank');
            }
        });
        $('body').on('click', '#sign-out-user-header', function (evt) {
            var leadId = _this.User != null ? _this.User.ULeadId() : "";
            if (leadId !== 0) {
                _this.pushSignoutSegmentAnalyticsEvents('user-initiated');
                _this.pushSignoutGoogleAnalyticsEvents();
            }
        });
        $('body').on('click', '#sign-out-header', function (evt) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.pushSignoutSegmentAnalyticsEvents('user-initiated');
                this.pushSignoutGoogleAnalyticsEvents();
                pushMenuClickEventLog($(evt.currentTarget).text(), $(evt.currentTarget).text(), 'Utility Bar');
                return [2 /*return*/];
            });
        }); });
        $('body').on('click', '#sign-out-profile', function (evt) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.pushSignoutSegmentAnalyticsEvents('user-initiated');
                this.pushSignoutGoogleAnalyticsEvents();
                return [2 /*return*/];
            });
        }); });
        $('body').on('click', '#sign-out', function (evt) { return __awaiter(_this, void 0, void 0, function () {
            var leadId;
            return __generator(this, function (_a) {
                leadId = this.User != null ? this.User.ULeadId() : "";
                if (document.URL.toString().indexOf("select-membership") > -1 && this.User.UserType() == UserType.None) {
                    this.DataLayer.push({
                        event: "logout",
                        memberType: "",
                        leadId: leadId,
                        pointsTotal: "",
                        pageName: document.URL
                    });
                    this.beginSignOut();
                }
                else if (leadId !== 0) {
                    this.pushSignoutSegmentAnalyticsEvents('user-initiated');
                    this.pushSignoutGoogleAnalyticsEvents();
                }
                //this.handleIFrameLoadedEvent(window.location.href);
                pushMenuClickEventLog($(evt.currentTarget).text(), $(evt.currentTarget).text(), 'Utility Bar');
                return [2 /*return*/];
            });
        }); });
        $('body').on('click', '.continue-session', function (evt) {
            evt.preventDefault();
            self.beginSessionTimeOut();
        });
        //$("a").click(function () { alert("You've clicked the link."); });
        $('body').on('click', '.main-hamburger', function () {
            $("#wrapper").toggleClass("toggled");
        });
        $('body').on('click', '.login-widget-submit', function (evt) {
            self.SubmitLoginRequest(evt);
        });
        $('body').on('click', '#two-factor-resend-code', function (evt) {
            self.resendTwoFactorCode();
        });
        $('body').on('click', '.two-factor-widget-submit', function (evt) {
            self.submitTwoFactor(evt);
        });
        $('body').on('keyup', '.login-widget-password', function (evt) {
            if (evt.keyCode === 13) {
                self.SubmitLoginRequest(evt);
            }
        });
        $('body').on('click', '.read-more-link', function (e) {
            $(this).closest('p').toggleClass('is-truncated');
            $(this).remove();
            return false;
        });
        $('body').on('click', '.toggle-overlay-status', function (evt) {
            var status = $(evt.target).data('overlay-status');
            self.HelpOverlaysEnabled(status);
        });
        $('body').on('click', '.loginWithReturnUrlCurrentPage', function (evt) {
            return setReturnurlCookie(window.location.href);
        });
        $('body').on('click', '.loginWithReturnUrl', function (evt) {
            var returnUrl = window.location.origin + "/" + (_this.CurrentLanguageCode() + $(evt.currentTarget).data("return-url"));
            return setReturnurlCookie(returnUrl);
        });
        function setReturnurlCookie(returnUrl) {
            var tenMinsFromNow = new Date();
            tenMinsFromNow.setMinutes(tenMinsFromNow.getMinutes() + 10);
            Cookies.set("RedirectFromLoginToDeepLinkUrl", returnUrl, { expires: tenMinsFromNow });
            return true;
        }
        function processGoogleAnalyticsCookie() {
            var cookieName = "dataLayerSso";
            var data = Cookies.get(cookieName);
            if (!data)
                return;
            var decryptedData = JSON.parse(atob(data));
            var dataLayer = self.getDataLayer();
            decryptedData.forEach(function (event) {
                return dataLayer.push(event);
            });
            var host = window.location.hostname.split('.').reverse();
            var domain = (host[1] ? (host[1] + '.') : '') + host[0];
            Cookies.remove(cookieName, { path: "/", domain: domain });
        }
        ;
        function processPostLoginCookeis() {
            var cookieName = "dataLayerSso";
            var data = Cookies.get(cookieName);
            if (!data)
                return;
            Cookies.set("post-login-dashboard-indentify", true);
        }
        processPostLoginCookeis();
        processGoogleAnalyticsCookie();
        //prevent disabled links from going anywhere
        $('body').on('click', 'a.is-disabled', function (evt) {
            evt.preventDefault();
            return false;
        });
        if (typeof (HGV) !== "undefined" && self.EnableLanguageSelector()) {
            // language selector
            HGV.Dropdown['language-selector'].on('change', onChangeLanguage);
            HGV.Dropdown['top-language-selector'].on('change', onChangeLanguage);
            HGV.Dropdown['top-language-selector-mobile'].on('change', onChangeLanguage);
        }
        //
        $('body').on('click', '#resetCacheModal', function (evt) {
            evt.preventDefault();
            _this.ResetCache();
        });
        $('body').on('click', 'a.sf-submission', function (e) {
            e.preventDefault();
            $('form.sf-submission').submit();
        });
        $('body').on('submit', 'form.sf-submission', function (e) {
            var $companyInput = $("input[name='company']");
            if ($companyInput.length > 0) {
                var fname = $("input[name='first_name']").val();
                var lname = $("input[name='last_name']").val();
                $companyInput.val(fname + "_" + lname + ".Household");
            }
            var errors = 0;
            var phoneNumbers = $("input[type='tel']");
            $.each(phoneNumbers, function (index, phoneNum) {
                var phone = $(phoneNum).val();
                phone = phone.replace("+", "").replace(/-/g, "");
                $(phoneNum).val(phone);
                var id = $(this).attr("id");
            });
            $('input.is-error').next('.form-control-error').remove();
            $('input.is-error').removeClass('is-error');
            $(".required").each(function (index, element) {
                var value = $(this).val();
                var id = $(this).attr("id");
                var type = $(this).attr("type");
                if (value == null || value == "") {
                    errors++;
                    var $element = $(this);
                    $element.addClass("is-error");
                    $element.parent().append('<div class="form-control-error">' + $element.data('label') + ' is required</div></div>');
                }
                else {
                    if (type == "email") {
                        if (!self.validateEmail(value)) {
                            errors++;
                            var $element = $(this);
                            $element.addClass("is-error");
                            $element.parent().append('<div class="form-control-error">Invalid email</div></div>');
                        }
                        else {
                            var $element = $(this);
                            $element.removeClass("is-error");
                            $element.parent().children('.form-control-error').remove();
                        }
                    }
                    else if (type == 'tel') {
                        if (!IsNumeric(value)) {
                            errors++;
                            var $element = $(this);
                            $element.addClass("is-error");
                            $element.parent().append('<div class="form-control-error">Invalid phone</div></div>');
                        }
                        else {
                            var $element = $(this);
                            $element.removeClass("is-error");
                            $element.parent().children('.form-control-error').remove();
                        }
                    }
                    else {
                        var $element = $(this);
                        $element.removeClass("is-error");
                        $element.parent().children('.form-control-error').remove();
                    }
                }
            });
            if (errors > 0) {
                e.preventDefault(); //prevent submitting the form if there are errors
                return;
            }
            var $companyId = $('form.sf-submission #oid');
            if ($companyId.length > 0 && $companyId.val().length === 0) {
                $companyId.val(self.SalesforceCompanyId()); //only update if it's present and the value is blank
            }
            var $successUrl = $('form.sf-submission #success');
            if ($successUrl.length > 0 && $successUrl.val().length === 0) {
                $successUrl.val(self.SalesforceReturnUrl()); //only update if it's present and the value is blank
            }
            var $returnUrl = $('form.sf-submission #retURL');
            if ($returnUrl.length > 0 && $returnUrl.val().length === 0) {
                $returnUrl.val(self.SalesforceReturnUrl()); //only update if it's present and the value is blank
            }
        });
        function onChangeLanguage(event, selectedItem) {
            //var lang = $(selectedItem.context).data('lang-id');  -- context property is depreciated from JQuery3.0 onwards
            var lang = $(selectedItem.prevObject).attr('data-lang-id');
            var validLanguageCodes = _.map(ko.toJS(self.SiteLanguages), 'Id');
            var urlParts = window.location.pathname.split('/');
            var firstPart = urlParts[1];
            var host = window.location.hostname.split('.').reverse();
            var domain = (host[1] ? (host[1] + '.') : '') + host[0];
            if (validLanguageCodes.indexOf(firstPart) > -1) {
                //replace current language with new
                urlParts[1] = lang;
            }
            else {
                // push new language onto array of parts
                urlParts.splice(1, 0, lang);
            }
            Cookies.remove('_culture');
            Cookies.set('_culture', lang, { expires: 3650, domain: domain });
            var newUrl = urlParts.join('/').replace(/\/$/, '');
            window.location.pathname = newUrl;
        }
        function IsNumeric(sText) {
            var ValidChars = "+0123456789";
            var IsNumber = true;
            var Char;
            for (var i = 0; i < sText.length && IsNumber == true; i++) {
                Char = sText.charAt(i);
                if (ValidChars.indexOf(Char) == -1) {
                    IsNumber = false;
                }
            }
            return IsNumber;
        }
        $('.main-menu > li:not(.user-info,.main-menu__user)').each(function () {
            var linkList = $(this).find("a");
            var parentMenu = linkList.first().text();
            linkList.each(function () {
                $(this).click(function (e) {
                    pushMenuClickEventLog($(this).text(), parentMenu, 'Main Menu');
                });
            });
        });
        $('[data-utility-bar-global-alerts]').on('click', function () {
            pushMenuClickEventLog('Alerts', 'Alert Menu', 'Utility Bar');
        });
        $('[data-utility-bar-alert-msg]').on('click', 'a', function (e) {
            self.getDataLayer().push({
                link_text: $(e.currentTarget).text(),
                parent_menu: 'Alert Menu',
                menu_type: 'Utility Bar',
                click_url: $(e.currentTarget).attr('href')
            });
        });
        $('[data-language-menu]').on('click', 'div', function (e) {
            pushMenuClickEventLog($(e.target).text(), 'Language Menu', 'Utility Bar');
        });
        $('[data-utility-bar-language]').on('click', 'li a', function (e) {
            pushMenuClickEventLog($(e.target).text(), 'Language Menu', 'Utility Bar');
        });
        $('[data-utility-bar-user-info]').on('click', 'a', function (e) {
            var linkText = $(e.currentTarget).text();
            if ($(e.currentTarget).find("img").length > 0) {
                linkText = 'User Menu';
            }
            pushMenuClickEventLog(linkText, 'User Menu', 'Utility Bar');
        });
        function pushMenuClickEventLog(linkText, parentMenu, menuType) {
            var dataLayer = self.getDataLayer();
            dataLayer.push({
                event: 'navigation',
                link_text: linkText,
                parent_menu: parentMenu,
                menu_type: menuType
            });
        }
    };
    VmBase.prototype.validateEmail = function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    VmBase.prototype.getContentBlock = function (slug, group) {
        var contentBlock = null;
        if (group !== undefined) {
            contentBlock = ko.utils.arrayFirst(this.ContentBlocks(), function (item) { return item.Slug() == slug && item.Group() == group; });
        }
        else {
            contentBlock = ko.utils.arrayFirst(this.ContentBlocks(), function (item) { return item.Slug() == slug; });
        }
        return contentBlock !== null ? contentBlock.Content() : '';
    };
    VmBase.prototype.getGlobalContentBlock = function (slug, group) {
        var contentBlock = null;
        if (group !== undefined) {
            contentBlock = ko.utils.arrayFirst(this.GlobalContentBlocks(), function (item) { return item.Slug() == slug && item.Group() == group; });
        }
        else {
            contentBlock = ko.utils.arrayFirst(this.GlobalContentBlocks(), function (item) { return item.Slug() == slug; });
        }
        return contentBlock !== null ? contentBlock.Content() : '';
    };
    VmBase.prototype.setupSecondaryNavigation = function () {
        var _this = this;
        var self = this;
        if (ko.unwrap(self.SecondaryNavigation) != null) {
            var compareUrls = function (first, second) {
                return ko.unwrap(first).toUpperCase().trim() === ko.unwrap(second).toUpperCase().trim();
            };
            var isItemActive = function (location, item) {
                var url = item.Url() ? item.Url() : '';
                var firstSlash = url.indexOf('/');
                var pathAndHash = firstSlash > 0 ? url.substring(firstSlash) : url;
                var exactMatch = compareUrls(location, url);
                var childMatch = false;
                if (!exactMatch && item.SubNav && item.SubNav().length > 0) {
                    childMatch = _.some(item.SubNav(), function (item) {
                        return (isItemActive(location, item));
                    });
                }
                return exactMatch || childMatch;
            };
            _.each(this.SecondaryNavigation.NavItems(), function (item) {
                item.IsActive = ko.computed(function () {
                    return isItemActive(self.WindowLocation, item);
                });
                if (item.SubNav && item.SubNav().length > 0) {
                    _.each(item.SubNav(), function (item) {
                        item.IsActive = ko.computed(function () {
                            return isItemActive(self.WindowLocation, item);
                        });
                        item.IsActive = ko.computed(function () {
                            return isItemActive(self.WindowLocation, item);
                        });
                        if (item.SubNav && item.SubNav().length > 0) {
                            _.each(item.SubNav(), function (item) {
                                item.IsActive = ko.computed(function () {
                                    return isItemActive(self.WindowLocation, item);
                                });
                            });
                        }
                    });
                }
            });
            this.SecondaryNavigation.ActiveTitle = ko.pureComputed(function () {
                var activeItem = ko.utils.arrayFirst(_this.SecondaryNavigation.NavItems(), function (item) {
                    return item.IsActive();
                });
                if (activeItem != null && activeItem != undefined)
                    return activeItem.DisplayName();
            });
        }
    };
    VmBase.prototype.mapInitData = function (data) {
        mapper.fromJsToModel(data, this);
    };
    VmBase.prototype.setNewItem = function () {
        this.NewItemDetail = mapper.toJs(this.ItemDetail);
    };
    VmBase.prototype.beginSignOut = function () {
        this.Analytics.reset();
        this.Analytics.setAnonymousId('');
        var signOutUrl = "/" + this.CurrentLanguageCode() + "/login/signout";
        this.addLogoutIframe('logout-frame', this.handleIFrameLoadedEvent(signOutUrl));
    };
    VmBase.prototype.beginSessionTimeOut = function () {
        var signOutUrl = "/" + this.CurrentLanguageCode() + "/login/signout";
        $("#sessionDialogBox").hide(); //*session popup hides at first load/reset
        $("#overlay").hide();
        //clear the actual timeout, if any in-progress
        clearTimeout(this.HandleForSessionTimeOut);
        clearTimeout(this.HandleForWarningTimeout);
        if (this.User.IsAuthenticated()) {
            //start the timer for warning timeout -- triggers at 18th minute
            this.HandleForWarningTimeout = setTimeout(this.showSessionTimeOutWarning, 1000 * 60 * this.WarningSessionTimeOut());
            //start the timer for actual timeout -- tirggers at 20th minute
            this.HandleForSessionTimeOut = setTimeout(this.executeSessionTimeOut.bind(this), 1000 * 60 * this.SessionTimeOut(), signOutUrl);
        }
    };
    //*warningTimer function to show warning popup after TimeToShowWarning
    VmBase.prototype.showSessionTimeOutWarning = function () {
        $("#sessionDialogBox").show();
        $("#overlay").show();
    };
    //executeSessionTimeOut function is to logout the session
    VmBase.prototype.executeSessionTimeOut = function (url) {
        this.pushSignoutSegmentAnalyticsEvents('inactivity');
        $("#sessionDialogBox").hide();
        $("#overlay").hide();
        this.addLogoutIframe('logout-frame', this.handleIFrameLoadedEvent(url));
    };
    VmBase.prototype.AddAlert = function (message, alertType, isLocalized) {
        if (isLocalized === void 0) { isLocalized = false; }
        //do not add duplicate messages
        if (_.some(this.Alerts(), function (x) { return x.Message === message; }))
            return;
        if (this.FrontEndContentBlocks() && this.FrontEndContentBlocks().length > 0) {
            var block = ko.utils.arrayFirst(this.FrontEndContentBlocks(), function (item) {
                return item.Slug() === message;
            });
            if (block) {
                message = block.Content();
            }
            else { // TODO: falling back to content blocks but if these are ever optimized out will need to update this logic
                if (this.ContentBlocks() && this.ContentBlocks().length > 0) {
                    block = ko.utils.arrayFirst(this.ContentBlocks(), function (item) {
                        return item.Slug() === message;
                    });
                    if (block)
                        message = block.Content();
                }
            }
        }
        var css = this.GetAlertClass(alertType);
        var alert = {
            Message: message,
            Type: css,
            IsVisible: ko.observable(true)
        };
        this.Alerts.push(alert);
        if (alertType === 'success') {
            setTimeout(function () {
                alert.IsVisible(false);
            }, 4000);
        }
    };
    VmBase.prototype.GetAlertClass = function (alertType) {
        var css = 'alert-warning';
        if (alertType === 'info')
            css = 'alert-info';
        if (alertType === 'error')
            css = 'alert--error bg-warning';
        if (alertType === 'success')
            css = 'alert--success bg-success';
        return css;
    };
    VmBase.prototype.RemoveAlert = function (message) {
        this.Alerts.remove(function (item) {
            return item.Message === message;
        });
    };
    VmBase.prototype.RemoveAlerts = function () {
        this.Alerts.removeAll();
    };
    VmBase.prototype.updateUserAlertsCookie = function (id) {
    };
    VmBase.prototype.SubmitLoginRequest = function (evt) {
        var _this = this;
        if (this.LoginViewModel != null && this.LoginViewModel != undefined) {
            var enteredEmailAddress = $('#ItemDetail_EmailAddress').val();
            var enteredPassword = $('#ItemDetail_Password').val();
            if (enteredEmailAddress.length > 0 && enteredEmailAddress !== this.LoginViewModel.ItemDetail.EmailAddress()) // Manually trigger a change for KO to get the updated values as some password managers won't trigger the change event
                this.LoginViewModel.ItemDetail.EmailAddress(enteredEmailAddress);
            if (enteredPassword.length > 0 && enteredPassword !== this.LoginViewModel.ItemDetail.Password())
                this.LoginViewModel.ItemDetail.Password(enteredPassword);
            this.IsLoading(true);
            evt.preventDefault();
            var ignoreMapping = {
                'ignore': this.data.KOMapping.allNonPostBack
            };
            var postData = mapper.toJsWithOptions(this.LoginViewModel, ignoreMapping);
            var authUrl = '/' + ko.unwrap(this.LoginViewModel.CurrentLanguageCode()) + '/login/authenticate';
            var loginUrl = '/' + ko.unwrap(this.LoginViewModel.CurrentLanguageCode()) + '/login#/index?error=true{{EMAIL}}';
            if (this.LoginViewModel.ItemDetail.RecaptchaNotValidError() == true || (this.LoginViewModel.ItemDetail.RecaptchaVerifyValue() == null)) {
                loginUrl = '/' + ko.unwrap(this.LoginViewModel.CurrentLanguageCode()) + '/login#/index?error=RecaptchaNotValidError{{EMAIL}}';
            }
            if (this.LoginViewModel.ItemDetail.TwoFactorServiceIssue() == true) {
                loginUrl = '/' + ko.unwrap(this.LoginViewModel.CurrentLanguageCode()) + '/login#/index?error=TwoFactorServiceIssue{{EMAIL}}';
            }
            var promise = $.ajax({
                url: authUrl,
                data: JSON.stringify(postData),
                type: 'POST',
                cache: false,
                contentType: 'application/json;charset=utf-8'
            });
            promise.done(function (data) {
                _this.DataLayer.push({
                    'event': 'memberArray',
                    'memberType': _this.User.ClubMemberType(),
                    'memberID': _this.User.MemberId()
                });
                window.location.href = data.ReturnUrl;
            });
            promise.fail(function (error) {
                _this.DataLayer.push({
                    'event': 'SigninError',
                    'error': error.statusText
                });
                _this.IsLoading(false);
                if (error.responseJSON.ItemDetail.TwoFactorAuthRequired === true) {
                    _this.LoginViewModel.ItemDetail.TwoFactorAuthRequired(true);
                    var element = $('.pl-l pr-s pos-r utility-bar__signin');
                    element.toggleClass('is-open');
                }
                else {
                    var email = error.responseJSON.ItemDetail.EmailAddress;
                    var redirect = loginUrl.replace('{{EMAIL}}', email == null ? '' : '&email=' + email);
                    window.location.href = redirect;
                }
            });
        }
    };
    VmBase.prototype.resendTwoFactorCode = function () {
        var _this = this;
        if (this.LoginViewModel != null && this.LoginViewModel != undefined) {
            this.IsLoading(true);
            var ignoreMapping = {
                'ignore': this.data.KOMapping.allNonPostBack
            };
            var postData = mapper.toJsWithOptions(this.LoginViewModel, ignoreMapping);
            var twofactor = '/' + ko.unwrap(this.LoginViewModel.CurrentLanguageCode()) + '/login/TwoFactorResendCode';
            document.getElementById("twoFactor_base_Login_Error").innerHTML = "";
            var promise = $.ajax({
                url: twofactor,
                data: JSON.stringify(postData),
                type: 'POST',
                cache: false,
                contentType: 'application/json;charset=utf-8'
            });
            promise.done(function (data) {
                if (data == true) {
                    _this.IsLoading(false);
                    var element = $('.pl-l pr-s pos-r utility-bar__signin');
                    element.toggleClass('is-open');
                }
            });
            promise.fail(function (failData) {
                _this.IsLoading(false);
                var data = failData.responseJSON;
                document.getElementById("twoFactor_base_Login_Error").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            });
        }
    };
    VmBase.prototype.submitTwoFactor = function (evt) {
        var _this = this;
        if (this.LoginViewModel != null && this.LoginViewModel != undefined) {
            document.getElementById("twoFactor_base_Login_Error").innerHTML = "";
            this.IsLoading(true);
            var ignoreMapping = {
                'ignore': this.data.KOMapping.allNonPostBack
            };
            var postData = mapper.toJsWithOptions(this.LoginViewModel, ignoreMapping);
            var twofactor = '/' + ko.unwrap(this.LoginViewModel.CurrentLanguageCode()) + '/login/twofactor';
            var promise = $.ajax({
                url: twofactor,
                data: JSON.stringify(postData),
                type: 'POST',
                cache: false,
                contentType: 'application/json;charset=utf-8'
            });
            promise.done(function (data) {
                if (data == true) {
                    _this.SubmitLoginRequest(evt);
                }
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                document.getElementById("twoFactor_base_Login_Error").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            });
            this.IsLoading(false);
        }
    };
    VmBase.prototype.initOverlayValues = function () {
        var _this = this;
        // Help overlays
        this.IsWebViewCookieKey = "IsWebView";
        var isWebViewCookieValue = Cookies.get(this.IsWebViewCookieKey);
        this.IsWebViewCookie = (isWebViewCookieValue === null || isWebViewCookieValue === undefined) ? false : isWebViewCookieValue === 'true';
        this.IsWebView(this.IsWebViewCookie);
        this.HelpOverlaysCookieKey = 'display-help-overlays';
        var helpOverlaysCookieValue = Cookies.get(this.HelpOverlaysCookieKey);
        this.HelpOverlaysCookie = (helpOverlaysCookieValue === null || helpOverlaysCookieValue === undefined) ? true : helpOverlaysCookieValue.toLowerCase() == 'true';
        this.UpdateHelpOverlaysEnabled(this.HelpOverlaysCookie, true, false, false);
        this.ClosedHelpOverlaysCookieKey = 'closed-help-overlays';
        var closedHelpOverlaysCookieValue = Cookies.get(this.ClosedHelpOverlaysCookieKey);
        this.ClosedHelpOverlaysCookie = (closedHelpOverlaysCookieValue === null || closedHelpOverlaysCookieValue === undefined) ? '' : closedHelpOverlaysCookieValue;
        this.ClosedHelpOverlays(this.ClosedHelpOverlaysCookie);
        this.HelpOverlaysEnabled.subscribe(function (value) {
            _this.UpdateHelpOverlaysEnabled(value, false, true, true);
        });
    };
    VmBase.prototype.initOverlay = function () {
        var element = $("#help-overlay");
        if (HGV['HelpOverlay'] === undefined)
            HGV['HelpOverlay'] = {};
        HGV.HelpOverlay['help-overlay'] = new HGV.Constructors.HelpOverlay(element);
    };
    VmBase.prototype.DisplayHelpOverlays = function () {
        if (ko.unwrap(this.HasHelpOverlay) && this.ShouldDisplayOverlay()) {
            HGV.HelpOverlay['help-overlay'].toggle(true);
        }
    };
    VmBase.prototype.ShouldDisplayOverlay = function (titleOverride) {
        var rendered = ko.unwrap(this.RenderHelpOverlay);
        var enabled = this.HelpOverlaysEnabled();
        var closedOverlays = this.ClosedHelpOverlays().split('|');
        var isWebView = this.IsWebView();
        var overlayTitle = titleOverride != null ? titleOverride : this.Title();
        var closed = _.some(closedOverlays, function (overlay) {
            return overlay === overlayTitle;
        });
        return rendered && enabled && !closed && !isWebView;
    };
    VmBase.prototype.UpdateHelpOverlaysEnabled = function (value, updateValue, updateClosedValue, trigger) {
        if (updateValue)
            this.HelpOverlaysEnabled(value);
        this.HelpOverlaysCookie = value;
        Cookies.set(this.HelpOverlaysCookieKey, value, { expires: 3650 });
        if (value === true && updateClosedValue === true) {
            this.UpdateClosedOverlays(null);
        }
        if (trigger)
            this.DisplayHelpOverlays();
    };
    VmBase.prototype.UpdateClosedOverlays = function (overlay) {
        var newValue = '';
        if (overlay !== null) {
            var currentValue = ko.unwrap(this.ClosedHelpOverlays());
            var newValue = currentValue + (currentValue.length > 0 ? '|' : '') + overlay;
        }
        this.ClosedHelpOverlays(newValue);
        this.ClosedHelpOverlaysCookie = newValue;
        Cookies.set(this.ClosedHelpOverlaysCookieKey, newValue, { expires: 3650 });
    };
    VmBase.prototype.updateUserPoints = function () {
        var _this = this;
        var postTo = "/" + this.CurrentLanguageCode() + "/Points-dashboard/RefreshPoints";
        var promise = $.ajax({
            url: postTo,
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8',
        });
        promise.done(function (data) {
            mapper.fromJsExisting(data, _this.GlobalNavigation.UserPoints);
        });
    };
    VmBase.prototype.getAllText = function () {
        var content = _.find(ko.toJS(this.GlobalContentBlocks()), function (p) { return (p.Slug === 'AllAvailable'); });
        if (content)
            return content.Content;
    };
    VmBase.prototype.getBonusText = function () {
        var content = _.find(ko.toJS(this.GlobalContentBlocks()), function (p) { return (p.Slug === 'BonusPoints'); });
        if (content)
            return content.Content;
    };
    VmBase.prototype.ResetCache = function () {
        var _this = this;
        $('#clearcache-modal').modal('hide');
        this.IsLoading(true);
        var authUrl = "/" + this.Area() + "/SystemContentPage/ClearCache";
        var promise = $.ajax({
            url: authUrl,
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.done(function (data) {
            _this.IsLoading(false);
            if (data === 'Ok') {
                //clearcache-done
                $('#clearcache-done').modal('show');
            }
            else {
                $('#clearcache-error').modal('show');
            }
        });
        promise.fail(function (error) {
            //
            _this.IsLoading(false);
        });
    };
    VmBase.prototype.enableIsLoading = function (showMessage) {
        $('#loading').height($(window).height());
        this.IsLoading(true);
        if (showMessage) {
            this.IsLoadingTransaction(true);
        }
    };
    VmBase.prototype.generateUniqueId = function (id) {
        var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); return v.toString(16); });
        return id + '_' + guid;
    };
    VmBase.prototype.getDataLayer = function () {
        return window.dataLayer || [];
    };
    VmBase.prototype.recaptchaSolveCallback = function (value) {
        this.LoginViewModel.ItemDetail.RecaptchaVerifyValue(value);
    };
    VmBase.prototype.getAnalytics = function () {
        return window.analytics || [];
    };
    VmBase.prototype.pushSignoutGoogleAnalyticsEvents = function () {
        var _this = this;
        var UserType;
        (function (UserType) {
            UserType[UserType["None"] = 0] = "None";
            UserType[UserType["Deeded"] = 1] = "Deeded";
            UserType[UserType["Trust"] = 2] = "Trust";
        })(UserType || (UserType = {}));
        var postTo = "/" + this.CurrentLanguageCode() + "/account/GetCurrentYearTotalPoints";
        var memberType = "";
        var leadId = this.User != null ? this.User.ULeadId() : "";
        //seprate data push for contract only/traditional owners or no active membership because they don't have points.
        if (this.User.UserType() === undefined
            || this.User.UserType() == UserType.None
            || this.User.IsContractOnlyMember() == true
            || this.User.IsTrustTraditionalOwner() == true) {
            this.DataLayer.push({
                event: "logout",
                memberType: memberType,
                leadId: leadId,
                pointsTotal: "",
                pageName: document.URL
            });
            this.beginSignOut();
        }
        else {
            if (this.User.UserType() == UserType.Deeded) {
                memberType = this.User.ClubMemberType();
            }
            else {
                memberType = this.User.TrustMemberContext.MemberTypeCode();
            }
            var promise = $.ajax({
                url: postTo,
                type: 'GET',
                cache: false,
                contentType: 'application/json;charset=utf-8',
            });
            promise.done(function (data) {
                _this.DataLayer.push({
                    event: "logout",
                    memberType: memberType,
                    leadId: leadId,
                    pointsTotal: data != null ? data.pointsTotal : 0,
                    pageName: document.URL
                });
                _this.beginSignOut();
            });
            promise.fail(function (error) {
                console.log(error.statusText);
            });
        }
    };
    VmBase.prototype.pushSignoutSegmentAnalyticsEvents = function (reason) {
        var uLeadId = this.User != null ? this.User.ULeadId() : "";
        var leadId = this.User != null ? this.User.LeadId() : "";
        var firstName = this.User != null ? this.User.FirstName() : "";
        var lastName = this.User != null ? this.User.LastName() : "";
        var profileName = firstName + " " + lastName;
        var userName = this.User != null ? this.User.UserName() : "";
        var email = this.User != null ? this.User.Email() : "";
        var timeStamp = new Date().toLocaleString();
        var sessionDuration = this.segmentSessionDuration();
        var logoutReason = reason;
        this.Analytics.track("Logout", {
            u_lead_id: uLeadId,
            lead_id: leadId,
            profile_name: profileName,
            username: userName,
            email: email,
            timestamp: timeStamp,
            session_duration: sessionDuration,
            logout_reason: logoutReason
        });
        sessionStorage.removeItem("SessionStartTime");
    };
    VmBase.prototype.segmentSessionDuration = function () {
        var analyticsEndTime = new Date().getTime();
        var analyticsStartTime = Number(this.AnalyticsStartTime);
        var fullSessionDuration = (analyticsEndTime - analyticsStartTime) / 1000;
        var sessionMinutes = Math.floor(fullSessionDuration / 60);
        var sessionSeconds = Math.trunc(fullSessionDuration % 60);
        var sessionDuration = sessionMinutes + " minutes " + sessionSeconds + " seconds";
        return sessionDuration;
    };
    VmBase.prototype.getAnalyticsSessionTime = function () {
        var analyticsSessionStartTime = new Date().getTime().toString();
        var sessionStartTime = sessionStorage.getItem("SessionStartTime");
        if (sessionStartTime == null || sessionStartTime == undefined) {
            sessionStartTime = sessionStorage.setItem("SessionStartTime", analyticsSessionStartTime);
        }
        sessionStartTime = sessionStorage.getItem("SessionStartTime");
        return sessionStartTime;
    };
    return VmBase;
}());
exports.VmBase = VmBase;

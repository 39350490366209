"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: HoaPayment
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVmBase");
var HoaPaymentTable = require("./HoaPaymentTable");
var HoaPaymentRouter = require("./HoaPaymentRouter");
var HoaPaymentForm = require("./HoaPaymentForm");
require("../../../Scripts/lib/knockoutgrid.extended.js");
var HoaPayment = /** @class */ (function (_super) {
    __extends(HoaPayment, _super);
    function HoaPayment(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, HoaPaymentForm, HoaPaymentTable, HoaPaymentRouter) || this;
        _this.data = data;
        _this.TotalAmountDue = ko.observable(0);
        _this.selectedPaymentCard = ko.observable();
        _this.showCreditCardFrame = ko.observable(false);
        _this.USCurrencyDisplay = ko.observable(false);
        _this.FlywireError = ko.observable(false);
        _this.HasBonusPointsError = ko.observable(false);
        _this.DisableLocalCurrBtn = ko.observable(false);
        _this.initHtsTokenizer();
        var frame = $('#jpnCC');
        frame.hide();
        _this.picker.setupCalendarBindings(_this, 'TopDatePicker');
        ko.applyBindings(_this);
        $('.hideUnbound').removeClass('hideUnbound');
        var baseVal = _this.ItemDetail;
        _this.cardDetails = ko.observable(_this.ItemDetail.CardInfo());
        var paymentUrl = _this.ItemDetail.YenPaymentLink();
        if (paymentUrl) {
            _this.paymentUrl = ko.observable(_this.ItemDetail.YenPaymentLink().replace(/['"]+/g, ''));
        }
        _this.ItemDetail.SelectedPayType.subscribe(function (newValue) {
            if (newValue === "1") {
                _this.ItemDetail.OtherAmount(0);
            }
            else if (newValue === "2") {
                //Make sure we don't have arda checked when selecting other
                _this.ItemDetail.ArdaPayment(false);
            }
        });
        _this.HOAStatementsByYear = ko.computed(function () {
            var self = _this;
            var HoaStatments;
            HoaStatments = ko.utils.arrayFilter(self.ItemDetail.HoaStatements(), function (item) {
                return item.StatementYear() == self.ItemDetail.HoaStatementYear();
            });
            return HoaStatments;
        });
        _this.ClubStatementsByYear = ko.computed(function () {
            var self = _this;
            var ClubStatments;
            ClubStatments = ko.utils.arrayFilter(self.ItemDetail.ClubStatements(), function (item) {
                return item.StatementYear() == self.ItemDetail.ClubStatementYear();
            });
            return ClubStatments;
        });
        _this.HOAPagedGridModel = function (items) {
            this.gridHOAViewModel = new ko.simpleGrid.viewModel({
                data: items,
                pageSize: 5,
                headerTemplate: "StatementHeaderTemplate",
                columnTemplate: "StatementBodyTemplate"
            });
        };
        _this.ClubPagedGridModel = function (items) {
            this.gridClubViewModel = new ko.simpleGrid.viewModel({
                data: items,
                pageSize: 5,
                headerTemplate: "StatementHeaderTemplate",
                columnTemplate: "StatementBodyTemplate"
            });
        };
        _this.HOAPagedGridModel(_this.HOAStatementsByYear);
        _this.ClubPagedGridModel(_this.ClubStatementsByYear);
        _this.ItemDetail.BonusPointsUsed.subscribe(function (newValue) {
            var self = _this.ItemDetail;
            if (newValue > self.TotalBonusPoints()) {
                self.BonusPointsUsed(self.TotalBonusPoints());
            }
            else if (newValue < 0) {
                self.BonusPointsUsed(0);
            }
            else if (isNaN(newValue)) {
                self.BonusPointsUsed(0);
            }
            else if (newValue === "") {
                self.BonusPointsUsed(0);
            }
            var used = parseFloat(self.BonusPointsUsed().toString());
            //Calc amount
            self.BonusPointAmount((used * self.PointConversion()));
            //Calc Left
            self.BonusPointsLeft(self.TotalBonusPoints() - used);
            if (_this.ItemDetail.RemainingAmount() == 0) {
                _this.DisableLocalCurrBtn(true);
            }
            else {
                _this.DisableLocalCurrBtn(false);
            }
        });
        _this.ItemDetail.TotalAmountDue = ko.computed(function () {
            var self = _this.ItemDetail;
            var payments = 0;
            if (self.PayAll()) {
                self.PayAllDetails().forEach(function (value) {
                    value.CalculatedFeeAmount(value.FeeAmount());
                    if (value.ArdaSelected()) {
                        value.CalculatedFeeAmount(value.FeeAmount() + value.ArdaAmount());
                        _this.ItemDetail.ArdaPayment(true);
                    }
                    if (value.Selected()) {
                        payments += value.CalculatedFeeAmount();
                    }
                });
            }
            else {
                if (self.SelectedPayType() === "1")
                    payments += parseFloat(self.AmountDue().toString());
                else if (self.SelectedPayType() === "2")
                    if (self.OtherAmount().toString() !== '') {
                        payments += parseFloat(self.OtherAmount().toString());
                    }
                if (self.IsClubDues())
                    payments += self.ClubDuesAmount();
                if (self.ArdaPayment())
                    payments += self.ArdaAmount();
            }
            //this.TotalAmountDue(payments);
            return (payments);
        });
        _this.ItemDetail.RemainingAmount = ko.computed(function () {
            //Calculate with points entered the amount.
            var self = _this.ItemDetail;
            var payments = ko.unwrap(self.TotalAmountDue());
            var bonusPointAmount = self.BonusPointAmount();
            var transactionFeeAmount = ko.unwrap(self.TransactionFeeAmount());
            var remainingAmount = payments - bonusPointAmount;
            var totalAmount = remainingAmount + transactionFeeAmount;
            _this.TotalAmountDue(totalAmount);
            _this.ItemDetail.CreditCardForm.Amount(totalAmount);
            return remainingAmount;
        });
        _this.ItemDetail.TotalPaymentAmountDue = ko.computed(function () {
            var self = _this.ItemDetail;
            var bonusPointsUsed = parseFloat(self.BonusPointsUsed().toString());
            var remainingAmount = ko.unwrap(self.RemainingAmount);
            if (bonusPointsUsed <= 0) {
                return remainingAmount;
            }
            var bonusPointsFee = self.BonusPointsFee();
            remainingAmount += bonusPointsFee;
            return remainingAmount;
        });
        _this.ItemDetail.TransactionFeeAmount = ko.computed(function () {
            var self = _this.ItemDetail;
            var bonusPointsUsed = parseFloat(self.BonusPointsUsed().toString());
            var bonusPointsFee = self.BonusPointsFee();
            var transactionFeeAmount = bonusPointsUsed > 0 ? bonusPointsFee : 0;
            return transactionFeeAmount;
        });
        _this.ItemDetail.BonusPointsLeft(_this.ItemDetail.TotalBonusPoints());
        _this.DocumentByYearAmount = ko.computed(function () {
            var self = _this;
            var amount = 0;
            if (typeof self.ItemDetail.HoaDocumentYear() != "undefined") {
                var year = self.ItemDetail.HoaDocumentYear();
                _.each(self.ItemDetail.HoaDocuments(), function (v) {
                    if (v.Year() === year) {
                        amount++;
                    }
                });
            }
            return amount;
        });
        _this.HOAStatementCountByYear = ko.computed(function () {
            var self = _this;
            return ko.utils.arrayFilter(self.ItemDetail.HoaStatements(), function (item) {
                return item.StatementYear() == self.ItemDetail.HoaStatementYear();
            }).length;
        });
        _this.TotalTranactionValue = ko.computed(function () {
            var self = _this;
            var tot = 0;
            _.each(self.ItemDetail.BalanceDetails(), function (v) {
                tot += Number(v.Balance());
            });
            return tot;
        });
        _this.ClubStatementCountByYear = ko.computed(function () {
            var self = _this;
            return ko.utils.arrayFilter(self.ItemDetail.ClubStatements(), function (item) {
                return item.StatementYear() == self.ItemDetail.ClubStatementYear();
            }).length;
        });
        _this.DocumentByYearAndCategoryAmount = function (category) {
            var self = _this;
            var amount = 0;
            if (typeof self.ItemDetail.HoaDocumentYear() != "undefined") {
                var year = self.ItemDetail.HoaDocumentYear();
                var documents = ko.utils.arrayFilter(self.ItemDetail.AllDocuments(), function (document) {
                    return document.Name() === category;
                });
                _.each(documents, function (v) {
                    _.each(v.Documents(), function (w) {
                        if (w.Year() === year) {
                            amount++;
                        }
                    });
                });
            }
            return amount;
        };
        _this.PermanentDocumentsCount = function (category) {
            var self = _this;
            var count = 0;
            if (typeof self.ItemDetail.PermanentDocuments() != "undefined") {
                var docs = ko.utils.arrayFilter(self.ItemDetail.PermanentDocuments(), function (document) {
                    return document.Name() === category;
                });
                _.each(docs, function (v) {
                    _.each(v.Documents(), function (w) {
                        count++;
                    });
                });
            }
            return count;
        };
        _this.selectedPaymentCard.subscribe(function (newValue) {
            if (newValue != undefined) {
                baseVal.CreditCardForm.CardToken = newValue['ProviderToken']();
                baseVal.CreditCardForm.LastFourCardNumber = newValue['MaskedCardNo']();
                baseVal.CreditCardForm.NameOnCard = newValue['HolderName']();
                //Number - Required
                //CardType - Required
                //Month -Required
                //Year - Required
                var month = newValue['Expiration']();
                var mo = month.substring(0, 2);
                baseVal.CreditCardForm.Month = mo;
                var year = month.substring(2, 4);
                baseVal.CreditCardForm.Year = year;
                console.log('month ', mo);
                console.log('CC token: ', baseVal.CreditCardForm.CardToken);
                console.log('LastFourCardNumber: ', baseVal.CreditCardForm.LastFourCardNumber);
                console.log('NameOnCard: ', baseVal.CreditCardForm.NameOnCard);
            }
            else {
                InitializeCard();
            }
        });
        function InitializeCard() {
            baseVal.CreditCardForm.CardToken = ko.observable("");
            baseVal.CreditCardForm.LastFourCardNumber = ko.observable("");
            baseVal.CreditCardForm.NameOnCard = ko.observable("");
            baseVal.CreditCardForm.Month = ko.observable("");
            baseVal.CreditCardForm.Year = ko.observable("");
        }
        _this.showPanel = function () {
            var frame = $('#jpnCC');
            frame.toggle();
            //console.log('Payment URL: ', this.paymentUrl());
            frame.attr('src', _this.paymentUrl());
            InitializeCard();
            $('#selectCard').hide();
            $('#populateCard').show();
        };
        _this.ShowUSCurrency = function () {
            _this.USCurrencyDisplay(true);
            _this.FlywireError(false);
        };
        _this.FlywireLog = function (args) {
            console.log(args);
            var data = {
                RawJson: ko.toJSON(args),
                Reference: args.reference,
                Status: args.status,
                Amount: args.amount,
                PaymentMethod: args.paymentMethod,
                Nonce: args._,
                ContractNumber: _this.ItemDetail.ContractNumber()
            };
            $.ajax({
                type: "POST",
                url: "../HoaPayment/Log",
                data: ko.toJSON(data),
                contentType: "application/json",
                success: function (d) {
                    console.log("log post success");
                },
                error: function () {
                    console.log("log post error", data);
                }
            });
        };
        _this.FlywirePaymentSuccess = function (args) {
            var self = _this;
            // no need to save response that happens in the background
            //store response, this needs to be our response object not args!!
            self.ItemDetail.FlyWireResponse = ko.observable({
                Amount: args && args.amount,
                ContractNumber: _this.ItemDetail.ContractNumber,
                Nonce: args && args._,
                PaymentMethod: args && args.paymentMethod,
                RawJson: ko.toJSON(args),
                Reference: args && args.reference,
                Status: args && args.status
            });
            self.ItemDetail.CreditCardForm = null; // remove it so we dont validate it
        };
        _this.AddAlert = function (message, alertType, isLocalized) {
            // key, error, true
            _super.prototype.AddAlert.call(_this, message, alertType, isLocalized); // continue calling base
            _this.HasBonusPointsError = ko.observable(message == 'FailedToMakePointPayment');
        };
        _this.HackIt = function () {
            _this.FlywirePaymentSuccess({
                reference: "HER193415408",
                status: "pending",
                amount: 1,
                paymentMethod: "bank_transfer",
                _: "t3TSj9lC1LGUfOglFS/016seWywLq6VUdyq6FgmWclLKzQIHjwQS0Sj24MyS+HguztUhupPOBZKgo0LeMFD7Og==",
                sig: '###$#$@#$@'
            });
        };
        // this isnt' working we'll move it into hoa payment form and attach tto the damn id
        _this.ShowFlywire = function (callback) {
            if (_this.ItemDetail.FlyWireResponse()) {
                callback();
                return; // skip flywire if we have a response, something may have failed in the callback
            }
            _this.USCurrencyDisplay(false);
            _this.FlywireError(false);
            var self = _this;
            var config = {
                // Set the target environment (demo, prod)
                env: _this.ItemDetail.FlyWireEnvVariable(),
                // Set your unique code (may differ between demo and prod)
                recipientCode: _this.ItemDetail.FlyWirePortalCode.PortalCode(),
                // Set the value of the payment to be processed
                amount: _this.ItemDetail.RemainingAmount(),
                showLiveChat: true,
                // Pass in all portal specific required custom fields
                // (may differ between portals)
                recipientFields: {
                    contract_number: _this.ItemDetail.ContractNumber()
                },
                firstName: _this.ItemDetail.Profile.FirstName1(),
                requestPayerInfo: true,
                skipCompletedSteps: true,
                lastName: _this.ItemDetail.Profile.LastName1(),
                email: _this.ItemDetail.Profile.EmailAddress1(),
                phone: _this.ItemDetail.Profile.PrimaryPhone(),
                address: _this.ItemDetail.Profile.Address1(),
                city: _this.ItemDetail.Profile.City(),
                country: _this.ItemDetail.CountryIsoCode(),
                // Enable payment status notification callbacks
                callbackUrl: _this.ItemDetail.FlyWirecallbackUrl(),
                callbackId: _this.ItemDetail.FlyWireClaimID(),
                nonce: _this.ItemDetail.FlyWireClaimID(),
                // Recommended but not required - include a validation callback.
                // Please provide your own validation reporting feature rather
                // than alerting the user and logging to the console.
                locale: _this.CurrentLanguageCode(),
                onInvalidInput: function (errors) {
                    //errors.forEach(function (error) { alert(error.msg); });
                    console.error(errors);
                    //alert('-error-');
                    //alert(errors); flywire handle these form/input erros on their end
                },
                onCompleteCallback: function (args) {
                    switch (args.status) {
                        case "pending":
                        case "success":
                            self.FlywirePaymentSuccess(args); // this will log 
                            if (callback)
                                callback();
                            break;
                        case "error":
                            // show error message on this window
                            self.FlywireError(true);
                            self.FlywireLog(args);
                            break;
                        default:
                            self.FlywireLog(args);
                            alert("Unknown status type, contact support: " + args.status);
                            break;
                    }
                }
            };
            if (_this.ItemDetail.RemainingAmount() > 0) {
                var modal = window["FlywirePayment"].initiate(config);
                modal.render();
            }
        };
        return _this;
    }
    return HoaPayment;
}(base.ResortSearchVmBase));
module.exports = HoaPayment;

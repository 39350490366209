"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: Login
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVMBase");
var LoginTable = require("./LoginTable");
var LoginRouter = require("./LoginRouter");
var LoginForm = require("./LoginForm");
var PasswordComplexity = require("../Base/PasswordComplexity");
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, LoginForm, LoginTable, LoginRouter) || this;
        _this.data = data;
        _this.ShowForgotPassword = ko.observable(false);
        _this.ShowForgotEmail = ko.observable(false);
        _this.ShowTwoFactorAuth = ko.observable(false);
        _this.HideTwoFactorAuth = ko.observable(true);
        _this.setupResetPasswordBindings();
        ko.applyBindings(_this);
        $('.hideUnbound').removeClass('hideUnbound');
        return _this;
    }
    //the callback called from the callback on the form index. Callback specified in the captcha div data-callback element
    Login.prototype.recaptchaSolveCallback = function (value) {
        this.ItemDetail.RecaptchaVerifyValue(value);
    };
    Login.prototype.setupResetPasswordBindings = function () {
        var _this = this;
        this.ResetPasswordDetail.PasswordMeetsMinimumLength = ko.computed(function () {
            return PasswordComplexity.MeetsMinimumLength(_this.ResetPasswordDetail.NewPassword());
        });
        this.ResetPasswordDetail.PasswordHasUppercaseLetter = ko.computed(function () {
            return PasswordComplexity.HasUppercaseLetter(_this.ResetPasswordDetail.NewPassword());
        });
        this.ResetPasswordDetail.PasswordHasNumberOrSpecialChar = ko.computed(function () {
            return PasswordComplexity.HasNumberOrSpecialChar(_this.ResetPasswordDetail.NewPassword());
        });
        this.ResetPasswordDetail.HasResetPasswordError = ko.computed(function () {
            var resetDto = _this.ResetPasswordDetail;
            return resetDto.PasswordsBlank() || resetDto.PasswordsDontMatch() || resetDto.PasswordsDontMeetComplexity() || resetDto.InvalidResetToken() || resetDto.NoMembershipsFoundError() || resetDto.NotOwnerOnMembershipError() || resetDto.ErrorResettingMembershipPassword();
        });
    };
    return Login;
}(base.ResortSearchVmBase));
module.exports = Login;

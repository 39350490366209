"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Router = require("../Base/Router");
var ManagePointsRouter = /** @class */ (function (_super) {
    __extends(ManagePointsRouter, _super);
    function ManagePointsRouter(data, vm, alternateroutes) {
        var _this = _super.call(this, data, vm, alternateroutes) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    ManagePointsRouter.prototype.getRoutes = function () {
        var self;
        self = this;
        return [
            {
                // No ID, default
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/index",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(false);
                    //Reset it all
                    self.vm.ItemDetail.IsAlreadyEnrolled(self.vm.isEnrolled);
                    self.vm.NewProtection.ProtectionAction(self.vm.protectionAction);
                    self.vm.NewProtection.CurrentFee(self.vm.protectionFee);
                    self.vm.NewForm("none");
                    self.vm.pointAction = "none";
                    self.vm.ItemDetail.Step(1);
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/rescue",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    //Set form
                    self.vm.NewForm("rescue");
                    self.vm.ItemDetail.Step(1);
                    self.vm.Fees(self.vm.NewRescue.Fees());
                    // Reset form
                    self.vm.NewRescue.ClubPointsToConvert(0);
                    self.vm.NewRescue.BhcPointsToConvert(0);
                    self.vm.setupCreditCardForm(false);
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/hhonors",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("hhonors");
                    self.vm.ItemDetail.Step(1);
                    self.vm.setupCreditCardForm(false);
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/rci",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("rci");
                    self.vm.ItemDetail.Step(1);
                    self.vm.refreshRciFees();
                    self.vm.setupCreditCardForm(false);
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/protection",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("protection");
                    self.vm.ItemDetail.Step(1);
                    //Reset it all
                    self.vm.ItemDetail.IsAlreadyEnrolled(self.vm.isEnrolled);
                    self.vm.NewProtection.ProtectionAction(self.vm.protectionAction);
                    self.vm.NewProtection.CurrentFee(self.vm.protectionFee);
                    //Reset it all
                    self.vm.ItemDetail.IsAlreadyEnrolled(self.vm.isEnrolled);
                    self.vm.NewProtection.ProtectionAction(self.vm.protectionAction);
                    self.vm.NewProtection.CurrentFee(self.vm.protectionFee);
                    self.vm.refreshProtectionFees(self.vm.NewProtection.ProtectionAction());
                    self.vm.setupCreditCardForm(true);
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/confirmation",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(true);
                    self.vm.ItemDetail.Step(3);
                    self.vm.updateUserPoints();
                    /* Preserve NewForm so we know where to go "back" to
                       for any edits.
            
                        self.vm.NewForm();
                    */
                }
            },
            {
                name: self.getArea() + "/" + self.data.Title + "#/help",
                func: function () {
                    // Update display properties
                    self.vm.FormVisible(false);
                    self.vm.NewForm("help");
                    self.vm.ItemDetail.Step(1);
                }
            }
        ];
    };
    return ManagePointsRouter;
}(Router));
module.exports = ManagePointsRouter;

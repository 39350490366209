"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _ = require("lodash");
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var HoaPaymentForm = /** @class */ (function (_super) {
    __extends(HoaPaymentForm, _super);
    function HoaPaymentForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    HoaPaymentForm.prototype.post = function (dataToPost) {
        var _this = this;
        var promise = _super.prototype.post.call(this, dataToPost);
        promise.done(function (data) {
            _this.vm.DataLayer.push({
                'event': 'MaintenanceFeePayment',
                'paymentAmount': data.ItemDetail.TotalAmountDue,
                'memberID': _this.vm.User.MemberId(),
                'transactionDate': (new Date(data.ItemDetail.CurrentDate)).toLocaleDateString()
            });
        });
        return promise;
    };
    HoaPaymentForm.prototype.getPostDate = function () {
        var ignoreMapping = {
            'ignore': this.data.KOMapping.allNonPostBack
        };
        var postData = mapper.toJsWithOptions(this.vm, ignoreMapping);
        delete postData.Alerts;
        delete postData.NewVersion;
        this.removeContentBlocks(postData);
        return postData;
    };
    HoaPaymentForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('body').on('click', '#flywireButton', function (e) {
            e.preventDefault();
            var callback = function () {
                self.vm.LeaveOpenOnAjaxSuccess(true);
                self.editAction = "Send";
                var promise = _this.post();
                promise.fail(function (error) {
                    self.scrollTop();
                });
                promise.done(function (data) {
                    self.editAction = "Edit";
                    window.location.hash = data.ReturnUrl;
                    _.each(data.ModelState.ModelErrors, function (v) {
                        self.vm.AddAlert(v.Key, 'error', true);
                    });
                });
            };
            self.vm.ShowFlywire(callback);
            return false;
        });
        $('body').on('click', "#populateCard", function (e) {
            _this.vm.RemoveAlerts();
            var populateGMOCardsUrl = "/api/cards/PopulateGMOCards?id=" + _this.getLeadId();
            var promise = $.ajax({
                cache: false,
                url: populateGMOCardsUrl
            });
            promise.done(function (data) {
                mapper.fromJsToModel(data, _this.vm.ItemDetail.CardInfo);
                $('#selectCard').show();
                $('#populateCard').hide();
                var frame = $('#jpnCC');
                frame.hide();
            });
            promise.fail(function (error) {
                console.log(error);
            });
        });
        $('body').on('click', "#showCCButton", function (e) {
            e.preventDefault();
            self.vm.ShowUSCurrency();
            return false;
        });
        $('body').on('click', '#confirmHoaPayment', function (e) {
            e.preventDefault();
            //Validating the page!
            if (_this.vm.IsLoading()) {
                return;
            }
            //make overlay loading div full height
            $('#loading').height($(window).height());
            _this.vm.IsLoading(true);
            if (_this.vm.TotalAmountDue() > 0 && !_this.vm.ItemDetail.IsJapanProperty()) {
                if (_this.validateCC(_this.vm.ItemDetail.CreditCardForm)) {
                    _this.GetFuseboxToken(self.vm.ItemDetail.CreditCardForm, window.location.href)
                        .then(function (data) {
                        if (data.CardToken().length > 0) {
                            self.vm.ItemDetail.CreditCardForm = data;
                            var stepOneData = self.getPostDate();
                            self.vm.IsLoading(true);
                            var promise = $.ajax({
                                url: self.area + "/" + self.vm.CurrentLanguageCode() + "/" + self.data.Title + "/Validate",
                                data: JSON.stringify(stepOneData),
                                type: 'POST',
                                cache: false,
                                contentType: 'application/json;charset=utf-8'
                            }).then(function (data) {
                                self.vm.RemoveAlerts();
                                self.vm.IsLoading(false);
                                self.vm.ItemDetail.Step(2);
                                self.scrollTop();
                            }).fail(function (postErr) {
                                self.vm.IsLoading(false);
                                self.postPromiseFail(postErr);
                                self.logFuseError(postErr);
                                self.scrollTop();
                            });
                        }
                        else {
                            self.postCCError();
                            self.logFuseError("token length is zero");
                        }
                    }).catch(function (e) {
                        self.postCCError();
                    });
                }
                else {
                    self.scrollTop();
                }
            }
            else {
                if ((_this.vm.ItemDetail.BonusPointAmount() > 0 && _this.vm.ItemDetail.TotalPaymentAmountDue() <= 0) || _this.validateJapanCC(_this.vm.ItemDetail.CreditCardForm)) {
                    var stepOneData = _this.getPostDate();
                    var promise = $.ajax({
                        url: _this.area + "/" + _this.vm.CurrentLanguageCode() + "/" + _this.data.Title + "/Validate",
                        data: JSON.stringify(stepOneData),
                        type: 'POST',
                        cache: false,
                        contentType: 'application/json;charset=utf-8'
                    });
                    promise.fail(function (error) { return self.postPromiseFail(error); });
                    promise.done(function (data) {
                        _this.vm.IsLoading(false);
                        //Dont want to map the complete creditcardform
                        _this.vm.ItemDetail.CreditCardForm.LastFourCardNumber =
                            data.ItemDetail.CreditCardForm.LastFourCardNumber;
                        console.log('LastFourCardNumber: ', _this.vm.ItemDetail.CreditCardForm.LastFourCardNumber);
                        _this.vm.ItemDetail.CreditCardForm.CardToken = data.ItemDetail.CreditCardForm.CardToken;
                        //Move to the next page
                        self.vm.RemoveAlerts();
                        self.vm.ItemDetail.Step(2);
                        self.scrollTop();
                    });
                }
            }
            return false;
        });
        $('body').on('click', '#submitHoaPayment', function (e) {
            $('#submitHoaPayment').hide();
            $('#goBackToDash').show();
            e.preventDefault();
            self.vm.LeaveOpenOnAjaxSuccess(true);
            self.editAction = "Send";
            var promise = _this.post();
            promise.fail(function (error) {
                self.scrollTop();
            });
            promise.done(function (data) {
                self.editAction = "Edit";
                window.location.hash = data.ReturnUrl;
                _.each(data.ModelState.ModelErrors, function (v) {
                    self.vm.AddAlert(v.Key, 'error', true);
                });
            });
            return false;
        });
        //Yes this is weird but it works!
        $('body').on('click', '.dummyClick', function (e) {
            e.preventDefault();
            var value = e.target.getAttribute("data-id"); //$(this).data('id');
            HGV.Dropdown['year-selector'].toggle();
            self.getYearHoaDocuments(value);
        });
        $('body').on('focus', '#ItemDetail_OtherAmount', function (e) {
            self.vm.ItemDetail.SelectedPayType('2');
        });
        $('body').on('click', '.btnPrint', function (event) {
            event.preventDefault();
            window.print();
        });
        if (this.vm.HTSIsEnabled()) {
            this.vm.ItemDetail.CreditCardForm.DisplayNumber.subscribe(function (newValue) {
                if (newValue != null) {
                    _this.vm.ItemDetail.CreditCardForm.Number(newValue);
                    _this.vm.ItemDetail.CreditCardForm.LastFourCardNumber(_this.vm.ItemDetail.CreditCardForm.DisplayNumber().substr(_this.vm.ItemDetail.CreditCardForm.DisplayNumber().length - 4));
                }
            });
        }
        this.vm.ItemDetail.CreditCardForm.CardType.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_CardType').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_CardType').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.CreditCardForm.Month.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Month').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Month').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.CreditCardForm.Year.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Year').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Year').addClass('creditForm');
            }
        });
    };
    HoaPaymentForm.prototype.getStaticHoaDocuments = function () {
        var _this = this;
        window.HGV.init();
        if (this.vm.InvalidContractId()) {
            return;
        }
        if (this.vm.IsLoading()) {
            return;
        }
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        this.editAction = "Documents";
        var url = this.postUrl + '/' + this.vm.ItemDetail.ContractNumber(); //.replace('ManagePoints/Edit', 'manage-points/ProcessPointsConversion');
        var promise = $.ajax({
            url: url,
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.fail(function (error) { return _this.getPromiseFail(error, null); });
        promise.done(function (data) { return _this.getStaticPromiseDone(data, null); });
    };
    HoaPaymentForm.prototype.komappingfromdata = function (data, id) {
        mapper.fromJsToModel(data.ItemDetail.HoaDocumentYear, this.vm.ItemDetail.HoaDocumentYear);
        mapper.fromJsToModel(data.ItemDetail.HoaDocumentYears, this.vm.ItemDetail.HoaDocumentYears);
        mapper.fromJsToModel(data.ItemDetail.HoaDocuments, this.vm.ItemDetail.HoaDocuments);
        mapper.fromJsToModel(data.ItemDetail.PermanentDocuments, this.vm.ItemDetail.PermanentDocuments);
        mapper.fromJsToModel(data.ItemDetail.BoardMemberDocuments, this.vm.ItemDetail.BoardMemberDocuments);
        mapper.fromJsToModel(data.ItemDetail.AllDocuments, this.vm.ItemDetail.AllDocuments);
        mapper.fromJsToModel(data.ItemDetail.HoaStatements, this.vm.ItemDetail.HoaStatements);
        mapper.fromJsToModel(data.ItemDetail.HoaStatementYears, this.vm.ItemDetail.HoaStatementYears);
        mapper.fromJsToModel(data.ItemDetail.BalanceDetails, this.vm.ItemDetail.BalanceDetails);
        mapper.fromJsToModel(data.ItemDetail.HoaStatementYear, this.vm.ItemDetail.HoaStatementYear);
        mapper.fromJsToModel(data.ItemDetail.ClubStatements, this.vm.ItemDetail.ClubStatements);
        mapper.fromJsToModel(data.ItemDetail.ClubStatementYears, this.vm.ItemDetail.ClubStatementYears);
        mapper.fromJsToModel(data.ItemDetail.ClubStatementYear, this.vm.ItemDetail.ClubStatementYear);
    };
    HoaPaymentForm.prototype.getStaticPromiseDone = function (data, id) {
        this.komappingfromdata(data, id);
        this.vm.IsLoading(false);
        this.editAction = "Edit";
    };
    HoaPaymentForm.prototype.getYearHoaDocuments = function (year) {
        var _this = this;
        if (this.vm.IsLoading()) {
            return;
        }
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        this.editAction = "DocumentYear";
        var url = this.postUrl + '/' + this.vm.ItemDetail.ContractNumber() + '/' + year; //.replace('ManagePoints/Edit', 'manage-points/ProcessPointsConversion');
        var promise = $.ajax({
            url: url,
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.fail(function (error) { return _this.getPromiseFail(error, null); });
        promise.done(function (data) { return _this.getDynamicPromiseDone(data, year); });
    };
    HoaPaymentForm.prototype.getDynamicPromiseDone = function (data, id) {
        mapper.fromJsToModel(data.ItemDetail.HoaDocuments, this.vm.ItemDetail.HoaDocuments);
        this.vm.ItemDetail.HoaDocumentYear(id);
        this.vm.IsLoading(false);
        this.editAction = "Edit";
    };
    HoaPaymentForm.prototype.mapPostData = function (data) {
        var totalAmountDue = this.vm.ItemDetail.TotalAmountDue;
        var totalBonusPoints = this.vm.ItemDetail.TotalBonusPoints;
        try {
            data.ItemDetail.CreditCardForm.Number = this.vm.ItemDetail.CreditCardForm.Number();
        }
        catch (e) {
        }
        var copyMapping = {
            'copy': this.data.KOMapping.copy
        };
        mapper.fromJsWithOptions(data.ItemDetail, copyMapping, this.vm.ItemDetail);
        this.vm.ItemDetail.TotalAmountDue = totalAmountDue;
        this.vm.ItemDetail.TotalBonusPoints = totalBonusPoints;
    };
    HoaPaymentForm.prototype.missingDates = function () { };
    HoaPaymentForm.prototype.refreshResortData = function () { };
    return HoaPaymentForm;
}(FrontEndForm));
module.exports = HoaPaymentForm;

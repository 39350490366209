"use strict";
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var JQuery = $;
var ko = require("knockout");
var komapping = require("knockout.mapping");
ko.mapping = komapping;
var _ = require("lodash");
var mapper = require("./Mapper");
var Form = /** @class */ (function () {
    function Form(data, vm, editAction) {
        if (editAction === void 0) { editAction = 'Edit'; }
        this.data = data;
        this.vm = vm;
        this.editAction = editAction;
        this.controllerUrl = "/" + data.Title;
        this.tabRowSelector = '.oo-form form > .row';
        this.getUrl = this.area + "/" + data.Title + "/Item";
        this.setupBindings();
        this.setupTabs();
    }
    Form.prototype.setupBindings = function () {
        var _this = this;
        var self = this;
        //tab click setup
        $('.oo-form-' + this.data.Title).on('click', '.oo-tabs a', function () {
            //set clicked one to active
            var formTab = ko.dataFor(this); //local this
            var index = _.indexOf(self.vm.FormTabs(), formTab);
            self.vm.ActiveTabIndex(index);
        });
        $('.oo-form-' + this.data.Title).on('click', '.save', function () {
            _this.post();
            return false;
        });
        $('#cancel-delete').on('click', function () {
            $('#delete-modal').modal('hide');
        });
        $('#confirm-delete').on('click', function () {
            _this.confirmDelete();
            return false;
        });
        $('body').on('click', 'a[data-remove-search]', function (evt) {
            evt.stopImmediatePropagation();
            var searchId = $(evt.target).data('remove-search');
            var removeType = $(evt.target).data('remove-type');
            var postData = {
                id: searchId,
                removeType: removeType
            };
            var promise = $.ajax({
                url: '/en/Resort/RemoveSearchHistory',
                data: JSON.stringify(postData),
                type: 'POST',
                cache: false,
                contentType: 'application/json;charset=utf-8',
            });
            $(evt.target.parentElement).remove();
            return false;
        });
        $('body').on('click', 'div > .typeahead__savedSearch', function (evt) {
            evt.stopImmediatePropagation();
            var searchId = $(evt.currentTarget).data('remove-search');
            var type = $(evt.currentTarget).data('search-type');
            var promise = $.ajax({
                url: "/en/Resort/RedirectToSavedSearch?type=" + type + "&id=" + searchId,
                cache: false,
                contentType: 'text',
            });
            promise.done(function (response) {
                if (response) {
                    window.location.href = response;
                }
            });
            return false;
        });
        $('body').on('click', 'a[href ^= "mailto:"]', function (evt) {
            var email = $(evt.target).attr('href');
            _this.vm.DataLayer.push({
                'event': 'emailAddressContacted',
                'email': email
            });
        });
        $('body').on('click', '.file-download', function (evt) {
            var fileName = $(evt.target).data('filename');
            _this.vm.DataLayer.push({
                'event': 'fileDownload',
                'file': fileName
            });
        });
    };
    Form.prototype.setupClearValidation = function (property) {
        //get property off of vm using string name coming from server
        var _this = this;
        var vmProperty = this.getVmProperty(property);
        if (ko.isObservable(vmProperty)) {
            var subscription = vmProperty.subscribe(function (newValue) {
                _this.removeError(property);
                //get rid of subscription
                subscription.dispose();
            });
        }
    };
    Form.prototype.confirmDelete = function () {
        var _this = this;
        this.editAction = 'Delete';
        var promise = this.post();
        promise.done(function () {
            $('#delete-modal').modal('hide');
            _this.editAction = 'Edit';
        });
    };
    Object.defineProperty(Form.prototype, "postUrl", {
        get: function () {
            return this.area + "/" + this.vm.CurrentLanguageCode() + "/" + this.data.Title + "/" + this.editAction;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Form.prototype, "area", {
        get: function () {
            return this.data.Area ? "/" + this.data.Area : '';
        },
        enumerable: false,
        configurable: true
    });
    Form.prototype.removeError = function (property) {
        var _this = this;
        //property id has _ instead of 
        var full = property.replace(/\./g, '_');
        var forminputs = $('#' + full + ', .' + full);
        // var forminputs = $('#' + property.replace(/\./g, '_'));
        _.each(forminputs, function (f) {
            var forminput = $(f);
            //select2 uses parent
            if (forminput.hasClass('select2-offscreen')) {
                forminput = $('.select2-container', forminput.parent());
            }
            //remove error class to parent element
            var formGroup = forminput.closest('div');
            formGroup.removeClass('is-error');
            //remove popover element
            $('.form-control-error', formGroup).remove();
            //refresh tabs
            _this.refreshTabValidationBadges();
            //handle span differently
            var isSpan = forminput.is('span');
            if (isSpan) {
                forminput.text('');
                forminput.removeClass('label label-danger');
            }
        });
    };
    Form.prototype.getVmProperty = function (property) {
        var propertyParts = property.split('.');
        var vmProperty = this.vm[propertyParts[0]][propertyParts[1]];
        return vmProperty;
    };
    Form.prototype.setupTabs = function () {
        var _this = this;
        $(this.tabRowSelector + ' > h2').hide();
        //create a knockoutobservable foreach h2 inside a row
        $(this.tabRowSelector + ' > h2').each(function (index, value) {
            var element = $(value);
            var attr = 'visible: $root.ActiveTabIndex() === ' + index.toString();
            $(value).parent().attr('data-bind', attr);
            _this.vm.FormTabs.push({
                Text: ko.observable(element.html()),
                ErrorCount: ko.observable(0),
                ChangeCount: ko.observable(0),
                Hidden: ko.observable(false),
                Id: ko.observable(null)
            });
        });
        this.vm.ActiveTabIndex(0);
    };
    Form.prototype.generateNewClientId = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); return v.toString(16); });
    };
    Form.prototype.add = function () {
        this.resetDetail();
        this.vm.ItemDetailIsNew(true);
        $('body').addClass('form-shown');
        this.vm.FormVisible(true);
    };
    Form.prototype.delete = function (id) {
        var _this = this;
        var promise = this.getDetail(id);
        promise.done(function () {
            _this.vm.routes.view(id);
            $('#delete-modal').modal('show');
        });
        return promise;
    };
    Form.prototype.cancel = function () {
        this.vm.FormVisible(false);
        setTimeout(function () {
            $('body').removeClass('form-shown');
        }, 250);
    };
    Form.prototype.post = function (dataToPost) {
        var _this = this;
        this.vm.HasScrolledToError(false);
        //prevent double post
        if (this.vm.IsLoading()) {
            return;
        }
        this.vm.RemoveAlerts();
        //make overlay loading div full height
        this.vm.enableIsLoading(true);
        var postData = dataToPost == undefined ? this.getPostDate() : dataToPost;
        var postTo = this.postUrl.replace('/null', '');
        //defect #495526 - Edit functionality was impacted by large amount of data in AllVersionsSummary. Cleaned up with this change
        if (postTo === '/Admin/ResortContentPage/Edit') {
            postData.ItemDetail.AllVersionsSummary = [];
        }
        var promise = $.ajax({
            url: postTo,
            data: JSON.stringify(postData),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8',
        });
        promise.fail(function (error) { return _this.postPromiseFail(error); });
        promise.done(function (data) { return _this.postPromiseDone(data); });
        return promise;
    };
    Form.prototype.getPostDate = function () {
        var ignoreMapping = {
            'ignore': this.data.KOMapping.allNonPostBack
        };
        var postData = mapper.toJsWithOptions(this.vm, ignoreMapping);
        delete postData.Alerts;
        delete postData.NewVersion;
        return postData;
    };
    Form.prototype.mapPostData = function (data) {
        var copyMapping = {
            'copy': this.data.KOMapping.copy
        };
        mapper.fromJsWithOptions(data.ItemDetail, copyMapping, this.vm.ItemDetail);
    };
    Form.prototype.removeContentBlocks = function (data) {
        delete data.GlobalNavigation;
        delete data.ContentBlocks;
        delete data.GlobalContentBlocks;
        delete data.FrontEndContentBlocks;
    };
    //find the form input based on proprty key
    Form.prototype.addPropertyError = function (propertyError) {
        var _this = this;
        var key = propertyError.Key, message = propertyError.ErrorMessage;
        //find matching input
        var full = key.replace(/\./g, '_');
        var forminputs = $('#' + full + ', .' + full);
        _.each(forminputs, function (f) {
            var forminput = $(f);
            if (forminput.hasClass('select2-offscreen')) {
                forminput = $('.select2-container', forminput.parent());
            }
            _this.formInputAddError(forminput, key, message);
        });
        //select2 need to use parent element
    };
    //add error state to dom
    Form.prototype.formInputAddError = function (forminput, key, message) {
        var isSpan = forminput.is('span');
        var isCheckbox = forminput.is(':checkbox');
        var formElementFound = true;
        if (forminput.length > 0) {
            var elementParent = forminput.parents('div').not('.dropdown').first();
            elementParent.addClass('is-error');
            if (!isCheckbox) { //skip adding the extra tag 
                if (!isSpan) {
                    elementParent.append('<div class="form-control-error">' + message + '</div>');
                }
                else {
                    forminput.text(message);
                    forminput.addClass('label label-danger');
                }
            }
        }
        else {
            formElementFound = false;
        }
        if (formElementFound) {
            this.setupClearValidation(key);
        }
    };
    //remove error state from don
    Form.prototype.formInputRemoveError = function (forminput, key) {
        if (forminput.hasClass('select2-offscreen')) {
            forminput = $('.select2-container', forminput.parent());
        }
        //remove error class to parent element
        var formGroup = forminput.closest('div');
        formGroup.removeClass('is-error');
        //remove popover element
        $('.form-control-error', formGroup).remove();
        //refresh tabs
        this.refreshTabValidationBadges();
        //handle span differently
        var isSpan = forminput.is('span');
        if (isSpan) {
            forminput.text('');
            forminput.removeClass('label label-danger');
        }
    };
    //setup tab validation notice
    Form.prototype.refreshTabValidationBadges = function () {
        var _this = this;
        //find elements inside row that have has-error class
        $('.oo-form-' + this.data.Title + ' .row').each(function (i, v) {
            //get the number of errors on in tab section
            var errors = $('.has-error', v).length;
            //find tab by title and set error count
            var tabTitle = $('h2', v).html();
            var match = ko.utils.arrayFirst(_this.vm.FormTabs(), function (item) { return (tabTitle === item.Text()); });
            if (match) {
                match.ErrorCount(errors);
            }
        });
    };
    //resets item detail so that it looks like a new insert
    Form.prototype.resetDetail = function () {
        this.copyNewToItemDetail();
        _.each(this.vm.FormTabs(), function (tab) { tab.ErrorCount(0); }); //reset errors to 0
        $('.main-modal-form form > .row').hide();
        this.vm.ActiveTabIndex(0);
        $('.main-modal-form form > .row:eq(0)').show();
    };
    Form.prototype.copyNewToItemDetail = function () {
        mapper.fromJsExisting(this.vm.NewItemDetail, this.vm.ItemDetail);
    };
    Form.prototype.getDetail = function (id) {
        var _this = this;
        this.resetDetail();
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        $('body').addClass('form-shown');
        var promise = $.ajax({
            url: this.getUrl,
            data: { id: id },
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.fail(function (error) { return _this.getPromiseFail(error, id); });
        promise.done(function (data) { return _this.getPromiseDone(data, id); });
        return promise;
    };
    Form.prototype.komappingfromdata = function (data, id) {
        var copyMapping = {
            'copy': this.data.KOMapping.allNonPostBack
        };
        mapper.fromJsWithOptions(data.ItemDetail, copyMapping, this.vm.ItemDetail);
    };
    Form.prototype.mapValidationErrors = function (error) {
        var _this = this;
        _.each(error.responseJSON.ModelState.PropertyErrors, function (propertyError) {
            _this.addPropertyError(propertyError);
        });
        _.each(error.responseJSON.ModelState.ModelErrors, function (v) {
            _this.vm.AddAlert(v.Key, 'error', true);
        });
    };
    //post ajax fail
    Form.prototype.postPromiseFail = function (error) {
        if (error.status === 400) {
            this.mapValidationErrors(error);
            this.refreshTabValidationBadges();
        }
        else {
            if (error.responseJSON) {
                if (error.responseJSON.Message) {
                    this.vm.AddAlert(error.responseJSON.Message, 'error');
                }
                else {
                    var html = error.error().responseText;
                    var element = $('<div/>').html(html);
                    $('style', element).remove();
                    var htmlText = element.html();
                    this.vm.AddAlert(htmlText, 'error');
                }
            }
            else {
                var html2 = error.error().responseText;
                var element2 = $('<div/>').html(html2);
                $('style', element2).remove();
                var htmlText2 = element2.html();
                this.vm.AddAlert(htmlText2, 'error');
            }
        }
        this.vm.IsLoading(false);
    };
    //post ajax success
    Form.prototype.postPromiseDone = function (data) {
        this.mapPostData(data);
        if (!this.vm.LeaveOpenOnAjaxSuccess()) {
            this.vm.routes.goBack();
            this.resetDetail();
            this.vm.AddAlert(data.Message, 'success');
        }
        this.vm.IsLoading(false);
        this.vm.IsLoadingTransaction(true);
    };
    //get ajax fail
    Form.prototype.getPromiseFail = function (error, id) {
        var html = error.error().responseText;
        var element = $('<div/>').html(html);
        $('style', element).remove();
        var htmlText = element.html();
        this.vm.AddAlert(htmlText, 'alert-error');
        this.vm.IsLoading(false);
        this.vm.IsLoadingTransaction(true);
    };
    //get ajax success
    Form.prototype.getPromiseDone = function (data, id) {
        this.vm.ItemDetailIsNew(false);
        this.komappingfromdata(data, id);
        //this.vm.routes.view(id); // removed to prevent double resort load, TODO verify admin still works
        this.vm.IsLoading(false);
        this.vm.FormVisible(true);
    };
    Object.defineProperty(Form.prototype, "submissionErrorKey", {
        get: function () {
            return 'SubmissionErrorMessage';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Form.prototype, "submissionSuccessKey", {
        get: function () {
            return 'SubmissionSuccess';
        },
        enumerable: false,
        configurable: true
    });
    return Form;
}());
module.exports = Form;
// uncomment to hook into ajax events for debugging
//$.ajax({
//    beforeSend: (jqXHR: JQueryXHR, settings: JQueryAjaxSettings) => {
//        debugger;
//    },
//    success: (data: any, textStatus: string, jqXHR: JQueryXHR) => {
//        debugger;
//    },
//    error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
//        debugger;
//    },
//    complete: (jqXHR: JQueryXHR, textStatus: string) => {
//        debugger;
//    }
//})

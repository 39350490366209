"use strict";
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var ClientSidePagination = /** @class */ (function () {
    function ClientSidePagination(allList, pageSize, tableSelector) {
        this.FullList = allList;
        this.PageNumber = ko.observable(1);
        this.PageBase = ko.observable(0); //sets up the base page numbers
        this.PageSize = ko.observable(pageSize);
        this.TableSelector = tableSelector;
        this.setupSubscriptions();
        this.setupBindings();
    }
    //subscribes to observables for filtering
    ClientSidePagination.prototype.setupSubscriptions = function () {
        var _this = this;
        this.TotalItemCount = ko.computed(function () {
            return _this.FullList().length;
        });
        this.PageCount = ko.computed(function () {
            return Math.ceil(_this.FullList().length / _this.PageSize());
        });
        this.HasPreviousPage = ko.computed(function () {
            return _this.PageNumber() > 1;
        });
        this.HasNextPage = ko.computed(function () {
            return (_this.PageNumber() !== _this.PageCount()) && _this.PageCount() > 0;
        });
        this.PagedList = ko.computed(function () {
            var first = (_this.PageNumber() - 1) * _this.PageSize();
            return _this.FullList.slice(first, first + _this.PageSize());
        });
        //update the page number values if there are more than 5 pages
        this.PageNumber.subscribe(function (newValue) {
            if ((newValue) > 3 && _this.PageCount() > 5) {
                _this.PageBase(_this.PageNumber() - 3);
                if (_this.PageNumber() === _this.PageCount()) {
                    _this.PageBase(_this.PageCount() - 5);
                }
                if (_this.PageNumber() === _this.PageCount() - 1) {
                    _this.PageBase(_this.PageCount() - 5);
                }
            }
            else {
                _this.PageBase(0);
            }
        });
        this.Page1 = ko.computed(function () {
            return _this.getPageNumberString(1);
        });
        this.Page2 = ko.computed(function () {
            return _this.getPageNumberString(2);
        });
        this.Page3 = ko.computed(function () {
            return _this.getPageNumberString(3);
        });
        this.Page4 = ko.computed(function () {
            return _this.getPageNumberString(4);
        });
        this.Page5 = ko.computed(function () {
            return _this.getPageNumberString(5);
        });
    };
    ClientSidePagination.prototype.setupBindings = function () {
        var self = this;
        // click - paging controls
        $(this.TableSelector).on('click', '.pagination a', function () {
            var $aLink = $(this);
            if ($aLink.parent().hasClass('disabled') === false) {
                var aText = $aLink.text().toLowerCase();
                var pageNumber = self.PageNumber();
                if (aText.indexOf('first') > -1) {
                    pageNumber = 1;
                }
                else if (aText.indexOf('prev') > -1 || $aLink.hasClass('page-prev')) {
                    pageNumber = pageNumber - 1;
                }
                else if (aText.indexOf('next') > -1 || $aLink.hasClass('page-next')) {
                    pageNumber = pageNumber + 1;
                }
                else if (aText.indexOf('last') > -1) {
                    pageNumber = self.PageCount();
                }
                else { //numbers
                    pageNumber = parseInt(aText);
                }
                self.PageNumber(pageNumber);
            }
            return false;
        });
    };
    ClientSidePagination.prototype.getPageNumberString = function (pageNumber) {
        if (this.PageCount() < pageNumber) {
            return "";
        }
        return (this.PageBase() + pageNumber).toString();
    };
    return ClientSidePagination;
}());
module.exports = ClientSidePagination;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var PointsForm = /** @class */ (function (_super) {
    __extends(PointsForm, _super);
    function PointsForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    PointsForm.prototype.setupBindings = function () {
        _super.prototype.setupBindings.call(this);
        var self = this;
    };
    PointsForm.prototype.missingDates = function () { };
    PointsForm.prototype.refreshResortData = function () { };
    return PointsForm;
}(FrontEndForm));
module.exports = PointsForm;

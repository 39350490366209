"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var MortgagePaymentForm = /** @class */ (function (_super) {
    __extends(MortgagePaymentForm, _super);
    function MortgagePaymentForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    MortgagePaymentForm.prototype.post = function (dataToPost) {
        var _this = this;
        this.vm.HasScrolledToError(false);
        //prevent double post
        if (this.vm.IsLoading()) {
            return;
        }
        this.vm.RemoveAlerts();
        //make overlay loading div full height
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        var postData = dataToPost == undefined ? this.getPostDate() : dataToPost;
        this.removeContentBlocks(postData);
        //must delete credit card if nothing is due
        if (this.vm.TotalAmountDue() === 0) {
            delete postData.ItemDetail.CreditCardForm;
        }
        var postTo = this.postUrl.replace('/null', '');
        var promise = $.ajax({
            url: postTo,
            data: JSON.stringify(postData),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8',
        });
        promise.fail(function (error) { return _this.postPromiseFail(error); });
        promise.done(function (data) {
            _this.vm.DataLayer.push({
                'event': 'LoanPayment',
                'paymentAmount': data.ItemDetail.TotalPayment,
                'memberID': _this.vm.User.MemberId(),
                'transactionDate': (new Date(data.ItemDetail.CurrentDate)).toLocaleDateString()
            });
            _this.postPromiseDone(data);
        });
        return promise;
    };
    MortgagePaymentForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('body').on('click', '#confirmMortgagePayment', function (e) {
            e.preventDefault();
            //Validating the page!
            //            self.vm.LeaveOpenOnAjaxSuccess(true);
            //            self.editAction = "Validate";
            //            var promise = self.post();
            //           
            //            promise.done((data) =>  {
            //                //Move to the next page
            //                self.editAction = "Edit";
            //
            //                window.location.hash = data.ReturnUrl;
            //
            //            });
            if (_this.vm.TotalAmountDue() > 0) {
                if (_this.validateCC(self.vm.ItemDetail.CreditCardForm)) {
                    self.vm.IsLoading(true);
                    var ip = '';
                    $.getJSON("https://api.ipify.org/?format=json")
                        .done(function (e) {
                        ip = e.ip;
                    })
                        .fail(function () {
                        ip = '127.0.0.1';
                    })
                        .always(function () {
                        var accessBlockPromise = $.ajax({
                            type: "POST",
                            url: self.area + "/" + self.vm.CurrentLanguageCode() + "/" + self.data.Title + "/SFourToken",
                            data: JSON.stringify({ 'ip': ip }),
                            contentType: "application/json; charset=utf-8",
                            dataType: "json"
                        }).then(function (accessblock) {
                            var postData = {
                                fuseaction: self.vm.FuseAction(),
                                i4Go_AccessBlock: accessblock,
                                i4Go_CardNumber: self.vm.ItemDetail.CreditCardForm.DisplayNumber(),
                                i4Go_ExpirationYear: self.vm.ItemDetail.CreditCardForm.Year(),
                                i4Go_ExpirationMonth: self.vm.ItemDetail.CreditCardForm.Month(),
                                i4Go_Server: self.vm.I4GoServer(),
                            };
                            var sFourPromise = $.ajax({
                                url: self.vm.I4GoServer(),
                                type: "GET",
                                dataType: "jsonp",
                                jsonpCallback: 'parseResponse',
                                timeout: 30000,
                                data: postData,
                            }).then(function (i4go) {
                                if (i4go.i4go_response != "FAILURE") {
                                    self.shiftFourToken = ko.mapping.toJS(i4go);
                                    var token = self.shiftFourToken.i4go_uniqueid;
                                    self.vm.ItemDetail.CreditCardForm.LastFourCardNumber(self.vm.ItemDetail.CreditCardForm.DisplayNumber().substr(self.vm.ItemDetail.CreditCardForm.DisplayNumber().length - 4));
                                    self.vm.ItemDetail.CreditCardForm.CardToken("S4:" + token);
                                    self.shiftFourToken = ko.mapping.toJS(i4go);
                                    self.vm.ItemDetail.CreditCardForm.Number(token);
                                    var cctype = self.getCardType(self.vm.ItemDetail.CreditCardForm.DisplayNumber());
                                    if (cctype === 'undefined'
                                        || cctype === '') {
                                        this.postCCError();
                                        return;
                                    }
                                    self.vm.ItemDetail.CreditCardForm.CardType(cctype);
                                    var tokenPostData = self.getPostDate();
                                    self.vm.IsLoading(true);
                                    var validatePromise = $.ajax({
                                        url: self.area + "/" + self.vm.CurrentLanguageCode() + "/" + self.data.Title + "/Validate",
                                        data: JSON.stringify(tokenPostData),
                                        type: 'POST',
                                        cache: false,
                                        contentType: 'application/json;charset=utf-8'
                                    });
                                    validatePromise.fail(function (validerror) { return self.postPromiseFail(validerror); });
                                    validatePromise.done(function (validdata) {
                                        //Dont want to map the complete creditcardform
                                        self.vm.ItemDetail.CreditCardForm.LastFourCardNumber(validdata.ItemDetail.CreditCardForm.LastFourCardNumber);
                                        //Move to the next page
                                        self.vm.ItemDetail.Step(2);
                                        self.scrollTop();
                                        self.vm.IsLoading(false);
                                        //window.location.hash = data.ReturnUrl;
                                    });
                                }
                                else {
                                    self.logFuseError("shift 4 error -" + JSON.stringify(i4go));
                                    self.postCCError();
                                }
                            }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
                                if (XMLHttpRequest.readyState == 4) {
                                    self.logFuseError(errorThrown);
                                }
                                else if (XMLHttpRequest.readyState == 0) {
                                    self.logFuseError("network refusal");
                                }
                                else {
                                    self.logFuseError(errorThrown);
                                }
                            });
                        }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
                            if (XMLHttpRequest.readyState == 4) {
                                self.logFuseError(errorThrown);
                            }
                            else if (XMLHttpRequest.readyState == 0) {
                                self.logFuseError("network refusal for mortgage payment");
                            }
                            else {
                                self.logFuseError(errorThrown);
                            }
                            self.postCCError();
                        });
                    });
                }
                else {
                    self.postCCError();
                }
            }
            else {
                var postData = _this.getPostDate();
                var promise = $.ajax({
                    url: _this.area + "/" + _this.vm.CurrentLanguageCode() + "/" + _this.data.Title + "/Validate",
                    data: JSON.stringify(postData),
                    type: 'POST',
                    cache: false,
                    contentType: 'application/json;charset=utf-8'
                });
                promise.fail(function (error) { return self.postPromiseFail(error); });
                promise.done(function (data) {
                    //Dont want to map the complete creditcardform
                    _this.vm.ItemDetail.CreditCardForm.LastFourCardNumber(data.ItemDetail.CreditCardForm.LastFourCardNumber);
                    //Move to the next page
                    self.vm.ItemDetail.Step(2);
                    self.scrollTop();
                    self.vm.IsLoading(false);
                    //window.location.hash = data.ReturnUrl;
                });
            }
            return false;
        });
        $('body').on('click', '#submitMortgagePayment', function (e) {
            e.preventDefault();
            self.vm.LeaveOpenOnAjaxSuccess(true);
            self.editAction = "Send";
            var promise = self.post();
            promise.fail(function (error) {
                //self.scrollTop();
            });
            promise.done(function (data) {
                self.editAction = "Edit";
                window.location.hash = data.ReturnUrl;
                self.scrollTop();
            });
            return false;
        });
        $('body').on('click', '.btnPrint', function (event) {
            event.preventDefault();
            window.print();
        });
        if (this.vm.HTSIsEnabled()) {
            this.vm.ItemDetail.CreditCardForm.DisplayNumber.subscribe(function (newValue) {
                if (newValue != null) {
                    _this.vm.ItemDetail.CreditCardForm.Number(newValue);
                    _this.vm.ItemDetail.CreditCardForm.LastFourCardNumber(_this.vm.ItemDetail.CreditCardForm.DisplayNumber().substr(_this.vm.ItemDetail.CreditCardForm.DisplayNumber().length - 4));
                }
            });
        }
        this.vm.ItemDetail.CreditCardForm.CardType.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_CardType').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_CardType').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.CreditCardForm.Month.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Month').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Month').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.CreditCardForm.Year.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Year').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Year').addClass('creditForm');
            }
        });
    };
    MortgagePaymentForm.prototype.mapPostData = function (data) {
        try {
            data.ItemDetail.CreditCardForm.Number = this.vm.ItemDetail.CreditCardForm.Number();
        }
        catch (e) {
        }
        var copyMapping = {
            'copy': this.data.KOMapping.copy
        };
        mapper.fromJsWithOptions(data.ItemDetail, copyMapping, this.vm.ItemDetail);
    };
    MortgagePaymentForm.prototype.missingDates = function () { };
    MortgagePaymentForm.prototype.refreshResortData = function () { };
    return MortgagePaymentForm;
}(FrontEndForm));
module.exports = MortgagePaymentForm;

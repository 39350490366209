"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var InviteUsersForm = /** @class */ (function (_super) {
    __extends(InviteUsersForm, _super);
    function InviteUsersForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    InviteUsersForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('#invite-users-wrapper').on('click', '#add-email', function (e) {
            e.preventDefault();
            self.editAction = "Validate";
            var promise = self.post();
            promise.done(function (data) {
                self.vm.InvitedEmails(data.InvitedEmails);
            });
            return false;
        });
        $('#invite-users-wrapper').on('click', '#invite-users', function (e) {
            e.preventDefault();
            _this.inviteOtherUsers();
            return false;
        });
        $('#invite-users-wrapper').on('click', '#skip-inviting', function (e) {
            e.preventDefault();
            _this.vm.InvitedEmails([]); //clear out any invited users if skipping
            _this.inviteOtherUsers();
            return false;
        });
        $('#invite-users-wrapper').on('click', '.remove-email', function (e) {
            e.preventDefault();
            var email = ko.dataFor(e.target);
            self.vm.InvitedEmails.remove(email);
            return false;
        });
        $('#update-users-wrapper').on('click', '#update-user', function (e) {
            e.preventDefault();
            self.editAction = "UpdateUser";
            var updateUserInfo = self.vm.UserInfo;
            var postData = mapper.toJs(updateUserInfo);
            var promise = self.post(postData);
            promise.done(function (data) {
                self.vm.UserInfo.ShowUserUpdateError(false);
                self.vm.UserInfo.UserUpdateErrorMessage('');
                window.location.href = data.ReturnUrl;
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                self.vm.UserInfo.ShowUserUpdateError(data.UserInfo.ShowUserUpdateError);
                self.vm.UserInfo.UserUpdateErrorMessage(data.UserInfo.UserUpdateErrorMessage);
            });
            return false;
        });
    };
    InviteUsersForm.prototype.inviteOtherUsers = function () {
        var _this = this;
        this.editAction = "Edit";
        var promise = this.post();
        promise.done(function (data) {
            _this.vm.HasInvitedUsers(data.HasInvitedUsers);
            _this.vm.ReturnUrl(data.ReturnUrl);
            if (!_this.vm.HasInvitedUsers()) { //if they didn't invite anyone skip the success screen and continue login
                $('#loading').height($(window).height());
                _this.vm.IsLoading(true);
                //this.vm.IsLoadingTransaction(true);
                window.location.href = data.ReturnUrl;
            }
        });
    };
    InviteUsersForm.prototype.postPromiseDone = function (data) {
        this.mapPostData(data);
        if (!this.vm.LeaveOpenOnAjaxSuccess()) {
            this.resetDetail();
        }
        this.vm.IsLoading(false);
    };
    InviteUsersForm.prototype.missingDates = function () { };
    InviteUsersForm.prototype.refreshResortData = function () { };
    return InviteUsersForm;
}(FrontEndForm));
module.exports = InviteUsersForm;

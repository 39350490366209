"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResortSearchVmBase = void 0;
var base = require("./VMBase");
var Cookies = require("js-cookie");
var HgvDatePicker = require("../Resort/HgvDatePicker");
var moment = require("moment");
var ResortSearchVmBase = /** @class */ (function (_super) {
    __extends(ResortSearchVmBase, _super);
    function ResortSearchVmBase(data, formType, tableType, routerType) {
        var _this = _super.call(this, data, formType, tableType, routerType) || this;
        _this.data = data;
        //Sets the default timezone that all further moment() calls will use. Fixes timezone issues with booking window requirements
        moment.tz.setDefault(_this.VoiceTimeZone());
        _this.SelectedUnit = ko.observable(null);
        _this.AutoCompleteId = ko.observable('');
        _this.AutoCompleteName = ko.observable('');
        _this.AutoCompleteCategory = ko.observable('');
        _this.AvailabilityAdaCache = {};
        _this.AvailabilityAdaCacheIndex = {};
        _this.AvailabilityCache = {};
        _this.AvailabilityCacheIndex = {};
        _this.AvailabilityCacheUpdate = ko.observable(new Date());
        _this.ItemDetail.ProjectNumber = ko.observable(0);
        _this.DatesSubmitted = ko.observable(false);
        _this.dateFormat = "MM/DD/YYYY";
        _this.picker = new HgvDatePicker(_this);
        _this.addToViewModel();
        var self = _this;
        //Don't use the cookie settings to override filters if they aren't logged in
        if (_this.User.IsAuthenticated()) {
            var adaOnlyCookie = Cookies.get("resort-search-ada-only");
            var adaOnly = adaOnlyCookie !== undefined && adaOnlyCookie !== null;
            if (adaOnly) {
                _this.skipADARefresh = true;
                _this.Filters.ADAOnly(true);
                _this.skipADARefresh = false;
            }
            //Don't use the cookie settings to override filters if they are booking a homeweek
            if (_this.IsDetail() || _this.IsSearch()) {
                _this.trackDestinationSelected();
                var openSeasonCookie = Cookies.get("resort-search-open-season");
                var openSeason = openSeasonCookie !== undefined && openSeasonCookie !== null;
                if (openSeason) {
                    _this.skipOpenSeasonRefresh = true;
                    _this.Filters.OpenSeasonMode(true);
                    _this.skipOpenSeasonRefresh = false;
                }
            }
        }
        //setup the typeahead callback for the Toolkit
        window.typeAheadCompleted = function (Name, Id, Category) {
            if (Name) {
                _this.Filters.SearchTerm(Name);
            }
            _this.AutoCompleteId(Id);
            _this.AutoCompleteCategory(Category);
            _this.AutoCompleteName(Name);
        };
        // Fixes a bug upon load. The koComputed value relies on a loaded DOM for its strings
        $(document).ready(function () {
            self.Filters.RoomSizes(self.Filters.RoomSizes()); // i.e. touch the value to force a recalculation
            var searchString = $('#txt-search').val();
            if (searchString.length > 0) {
                var found = window.searchTermsJson().find(function (i) { return i.Name == searchString; });
                if (found !== null) {
                    self.AutoCompleteId(found.Id);
                    self.AutoCompleteCategory(found.Category);
                    self.AutoCompleteName(found.Name);
                }
            }
            if (window.bookingApp !== undefined) {
                var checkinStr = $('#checkInDateBooking').val();
                var checkoutStr = $('#checkOutDateBooking').val();
                var checkin = moment(checkinStr);
                var checkout = moment(checkoutStr);
                self.Filters.CheckIn(checkin.isValid() ? checkin.format(self.dateFormat) : null);
                self.Filters.CheckOut(checkout.isValid() ? checkout.format(self.dateFormat) : null);
            }
            $(document).on('bookingAppStartDateChanged', function (e, val) {
                var checkin = val.isValid() ? val.format(self.dateFormat) : null;
                //self.Filters.CheckIn(checkin);
                //self.picker.refreshSelectedDate(self, true);
            });
            $(document).on('bookingAppEndDateChanged', function (e, val) {
                var checkout = val.isValid() ? val.format(self.dateFormat) : null;
                //self.Filters.CheckOut(checkout);
                //self.picker.refreshSelectedDate(self, true);
            });
        });
        return _this;
    }
    ResortSearchVmBase.prototype.trackResortClicked = function () {
        var _a, _b, _c, _d, _e, _f;
        this.Analytics.track("Resort Clicked", {
            event: "Resort Clicked",
            u_lead_id: (_b = (_a = this.User) === null || _a === void 0 ? void 0 : _a.ULeadId()) !== null && _b !== void 0 ? _b : "",
            lead_id: (_d = (_c = this.User) === null || _c === void 0 ? void 0 : _c.LeadId()) !== null && _d !== void 0 ? _d : "",
            resort_details: {
                resort_name: (_e = this.ItemDetail.DisplayName().trim()) !== null && _e !== void 0 ? _e : "",
                resort_location: (_f = this.ItemDetail.CityStateCountry().trim()) !== null && _f !== void 0 ? _f : "",
                navigation__link: window.location.href
            }
        });
    };
    ResortSearchVmBase.prototype.trackDestinationSelected = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var firstName = (_b = (_a = this.User) === null || _a === void 0 ? void 0 : _a.FirstName()) !== null && _b !== void 0 ? _b : "";
        var lastName = (_d = (_c = this.User) === null || _c === void 0 ? void 0 : _c.LastName()) !== null && _d !== void 0 ? _d : "";
        var DestinationId = "";
        var DestinationName = "";
        if (this.IsDetail()) {
            DestinationId = (_e = this.ItemDetail.RegionId()) !== null && _e !== void 0 ? _e : "";
            DestinationName = (_f = this.ItemDetail.RegionSlug()) !== null && _f !== void 0 ? _f : "";
            this.trackResortClicked();
        }
        else if (this.IsSearch()) {
            DestinationId = (_g = this.ItemDetail.RegionId()) !== null && _g !== void 0 ? _g : "";
            DestinationName = (_h = this.SearchedCategoryName()) !== null && _h !== void 0 ? _h : "";
        }
        this.Analytics.track("Destination Clicked", {
            event: "Destination Clicked",
            lead_id: (_k = (_j = this.User) === null || _j === void 0 ? void 0 : _j.LeadId()) !== null && _k !== void 0 ? _k : "",
            u_lead_id: (_m = (_l = this.User) === null || _l === void 0 ? void 0 : _l.ULeadId()) !== null && _m !== void 0 ? _m : "",
            destination_details: {
                destination_name: DestinationName,
                navigation__link: window.location.href
            }
        });
    };
    ResortSearchVmBase.prototype.setOpenSeasonCookie = function (enabled) {
        if (enabled) {
            Cookies.set("resort-search-open-season", enabled);
        }
        else {
            Cookies.remove("resort-search-open-season");
        }
    };
    ResortSearchVmBase.prototype.setADACookie = function (enabled) {
        if (enabled) {
            Cookies.set("resort-search-ada-only", enabled);
        }
        else {
            Cookies.remove("resort-search-ada-only");
        }
    };
    ResortSearchVmBase.prototype.getIdsMissingDates = function () {
        return [];
    };
    ResortSearchVmBase.prototype.refreshAvailabilityAppResorts = function () { };
    ;
    ResortSearchVmBase.prototype.getMoreDates = function (ids) { };
    ResortSearchVmBase.prototype.refreshAllUnits = function (initialLoad) { };
    ResortSearchVmBase.prototype.refreshHomeWeekDateSelection = function (checkinDate, checkoutDate) { };
    ;
    ResortSearchVmBase.prototype.getAvailabilityCache = function (key) {
        return undefined;
    };
    ResortSearchVmBase.prototype.addToViewModel = function () {
        var _this = this;
        this.Filters.CheckIn.subscribeChanged(function (newValue, oldValue) {
            if (newValue == null || newValue === "") {
                _this.Filters.CheckIn(null);
                _this.picker.refreshSelectedDate(_this);
            }
        });
        this.Filters.CheckOut.subscribeChanged(function (newValue, oldValue) {
            if (newValue == null || newValue === "") {
                _this.Filters.CheckOut(null);
                _this.picker.refreshSelectedDate(_this);
            }
        });
        this.CheckinSelected = ko.computed(function () {
            return _this.Filters.CheckIn() !== null && _this.Filters.CheckIn() !== '';
        });
        this.CheckoutSelected = ko.computed(function () {
            return _this.Filters.CheckOut() !== null && _this.Filters.CheckOut() !== '';
        });
        this.SearchExceedsAllowedNumDays = ko.computed(function () {
            var checkIn = _this.Filters.CheckIn() != null ? moment(_this.Filters.CheckIn(), 'MM/DD/YYYY').format('MM/DD/YYYY') : null;
            var checkOut = _this.Filters.CheckOut() != null ? moment(_this.Filters.CheckOut(), 'MM/DD/YYYY').format('MM/DD/YYYY') : null;
            if (checkIn == null
                || checkOut == null)
                return false;
            var date1 = new Date(checkIn);
            var date2 = new Date(checkOut);
            var timeDiff = Math.abs(date2.getTime() - date1.getTime());
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            if (diffDays > 29) {
                return true;
            }
            else {
                return false;
            }
        });
        //both dates
        this.DatesSelected = ko.computed(function () {
            return _this.Filters.CheckOut() !== null && _this.Filters.CheckOut() !== '' && _this.Filters.CheckOut() !== null && _this.Filters.CheckOut() !== '';
        });
        this.RoomSizesLabel = ko.computed(function () {
            var label = "";
            if (_this.Filters.ADAOnly()) {
                label = $("#checkbox--ada-text").text();
            }
            if (_this.Filters.RoomSizes().length > 0) {
                if (label.length > 0) {
                    label += " + " + _this.Filters.RoomSizes().length;
                }
                else {
                    label = $("#checkbox--ada-roomsize-" + _this.Filters.RoomSizes().sort()[0]).text();
                    if (_this.Filters.RoomSizes().length > 1) {
                        label += " + " + ((_this.Filters.RoomSizes().length === 6) ? _this.Filters.RoomSizes().length - 2 : _this.Filters.RoomSizes().length - 1);
                    }
                }
            }
            return label;
        });
        this.QueryParameters = ko.computed(function () {
            var query = {};
            var searchTerm = _this.Filters.SearchTerm();
            var autoCompleteCategory = _this.AutoCompleteCategory();
            var autoCompleteName = _this.AutoCompleteName();
            var hasSearchterm = searchTerm;
            var validSearchTerm = searchTerm !== autoCompleteName ||
                (autoCompleteCategory !== 'Resort' && autoCompleteCategory !== 'Country' && autoCompleteCategory !== 'State' && autoCompleteCategory !== 'City');
            if (hasSearchterm && validSearchTerm) {
                query['searchTerm'] = searchTerm; // Avoid adding the search term if it was an autocomplete to a resort name
            }
            else if (autoCompleteCategory === 'Country') {
                query['country'] = searchTerm;
                _this.Filters.Country = (autoCompleteCategory === 'Country') ? ko.observable(searchTerm) : ko.observable(null);
                _this.Filters.State = ko.observable(null);
                _this.Filters.City = ko.observable(null);
            }
            else if (autoCompleteCategory === 'State') {
                query['state'] = searchTerm;
                _this.Filters.State = (autoCompleteCategory === 'State') ? ko.observable(searchTerm) : ko.observable(null);
                _this.Filters.Country = ko.observable(null);
                _this.Filters.City = ko.observable(null);
            }
            else if (autoCompleteCategory === 'City') {
                query['city'] = searchTerm;
                _this.Filters.City = (autoCompleteCategory === 'City') ? ko.observable(searchTerm) : ko.observable(null);
                _this.Filters.Country = ko.observable(null);
                _this.Filters.State = ko.observable(null);
            }
            if (_this.Filters.CheckIn())
                query['checkIn'] = moment.tz(_this.Filters.CheckIn(), _this.dateFormat, _this.UserTimeZone).format('YYYY-MM-DD');
            if (_this.Filters.CheckOut())
                query['checkOut'] = moment.tz(_this.Filters.CheckOut(), _this.dateFormat, _this.UserTimeZone).format('YYYY-MM-DD');
            if (_this.Filters.NumberOfGuest())
                query['guests'] = _this.Filters.NumberOfGuest();
            if (_this.Filters.ReservationNumber())
                query['reservationNumber'] = _this.Filters.ReservationNumber();
            if (_this.Filters.OpenSeasonMode())
                query['openSeason'] = _this.Filters.OpenSeasonMode();
            if (_this.Filters.RoomSizes() && _this.Filters.RoomSizes().length > 0)
                query['roomSizes'] = JSON.stringify(_this.Filters.RoomSizes());
            if (_this.Filters.CategoryName())
                query['categoryName'] = _this.Filters.CategoryName();
            if (_this.Filters.BookingType()) {
                query['bookingType'] = _this.Filters.BookingType();
            }
            var queryString = $.param(query); //generate the query string for all the filters
            if (queryString.length > 0) {
                queryString = "?" + queryString;
            }
            return queryString;
        });
    };
    ResortSearchVmBase.prototype.generateQueryStringFromFilters = function (filters) {
        var query = {};
        // SearchTerm logic Parallels ResortViewModelQueryService::BuildSearchHistoryCookieNewValue
        if (filters.Country()) {
            query['country'] = filters.Country();
        }
        else if (filters.State()) {
            query['state'] = filters.State();
        }
        else if (filters.City()) {
            query['city'] = filters.City();
        }
        else if (filters.CategoryName()) {
            query['categoryName'] = filters.CategoryName();
        }
        else if (filters.SearchTerm()) {
            query['searchTerm'] = filters.SearchTerm();
        }
        if (filters.CheckIn()) {
            query['checkIn'] = filters.CheckIn(); //.format("YYYY-MM-DD");
        }
        else {
            var checkin = moment($('#checkInDateBooking').val());
            filters.CheckIn = ko.observable(null);
            filters.CheckIn(checkin.format(this.dateFormat));
            query['checkIn'] = checkin.format("YYYY-MM-DD");
        }
        if (filters.CheckOut()) {
            query['checkOut'] = filters.CheckOut(); //.format("YYYY-MM-DD");
        }
        else {
            var checkOut = moment($('#checkOutDateBooking').val());
            filters.CheckOut = ko.observable(null);
            filters.CheckOut(checkOut.format(this.dateFormat));
            query['checkOut'] = checkOut.format("YYYY-MM-DD");
        }
        if (filters.NumberOfGuest()) {
            query['guests'] = filters.NumberOfGuest();
        }
        if (filters.ADAOnly()) {
            query['ADAOnly'] = true;
        }
        if (filters.RoomSizes() && filters.RoomSizes().length > 0) {
            var stringSizes = filters.RoomSizes().toString();
            var sizes = stringSizes.split(',').map(String);
            query['roomSizes'] = JSON.stringify(sizes);
        }
        filters.Url = ko.observable(null);
        filters.Url(window.location.pathname + '?' + $.param(query));
        query['url'] = window.location.pathname + '?' + $.param(query);
        var queryString = $.param(query);
        if (queryString.length > 0) {
            queryString = "?" + queryString;
        }
        return filters;
    };
    ResortSearchVmBase.prototype.getAnalytics = function () {
        return window.analytics || [];
    };
    return ResortSearchVmBase;
}(base.VmBase));
exports.ResortSearchVmBase = ResortSearchVmBase;

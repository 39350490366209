"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ResortSearchTable = require("../Base/ResortSearchTable");
var moment = require("moment");
var _ = require("lodash");
var Cookies = require("js-cookie");
var ResortTable = /** @class */ (function (_super) {
    __extends(ResortTable, _super);
    function ResortTable(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        _this.vm.Analytics = _this.vm.getAnalytics();
        return _this;
    }
    ResortTable.prototype.getIdsMissingDates = function () {
        var _this = this;
        var idsMissing = [];
        _.each(this.vm.List(), function (r) {
            var projectNumber = r.ProjectNumber.toString();
            if (_this.vm.AvailabilityCacheIndex[projectNumber] === undefined) {
                idsMissing.push(r.Id);
            }
            else {
                var startMissing = moment(_this.vm.AvailabilityCacheIndex[projectNumber].Start) > moment(_this.vm.CalendarWeek1()[0].Date);
                var endMissing = moment(_this.vm.AvailabilityCacheIndex[projectNumber].End) < moment(_this.vm.CalendarWeek12()[6].Date);
                if (startMissing || endMissing) {
                    idsMissing.push(r.Id);
                }
            }
        });
        return idsMissing;
    };
    ResortTable.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        //filtering drawer
        //when drawer is open checking a box does not change the observable
        //untile apply is clicked
        $('.btn-open-filters').on('click', function () { return _this.copyFilterToTemp(); });
        $('.filter-drawer .btn-cancel').on('click', function () { return _this.copyFilterToTemp(); });
        $('.filter-drawer .btn-apply').on('click', function () { return _this.copyTempToFilter(); });
        var self = this;
        //remove filter
        $('.filter-bar').on('click', '.filter', function () {
            var data = ko.dataFor(this); //local this
            self.vm.Filters.Filters.remove(data);
        });
        $('.filter-list').on('click', '.filter', function () {
            var data = ko.dataFor(this); //local this
            self.vm.Filters.Filters.remove(data);
        });
        $('.btn-clear-filters').on('click', function (e) {
            e.preventDefault();
            _this.clearFilters();
            return false;
        });
        $('body').on('click', '.btn-save-search', function (e) {
            e.preventDefault();
            var filters = _this.vm.Filters;
            var checkin = $('#checkInDateBooking').val();
            var checkOut = $('#checkOutDateBooking').val();
            if (checkin != _this.vm.StartDate || checkOut != _this.vm.EndDate) {
                filters.CheckIn(moment(checkin).format(_this.dateFormat));
                filters.CheckOut(moment(checkOut).format(_this.dateFormat));
            }
            var data = ko.toJS(_this.vm.generateQueryStringFromFilters(filters));
            var response = $.ajax({
                url: "/en/resort/savesearch",
                data: data,
                type: 'POST',
                dataType: 'text',
                cache: false
            });
            _this.jqXHR = response;
            response.done(function () {
                _this.vm.IsSearchSaved(true);
            });
            return false;
        });
        $('body').on('click', '.btn-unsave-search', function (e) {
            e.preventDefault();
            var data = ko.toJS(_this.vm.generateQueryStringFromFilters(_this.vm.Filters));
            var response = $.ajax({
                url: "/en/resort/RemoveSavedSearch",
                data: data,
                type: 'POST',
                dataType: 'text',
                cache: false
            });
            _this.jqXHR = response;
            response.done(function (responsedata) {
                _this.vm.IsSearchSaved(false);
            });
            return false;
        });
        $('body').on('click', 'a[data-remove-search]', function (evt) {
            var data = ko.toJS(_this.vm.generateQueryStringFromFilters(_this.vm.Filters));
            var response = $.ajax({
                url: "/en/resort/IsSearchSaved",
                data: data,
                type: 'POST',
                dataType: 'text',
                cache: false
            });
            _this.jqXHR = response;
            response.done(function (response) {
                if (response === 'True') {
                    _this.vm.IsSearchSaved(true);
                }
                else {
                    _this.vm.IsSearchSaved(false);
                }
            });
            return false;
        });
        this.previousRequestData = null;
    };
    ResortTable.prototype.clearFilters = function (triggerSearch) {
        var _this = this;
        if (triggerSearch === void 0) { triggerSearch = true; }
        _.each(this.vm.Filters.Filters(), function (filter) {
            // on experience pages, like destinations, we want to always keep that filter
            if (!filter.IsSearchedProperty) {
                _this.vm.Filters.Filters.remove(filter);
            }
        });
    };
    ResortTable.prototype.copyTempToFilter = function () {
        var _this = this;
        this.vm.Filters.Filters.removeAll();
        _.each(this.vm.FiltersToAdd(), function (id) {
            var filter = _this.getFilterById(id);
            _this.vm.Filters.Filters().push(filter);
        });
        this.vm.Filters.Filters.valueHasMutated();
        var uLeadId = this.vm.User != null ? this.vm.User.ULeadId() : "";
        var leadId = this.vm.User != null ? this.vm.User.LeadId() : "";
        var filterNames = this.vm.Filters != null ? this.vm.Filters.Filters() : "";
        var filterApplied = filterNames != null ? filterNames.map(function (item) {
            var _a;
            return (_a = {}, _a["value"] = item.Name, _a);
        }) : "";
        this.vm.Analytics.track("Content Filtered", {
            event: "content_filtered",
            lead_id: leadId,
            u_lead_id: uLeadId,
            filter_applied: filterApplied
        });
    };
    ResortTable.prototype.getFilterById = function (filterId) {
        var allFilters = this.vm.Meta.AllLocations.concat(this.vm.Meta.AllExperiences, this.vm.Meta.AllAmenities, this.vm.Meta.AllRoomSizes, this.vm.Meta.AllResortTypes);
        var filter = ko.utils.arrayFirst(allFilters, function (location) {
            return location.Id === filterId;
        });
        return filter;
    };
    ResortTable.prototype.copyFilterToTemp = function () {
        var _this = this;
        this.vm.FiltersToAdd.removeAll();
        _.each(this.vm.Filters.Filters(), function (filter) {
            var id = ko.unwrap(filter.Id);
            _this.vm.FiltersToAdd.push(id);
        });
    };
    //loads ajax data into table
    ResortTable.prototype.loadResponse = function (responsedata) {
        var _this = this;
        //clear the list
        this.vm.List.removeAll();
        //reload page info
        ko.mapping.fromJS(responsedata.PageInfo, {}, this.vm.PageInfo);
        this.vm.PageResortIds(responsedata.PageResortIds);
        this.vm.ShowSearchResults(responsedata.PageInfo.TotalItemCount > 0);
        //ko.mapping.fromJS(responsedata.DatesLoaded, {}, this.vm.DatesLoaded);
        //this.vm.addToIndex(ko.toJS(this.vm.ItemDetail), ko.toJS(this.vm.DatesLoaded));
        var resortItems = [];
        if (responsedata.List.length > 0) {
            responsedata.List.forEach(function (item) { return resortItems.push({ currencySymbol: item.CurrencySymbol, resort_tile_title: item.DisplayName.trim(), resorts_tile_location: item.CityStateCountry.trim() }); });
            this.trackResortListViews(resortItems);
        }
        //add the new items
        var hasEliteDiscounts = false;
        _.each(responsedata.List, function (v) {
            _this.pushResortToList(v, responsedata.DatesLoaded);
            hasEliteDiscounts = hasEliteDiscounts || v.HasEliteCashDiscounts; //get if any of the resorts have elite discounts
        });
        this.vm.HasEliteCashDiscounts(hasEliteDiscounts);
        //this.vm.refreshAllUnits();
        this.vm.AvailabilityCacheUpdate(new Date());
        if (this.vm.detailLoading === false)
            this.vm.IsLoading(false);
        var filters = this.vm.Filters;
        this.vm.ShowOpenSeason(filters.OpenSeasonMode()); //toggle showing open season pricing etc.
        this.vm.NumberOfNights(responsedata.NumberOfNights);
        this.vm.DatesSubmitted(filters.CheckIn() && filters.CheckIn !== '' && filters.CheckOut() && filters.Checkout !== '');
        //HGV.Search['TopDatePicker'].close();    // Close the search UI; Removed per HGV4-487 (Paulb)
        this.vm.OpenSeasonMode(responsedata.OpenSeasonMode);
        var isChangingReservation = this.vm.Filters.ReservationNumber() !== null;
        if (isChangingReservation) {
            this.vm.form.getReservationToChange(this.vm.Filters.ReservationNumber());
        }
        else {
            if (this.vm.DatesSubmitted()) {
                this.vm.picker.setMonthOffset(filters.CheckIn());
            }
        }
    };
    //protected setupSubscriptions() {
    //    super.setupSubscriptions();
    //}
    ResortTable.prototype.pushResortToList = function (resort, dates) {
        // this.vm.addToIndex(resort, dates);
        var resortItem = ko.mapping.fromJS(resort);
        this.vm.SetCosts(resortItem);
        _super.prototype.pushToList.call(this, resortItem);
    };
    ResortTable.prototype.somethingChanged = function () {
        if (this.vm.IsSearch() && this.vm.CurrentAvailabilityMode() == this.vm.AvailabilityGridMode()) {
            this.loadAvailabilityGrid();
        }
        else {
            _super.prototype.somethingChanged.call(this);
        }
        //save fitlers ids to a cookie to make sure we dont hit get request size limit
        var data = ko.toJS(this.vm.Filters);
        Cookies.set('resort-filter-values', data);
    };
    ResortTable.prototype.load = function (forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        if (this.vm.detailLoading)
            return null;
        if (!moment(this.vm.Filters.CheckIn(), 'MM/DD/YYYY').isValid() || !moment(this.vm.Filters.CheckOut(), 'MM/DD/YYYY').isValid()) {
            return;
        }
        var thisMonth = this.vm.picker.getAllSquareMonthDates();
        var nextMonth = this.vm.picker.getAllSquareMonthDates(1);
        var start = moment(thisMonth[0].Date).format(this.dateFormat);
        var last = moment(nextMonth[nextMonth.length - 1].Date).format(this.dateFormat);
        var filters = ko.toJS(this.vm.Filters);
        filters.CalendarStart = start;
        filters.CalendarEnd = last;
        filters.CurrentLanguageCode = this.vm.CurrentLanguageCode();
        //format the request
        var requestData = {
            $inlinecount: 'allpages',
            $orderby: this.orderby,
            $top: this.top,
            $skip: this.skip
        };
        //add in filters
        $.extend(requestData, filters);
        //do not duplicate the last request
        if (JSON.stringify(requestData) !== this.previousRequestData || forceRefresh) {
            this.vm.enableIsLoading(false);
            //this.vm.IsLoading(true);
            this.previousRequestData = JSON.stringify(requestData);
            //cancel previous pending request
            if (this.jqXHR && this.jqXHR.state() === 'pending') {
                //Calls any error / fail callbacks of jqXHR
                this.jqXHR.abort();
            }
            //make the request
            var response = $.ajax({
                url: this.oDataUrl,
                dataType: 'json',
                cache: false,
                data: requestData
            });
            if (!forceRefresh && this.vm.CurrentAvailabilityMode() == 'list') {
                var destinationSearch = '';
                if (filters.CategoryName != null && filters.CategoryName.length > 0) {
                    destinationSearch = filters.CategoryName;
                }
                else if (filters.SearchTerm != null && filters.SearchTerm.length > 0) {
                    destinationSearch = filters.SearchTerm;
                }
                this.vm.DataLayer.push({
                    'event': 'select_dates',
                    'check_in': filters.CheckIn,
                    'check_out': filters.CheckOut,
                    'destination': destinationSearch,
                    'memberType': this.vm.User.ClubMemberType(),
                    'memberID': this.vm.User.MemberId()
                });
            }
            this.jqXHR = response;
            //handle successful response
            response.done(function (responsedata) {
                _this.loadResponse(responsedata);
                // update the map
                _this.vm.forceMapRefresh();
            });
            return response;
        } // else if (this.vm.CurrentAvailabilityMode() == this.vm.AvailabilityListMode()) {
        //Paulb:: Removing under belief that this is causing a duplicate inclusion of the google map library
        //this.vm.forceMapRefresh();
        // }
    };
    ResortTable.prototype.loadAvailabilityGrid = function () {
        var _this = this;
        //format the request
        var requestData = {
            $inlinecount: 'allpages',
            $orderby: 'DisplayName asc',
            $top: 1000,
            $skip: 0
        };
        if (this.vm.Filters.OpenSeasonMode()) {
            this.vm.Filters.BookingType("dollars");
        }
        var filters = ko.toJS(this.vm.Filters);
        filters.CurrentLanguageCode = this.vm.CurrentLanguageCode();
        $.extend(requestData, filters);
        //if (JSON.stringify(requestData) !== this.previousAvailabilityRequestData) {
        this.vm.enableIsLoading(false);
        this.previousAvailabilityRequestData = JSON.stringify(requestData);
        //cancel previous pending request
        if (this.jqXHR && this.jqXHR.state() === 'pending') {
            //Calls any error / fail callbacks of jqXHR
            this.jqXHR.abort();
        }
        //make the request
        var response = $.ajax({
            url: '/api/availability/resorts',
            dataType: 'json',
            cache: false,
            data: requestData
        });
        this.jqXHR = response;
        //handle successful response
        response.done(function (responsedata) {
            var checkIn = 'none', checkOut = 'none';
            if (_this.vm.Filters.CheckIn()) {
                checkIn = (new Date(_this.vm.Filters.CheckIn())).toLocaleDateString();
            }
            if (_this.vm.Filters.CheckOut()) {
                checkOut = (new Date(_this.vm.Filters.CheckOut())).toLocaleDateString();
            }
            _this.vm.DataLayer.push({
                'event': 'Search',
                'searchTerm': _this.vm.Filters.SearchTerm(),
                'dates': checkIn + ' - ' + checkOut,
                'guests': _this.vm.Filters.NumberOfGuest(),
                'numberofResults': responsedata.PageInfo.TotalItemCount,
                'roomType': _this.vm.Filters.RoomSizes().toString()
            });
            _this.loadAvailabilityGridResponse(responsedata);
            _this.setCheckIn(responsedata);
        });
        return response;
        //}
    };
    ResortTable.prototype.setCheckIn = function (responseData) {
        var resortIds = Object.keys(responseData.Resorts);
        var resortList = [];
        var minBookingPointsDates = [];
        var minBookingCashDates = [];
        resortIds.forEach(function (k) { return resortList.push(responseData.Resorts[k]); });
        resortList.forEach(function (k) {
            if (k.units.minBookingWindowPoints != null) {
                minBookingPointsDates.push(k.units.minBookingWindowPoints);
            }
            if (k.units.minBookingWindowCash != null) {
                minBookingCashDates.push(k.units.minBookingWindowCash);
            }
        });
        var minDate = null;
        var isOpenSeason = this.vm.Filters.OpenSeasonMode();
        if (isOpenSeason) {
            if (minBookingPointsDates.length > 0) {
                minDate = minBookingPointsDates.sort()[0];
            }
        }
        else {
            if (minBookingCashDates.length > 0) {
                minDate = minBookingCashDates.sort()[0];
            }
        }
        if (minDate != null && this.vm.Filters.CheckIn == null) {
            this.vm.Filters.CheckIn(moment(minDate).format(this.dateFormat));
        }
    };
    ResortTable.prototype.loadAvailabilityGridResponse = function (responseData) {
        if (this.vm.detailLoading === false)
            this.vm.IsLoading(false);
        if (!responseData.PageInfo)
            throw "Error loading resort list | Message " + responseData.Message;
        this.vm.PageInfo.TotalItemCount(responseData.PageInfo.TotalItemCount);
        this.vm.ShowSearchResults(responseData.PageInfo.TotalItemCount > 0);
        // Hold onto the resorts (not the dictionary) returned for lookup of the unitType modal
        var resortIds = Object.keys(responseData.Resorts);
        var resortList = [];
        var resortItems = [];
        resortIds.forEach(function (k) { return resortList.push(responseData.Resorts[k]); });
        if (resortList.length > 0) {
            resortList.forEach(function (item) { return resortItems.push({ currencySymbol: item.currencySymbol, resort_tile_title: item.name, resorts_tile_location: item.cityStateCountry }); });
            this.trackResortListViews(resortItems);
        }
        this.vm.AvailabilityGridResortList(resortList);
        if (window.bookingApp) {
            var numberOfGuests = this.vm.Filters.NumberOfGuest() !== null ? this.vm.Filters.NumberOfGuest() : 2;
            this.vm.originalAvailabilityGuestNumber(numberOfGuests);
            window.bookingApp.$data.guests = numberOfGuests;
            window.bookingApp.$data.ada = this.vm.Filters.ADAOnly();
            window.bookingApp.$data.roomTypes = this.vm.Filters.RoomSizes();
            window.bookingApp.$data.bookingType = this.vm.Filters.BookingType();
            window.bookingApp.$data.resorts = responseData.Resorts;
        }
    };
    ResortTable.prototype.trackResortListViews = function (resortItems) {
        var _a, _b, _c, _d;
        this.vm.Analytics.track("Resort List Viewed", {
            event: "Resort List Viewed",
            lead_id: (_b = (_a = this.vm.User) === null || _a === void 0 ? void 0 : _a.LeadId()) !== null && _b !== void 0 ? _b : "",
            u_lead_id: (_d = (_c = this.vm.User) === null || _c === void 0 ? void 0 : _c.ULeadId()) !== null && _d !== void 0 ? _d : "",
            ecommerce: {
                item_list_name: 'resorts',
                items: resortItems
            }
        });
    };
    ResortTable.prototype.findCategory = function (id) {
        var category = null;
        id = id.toLowerCase();
        category = _.find(this.vm.Meta.AllAmenities, { Id: id });
        if (category)
            return category;
        category = _.find(this.vm.Meta.AllExperiences, { Id: id });
        if (category)
            return category;
        category = _.find(this.vm.Meta.AllResortTypes, { Id: id });
        if (category)
            return category;
        category = _.find(this.vm.Meta.AllLocations, { Id: id });
        if (category)
            return category;
        category = _.find(this.vm.Meta.AllRoomSizes, { Id: id });
        if (category)
            return category;
        return null;
    };
    ResortTable.prototype.filter = function (id, maintainFilters) {
        if (maintainFilters === void 0) { maintainFilters = false; }
        var category = this.findCategory(id);
        if (!maintainFilters)
            this.vm.Filters.Filters.removeAll();
        this.vm.Filters.Filters.push(category);
        var data = ko.toJS(this.vm.Filters);
        Cookies.set('resort-filter-values', data);
    };
    return ResortTable;
}(ResortSearchTable));
module.exports = ResortTable;

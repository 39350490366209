"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Table = require("./Table");
var moment = require("moment");
var ResortSearchTable = /** @class */ (function (_super) {
    __extends(ResortSearchTable, _super);
    function ResortSearchTable(data, vm, pageInfoOverride, titleOverride) {
        if (pageInfoOverride === void 0) { pageInfoOverride = undefined; }
        if (titleOverride === void 0) { titleOverride = undefined; }
        var _this = _super.call(this, data, vm, pageInfoOverride, titleOverride) || this;
        _this.data = data;
        _this.vm = vm;
        _this.pageInfoOverride = pageInfoOverride;
        _this.titleOverride = titleOverride;
        _this.dateFormat = 'MM/DD/YYYY';
        return _this;
    }
    ResortSearchTable.prototype.search = function () {
        var _this = this;
        var filters = this.vm.Filters;
        var hasSearchTerm = filters && filters.SearchTerm() && filters.SearchTerm() !== '';
        var hasNumberOfGuests = filters && filters.NumberOfGuest() != null;
        if (!hasSearchTerm && filters != null && (filters.CheckIn() == null || filters.CheckOut() == null) && !hasNumberOfGuests) {
            $('#searchError').show();
            return;
        }
        else {
            $('#searchError').hide();
        }
        //if no search term (didn't change the search bar at all) then just reload the data with selected dates/guests
        if (!hasSearchTerm && this.vm.IsSearch()) {
            if (this.vm.CurrentAvailabilityMode() === this.vm.AvailabilityGridMode()) {
                this.vm.refreshAvailabilityAppResorts();
                return;
            }
            this.load();
            return;
        }
        var queryParameters = this.vm.QueryParameters();
        var autoCompleteCategory = this.vm.AutoCompleteCategory();
        if (autoCompleteCategory === 'Resort') {
            var selectedResort = ko.utils.arrayFirst(this.vm.SearchTerms(), function (searchTerm) {
                return searchTerm.Id() === _this.vm.AutoCompleteId();
            });
            if (selectedResort !== null && selectedResort !== undefined) { //if it's a resort go directly to it's detail page
                var url = "/" + this.vm.CurrentLanguageCode() + "/resort/" + selectedResort.RegionSlug() + "/" + selectedResort.Slug() + "/" + queryParameters;
                window.location.href = url;
            }
        }
        else { //redirect with the current search params
            var redirectUrl = "/" + this.vm.CurrentLanguageCode() + "/resort/search/" + queryParameters;
            window.location.href = redirectUrl;
        }
        this.vm.AutoCompleteId(null);
        this.vm.AutoCompleteCategory(null);
        this.vm.AutoCompleteName(null);
        this.vm.Analytics = this.vm.getAnalytics();
        var searchTerm = this.vm.Filters != null ? this.vm.Filters.SearchTerm() : "";
        var checkIn = this.vm.Filters != null ? this.vm.Filters.CheckIn() : "";
        var checkOut = this.vm.Filters != null ? this.vm.Filters.CheckOut() : "";
        var guests = this.vm.Filters != null ? this.vm.Filters.NumberOfGuest() : "";
        var roomType = this.vm.Filters != null ? this.vm.Filters.RoomSizes().join() : "";
        var leadId = this.vm.User != null ? this.vm.User.LeadId() : "";
        var uLeadId = this.vm.User != null ? this.vm.User.ULeadId() : "";
        this.vm.Analytics.track("Resort Searched", {
            event: "Search",
            search_term: searchTerm,
            dates: checkIn + " - " + checkOut,
            guests: guests,
            room_type: roomType,
            lead_id: leadId,
            u_lead_id: uLeadId
        });
    };
    ;
    ResortSearchTable.prototype.setupSubscriptions = function () {
        var _this = this;
        _super.prototype.setupSubscriptions.call(this);
        var filters = this.vm.Filters;
        if (filters == null)
            return;
        //reset observable to remove subscribe
        filters.SearchTerm = ko.observable(filters.SearchTerm());
        filters.SearchTerm.subscribe(function () {
            // this.vm.PageInfo.PageNumber(1);
        });
        filters.NumberOfGuest = ko.observable(filters.NumberOfGuest());
        filters.CheckIn = ko.observable(filters.CheckIn());
        filters.CheckOut = ko.observable(filters.CheckOut());
        filters.CalendarStart = ko.observable(filters.CalendarStart());
        filters.CalendarEnd = ko.observable(filters.CalendarEnd());
        filters.ADAOnly = ko.observable(filters.ADAOnly());
        filters.OpenSeasonMode = ko.observable(filters.OpenSeasonMode());
        filters.BookingType = ko.observable(filters.BookingType());
        filters.ResortId = ko.observable(filters.ResortId());
        filters.ReservationNumber = ko.observable(filters.ReservationNumber());
        if (!Array.isArray(filters.RoomSizes())) // only if the object isn't an array otherwise we'll get an error
            filters.RoomSizes = ko.observableArray(JSON.parse(filters.RoomSizes()));
        filters.ADAOnly.subscribe(function (value) {
            if (_this.vm.skipADARefresh)
                return;
            _this.vm.setADACookie(value);
            if (_this.vm.IsDetail() || _this.vm.IsSearch()) {
                _this.vm.form.refreshResortData();
                _this.load();
            }
        });
        filters.BookingType.subscribe(function (value) {
            if (value == 'dollars')
                filters.OpenSeasonMode(true);
            else
                filters.OpenSeasonMode(false);
            if (window.bookingApp) {
                window.bookingApp.$data.bookingType = _this.vm.Filters.BookingType();
            }
        });
        filters.NumberOfGuest.subscribe(function () {
            if (_this.vm.IsDetail() || _this.vm.IsSearch()) {
                _this.vm.form.refreshResortData();
                if (_this.vm.CurrentAvailabilityMode() === _this.vm.AvailabilityListMode()) {
                    _this.load();
                }
                if (window.bookingApp) {
                    window.bookingApp.$data.guests = _this.vm.Filters.NumberOfGuest();
                }
            }
        });
        filters.CheckIn.subscribe(function () { return _this.onSearchBarDateChanged(); });
        filters.CheckOut.subscribe(function () { return _this.onSearchBarDateChanged(); });
        filters.OpenSeasonMode.subscribe(function (value) {
            if (_this.vm.skipOpenSeasonRefresh)
                return;
            _this.vm.setOpenSeasonCookie(value);
            if (!_this.vm.IsSearch() || _this.vm.CurrentAvailabilityMode() === _this.vm.AvailabilityGridMode())
                return;
            _this.load();
        });
        $('#search-button').on('click', function () {
            _this.search();
        });
        $('body').on('keydown', '#txt-search', function (event) {
            var isEnterKey = event.keyCode === 13;
            var autoCompleteOptionsHidden = $('ul.typeahead').is(':hidden');
            if (isEnterKey && autoCompleteOptionsHidden) { //only search if they hit enter and they aren't just hitting enter to select an autocomplete item
                _this.search();
            }
        });
    };
    ResortSearchTable.prototype.onSearchBarDateChanged = function () {
        if (window.bookingApp) {
            var checkIn = this.vm.Filters.CheckIn() != null ? moment(this.vm.Filters.CheckIn(), 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
            var checkOut = this.vm.Filters.CheckOut() != null ? moment(this.vm.Filters.CheckOut(), 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
            window.bookingApp.datePickerChanged([checkIn, checkOut], true);
        }
        else {
            if (this.vm.IsDetail() || this.vm.IsSearch()) {
                this.load();
            }
        }
    };
    return ResortSearchTable;
}(Table));
module.exports = ResortSearchTable;

"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: SelectMembership
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVMBase");
var SelectMembershipTable = require("./SelectMembershipTable");
var SelectMembershipForm = require("./SelectMembershipForm");
var SelectMembershipRouter = require("./SelectMembershipRouter");
var SelectMembership = /** @class */ (function (_super) {
    __extends(SelectMembership, _super);
    function SelectMembership(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, SelectMembershipForm, SelectMembershipTable, SelectMembershipRouter) || this;
        _this.data = data;
        ko.applyBindings(_this);
        $('.hideUnbound').removeClass('hideUnbound');
        _this.form.getDetail(_this.CurrentLanguageCode);
        return _this;
    }
    return SelectMembership;
}(base.ResortSearchVmBase));
module.exports = SelectMembership;

"use strict";
var ImageGallery = /** @class */ (function () {
    function ImageGallery() {
    }
    ImageGallery.Initialize = function (options) {
        if (options && options.beforeInit) {
            options.beforeInit();
        }
        // must iterate over each that matches this selector instead of just chaining to the selector
        $('.popup-gallery').each(function (index, galleryContainer) {
            var galleryId = $(galleryContainer).attr('id');
            if (!galleryId) {
                console.error("undefined galleryId");
                return;
            }
            if (!HGV || !HGV.ImageGallery || !HGV.ImageGallery[galleryId]) {
                console.error('undefined HGV ImageGallery');
                return;
            }
            HGV.ImageGallery[galleryId].init({
                delegate: 'a',
                type: 'image',
                tLoading: 'Loading image #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    //preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                },
                image: {
                    tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    //titleSrc: function (item) {
                    //    return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
                    //}
                }
            });
            if (options && options.afterInit) {
                options.afterInit(galleryId);
            }
        });
    };
    return ImageGallery;
}());
module.exports = ImageGallery;

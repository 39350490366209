/// This code was generated by Orange Onion Scaffolder.
// template: MVC.TSApp.cs
// class: GlobalAlert
hgv = {};

var Account = require('./app/Account/Account');
hgv.Account = Account;

var ContactUsEntry = require('./app/ContactUsEntry/ContactUsEntry');
hgv.ContactUsEntry = ContactUsEntry;

var Dashboard = require('./app/Dashboard/Dashboard');
hgv.Dashboard = Dashboard;

var HoaPayment = require('./app/HoaPayment/HoaPayment');
hgv.HoaPayment = HoaPayment;

var InviteUsers = require('./app/InviteUsers/InviteUsers');
hgv.InviteUsers = InviteUsers;

var Login = require('./app/Login/Login');
hgv.Login = Login;

var ManagePoints = require('./app/ManagePoints/ManagePoints');
hgv.ManagePoints = ManagePoints;

var MortgagePayment = require('./app/MortgagePayment/MortgagePayment');
hgv.MortgagePayment = MortgagePayment;

var Ownership = require('./app/Ownership/Ownership');
hgv.Ownership = Ownership;

var PartnerPerk = require('./app/PartnerPerk/PartnerPerk');
hgv.PartnerPerk = PartnerPerk;

var Points = require('./app/Points/Points');
hgv.Points = Points;

var Profile = require('./app/Profile/Profile');
hgv.Profile = Profile;

var PublicContentPage = require('./app/PublicContentPage/PublicContentPage');
hgv.PublicContentPage = PublicContentPage;

var PurchaseRequest = require('./app/PurchaseRequest/PurchaseRequest');
hgv.PurchaseRequest = PurchaseRequest;

var RecentHistory = require('./app/RecentHistory/RecentHistory');
hgv.RecentHistory = RecentHistory;

var Reservation = require('./app/Reservation/Reservation');
hgv.Reservation = Reservation;

var Resort = require('./app/Resort/Resort');
hgv.Resort = Resort;

var ResortReviewFrontEnd = require('./app/ResortReviewFrontEnd/ResortReviewFrontEnd');
hgv.ResortReviewFrontEnd = ResortReviewFrontEnd;

var SetupProfile = require('./app/SetupProfile/SetupProfile');
hgv.SetupProfile = SetupProfile;

var SelectMembership = require('./app/SelectMembership/SelectMembership');
hgv.SelectMembership = SelectMembership;

var SignUp = require('./app/SignUp/SignUp');
hgv.SignUp = SignUp;

var UpdateYourInformation = require('./app/UpdateYourInformation/UpdateYourInformation');
hgv.UpdateYourInformation = UpdateYourInformation;
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Router = require("../Base/Router");
var MortgagePaymentRouter = /** @class */ (function (_super) {
    __extends(MortgagePaymentRouter, _super);
    function MortgagePaymentRouter(data, vm, alternateroutes) {
        var _this = _super.call(this, data, vm, alternateroutes) || this;
        _this.data = data;
        _this.vm = vm;
        _this.reloadingPage = false;
        return _this;
    }
    MortgagePaymentRouter.prototype.getRoutes = function () {
        var self;
        self = this;
        return [
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "(.*)#/index",
                func: function () {
                    // Set view properties
                    self.vm.FormVisible(false);
                    self.vm.NewForm("none");
                    self.vm.ItemDetail.Step(1);
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "(.*)#/new",
                func: function () {
                    if (self.reloadingPage) {
                        return;
                    }
                    window.HGV.init();
                    // Set view properties
                    self.vm.FormVisible(true);
                    self.vm.NewForm("none");
                    self.vm.ItemDetail.Step(1);
                    self.vm.ItemDetail.CreditCardForm.CardType.valueHasMutated(); // Trigger a change so the value is selected from the toolkit dropdown                    
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "(.*)#/another",
                func: function () {
                    // Set view properties
                    // onclick="window.location.assign('@Url.Action("Index", "HoaPayment")#/new');window.location.reload(true);" 
                    $('#loading').height($(window).height());
                    self.vm.IsLoading(true);
                    self.vm.NewForm("none");
                    self.reloadingPage = true;
                    window.location.assign('#/new');
                    window.location.reload(true);
                }
            },
            {
                // Switch to ContractID;
                // NOTE: Confirmation must come before Confirm in list of routes.
                name: self.getArea() + "/" + self.data.FrontEndRoute + "(.*)#/confirmation",
                func: function () {
                    // Set display properties
                    self.vm.NewForm("none");
                    self.vm.ItemDetail.Step(3);
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "(.*)#/loan-transactions",
                func: function () {
                    // Set display properties       
                    //self.vm.FormVisible(false);
                    self.vm.NewForm("loan-transactions");
                    self.vm.PaginatedLoanTransactions.setupBindings();
                }
            }
        ];
    };
    return MortgagePaymentRouter;
}(Router));
module.exports = MortgagePaymentRouter;

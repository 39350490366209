"use strict";
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var mapper = require("../Base/Mapper");
var moment = require("moment");
var ResortBookingWindow = /** @class */ (function () {
    function ResortBookingWindow(resortVM) {
        this.resortVM = resortVM;
        var resortDetail = resortVM.ItemDetail;
        mapper.fromJsExisting(resortDetail.BookingWindow, this);
        this.postUrlBase = resortVM.form.postUrl.replace('Edit', '');
        this.apiUrlBase = "/api/resort/";
        this.UserTimeZone = resortVM.UserTimeZone;
        this.dateFormat = 'M/D/YYYY';
        this.ResortUrl = null;
        this.popupRoomSizes = [];
        this.allUnitTypes = [];
        this.setupBindings();
        this.setupEventHandlers();
    }
    ResortBookingWindow.prototype.setupBindings = function () {
        var _this = this;
        this.CurrentLanguageCode = ko.observable(this.resortVM.CurrentLanguageCode());
        this.ResortName = ko.observable(this.resortVM.ResortDisplayName());
        this.unitTypes = ko.observableArray([]);
        this.ShowSuccess = ko.observable(false);
        this.EmailSentDate = ko.observable('');
        this.SelectedStartDate = ko.observable(moment());
        this.SelectedEndDate = ko.observable(moment());
        this.RequiresOwnershipPoints = ko.observable(true);
        this.RequiresOwnershipCash = ko.observable(true);
        this.IsOpenSeasonBlocked = ko.observable(true);
        this.CantBookOnlineMessage = ko.observable('');
        this.BookableOnline = ko.observable(true);
        this.MinNightsPoints = ko.observable(0);
        this.MinNightsCash = ko.observable(0);
        this.NumberOfNights = ko.observable(0);
        this.SelectedStartDateFormatted = ko.pureComputed(function () {
            return _this.SelectedStartDate().format(_this.dateFormat);
        });
        this.SelectedEndDateFormatted = ko.pureComputed(function () {
            return _this.SelectedEndDate().format(_this.dateFormat);
        });
        this.IsClubBookingEligible = ko.pureComputed(function () {
            return _this.getIsEligible(_this.MinBookingWindowPointsDate(), _this.MaxBookingWindowPointsDate()) && _this.NumberOfNights() >= _this.MinNightsPoints();
        });
        this.IsOpenSeasonBookingEligible = ko.pureComputed(function () {
            return _this.getIsEligible(_this.MinBookingWindowCashDate(), _this.MaxBookingWindowCashDate()) && _this.NumberOfNights() >= _this.MinNightsCash();
        });
        this.PointsEligibilityDayRangeMessage = ko.pureComputed(function () {
            return _this.getEligibilityDayRangeMessage(_this.MinBookingWindowPoints(), _this.MaxBookingWindowPoints());
        });
        this.PointsEligibilityDateRangeMessage = ko.pureComputed(function () {
            return _this.getEligibilityDateRangeMessage(_this.MinBookingWindowPointsDate(), _this.MaxBookingWindowPointsDate());
        });
        this.CashEligibilityDayRangeMessage = ko.pureComputed(function () {
            return _this.getEligibilityDayRangeMessage(_this.MinBookingWindowCash(), _this.MaxBookingWindowCash());
        });
        this.CashEligibilityDateRangeMessage = ko.pureComputed(function () {
            return _this.getEligibilityDateRangeMessage(_this.MinBookingWindowCashDate(), _this.MaxBookingWindowCashDate());
        });
        this.BookingReminderConfirmationMessage = ko.pureComputed(function () {
            var message = _this.BookingReminderConfirmationMessageFormat();
            if (message) {
                message = message.replace('{0}', _this.resortVM.User.Email());
                message = message.replace('{1}', _this.EmailSentDate());
            }
            return message;
        });
    };
    ResortBookingWindow.prototype.getIsEligible = function (minBookingWindowDate, maxBookingWindowDate) {
        var minDate = moment.tz(minBookingWindowDate, this.UserTimeZone);
        var maxDate = moment.tz(maxBookingWindowDate, this.UserTimeZone);
        var selectedStartDate = moment.tz(this.SelectedStartDate(), this.UserTimeZone);
        var selectedEndDate = moment.tz(this.SelectedEndDate(), this.UserTimeZone);
        var isWithinRange = selectedStartDate.diff(minDate, 'days') >= 0 && selectedEndDate <= maxDate;
        return isWithinRange;
    };
    ResortBookingWindow.prototype.getEligibilityDateRangeMessage = function (minBookingWindowDate, maxBookingWindowDate) {
        if (!minBookingWindowDate || !maxBookingWindowDate) {
            return "";
        }
        var message = this.EligibilityDateRangeMessageFormat();
        if (!message) {
            return "";
        }
        message = message.replace('{0}', moment.tz(minBookingWindowDate, this.UserTimeZone).format(this.dateFormat));
        message = message.replace('{1}', moment.tz(maxBookingWindowDate, this.UserTimeZone).format(this.dateFormat));
        return message;
    };
    ResortBookingWindow.prototype.getEligibilityDayRangeMessage = function (minBookingWindow, maxBookingWindow) {
        if (!minBookingWindow || !maxBookingWindow) {
            return "";
        }
        var message = this.EligibilityDayRangeMessageFormat();
        if (!message) {
            return "";
        }
        message = message.replace('{0}', minBookingWindow.toString());
        message = message.replace('{1}', maxBookingWindow.toString());
        return message;
    };
    ResortBookingWindow.prototype.setupEventHandlers = function () {
        // these events are emitted from bookingJS availability app.
        var self = this;
        var selectDatesEvent = null;
        $(document).on('mouseup', function (e) {
            var $elem = $(e.target);
            var isSliderEvent = $elem.hasClass('range-slider__date-out') || $elem.hasClass('range-slider__date-in');
            if (selectDatesEvent && isSliderEvent) {
                self.resortVM.DataLayer.push(selectDatesEvent);
                selectDatesEvent = null;
            }
        });
        $(document).on('bookingAppStartDateChanged', function (e, startDate) {
            self.SelectedStartDate(startDate);
            setSelectDatesEvent();
        });
        $(document).on('bookingAppEndDateChanged', function (e, endDate) {
            self.SelectedEndDate(endDate);
            setSelectDatesEvent();
            if ($('.datepicker').is(":visible")) {
                self.resortVM.DataLayer.push(selectDatesEvent);
            }
        });
        function setSelectDatesEvent() {
            var destinationSearch = '';
            var categoryName = self.resortVM.Filters.CategoryName();
            var searchTerm = self.resortVM.Filters.SearchTerm();
            if (categoryName != null && categoryName.length > 0) {
                destinationSearch = categoryName;
            }
            else if (searchTerm != null && searchTerm.length > 0) {
                destinationSearch = searchTerm;
            }
            selectDatesEvent = {
                'event': 'select_dates',
                'check_in': moment(self.SelectedStartDate()).format('MM/DD/YYYY'),
                'check_out': moment(self.SelectedEndDate()).format('MM/DD/YYYY'),
                'destination': destinationSearch,
                'memberType': self.resortVM.User.ClubMemberType(),
                'memberID': self.resortVM.User.MemberId()
            };
        }
        $(document).on('bookingAppViewBookingEligibilityLinkClicked', function (e, bookingWindowData) {
            bookingWindowData.NumberOfNights = moment($('#checkOutDateBooking').val(), this.dateFormat).diff(moment($('#checkInDateBooking').val(), this.dateFormat), 'days');
            mapper.fromJsExisting(bookingWindowData, self);
            //setTimeout(() => {
            //    $('.submit-checkbox--roomsizes').trigger('change');
            //}, 2000);
            //$("#checkInDate").val($('#checkInDateBooking').val());
            //$("#checkOutDate").val($('#checkOutDateBooking').val());
            // self.allUnitTypes.removeAll();
        });
        $(document).on('submitSearchRequestLinkClicked', function (e, bookingWindowData) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            bookingWindowData.NumberOfNights = moment($('#checkOutDateBooking').val(), this.dateFormat).diff(moment($('#checkInDateBooking').val(), this.dateFormat), 'days');
            mapper.fromJsExisting(bookingWindowData, self);
            if (self.resortVM.CurrentAvailabilityMode() == 'grid') {
                self.resortVM.Filters.CheckIn($('#checkInDateBooking').val());
                self.resortVM.Filters.CheckOut($('#checkOutDateBooking').val());
            }
            $("#resortNameText").text(self.ResortName());
            self.allUnitTypes = [];
            if (self.allUnitTypes.length == 0) {
                $(self.unitTypes()).each(function (i, dat) {
                    //if (self.unitIsVisible(self.unitTypes()[i], self.resortVM.Filters.NumberOfGuest(), self.resortVM.Filters.RoomSizes())) {
                    self.allUnitTypes.push(self.unitTypes()[i]);
                    //  }
                });
            }
            self.showSubmitRequestModal();
        });
        //Needed to toggle ADA for the availability view
        $('body').on('change', '.submit-checkbox--ada', function (e) {
            e.stopImmediatePropagation();
            if ($(e.target).hasClass('is-disabled'))
                return false;
            //var selected = $(this).is(':checked');
        });
        $(document).on('click', '.view-booking-eligibility-link', function (e) {
            e.preventDefault();
            self.showBookingEligibilityModal();
        });
    };
    ResortBookingWindow.prototype.showBookingEligibilityModal = function () {
        this.ShowSuccess(false);
        this.toggleCalculatorModal(true);
    };
    ResortBookingWindow.prototype.showSubmitRequestModal = function () {
        this.ShowSuccess(false);
        this.toggleSubmitRequestModal(true);
    };
    ResortBookingWindow.prototype.toggleCalculatorModal = function (toggle) {
        if (HGV.Modal) {
            $.each(HGV.Modal, function (key, value) {
                if ($(value.$target).is('#booking-window-calculator-modal')) {
                    if (toggle) {
                        value.open();
                    }
                    else {
                        value.close();
                    }
                }
            });
        }
    };
    ResortBookingWindow.prototype.toggleSubmitRequestModal = function (toggle) {
        if (HGV.Modal) {
            $.each(HGV.Modal, function (key, value) {
                if ($(value.$target).attr("id") != undefined && $(value.$target).attr("id").indexOf('submit-request-modal') != -1) {
                    $("#txt-minpoints").val("");
                    $("#txt-maxpoints").val("");
                    $("#txt-borrowpoints").val("");
                    if (toggle) {
                        value.open();
                    }
                    else {
                        $("#resortNameText").val("");
                        value.close();
                    }
                }
            });
        }
    };
    ResortBookingWindow.prototype.sendPointsEmailNotification = function () {
        this.sendNotification(this.MinBookingWindowPointsDate(), this.MaxBookingWindowPointsDate(), this.ClubBookingHeader());
    };
    ResortBookingWindow.prototype.sendCashEmailNotification = function () {
        this.sendNotification(this.MinBookingWindowCashDate(), this.MaxBookingWindowCashDate(), this.OpenSeasonBookingHeader());
    };
    ResortBookingWindow.prototype.sendNotification = function (minDate, maxDate, bookingType) {
        var _this = this;
        var self = this;
        if (this.resortVM.IsLoading() === true) {
            return;
        }
        this.resortVM.IsLoading(true);
        if (this.resortVM.IsDetail()) {
            this.ResortUrl = this.resortVM.CurrentLanguageCode() + '/resort/' + this.resortVM.ItemDetail.RegionSlug() + '/' + this.resortVM.ItemDetail.Slug();
        }
        $.ajax({
            url: this.apiUrlBase + 'QueueEligibilityEmail',
            data: JSON.stringify({
                UserId: this.UserId(),
                ResortId: this.ResortId(),
                Language: this.CurrentLanguageCode(),
                BookingType: bookingType,
                SelectedStartDate: this.SelectedStartDate(),
                SelectedEndDate: this.SelectedEndDate(),
                MinBookingStartDate: minDate,
                MaxBookingEndDate: maxDate,
                CantBookOnlineMessage: this.CantBookOnlineMessage(),
                BookableOnline: this.BookableOnline()
            }),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8',
        }).done(function (sendByDate) {
            self.EmailSentDate(moment(sendByDate).format(_this.dateFormat));
            self.ShowSuccess(true);
        }).always(function (response) {
            self.resortVM.IsLoading(false);
        });
    };
    ResortBookingWindow.prototype.closeModal = function () {
        this.ShowSuccess(false);
        this.toggleCalculatorModal(false);
    };
    ResortBookingWindow.prototype.closeSubmitModal = function () {
        this.ShowSuccess(false);
        this.toggleSubmitRequestModal(false);
    };
    return ResortBookingWindow;
}());
module.exports = ResortBookingWindow;

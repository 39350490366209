"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _ = require("lodash");
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var ReservationForm = /** @class */ (function (_super) {
    __extends(ReservationForm, _super);
    function ReservationForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        _this.guestCertFuncCode = ['GCFT', 'GCTR', 'GCRT', 'HCRT'];
        _this.oldGuestCertificate = ko.mapping.toJS(_this.vm.ChangePrimaryGuest);
        _this.oldComplimentaryGuestCertificate = _this.vm.ComplimentaryGuestCertificate;
        _this.cardType = ko.observable('');
        return _this;
    }
    ReservationForm.prototype.post = function (dataToPost) {
        var _this = this;
        var promise = _super.prototype.post.call(this, dataToPost);
        promise.done(function (data) {
            var amountSpent = '';
            if (data.ItemDetail.PaymentMethod === 1 || data.ItemDetail.PaymentMethod === 0) { // cash or guest cert payment?
                amountSpent = data.ItemDetail.PaymentLineItems[0].Amount;
            }
            else if (data.ItemDetail.PaymentMethod === 2) { // points
                amountSpent = data.ItemDetail.PointsUsed[0].UsedAmount + " points";
            }
            else if (data.ItemDetail.PaymentMethod === 3) { // bonus points
                amountSpent = data.ItemDetail.BonusPointsUsed[0].UsedAmount + " points";
            }
            _this.vm.DataLayer.push({
                'event': 'TripBooking',
                'resortName': data.ItemDetail.ResortNameDisplay,
                'tripDates': (new Date(data.ItemDetail.DateCheckIn)).toLocaleDateString() + ' - ' + (new Date(data.ItemDetail.DateCheckOut)).toLocaleDateString(),
                'accommodation': data.ItemDetail.UnitType.Name,
                'paymentType': data.ItemDetail.PaymentMethod,
                'amountSpent': amountSpent,
                'bookingDate': (new Date(data.ItemDetail.BookingDate)).toLocaleDateString(),
                'cancellationProtection': data.ItemDetail.EnrollCancelProt,
                'guestCertificate': data.ItemDetail.AllowGuestCertificate,
                'memberID': _this.vm.User.MemberId(),
                'membership_type': data.ItemDetail.MembershipType,
                'ml_personalization': data.ItemDetail.IsSearchRecommended ? 'Yes' : 'No'
            });
            _this.vm.Analytics.track("Reservation Completed", {
                event: "reservation_completed",
                lead_id: _this.vm.User.LeadId(),
                u_lead_id: _this.vm.User.ULeadId(),
                ecommerce: {
                    items: [{
                            unit_id: data.ItemDetail.UnitType.UnitId,
                            item_name: data.ItemDetail.UnitType.Name,
                            price: amountSpent
                        }],
                    points_used: [{
                            previous_amount: _this.vm.ItemDetail.PreviousPointsTotal(),
                            used_amount: _this.vm.ItemDetail.UsedPointsTotal(),
                            remaining_amount: _this.vm.ItemDetail.RemainingPointsTotal()
                        }]
                },
                date_check_in: (new Date(data.ItemDetail.DateCheckIn)).toLocaleDateString(),
                date_check_out: (new Date(data.ItemDetail.DateCheckOut)).toLocaleDateString(),
                number_of_nights: data.ItemDetail.NumberOfNights,
                number_of_guests: data.ItemDetail.NumberOfGuests,
                resort_name: data.ItemDetail.ResortName,
                resort_display_name: data.ItemDetail.ResortDisplayName,
                resort_city_state_zip: data.ItemDetail.ResortCityStateZip.trim(),
                room_size: data.ItemDetail.UnitType.Size,
                occupancy: data.ItemDetail.UnitType.Occupancy,
                payment_method: data.ItemDetail.PaymentMethod,
                guest_first_name: _this.vm.ActualGuestFirstName(),
                guest_last_name: _this.vm.ActualGuestLastName(),
                guest_email: _this.vm.ActualGuestEmail(),
                guest_phone: _this.vm.ActualGuestPhone(),
                total_points_cost: _this.vm.ItemDetail.UsedPointsTotal()
            });
            _this.vm.Analytics.identify({
                username: _this.vm.User.UserName(),
                email: _this.vm.User.Email(),
                guest_phone: _this.vm.ActualGuestPhone(),
                lead_id: _this.vm.User.LeadId(),
                u_lead_id: _this.vm.User.ULeadId()
            });
        });
        return promise;
    };
    ReservationForm.prototype.refreshBorrowingLineItem = function () {
        var isBorrowing = _.filter(this.vm.ItemDetail.ViewingPointsUsed(), function (p) { return (p.UsedAmount() > 0 && p.Type() === 3); }).length > 0;
        var payItems = this.vm.ItemDetail.PaymentLineItems;
        var fc = this.vm.ItemDetail.BorrowFee.FunctionCode();
        var borrowLineItem = _.filter(payItems(), function (lineItem) { return (lineItem.FunctionCode() === fc); });
        if (isBorrowing) {
            if (borrowLineItem.length === 0) {
                //borrowing but missing line item
                payItems.push(this.vm.ItemDetail.BorrowFee);
            }
        }
        else {
            if (borrowLineItem.length !== 0) {
                //not borrowing but has line item
                payItems.remove(function (item) { return (item.FunctionCode() === fc); });
            }
        }
    };
    ReservationForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('body').on('click', '#btnRemoveRescuePoints', function (event) {
            if (self.vm.ItemDetail.BookingEnabled() === false) {
                // Add alert for not having enough points to book
                var errContent = ko.utils.arrayFirst(self.vm.FrontEndContentBlocks(), function (item) {
                    return item.Slug() === 'NotEnoughPointsErr';
                });
                if (errContent)
                    self.vm.AddAlert(errContent.Content(), 'warning');
            }
            self.setupPaymentLine(self.vm.ItemDetail.PaymentMethod().toString());
            //self.refreshBorrowingLineItem();
            window.HGV.Modal['btnRemoveRescuePoints'].close();
            $.smoothScroll('#user-alerts-container');
        });
        $('body').on('click', '#btnCancelRescuePoints', function (event) {
            window.HGV.Modal['btnCancelRescuePoints'].close();
        });
        $('body').on('click', '.remove-points-link', function () {
            self.removePointsId = $(this).data('id');
            return false;
        });
        $('body').on('click', '#btnBook, #btnCommitChange', function (event) {
            event.preventDefault();
            $("#divGuestRadio").css("border", "");
            if ($("#rdnGuestSelection").is(":checked") && !self.vm.isPrimayGuestDataEntered()) {
                $("#divGuestRadio").css("border", "2px solid red");
                self.scrollTop();
                return false;
            }
            //Validating the page!
            if (self.vm.IsLoading()) {
                return;
            }
            if (!self.vm.ItemDetail.BookingEnabled())
                return false;
            if ($("#rdnGuestSelection").is(":checked") && !self.vm.isPrimayGuestDataEntered()) {
                $("#divGuestRadio").css("border", "2px solid red");
                self.scrollTop();
                return false;
            }
            if ($("#ItemDetail_PointsReadPolicies").is(":visible")) {
                if (!$("#ItemDetail_PointsReadPolicies").is(":checked")) {
                    var object = { Key: "ItemDetail.PointsReadPolicies", ErrorMessage: "< !--The field ReadPolicies must be between True and True. -->" };
                    self.addPropertyError(object);
                    return false;
                }
            }
            if ($("#ItemDetail_ReadRoomDescription").is(":visible")) {
                if (!$("#ItemDetail_ReadRoomDescription").is(":checked")) {
                    var object = { Key: "ItemDetail.ReadRoomDescription", ErrorMessage: "< !--The field ReadRoomDescription must be between True and True. -->" };
                    self.addPropertyError(object);
                    return false;
                }
            }
            if ($("#ItemDetail_ReadNonHiltonDisclosure").is(":visible")) {
                if (!$("#ItemDetail_ReadNonHiltonDisclosure").is(":checked")) {
                    var object = { Key: "ItemDetail.ReadNonHiltonDisclosure", ErrorMessage: "< !--The field ReadNonHiltonDisclosure must be between True and True. -->" };
                    self.addPropertyError(object);
                    return false;
                }
            }
            if ($("#ItemDetail_ReadNonHiltonDisclosure_Cash").is(":visible")) {
                if (!$("#ItemDetail_ReadNonHiltonDisclosure_Cash").is(":checked")) {
                    var object = { Key: "ItemDetail.ReadNonHiltonDisclosure_Cash", ErrorMessage: "< !--The field ReadNonHiltonDisclosure must be between True and True. -->" };
                    self.addPropertyError(object);
                    return false;
                }
            }
            self.vm.IsLoading(true);
            if (self.vm.OSCAmount() != null && self.vm.OSCAmount() > 0) {
                self.vm.IsOpenSeasonCredit(true);
            }
            else {
                self.vm.IsOpenSeasonCredit(false);
            }
            self.editAction = "Book";
            self.vm.CoOwnerLeadId = $("#coownerLeadId").length > 0 ? $("#coownerLeadId").val() : self.vm.User.LeadNumber(); //self.vm.CoOwnerLeadId;
            self.vm.IsCownerSelected = $("#rdnCoownerSelection").is(":checked");
            if (!(self.vm.ItemDetail.PaymentMethod() === 1 && self.vm.ItemDetail.IsJapanProperty())) {
                if (self.vm.TotalAmountDue() > 0) {
                    if (!self.validateCC(self.vm.ItemDetail.CreditCardForm)) {
                        return false;
                    }
                }
                if (self.vm.TotalAmountDue() > 0) {
                    self.GetFuseboxToken(self.vm.ItemDetail.CreditCardForm, window.location.href)
                        .then(function (data) {
                        if (data.CardToken().length > 0) {
                            self.vm.IsLoading(false);
                            var postData = self.getPostDate();
                            postData.ItemDetail.CreditCardForm.CardType = self.cardType();
                            self.post(postData);
                        }
                        else {
                            self.postCCError();
                            self.logFuseError("token length is zero");
                        }
                    }).catch(function (e) {
                        self.postCCError();
                    });
                }
                else {
                    self.vm.IsLoading(false);
                    self.post();
                }
            }
            else if (self.vm.ItemDetail.PaymentMethod() === 1 && self.vm.ItemDetail.IsJapanProperty()) {
                if (self.vm.TotalAmountDue() > 0 && !self.validateJapanCC(self.vm.ItemDetail.CreditCardForm)) {
                    return false;
                }
                else {
                    self.vm.IsLoading(false);
                    self.post();
                }
            }
            return false;
        });
        $('body').on('click', '#btnCommitChangeGuest', function (event) {
            event.preventDefault();
            //Validating the page!
            if (self.vm.IsLoading()) {
                return;
            }
            if (!self.vm.ItemDetail.CommitChangeGuestEnabled() && !self.vm.ItemDetail.IsCoownerDropdownChanged())
                return false;
            //if ($("#rdnGuestSelection").is(":checked") && self.vm.isGuestButtonVisible()) {
            //    $("#divGuestRadio").css("border", "2px solid red");
            //    self.scrollTop();
            //    return false;
            //}
            self.vm.IsLoading(true);
            if (self.vm.TotalAmountDue() > 0) {
                if (!self.validateCC(self.vm.ItemDetail.CreditCardForm)) {
                    return false;
                }
            }
            self.editAction = "Book";
            self.vm.CoOwnerLeadId = $("#coownerLeadId").length > 0 ? $("#coownerLeadId").val() : self.vm.User.LeadNumber(); //self.vm.CoOwnerLeadId;
            self.vm.IsCownerSelected = $("#rdnCoownerSelection").is(":checked");
            if (self.vm.TotalAmountDue() > 0) {
                self.GetFuseboxToken(self.vm.ItemDetail.CreditCardForm, window.location.href)
                    .then(function (data) {
                    if (data.CardToken().length > 0) {
                        var postData = self.getPostDate();
                        postData.AllowChangeableReservation = false;
                        postData.ItemDetail.ReadPolicies = true;
                        postData.ItemDetail.CreditCardForm.CardType = self.cardType();
                        self.vm.IsLoading(false);
                        var promise = self.post(postData);
                        promise.done(function (data) {
                            // HGV['Modal']['btnOpenChangeGuest'].close();
                            window.HGV.init();
                            self.showReceipt();
                            self.vm.IsLoading(false);
                        });
                    }
                    else {
                        self.vm.IsLoading(false);
                        self.postCCError();
                        self.logFuseError("token length is zero");
                    }
                }).catch(function (e) {
                    self.vm.IsLoading(false);
                    self.scrollTop();
                });
            }
            else {
                var postData = self.getPostDate();
                postData.AllowChangeableReservation = false;
                postData.ItemDetail.ReadPolicies = true;
                self.vm.IsLoading(false);
                var promise = self.post(postData);
                promise.done(function (data) {
                    mapper.fromJsToModel(data.ChangePrimaryGuest, self.vm.ChangePrimaryGuest);
                    // HGV['Modal']['btnOpenChangeGuest'].close();
                    window.HGV.init();
                    self.showReceipt();
                });
            }
            return false;
        });
        $('body').on('click', '#btnChangeGuest', function () {
            if (self.vm.IsLoading()) {
                return;
            }
            self.vm.IsLoading(true);
            self.vm.ChangePrimaryGuest.IsHomeWeek(self.vm.IsHomeWeekBooking());
            self.vm.ChangePrimaryGuest.GlobalContentBlocks = self.vm.GlobalContentBlocks();
            self.vm.ChangePrimaryGuest.ProjectNumber(self.vm.ItemDetail.ProjectNumber());
            var postData = mapper.toJs(self.vm.ChangePrimaryGuest);
            if (self.vm.ChangePrimaryGuest.CountryId() == 192) {
                var regPostcode = /^([0-9]){3}[-]([0-9]){4}$/;
                if (regPostcode.test(self.vm.ChangePrimaryGuest.Zip()) == false) {
                    var $element = $('#ChangePrimaryGuest_Zip').closest('.form-group');
                    $element.addClass("is-error");
                    $element.append('<div class="form-control-error">please use format: 111-1111</div></div>');
                    self.vm.IsLoading(false);
                    return false;
                }
                else {
                    var $element = $('#ChangePrimaryGuest_Zip').closest('.form-group');
                    $element.removeClass("is-error");
                    $element.children('.form-control-error').remove();
                }
            }
            //url: `/${this.vm.CurrentLanguageCode() }/${this.data.FrontEndRoute}/` + 'CancelReservation',
            var promise = $.ajax({
                //url: '/' + self.vm.CurrentLanguageCode() + '/reservation/ValidateChangePrimaryGuest',
                url: "/" + self.vm.CurrentLanguageCode() + "/" + self.data.FrontEndRoute + "/" + 'ValidateChangePrimaryGuest',
                data: JSON.stringify(postData),
                type: 'POST',
                cache: false,
                contentType: 'application/json;charset=utf-8'
            });
            promise.fail(function (error) { return self.postPromiseFail(error); });
            promise.done(function (data) {
                var dto = mapper.fromJs(data);
                if (dto.Fee && self.vm.ItemDetail.HasPrimaryGuestChanged() === false) {
                    //var certificatePromise = $.ajax({
                    //    url: `/${self.vm.CurrentLanguageCode()}/${self.data.FrontEndRoute}/`+'',
                    //    cache: false,
                    //    contentType: 'application/json;charset=utf-8'
                    //});
                    var isExistingReservation = self.vm.ItemDetail.ReservationNumber() > 0;
                    var hasUsedComplimentaryGuestCertificates = self.vm.ItemDetail.HasUsedComplimentaryGuestCertificates();
                    if (!(isExistingReservation && hasUsedComplimentaryGuestCertificates)) {
                        if (self.vm.ShowGuestCert() && self.vm.ComplimentaryGuestCertificate.Remaining != undefined) {
                            if (self.vm.ComplimentaryGuestCertificate.Remaining() > 0) {
                                self.oldComplimentaryGuestCertificate = self.vm.ComplimentaryGuestCertificate;
                                dto.Fee.ActualAmount(dto.Fee.Amount());
                                dto.Fee.Amount(0);
                            }
                        }
                        self.vm.ItemDetail.PaymentLineItems.push(dto.Fee);
                        self.vm.ItemDetail.ChangeGuestFee = dto.Fee;
                    }
                }
                // Update phone numbers with formatted versions from the dto
                self.vm.ChangePrimaryGuest.Phone(dto.Phone());
                self.vm.ChangePrimaryGuest.SecondaryPhone(dto.SecondaryPhone());
                var changeGuest = self.vm.ChangePrimaryGuest;
                self.oldGuestCertificate = ko.mapping.toJS(changeGuest); //cache the original to reset if they make changes and cancel
                self.vm.ItemDetail.HasPrimaryGuestChanged(true);
                //HGV['Modal']['btnOpenChangeGuest'].close();
                self.vm.isPrimayGuestDataEntered(true);
                window.HGV.init();
                self.vm.IsLoading(false);
                self.scrollTop();
            });
            return false;
        });
        $('body').on('click', '#btnOpenChangeGuest', function (e) {
            //Get default data?
            e.preventDefault();
            // HGV['Modal']['btnOpenChangeGuest'].open();
            return false;
        });
        $('body').on('click', '#btnReplaceEdit', function (e) {
            self.vm.isPrimayGuestDataEntered(false);
            self.vm.isGuestButtonVisible(false);
            return false;
        });
        $('body').on('click', '#btnAddGuest', function (e) {
            self.clearPrimaryGuest();
            self.vm.isGuestButtonVisible(false);
            self.vm.isPrimayGuestDataEntered(false);
            return false;
        });
        $('body').on('click', '#btnXReplace', function (e) {
            self.vm.isGuestButtonVisible(true);
            self.vm.isPrimayGuestDataEntered(false);
            self.vm.ItemDetail.HasPrimaryGuestChanged(false);
            self.clearPrimaryGuest();
            self.vm.ItemDetail.PaymentLineItems.remove(function (item) {
                return self.guestCertFuncCode.indexOf(item.FunctionCode()) >= 0;
            });
            //self.resetGuest();
            self.resetComplimentaryGuestCertificate();
            return false;
        });
        $('body').on('click', '#rdnGuestSelection', function (e) {
            self.vm.isGuestButtonVisible($(this).is(":checked"));
            self.vm.isGuestFormVisible($(this).is(":checked"));
            self.vm.isOwnerVisible(!$(this).is(":checked"));
            self.vm.ItemDetail.IsCoownerDropdownChanged = ko.observable(false);
        });
        $('body').on('click', '#rdnCoownerSelection', function (e) {
            self.vm.isGuestButtonVisible(!$(this).is(":checked"));
            self.vm.isGuestFormVisible(!$(this).is(":checked"));
            self.vm.isOwnerVisible($(this).is(":checked"));
            self.vm.isPrimayGuestDataEntered(false);
            self.vm.ItemDetail.PaymentLineItems.remove(function (item) {
                return self.guestCertFuncCode.indexOf(item.FunctionCode()) >= 0;
            });
            self.vm.ItemDetail.HasPrimaryGuestChanged(false);
            self.resetComplimentaryGuestCertificate();
        });
        $('body').on('click', '#btnEditGuest', function () {
            //Get default data?
            // HGV['Modal']['btnOpenChangeGuest'].open();
            return false;
        });
        $('body').on('click', '#btnResetGuest', function () {
            self.resetGuest();
            return false;
        });
        $('body').on('click', '#btnCancelGuest, #btnXGuest', function () {
            //  HGV['Modal']['btnOpenChangeGuest'].close();
            self.resetPrimaryGuest();
            self.vm.isGuestButtonVisible(true);
            self.vm.isPrimayGuestDataEntered(true);
            // self.vm.ItemDetail.HasPrimaryGuestChanged(false);
            self.scrollTop();
            return false;
        });
        $('body').on("click", ".side-modal-container", function (e) {
            //e
            if ($(this).hasClass("is-open")) {
                return;
            }
            var id = $(this).attr('id');
            self.resetPrimaryGuest();
        });
        $('body').on('click', "#populateCard", function (e) {
            _this.vm.RemoveAlerts();
            var populateGMOCardsUrl = "/api/cards/PopulateGMOCards?id=" + _this.getLeadId();
            var promise = $.ajax({
                cache: false,
                url: populateGMOCardsUrl
            });
            promise.done(function (data) {
                mapper.fromJsToModel(data, _this.vm.ItemDetail.CardInfo);
                $('#selectCard').show();
                $('#populateCard').hide();
                var frame = $('#jpnCC');
                frame.hide();
            });
            promise.fail(function (error) {
                console.log(error);
            });
        });
        if (this.vm.HTSIsEnabled()) {
            this.vm.ItemDetail.CreditCardForm.DisplayNumber.subscribe(function (newValue) {
                if (newValue != null) {
                    _this.vm.ItemDetail.CreditCardForm.Number(newValue);
                    _this.vm.ItemDetail.CreditCardForm.LastFourCardNumber(_this.vm.ItemDetail.CreditCardForm.DisplayNumber().substr(_this.vm.ItemDetail.CreditCardForm.DisplayNumber().length - 4));
                }
            });
        }
        this.vm.ChangePrimaryGuest.CountryId.subscribe(function (newValue) {
            var country = ko.utils.arrayFirst(_this.vm.AllCountries(), function (country) {
                return country.Id() === newValue;
            });
            if (country == null || !country.HasStateDropdown()) {
                _this.vm.AllStates.removeAll();
                _this.vm.ChangePrimaryGuest.State(null);
                return;
            }
            var promise = _this.getAllStates(_this.vm.ChangePrimaryGuest.CountryId());
            promise.done(function (allStates) {
                _this.vm.AllStates(allStates);
                var state = ko.utils.arrayFirst(allStates, function (state) {
                    return state.Id === _this.vm.ChangePrimaryGuest.State();
                });
                if (state == null) {
                    _this.vm.ChangePrimaryGuest.State(allStates[0].Id);
                }
                else {
                    _this.vm.ChangePrimaryGuest.State.valueHasMutated(); //trigger a change so the state is selected from the toolkit dropdown
                }
            });
        });
        //this.vm.CoOwnerLeadId.subscribe((newValue) => {
        //    var country = ko.utils.arrayFirst(this.vm.CoOwners(), (cw: any) => {
        //        return cw.CoOwnerLeadId() === newValue;
        //    });
        //});
        $('body').on('change', '#coownerLeadId', function () {
            self.confirmChangesButtonToggle();
            if ($("#coownerLeadId").val() != null && $("#coownerLeadId").val() != "" && $("#coownerLeadId").val() != "0") {
                self.vm.ItemDetail.IsCoownerDropdownChanged = ko.observable(true);
            }
            self.clearPrimaryGuest();
            self.resetComplimentaryGuestCertificate();
        });
        $('body').on('change', 'input[name=AvailablePaymentMethods]:radio', function (e) {
            var paymentMethod = ko.dataFor(e.target);
            self.vm.ItemDetail.RequiresSavingPoints(paymentMethod.RequiresSavingPoints());
            self.setupPaymentLine(paymentMethod.Key().toString());
            $(window).trigger('resize'); //trigger a resize event so that the sticky sidebar can re-adjust
        });
        $('body').on('click', '#change-reservation', function (e) {
            e.preventDefault();
            HGV.Modal['change-reservation'].close();
        });
        $('.reservation-list').on('click', '.change-reservation', function (e) {
            e.preventDefault();
            //set the reservation to change
            var reservation = ko.dataFor(e.target);
            _this.vm.ReservationToChange(reservation.Id());
            _this.vm.ResortChangeBaseUrl(reservation.ResortUrl());
            if (reservation.ShowChangeWarning()) {
                HGV.Modal['change-reservation'].open();
            }
            else {
                window.location.href = $(e.target).attr('href');
            }
            return false;
        });
        $('.confirm-cancel-reservation').on('click', '#cancel-policy-modal', function (e) {
            e.preventDefault();
            HGV.Modal['cancel-reservation-policy'].open();
            return false;
        });
        $('.confirm-cancel-reservation').on('click', '#confirm-cancel', function (e) {
            e.preventDefault();
            if (!$("#ItemDetail_ReadCancelPolicies").is(":checked")) {
                var object = { Key: "ItemDetail.ReadCancelPolicies", ErrorMessage: "< !--The field ReadPolicies must be between True and True. -->" };
                self.addPropertyError(object);
                return false;
            }
            _this.vm.IsLoading(true);
            var postData = _super.prototype.getPostDate.call(_this);
            delete postData.ChangePrimaryGuest;
            delete postData.ReservationConfirmCancel.CreditCardForm;
            postData.ItemDetail.ReadPolicies = true;
            if (_this.vm.TotalAmountDue() === 0) { //delete the credit card if it's not required
                delete postData.ReservationConfirmCancel.CreditCardForm;
                var postData = self.getPostDate();
                delete postData.ChangePrimaryGuest;
                delete postData.ReservationConfirmCancel.CreditCardForm;
                postData.ItemDetail.ReadPolicies = true;
                self.vm.IsLoading(true);
                var promise = $.ajax({
                    url: "/" + self.vm.CurrentLanguageCode() + "/" + self.data.FrontEndRoute + "/" + 'CancelReservation',
                    data: JSON.stringify(postData),
                    type: 'POST',
                    cache: false,
                    contentType: 'application/json;charset=utf-8'
                });
                promise.done(function (data) {
                    mapper.fromJsWithOptions(data.ReservationCancelReceipt, {}, self.vm.ReservationCancelReceipt);
                    self.vm.CancelReservation(false);
                    self.vm.CancelReservationReceipt(true);
                    self.scrollTop();
                    self.vm.updateUserPoints();
                    self.vm.IsLoading(false);
                    //console.log('cancel done');
                });
                promise.fail(function (failData) {
                    var data = failData.responseJSON;
                    self.vm.CreditCardIsMissing(data.CreditCardIsMissing);
                    self.postPromiseFail(failData);
                    if (data.ReservationConfirmCancel.CancelationError) {
                        self.vm.ReservationConfirmCancel.CancelationError(true);
                    }
                    self.vm.IsLoading(false);
                    return false;
                });
            }
            else {
                if (self.vm.TotalAmountDue() > 0) {
                    if (!self.validateCC(self.vm.ItemDetail.CreditCardForm)) {
                        return false;
                    }
                }
                _this.GetFuseboxToken(self.vm.ItemDetail.CreditCardForm, window.location.href)
                    .then(function (data) {
                    if (data.CardToken().length > 0) {
                        self.vm.ReservationConfirmCancel.CreditCardForm = self.vm.ItemDetail.CreditCardForm;
                        var postData = self.getPostDate();
                        postData.ItemDetail.CreditCardForm.CardType = self.cardType();
                        delete postData.ChangePrimaryGuest;
                        delete postData.ReservationConfirmCancel.CreditCardForm;
                        postData.ItemDetail.ReadPolicies = true;
                        self.vm.IsLoading(false);
                        var promise = $.ajax({
                            url: "/" + self.vm.CurrentLanguageCode() + "/" + self.data.FrontEndRoute + "/" + 'CancelReservation',
                            data: JSON.stringify(postData),
                            type: 'POST',
                            cache: false,
                            contentType: 'application/json;charset=utf-8'
                        });
                        promise.done(function (data) {
                            mapper.fromJsWithOptions(data.ReservationCancelReceipt, {}, self.vm.ReservationCancelReceipt);
                            self.vm.CancelReservation(false);
                            self.vm.CancelReservationReceipt(true);
                            self.scrollTop();
                            self.vm.updateUserPoints();
                            self.vm.IsLoading(false);
                            //console.log('cancel done');
                        });
                        promise.fail(function (failData) {
                            var data = failData.responseJSON;
                            self.vm.CreditCardIsMissing(data.CreditCardIsMissing);
                            self.postPromiseFail(failData);
                            if (data.ReservationConfirmCancel.CancelationError) {
                                self.vm.ReservationConfirmCancel.CancelationError(true);
                            }
                            self.vm.IsLoading(false);
                            return false;
                        });
                    }
                    else {
                        self.postCCError();
                        self.logFuseError("token length is zero");
                    }
                }).catch(function (e) {
                    self.postCCError();
                });
            }
        });
        $('body').on('click', '#btnAcceptUpgrade', function (event) {
            event.preventDefault();
            var promise = $.ajax({
                url: "/" + self.vm.CurrentLanguageCode() + "/" + self.data.FrontEndRoute + "/" + 'EnrollEliteUpgrade',
                data: 'accept=true&resNum=' + self.vm.ItemDetail.ReservationNumber(),
                type: 'POST',
                cache: false
            });
            promise.fail(function (error) { return self.postPromiseFail(error); });
            promise.done(function (data) {
                self.vm.ItemDetail.AcceptEliteUpgrade(true);
            });
            HGV['Modal']['upgrade-modal-trigger'].close();
            self.scrollTop();
            return false;
        });
        $('body').on('click', '#btnAcceptReservation', function (event) {
            event.preventDefault();
            HGV['Modal']['acceptreservation-modal-trigger'].open();
            $('#accept-modal').on('click', '#btnOk', function () {
                self.vm.IsLoading(true);
                self.vm.AcceptOrRejectResProcessing(true);
                var promise = $.ajax({
                    url: "/" + self.vm.CurrentLanguageCode() + "/" + self.data.FrontEndRoute + "/" + 'AcceptOrRejectReservation',
                    data: 'accept=true&resNum=' + self.vm.ItemDetail.ReservationNumber(),
                    type: 'POST',
                    cache: false
                });
                promise.fail(function (error) {
                    HGV['Modal']['acceptreservation-modal-trigger'].close();
                    self.vm['AcceptOrRejectReservationVisible'](false);
                    self.vm['NotAbletoAcceptOrRejectReservation'](true);
                    self.getAcceptOrRejectDetailFail();
                });
                promise.done(function (data) {
                    window.HGV.init();
                    if (data.Result == true) {
                        //timer for 10 seconds for the data to sync
                        setTimeout(function () {
                            self.vm.IsLoading(false);
                            self.vm.AcceptOrRejectResProcessing(false);
                            self.vm['NotAbletoAcceptOrRejectReservation'](false);
                            HGV['Modal']['acceptreservation-modal-trigger'].close();
                            window.location.href = "/" + self.vm.CurrentLanguageCode() + "/" + self.data.FrontEndRoute + "#/view/" + self.vm.ItemDetail.ReservationNumber();
                        }, 10000);
                    }
                    else {
                        self.vm.IsLoading(false);
                        self.vm.AcceptOrRejectResProcessing(false);
                        HGV['Modal']['acceptreservation-modal-trigger'].close();
                        self.vm['AcceptOrRejectReservationVisible'](false);
                        self.vm['NotAbletoAcceptOrRejectReservation'](true);
                        self.getAcceptOrRejectDetailFail();
                    }
                });
            });
            // Handle "Cancel" button click inside the modal
            $('#accept-modal').on('click', '#btnCancel', function () {
                HGV['Modal']['acceptreservation-modal-trigger'].close();
            });
            return false;
        });
        $('body').on('click', '#btnRejectReservation', function (event) {
            event.preventDefault();
            HGV['Modal']['rejectreservation-modal-trigger'].open();
            $('#reject-modal').on('click', '#btnOk', function () {
                var promise = $.ajax({
                    url: "/" + self.vm.CurrentLanguageCode() + "/" + self.data.FrontEndRoute + "/" + 'AcceptOrRejectReservation',
                    data: 'accept=false&resNum=' + self.vm.ItemDetail.ReservationNumber(),
                    type: 'POST',
                    cache: false
                });
                promise.fail(function (error) {
                    HGV['Modal']['rejectreservation-modal-trigger'].close();
                    self.vm['AcceptOrRejectReservationVisible'](false);
                    self.vm['NotAbletoAcceptOrRejectReservation'](true);
                    self.getAcceptOrRejectDetailFail();
                });
                promise.done(function (data) {
                    HGV['Modal']['rejectreservation-modal-trigger'].close();
                    if (data.Result = true) {
                        window.location.href = "/" + self.vm.CurrentLanguageCode() + '/dashboard#/index';
                    }
                    else {
                        HGV['Modal']['rejectreservation-modal-trigger'].close();
                        self.vm['AcceptOrRejectReservationVisible'](false);
                        self.vm['NotAbletoAcceptOrRejectReservation'](true);
                        self.getAcceptOrRejectDetailFail();
                    }
                });
            });
            // Handle "Cancel" button click inside the modal
            $('#reject-modal').on('click', '#btnCancel', function () {
                HGV['Modal']['rejectreservation-modal-trigger'].close();
            });
            return false;
        });
        $('body').on('click', '#btnDeclineUpgrade', function (event) {
            event.preventDefault();
            var promise = $.ajax({
                url: "/" + self.vm.CurrentLanguageCode() + "/" + self.data.FrontEndRoute + "/" + 'EnrollEliteUpgrade',
                data: 'accept=false&resNum=' + self.vm.ItemDetail.ReservationNumber(),
                type: 'POST',
                cache: false
            });
            promise.fail(function (error) {
                self.postPromiseFail(error);
                HGV['Modal']['upgrade-modal-trigger'].close();
            });
            promise.done(function (data) {
                self.vm.ItemDetail.AcceptEliteUpgrade(false);
                HGV['Modal']['upgrade-modal-trigger'].close();
            });
            self.scrollTop();
        });
        $('body').on('click', '.btnPrint', function (event) {
            event.preventDefault();
            window.print();
        });
        $('body').on('click', '#remove-rescue-points-button', function (event) {
            HGV['Modal']['remove-points-modal-trigger'].open();
        });
        $('body').on('click', "#populateCard", function (e) {
            _this.vm.RemoveAlerts();
            var populateGMOCardsUrl = "/api/cards/PopulateGMOCards?id=" + _this.getLeadId();
            var promise = $.ajax({
                cache: false,
                url: populateGMOCardsUrl
            });
            promise.done(function (data) {
                mapper.fromJsToModel(data, _this.vm.ItemDetail.CardInfo);
                $('#selectCard').show();
                $('#populateCard').hide();
                var frame = $('#jpnCC');
                frame.hide();
            });
            promise.fail(function (error) {
                console.log(error);
            });
        });
        //ReservationConfirmCancel.CreditCardForm
        this.vm.ReservationConfirmCancel.CreditCardForm.NameOnCard.subscribe(function (data) {
            _this.vm.ItemDetail.CreditCardForm.NameOnCard(data);
        });
        this.vm.ReservationConfirmCancel.CreditCardForm.CardType.subscribe(function (data) {
            if (data != null && data !== '' && data != undefined) {
                _this.cardType(data);
                $('#ItemDetail_CreditCardForm_CardType').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_CardType').addClass('creditForm');
            }
        });
        this.vm.ReservationConfirmCancel.CreditCardForm.Number.subscribe(function (data) {
            _this.vm.ItemDetail.CreditCardForm.Number(data);
        });
        this.vm.ReservationConfirmCancel.CreditCardForm.Month.subscribe(function (data) {
            _this.vm.ItemDetail.CreditCardForm.Month(data);
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Month').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Month').addClass('creditForm');
            }
        });
        this.vm.ReservationConfirmCancel.CreditCardForm.Year.subscribe(function (data) {
            _this.vm.ItemDetail.CreditCardForm.Year(data);
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Year').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Year').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.EnrollCancelProt.subscribe(function (data) {
            _this.vm.ItemDetail.EnrollCancelProt(data);
            if (!self.vm.ItemDetail.IsHistory()) {
                self.setupPaymentLine(self.vm.ItemDetail.PaymentMethod().toString());
            }
        });
        this.vm.ItemDetail.CreditCardForm.CardType.subscribe(function (data) {
            if (data != null && data !== '' && data != undefined) {
                _this.cardType(data);
                $('#ItemDetail_CreditCardForm_CardType').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_CardType').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.CreditCardForm.Month.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Month').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Month').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.CreditCardForm.Year.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Year').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Year').addClass('creditForm');
            }
        });
    };
    ReservationForm.prototype.setupPaymentLine = function (bookingType) {
        var self = this;
        var bookingFee = null;
        self.vm.ItemDetail.PaymentLineItems.removeAll();
        if (bookingType === '1') {
            // Cash booking
            bookingFee = self.vm.ItemDetail.CashBookingFee;
            self.vm.ItemDetail.PaymentLineItems.push(self.vm.ItemDetail.CashBookingAmount);
            if (ko.unwrap(self.vm.ItemDetail.CashBookingDiscountAmount))
                self.vm.ItemDetail.PaymentLineItems.push(self.vm.ItemDetail.CashBookingDiscountAmount);
            self.vm.ItemDetail.PaymentLineItems.push(self.vm.ItemDetail.TaxFee);
            self.vm.ItemDetail.EnrollCancelProt(false); // No cancel prot for cash
            self.resetGuest();
        }
        else if (bookingType === '2') {
            // Regular points booking
            bookingFee = self.vm.ItemDetail.ClubBookingFee;
            if (self.vm.ItemDetail.RequiresBorrowing())
                self.vm.ItemDetail.PaymentLineItems.push(self.vm.ItemDetail.BorrowFee);
            if (self.vm.ItemDetail.EnrollCancelProt()) // Put cancel prot fee back if we're opting in
                self.vm.ItemDetail.PaymentLineItems.push(self.vm.ItemDetail.CancelProtectionFee);
        }
        else if (bookingType === '3') { // Bonus points booking
            bookingFee = self.vm.ItemDetail.BonusBookingFee;
            self.vm.ItemDetail.EnrollCancelProt(false); // No cancel prot for bonus points
        }
        self.vm.ItemDetail.PaymentLineItems.push(bookingFee);
        if (self.vm.ItemDetail.ChangeGuestFee)
            self.vm.ItemDetail.PaymentLineItems.push(self.vm.ItemDetail.ChangeGuestFee);
    };
    ReservationForm.prototype.resetPrimaryGuest = function () {
        var changeGuest = this.vm.ChangePrimaryGuest;
        changeGuest.Address(this.oldGuestCertificate.Address);
        changeGuest.AgreeGuestsAre18(this.oldGuestCertificate.AgreeGuestsAre18);
        changeGuest.AgreeResponsible(this.oldGuestCertificate.AgreeResponsible);
        changeGuest.AgreeAuthorizedGuest(this.oldGuestCertificate.AgreeAuthorizedGuest);
        changeGuest.City(this.oldGuestCertificate.City);
        changeGuest.CountryId(this.oldGuestCertificate.CountryId);
        changeGuest.CountryName(null);
        changeGuest.Email(this.oldGuestCertificate.Email);
        changeGuest.Fee(null);
        changeGuest.FirstName(this.oldGuestCertificate.FirstName);
        changeGuest.LastName(this.oldGuestCertificate.LastName);
        changeGuest.Phone(this.oldGuestCertificate.Phone);
        changeGuest.SecondaryEmail(this.oldGuestCertificate.SecondaryEmail);
        changeGuest.SecondaryFirstName(this.oldGuestCertificate.SecondaryFirstName);
        changeGuest.SecondaryLastName(this.oldGuestCertificate.SecondaryLastName);
        changeGuest.SecondaryPhone(this.oldGuestCertificate.SecondaryPhone);
        changeGuest.State(this.oldGuestCertificate.State);
        changeGuest.Zip(this.oldGuestCertificate.Zip);
    };
    ReservationForm.prototype.clearPrimaryGuest = function () {
        var changeGuest = this.vm.ChangePrimaryGuest;
        changeGuest.Address("");
        changeGuest.AgreeGuestsAre18(false);
        changeGuest.AgreeResponsible(false);
        changeGuest.AgreeAuthorizedGuest(false);
        changeGuest.City("");
        changeGuest.CountryId(null);
        changeGuest.CountryName(null);
        changeGuest.Email("");
        changeGuest.Fee(null);
        changeGuest.FirstName("");
        changeGuest.LastName("");
        changeGuest.Phone("");
        changeGuest.SecondaryEmail("");
        changeGuest.SecondaryFirstName("");
        changeGuest.SecondaryLastName("");
        changeGuest.SecondaryPhone("");
        changeGuest.State("");
        changeGuest.Zip("");
    };
    ReservationForm.prototype.getConfirmCancellationSummary = function (reservationNumber) {
        var _this = this;
        this.vm.ReservationToCancel(reservationNumber);
        var postData = _super.prototype.getPostDate.call(this);
        this.vm.IsLoading(true);
        var promise = $.ajax({
            url: "/" + this.vm.CurrentLanguageCode() + "/" + this.data.FrontEndRoute + "/" + 'GetCancellationSummary',
            data: JSON.stringify(postData),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.done(function (data) {
            mapper.fromJsWithOptions(data.ReservationConfirmCancel, {}, _this.vm.ReservationConfirmCancel);
            mapper.fromJsWithOptions(data.ItemDetail, {}, _this.vm.ItemDetail); // this was missing so it never populates the discount
            var cancelFee = _this.vm.ReservationConfirmCancel.CancelFee();
            if (cancelFee > 0) {
                _this.vm.initHtsTokenizer(_this.vm.ReservationConfirmCancel);
                _this.vm.TotalAmountDue(cancelFee);
                _this.vm.ItemDetail.CreditCardForm.Amount(cancelFee);
            }
            _this.vm.IsLoading(false);
        });
        promise.fail(function (data) {
            _this.vm.ReservationConfirmCancel.CancelationError(true);
            _this.vm.IsLoading(false);
        });
    };
    ReservationForm.prototype.getAllStates = function (countryId) {
        var countryIdDto = { 'countryId': countryId };
        var promise = $.ajax({
            url: this.area + "/" + this.vm.CurrentLanguageCode() + "/address/GetStates",
            data: JSON.stringify(countryIdDto),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        return promise;
    };
    ReservationForm.prototype.getPostDate = function () {
        var ignoreMapping = {
            'ignore': this.data.KOMapping.allNonPostBack
        };
        var postData = mapper.toJsWithOptions(this.vm, ignoreMapping);
        delete postData.Alerts;
        delete postData.NewVersion;
        delete postData.AllCountries;
        delete postData.AllStates;
        delete postData.AvailableGuestAmounts;
        delete postData.ReservationCancelReceipt.CreditCardForm;
        this.removeContentBlocks(postData);
        if (!this.data.ItemDetail.ResortUpdate)
            delete postData.ReadResortUpdate;
        if (!this.vm.ItemDetail.HasPrimaryGuestChanged())
            delete postData.ChangePrimaryGuest;
        if (this.vm.TotalAmountDue() === 0)
            delete postData.ItemDetail.CreditCardForm;
        if (!this.vm.CancelReservation())
            delete postData.ReservationConfirmCancel.CreditCardForm;
        return postData;
    };
    ReservationForm.prototype.getDetail = function (id) {
        var _this = this;
        this.resetDetail();
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        $('body').addClass('form-shown');
        var url = "/" + this.vm.CurrentLanguageCode() + "/" + this.vm.FrontEndRoute() + "/item";
        var promise = $.ajax({
            url: url,
            data: { id: id },
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.fail(function (error) { return _this.getDetailFail(error, id); });
        promise.done(function (data) { return _this.getPromiseDone(data, id); });
        return promise;
    };
    ReservationForm.prototype.getAcceptOrRejectDetail = function (id) {
        var _this = this;
        this.resetDetail();
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        $('body').addClass('form-shown');
        var url = "/" + this.vm.CurrentLanguageCode() + "/" + this.vm.FrontEndRoute() + "/acceptOrRejectDetails";
        var promise = $.ajax({
            url: url,
            data: { id: id },
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.fail(function (error) { return _this.getAcceptOrRejectDetailFailure(error, id); });
        promise.done(function (data) { return _this.getPromiseDone(data, id); });
        return promise;
    };
    ReservationForm.prototype.getPromiseDone = function (data, id) {
        this.vm.ItemDetail.IsHistory(data.ItemDetail.IsHistory);
        _super.prototype.getPromiseDone.call(this, data, id);
        this.vm.IsLoading(false);
        this.vm.FormVisible(false);
        this.vm.ReceiptVisible(true);
        this.vm.IsEligibleForCancelProt(data.IsEligibleForCancelProt);
    };
    ReservationForm.prototype.getAcceptOrRejectDetailFail = function () {
        this.vm.IsLoading(false);
        this.vm.FormVisible(false);
        this.vm.ReceiptVisible(false);
        this.vm.NotAbletoAcceptOrRejectReservation(true);
    };
    ReservationForm.prototype.getDetailFail = function (data, id) {
        this.vm.IsLoading(false);
        this.vm.FormVisible(false);
        this.vm.ReceiptVisible(false);
        this.vm.ReservationNotFound(true);
        if (data.responseJSON)
            this.vm.ReservationResortNotFound(data.responseJSON.ReservationResortNotFound === true);
    };
    ReservationForm.prototype.getAcceptOrRejectDetailFailure = function (data, id) {
        this.vm.IsLoading(false);
        this.vm.FormVisible(false);
        this.vm.ReceiptVisible(false);
        this.vm.ReservationRequestIsInValid(true);
    };
    ReservationForm.prototype.postPromiseDone = function (data) {
        var _this = this;
        this.mapPostData(data);
        this.vm.IsLoading(false);
        this.vm.IsLoadingTransaction(false);
        if (this.vm.ItemDetail.ReservationNumber() === 0) {
            this.logFuseError("res # 0" + data);
        }
        this.showReceipt();
        $('#change-reservation').remove();
        window.HGV.init();
        if (this.vm.ItemDetail.IsEligibleForEliteUpgrade()) {
            HGV['Modal']['upgrade-modal-trigger'].open();
        }
        if (this.vm.PromotionAccepted()) {
            setTimeout(function () {
                window.open(_this.vm.PromotionUrl(), '_blank');
            }, 3000);
        }
    };
    ReservationForm.prototype.postPromiseFail = function (error) {
        if (error.status === 409) {
            this.vm.ErrorReason(error.responseJSON.ErrorReason);
            this.vm.routes.showAvailabilityError();
        }
        else {
            try {
                if (error.responseJSON.CreditCardIsMissing === true)
                    this.vm.CreditCardIsMissing(true);
            }
            catch (e) {
            }
            _super.prototype.postPromiseFail.call(this, error);
        }
        this.vm.IsLoading(false);
        this.vm.IsLoadingTransaction(false);
        //$.smoothScroll('#user-alerts-container');
    };
    ReservationForm.prototype.changeGuest = function (id) {
        var _this = this;
        //this.vm.IsLoading(true);
        this.vm.ItemDetail.IsCoownerDropdownChanged = ko.observable(false);
        $("#btnCommitChangeGuest").addClass("is-disabled");
        var promise = $.ajax({
            url: "/" + this.vm.CurrentLanguageCode() + "/" + this.data.FrontEndRoute + "/ChangeGuest?resNum=" + id,
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.done(function (data) {
            _this.vm.ItemDetail.IsCoownerDropdownChanged = ko.observable(false);
            if (id !== 0) {
                _this.mapPostData(data);
            }
            _this.vm.IsHomeWeekBooking(data.IsHomeWeekBooking);
            mapper.fromJsToModel(data.ChangePrimaryGuest, _this.vm.ChangePrimaryGuest);
            $("#coownerLeadId").val(data.CoOwnerLeadId);
            if (_this.vm.ItemDetail.HasPrimaryGuestChanged() == false) {
                //  $("#rdnCoownerSelection").prop("checked", true);
                _this.vm.isGuestButtonVisible(true);
                _this.clearPrimaryGuest();
                //var isDropdownChanged = $("#rdnCoownerSelection").is(":checked") && $("#coownerLeadId").val() != 0 && $("#coownerLeadId").val() != "" && $("#coownerLeadId").val() != null;
            }
            //mapper.fromJsToModel(data.ComplimentaryGuestCertificate, this.vm.ComplimentaryGuestCertificate);
            _this.oldGuestCertificate = data.ChangePrimaryGuest;
            // this.vm.IsHomeWeekBooking(data.IsHomeWeekBooking);
            _this.vm.IsChangingGuest(data.IsChangingGuest);
            // this.vm.IsLoading(false);
            if (id !== 0) {
                //  HGV['Modal']['btnOpenChangeGuest'].open();
            }
        });
        promise.fail(function () {
            _this.vm.IsLoading(false);
        });
    };
    ReservationForm.prototype.refreshReservationStickySidebar = function () {
        HGV.StickySidebar['stickyBookingSidebar'].reset();
    };
    ReservationForm.prototype.bindingsApplied = function () {
    };
    ReservationForm.prototype.mapPostData = function (data) {
        try {
            data.ItemDetail.CreditCardForm.Number = this.vm.ItemDetail.CreditCardForm.Number();
            data.ItemDetail.ResortUpdate = this.vm.ItemDetail.ResortUpdate();
        }
        catch (e) {
        }
        var copyMapping = {
            'copy': this.data.KOMapping.copy
        };
        mapper.fromJsWithOptions(data.ItemDetail, copyMapping, this.vm.ItemDetail);
        mapper.fromJsToModel(data.ChangePrimaryGuest, this.vm.ChangePrimaryGuest);
        if (data.ComplimentaryGuestCertificate != null) {
            mapper.fromJsToModel(data.ComplimentaryGuestCertificate, this.vm.ComplimentaryGuestCertificate);
        }
    };
    ReservationForm.prototype.resetComplimentaryGuestCertificate = function () {
        var self = this;
        if (self.oldComplimentaryGuestCertificate != null && self.oldComplimentaryGuestCertificate != undefined) {
            self.vm.ComplimentaryGuestCertificate.Allowed(self.oldComplimentaryGuestCertificate.Allowed());
            self.vm.ComplimentaryGuestCertificate.Booked(self.oldComplimentaryGuestCertificate.Booked());
            self.vm.ComplimentaryGuestCertificate.Remaining(self.oldComplimentaryGuestCertificate.Remaining());
            self.vm.ComplimentaryGuestCertificate.Year(self.oldComplimentaryGuestCertificate.Year());
        }
    };
    ReservationForm.prototype.resetGuest = function () {
        var self = this;
        //Get default data?
        self.vm.ItemDetail.HasPrimaryGuestChanged(false);
        self.vm.ItemDetail.ChangeGuestFee = null;
        self.vm.ItemDetail.IsCoownerDropdownChanged = ko.observable(false);
        self.vm.ItemDetail.PaymentLineItems.remove(function (item) {
            return self.guestCertFuncCode.indexOf(item.FunctionCode()) >= 0;
        });
        window.HGV.init();
        //reset the guest data.
        self.changeGuest(self.vm.ItemDetail.ReservationNumber());
    };
    ReservationForm.prototype.showReceipt = function () {
        var self = this;
        self.vm.FormVisible(false);
        self.vm.RemoveAlerts();
        self.vm['ReceiptVisible'](true);
        self.vm.form.refreshReservationStickySidebar();
        self.vm.updateUserPoints();
        self.scrollTop();
    };
    ReservationForm.prototype.confirmChangesButtonToggle = function () {
        var self = this;
        var isDropdownChanged = $("#rdnCoownerSelection").is(":checked") && $("#coownerLeadId").val() != 0 && $("#coownerLeadId").val() != "" && $("#coownerLeadId").val() != null;
        if (isDropdownChanged || self.vm.ItemDetail.CommitChangeGuestEnabled()) {
            $("#btnCommitChangeGuest").removeClass("is-disabled");
        }
        else {
            $("#btnCommitChangeGuest").addClass("is-disabled");
        }
    };
    ReservationForm.prototype.missingDates = function () { };
    ReservationForm.prototype.refreshResortData = function () { };
    return ReservationForm;
}(FrontEndForm));
module.exports = ReservationForm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVMBase");
var ResortTable = require("./ResortTable");
var ResortForm = require("./ResortForm");
var PurchaseRequest = require("../PurchaseRequest/PurchaseRequest");
var ResortReviewFrontEnd = require("../ResortReviewFrontEnd/ResortReviewFrontEnd");
var _ = require("lodash");
var mapper = require("../Base/Mapper");
var moment = require("moment-timezone");
window['moment'] = moment;
var numeral = require("numeral");
var ClubGuideQuestionResponses = require("../ClubGuideResponse/ClubGuideQuestionResponsesDto");
var ResortBookingWindow = require("./ResortBookingWindow");
var ImageGallery = require("../Base/ImageGallery");
var Resort = /** @class */ (function (_super) {
    __extends(Resort, _super);
    function Resort(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, ResortForm, ResortTable, null) || this;
        _this.data = data;
        _this.enumerateDaysBetweenDateStrings = function (start, end, callback) {
            //var momentFomrat = 'MM/DD/YYYY';
            var startDate = moment(start, _this.dateFormat);
            var endDate = moment(end, _this.dateFormat);
            var currDate = startDate.clone().startOf('day');
            var lastDate = endDate.clone().startOf('day');
            var stop = callback(currDate);
            if (!stop) {
                while (currDate.add(1, 'day').diff(lastDate) < 0) {
                    stop = callback(currDate);
                    if (stop) {
                        currDate = lastDate;
                    }
                }
            }
        };
        _this.CanAffordList = ko.computed(function () {
            var vm = _this;
            var available = _.filter(_this.List(), function (x) { return x.StartingAt() > 0 && x.StartingAt() !== null && x.StartingAt() <= vm.getMaxPoints(null, x.FunctionCodes()); });
            return available;
        });
        _this.CantAffordList = ko.computed(function () {
            var vm = _this;
            var unavailable = _.filter(_this.List(), function (x) {
                return x.StartingAt() == null || x.StartingAt() === 0;
            });
            var available = _.filter(_this.List(), function (x) {
                return x.StartingAt() > 0 && x.StartingAt() !== null && x.StartingAt() > vm.getMaxPoints(null, x.FunctionCodes());
            });
            return _.union(available, unavailable);
        });
        _this.ShowBanner = ko.computed(function () { return (_this.CanAffordList().length > 0 && _this.CantAffordList().length > 0); });
        _this.getNumberOfNights = ko.computed(function () {
            var total = moment(_this.Filters.CheckOut(), _this.dateFormat).diff(moment(_this.Filters.CheckIn(), _this.dateFormat), 'days');
            return total;
        });
        _this.ShowSearchResults = ko.observable(true);
        _this.ShowSuccessMessage = ko.observable(false);
        _this.ShowFailedMessage = ko.observable(false);
        _this.ShowSRValidation = ko.observable(false);
        //this.ShowValidationMessage = ko.observable('');
        _this.detailLoading = false;
        _this.addDisplayed = false;
        _this.timeout = 0;
        _this.SubmitGuest = ko.observable('');
        _this.SubmitNights = ko.observable('');
        _this.MaxPoints = ko.observable(0);
        _this.MinPoints = ko.observable('');
        _this.BorrowPoints = ko.observable('');
        _super.prototype.initOverlayValues.call(_this);
        _this.picker.setupCalendarBindings(_this, 'TopDatePicker');
        _this.picker.setupCalendarBindings(_this, 'StickyDatePicker');
        _this.picker.setupCalendarBindings(_this, 'DestinationDetailsDatePicker');
        _this.picker.setupCalendarBindings(_this, 'ModifySearchDatePicker');
        _this.picker.setupCalendarBindings(_this, 'ResortListDatePicker');
        _this.picker.setupCalendarBindings(_this, 'SubmitRequestDatePicker');
        _this.PurchaseRequestVM = new PurchaseRequest(data.PurchaseRequest, false);
        _this.ReviewVM = new ResortReviewFrontEnd(data.Review, false);
        _this.ViewedClubGuideQuestion = new ClubGuideQuestionResponses(_this, false);
        var checkIn = moment.tz(_this.Filters.CheckIn(), _this.UserTimeZone);
        var checkOut = moment.tz(_this.Filters.CheckOut(), _this.UserTimeZone);
        _this.StartDate = $('#checkInDateBooking').val();
        _this.EndDate = $('#checkOutDateBooking').val();
        _this.RequiresOwnershipCash = ko.observable(false);
        _this.RequiresOwnershipPoints = ko.observable(false);
        _this.IsMapView = ko.observable(true);
        _this.ViewedUnitType = ko.observable(null);
        _this.AvailabilityGridResortList = ko.observableArray([]);
        if (!_this.isValidForAvailabilityView(checkIn, checkOut)) {
            _this.CurrentAvailabilityMode(_this.AvailabilityListMode());
        }
        _this.HomeWeekUnitPicked = ko.observable(null);
        _this.originalAvailabilityGuestNumber = ko.observable(_this.Filters.NumberOfGuest());
        _this.HideDatePickerAccomodations = ko.observable(false);
        _this.ShowOpenSeason = ko.observable(false);
        _this.ShowAdaUnits = ko.observable(false);
        _this.HasEliteCashDiscounts = ko.observable(false);
        _this.ItemDetail.ShowEliteCashDiscounts = ko.observable(false);
        _this.ItemDetail.SelectedUnitFloorplanSrc = ko.observable(null);
        _this.ImageGallery = ko.observableArray([]);
        _this.ItemDetail.VisibleAttractions = ko.observableArray();
        _this.ItemDetail.CashCostLabel = ko.observable('0');
        _this.ItemDetail.PointsCostLabel = ko.observable('0');
        _this.ItemDetail.ShowAddMoreAttractions = ko.computed(function () {
            return ((_this.ItemDetail.VisibleAttractions && _this.ItemDetail.Attractions)
                && (_this.ItemDetail.VisibleAttractions().length < _this.ItemDetail.Attractions().length));
        });
        //this.RoomSizesLabelDisplay = ko.computed(() => {
        //    var label = "";
        //    if (this.Filters.ADAOnly()) {
        //        label = $("#checkbox--ada-text").text();
        //    }
        //    if (this.Filters.RoomSizes().length > 0) {
        //        if (label.length > 0) {
        //            label += " + " + this.Filters.RoomSizes().length;
        //        } else {
        //            label = $("#checkbox--ada-roomsize-" + this.Filters.RoomSizes().sort()[0]).text();
        //            if (this.Filters.RoomSizes().length > 1) {
        //                label += ` + ${(this.Filters.RoomSizes().length === 6) ? this.Filters.RoomSizes().length - 2 : this.Filters.RoomSizes().length - 1}`;
        //            }
        //        }
        //    }
        //    return label;
        //});
        _this.UnitSelected = ko.computed(function () { return _this.SelectedUnit() != null; });
        var isChangingReservation = _this.Filters.ReservationNumber() !== null;
        if (_this.IsDetail()) {
            _this.form.loadDetail();
            HGV.Carousel['image-carousel'].toggle();
        }
        if (_this.IsHomeWeek()) {
            _this.form.loadHomeWeekDetail();
            _this.picker.setupHomeWeekCalendarBindings(_this, 'HomeWeekDatePicker');
            HGV.Carousel['image-carousel'].toggle();
        }
        _this.HomeWeekBookingUnit = ko.pureComputed(function () {
            if (_this.IsHomeWeek() && _this.ItemDetail.Units().length >= 1 && _this.HomeWeekUnitPicked() !== null) {
                return _.find(_this.ItemDetail.Units(), function (u) { return u.UnitId() === _this.HomeWeekUnitPicked(); });
            }
            return null;
        });
        _this.mapPoints = ko.computed(function () {
            var points = [];
            if (_this.IsSearch()) { // Destination Detail
                if (!_this.OpenSeasonMode()) {
                    _.each(_this.CanAffordList(), function (resort) {
                        if (resort.MapsAddress != null && resort.MapsAddress.Longitude() !== null)
                            points.push({ lng: resort.MapsAddress.Longitude(), lat: resort.MapsAddress.Latitude() });
                    });
                    _.each(_this.CantAffordList(), function (resort) {
                        if (resort.MapsAddress != null && resort.MapsAddress.Longitude() !== null)
                            points.push({ lng: resort.MapsAddress.Longitude(), lat: resort.MapsAddress.Latitude() });
                    });
                }
                else {
                    _.each(_this.List(), function (resort) {
                        if (resort.MapsAddress != null && resort.MapsAddress.Longitude() !== null)
                            points.push({ lng: resort.MapsAddress.Longitude(), lat: resort.MapsAddress.Latitude() });
                    });
                }
            }
            else if (_this.IsLanding()) { // Destinations Home
                _.each(_this.MapData(), function (resort) {
                    if (resort.Longitude() !== null) {
                        points.push({ lng: resort.Longitude(), lat: resort.Latitude() });
                    }
                });
            }
            else if (_this.IsDetail()) { // Resort Detail
                if (_this.ItemDetail.Longitude() !== null) {
                    points.push({ lng: _this.ItemDetail.Longitude(), lat: _this.ItemDetail.Latitude() });
                }
            }
            return points;
        }, _this);
        _this.CantBookOnlineMessage.subscribe(function () {
            if (!this.ItemDetail.BookOnline)
                return this.ItemDetail.CantBookOnlineMessage;
        });
        _this.Filters.ADAOnly.subscribe(function (newValue) {
            _this.form.refreshResortData();
        });
        _this.MinPoints.subscribe(function (newValue) {
            var minPoints = _this.MinPoints;
            _this.applyValidRange(99999999, newValue, minPoints);
        });
        _this.MaxPoints.subscribe(function (newValue) {
            var maxPoints = _this.MaxPoints;
            _this.applyValidRange(99999999, newValue, maxPoints);
        });
        _this.BorrowPoints.subscribe(function (newValue) {
            var borrowPoints = _this.BorrowPoints;
            _this.applyValidRange(99999999, newValue, borrowPoints);
        });
        _this.ShowAdaUnits.subscribe(function () {
            self.IsLoading(true);
            var promise = $.ajax({
                url: '/' + self.BaseHomeWeekSelectionUrl() + self.ShowAdaUnits(),
                type: 'POST',
                cache: false,
                contentType: 'application/json;charset=utf-8'
            });
            promise.done(function (data) {
                mapper.fromJsToModel(data.ItemDetail, self.ItemDetail);
                self.IsLoading(false);
            });
            promise.fail(function (data) {
                self.IsLoading(false);
            });
        });
        _this.ItemDetail.ShowNoAccomodationsMatchMessage = ko.computed(function () {
            if (_this.ItemDetail.Units().length === 0)
                return true;
            if (_this.ItemDetail.HideUnavailable() === false) //don't show if they are showing those that are not available
                return false;
            return _.all(_this.ItemDetail.Units(), function (unit) {
                return !unit.UnitAvailable() || !unit.SizeReqMet();
            });
        });
        var self = _this;
        $('body').on('change', '.submit-checkbox-selectall', function (e) {
            var roomSizes = [];
            var val = e.target.value;
            if ($(e.target).hasClass('is-disabled'))
                return false;
            var selected = $(this).is(':checked');
            if (selected) {
                $('.submit-checkbox--roomsizes').each(function () {
                    this.checked = selected;
                    var id = $(this)[0].dataset["id"];
                    if (id && !roomSizes.includes(id)) {
                        roomSizes.push(id);
                    }
                });
                roomSizes.push(val);
            }
            else if (!selected) {
                $('.submit-checkbox--roomsizes').each(function () {
                    this.checked = selected;
                });
                roomSizes.length = 0;
            }
            self.Filters.RoomSizes(roomSizes);
            //self.resortVM.RoomSizesLabelDisplay(roomSizes);
            self.ResortBookingWindowVM.unitTypes.removeAll();
            //self.popupRoomSizes = roomSizes;
            roomSizes = roomSizes.sort();
            $(roomSizes).each(function (i, dat) {
                var num = parseInt(dat[0]);
                var roomName = self.AllRoomSizes()[num].Name();
                var filteredData = self.ResortBookingWindowVM.allUnitTypes.filter(function (i) { return i.name().trim().indexOf(roomName) > -1; });
                $(filteredData).each(function (j, newData) {
                    self.ResortBookingWindowVM.unitTypes.push(newData);
                });
            });
        });
        //Needed to collect up room sizes
        $('body').on('change', '.submit-checkbox--roomsizes', function (e) {
            e.stopImmediatePropagation();
            if ($(e.target).hasClass('is-disabled'))
                return false;
            var selected = $(this).is(':checked');
            var id = $(this)[0].dataset["id"];
            if (id !== undefined) {
                var roomSizes = ko.unwrap(self.Filters.RoomSizes());
                var beginLength = roomSizes.length;
                if (selected) {
                    if (!roomSizes.includes(id)) {
                        roomSizes.push(id);
                    }
                }
                else {
                    if (id && roomSizes.includes(id)) {
                        var idx = roomSizes.indexOf(id);
                        roomSizes.splice(idx, 1);
                    }
                    $('.submit-checkbox-selectall').each(function () {
                        this.checked = selected;
                    });
                    if (roomSizes.includes("5")) {
                        var selectAllidx = roomSizes.indexOf("5");
                        roomSizes.splice(selectAllidx, 1);
                    }
                }
                if (roomSizes.length != beginLength) {
                    // self.popupRoomSizes = roomSizes;
                    // self.resortVM.RoomSizesLabelDisplay(roomSizes);
                    self.Filters.RoomSizes(roomSizes);
                    self.ResortBookingWindowVM.unitTypes.removeAll();
                    roomSizes = roomSizes.sort();
                    $(roomSizes).each(function (i, dat) {
                        var num = parseInt(dat[0]);
                        var roomName = self.AllRoomSizes()[num].Name();
                        var filteredData = self.ResortBookingWindowVM.allUnitTypes.filter(function (i) { return i.name().trim().indexOf(roomName) > -1; });
                        $(filteredData).each(function (j, newData) {
                            self.ResortBookingWindowVM.unitTypes.push(newData);
                        });
                    });
                }
            }
        });
        $('body').on('click', 'div.expand', function (evt) {
            evt.stopImmediatePropagation();
            $(this).toggleClass('is-open');
        });
        $('body').on('click', '#btnSaveSubmitRequest', function (evt) {
            evt.stopImmediatePropagation();
            self.saveSubmitRequest();
        });
        $('body').on('click', 'div.attractions-detail', function (evt) {
            evt.stopImmediatePropagation();
            var elem = $(this);
            var id = elem.attr('id');
            var detailsUrl = elem.data('details-url');
            $.get(detailsUrl).done(function (response) {
                self.ViewedAttraction.ImageUrl(response.ImageUrl);
                self.ViewedAttraction.Category(response.CategoryName);
                self.ViewedAttraction.Name(response.Name);
                self.ViewedAttraction.Rating(response.Rating);
                self.ViewedAttraction.RatingCount(response.RatingCount);
                var hoursHtml = "";
                Object.keys(response.Hours).forEach(function (key) {
                    hoursHtml += key + " - " + response.Hours[key] + "<br/>";
                });
                self.ViewedAttraction.Hours(hoursHtml);
                var locationHtml = response.Address.replace("\n", "<br/>");
                self.ViewedAttraction.Location(locationHtml);
                var locationUrl = response.Address.replace(/\t|\n|\r+/g, ' ');
                self.ViewedAttraction.LocationMapUrl(locationUrl);
                self.ViewedAttraction.PhoneNumber(response.Phone);
                self.ViewedAttraction.MenuUrl(response.MobileMenuUrl);
                self.ViewedAttraction.VenueUrl(response.VenueUrl);
                HGV.Modal[id].open();
            });
        });
        $('body').on('click', 'div.dining-detail', function (evt) {
            evt.stopImmediatePropagation();
            var elem = $(this);
            var id = elem.attr('id');
            $('#detail-image').css('background-image', "url(\'" + elem.find('.dining-image').data('imageUrl') + "\')");
            $('#detail-heading').text(elem.find('.dining-name').text());
            $('#detail-subheading').text(elem.find('.dining-cuisine-type').text());
            $('#detail-hours').html($('#detail-hours').data('icon-html') + elem.find('.dining-hours').html());
            $('#detail-location').text(elem.find('.dining-location').text());
            $('#detail-description').text(elem.find('.dining-details').text());
            $('#detail-phone').text(elem.find('.dining-phone').text());
            $('#detail-phone').attr('href', 'tel:' + elem.find('.dining-phone').text().replace(/[^0-9]+/g, ''));
            $('#detail-phone-p').show();
            $('#detail-location-p').show();
            HGV.Modal[id].open();
        });
        $('body').on('click', 'div.activity-detail', function (evt) {
            evt.stopImmediatePropagation();
            var elem = $(this);
            var id = elem.attr('id');
            $('#detail-image').css('background-image', "url(\'" + elem.find('.activity-image').data('imageUrl') + "\')");
            $('#detail-heading').text(elem.find('.activity-name').text());
            $('#detail-subheading').text('');
            $('#detail-hours').html($('#detail-hours').data('icon-html') + elem.find('.activity-recurring-days').text() + '&nbsp;' + elem.find('.activity-hours').text());
            $('#detail-location').text(elem.find('.activity-location').text());
            $('#detail-description').text(elem.find('.activity-description').text());
            $('#detail-location-p').show();
            $('#detail-phone').text('');
            $('#detail-phone').attr('href', '#');
            $('#detail-phone-p').hide();
            HGV.Modal[id].open();
        });
        $('body').on('click', 'div.experience-detail', function (evt) {
            evt.stopImmediatePropagation();
            var elem = $(this);
            var id = elem.attr('id');
            $('#detail-image').css('background-image', "url(\'" + elem.find('.experience-image').data('imageUrl') + "\')");
            $('#detail-heading').text(elem.find('.experience-name').text());
            $('#detail-subheading').text(elem.find('.experience-details').text());
            $('#detail-phone').text('');
            $('#detail-phone').attr('href', '#');
            $('#detail-phone-p').hide();
            $('#detail-location-p').hide();
            $('#detail-description').text('');
            HGV.Modal[id].open();
        });
        $('body').on('click', '.availability_map__toggle', function (evt) {
            evt.stopImmediatePropagation();
            self.IsMapView(!self.IsMapView());
        });
        $(document).ready(function (e) {
            if (self.IsLanding() || self.IsDetail()) { // i.e. Destinations Home or Resort Detail
                self.forceMapRefresh();
            }
        });
        $(document).on('bookingTypeChanged', function (e, newVal) {
            var isDollars = newVal === 'dollars';
            self.Filters.OpenSeasonMode(isDollars);
            if (newVal != '' || newVal != null) {
                self.Filters.BookingType(newVal);
            }
        });
        $('.booking-type').on('click', function () {
            var val = $(this).attr('data-totals');
            $(document).trigger('bookingTypeChanged', val);
        });
        $('body').on('change', 'input[name=AvailablePaymentMethods]:radio', function (e) {
            console.log($(this).val());
            if ($(this).val() === 'dollars') {
                $('#divDollars').show();
                $('#divClubPoints').hide();
                $('#divBonusPoints').hide();
            }
            else if ($(this).val() === 'points') {
                $('#divDollars').hide();
                $('#divBonusPoints').hide();
                $('#divClubPoints').show();
            }
            else if ($(this).val() === 'bonus') {
                $('#divDollars').hide();
                $('#divClubPoints').hide();
                $('#divBonusPoints').show();
            }
            self.Filters.BookingType($(this).val());
        });
        // Event from BookingJs to display a clicked unitDetails item
        $(document).on('unitDetailsModal', function (event, obj) {
            self.setUnitTypeSideModel(obj.unitCode, obj.project, obj.resort);
        });
        $(document).on('click', '#accommodation-list-photo', function (e) {
            var clickTarget = $(e.target);
            self.setUnitTypeSideModel(clickTarget.data('unit-id'), clickTarget.data('project'), ko.toJS(self.ItemDetail.AvailabilityGridInfo)[self.ItemDetail.Id()]);
        });
        $(document).on('click', '.side-modal__close', function (event, obj) {
            HGV.Modal['viewed-unit-type'].close();
        });
        $(document).on('click', '.side-modal__prev, .side-modal__next', function (event) {
            var bookingJsResorts;
            if (window.bookingApp) {
                bookingJsResorts = window.bookingApp.$data.resorts;
            }
            else {
                bookingJsResorts = ko.toJS(self.ItemDetail.AvailabilityGridInfo);
            }
            if (self.IsDetail()) {
                var resortId = self.ItemDetail.Id();
                var resort = bookingJsResorts[resortId];
                var selectedUnit = $(this).find('.unit-code').text();
                var project = Number($(this).find('.project').text());
                self.setUnitTypeSideModel(selectedUnit, project, resort, false);
            }
            else if (self.IsSearch()) {
                var resortName_1 = ko.toJS(ko.dataFor(event.target)).resortName;
                Object.keys(bookingJsResorts).forEach(function (resortId) {
                    var resort = bookingJsResorts[resortId];
                    if (resort.name == resortName_1) {
                        var selectedUnit = $(event.currentTarget).find('.unit-code').text();
                        var project = Number($(event.currentTarget).find('.project').text());
                        self.setUnitTypeSideModel(selectedUnit, project, resort, false);
                    }
                });
            }
        });
        _this.ResortBookingWindowVM = new ResortBookingWindow(_this);
        _this.initializeImageGallery();
        ko.applyBindings(_this);
        $(".hideUnbound").removeClass("hideUnbound");
        var self = _this;
        _this.ReviewVM.Alerts.subscribe(function (newValue) {
            _.each(newValue, function (a) {
                var message = a.value.Message;
                var alertType = a.value.Type;
                alertType = alertType.substr(alertType.lastIndexOf('-') + 1);
                if (message !== undefined && alertType !== undefined)
                    self.AddAlert(message, alertType);
            });
            _this.ReviewVM.RemoveAlerts();
        }, null, "arrayChange");
        $("#discard-change-reservation").click(function (e) {
            e.stopPropagation();
        });
        $("#change-reservation-change-resort").click(function (e) {
            e.stopPropagation();
        });
        if (checkIn.isValid())
            _this.Filters.CheckIn(checkIn.format(_this.dateFormat));
        if (checkOut.isValid())
            _this.Filters.CheckOut(checkOut.format(_this.dateFormat));
        _this.setupOverlays();
        if (_this.IsSearch()) {
            if (_this.CurrentAvailabilityMode() === _this.AvailabilityGridMode()) {
                _this.startAvailabilityApp();
            }
            else {
                _this.table.load(); //load the resort list based on the set filters
            }
        }
        if (_this.IsDetail()) {
            if (_this.CurrentAvailabilityMode() === _this.AvailabilityGridMode() && !isChangingReservation) { //if they are changing wait to get the checkin/out dates
                _this.startAvailabilityApp();
            }
            window.typeAheadCompleted(_this.ItemDetail.DisplayName(), _this.ItemDetail.Id(), 'Resort'); //prefill the resort data when loading the resort detail
            //If the ADA is set on page load and we're on list mode refresh the item detail to get the latest ADA room types
            if (_this.Filters.ADAOnly() && _this.CurrentAvailabilityMode() === _this.AvailabilityListMode()) {
                _this.form.refreshResortData();
            }
            _this.refreshAllUnits();
            _this.picker.refreshSelectedDate(_this);
            _this.ReviewVM.table.load();
        }
        if (_this.IsHomeWeek()) {
            _this.setHomeWeekCalendarDates();
        }
        if ((!_this.IsSearch() || _this.CurrentAvailabilityMode() === _this.AvailabilityGridMode()) && _this.Filters.ReservationNumber() !== null) {
            _this.form.getReservationToChange(_this.Filters.ReservationNumber());
        }
        $('body').on('click', '#sticky-room-type-booking', function (el) {
            $(el.target).toggleClass('is-active');
        });
        _this.redirectOldClientRoutes();
        _this.loadToSpecificDetailTab();
        return _this;
    }
    Resort.prototype.setUnitTypeSideModel = function (selectedUnitCode, project, resort, openModal) {
        if (openModal === void 0) { openModal = true; }
        var self = this;
        var unit = null;
        if (self.IsDetail()) {
            unit = self.ItemDetail.Units().filter(function (i) { return i.UnitId() == selectedUnitCode && i.AvailabilityProjectId() == project; })[0];
        }
        else if (self.IsSearch()) {
            var resortGridDto = self.AvailabilityGridResortList().filter(function (r) { return r.name == resort.name; })[0];
            var unitGridDto = resortGridDto.unitTypes.filter(function (u) { return u.unit == selectedUnitCode && u.project == project; })[0];
            // Hack alert!! Not fully defining the object - providing just enough to build the side modal
            unit = {};
            unit.UnitId = ko.observable(unitGridDto.unit);
            unit.Name = ko.observable(unitGridDto.name);
            unit.Description = ko.observable(unitGridDto.description);
            unit.Size = ko.observable(unitGridDto.size);
            unit.Occupancy = ko.observable(unitGridDto.accomodates);
            unit.Images = unitGridDto.images;
            unit.resortName = resortGridDto.name;
            unit.AdaDescription = unitGridDto.adaDescriptions;
            unit.AvailabilityProjectId = ko.observable(unitGridDto.project);
        }
        if (!unit) {
            console.warn('unit not found');
            return;
        }
        unit.ImagesDisplay = ko.pureComputed(function () {
            var images = ko.toJS(unit.Images);
            var mappedImages = images.map(function (i) {
                var url = i.ImageSizes[0].Url;
                return {
                    imageUrl: url,
                    caption: i.Caption
                };
            });
            return mappedImages;
        });
        var selectedUnitIndex = _.findIndex(resort.unitTypes, function (u) { return u.unit == selectedUnitCode && u.project == project; });
        var prevIndex = selectedUnitIndex - 1;
        var nextIndex = selectedUnitIndex + 1;
        if (prevIndex < 0) {
            prevIndex = resort.unitTypes.length - 1;
        }
        if (nextIndex >= resort.unitTypes.length) {
            nextIndex = 0;
        }
        unit.prevUnitCode = resort.unitTypes[prevIndex].unit;
        unit.prevUnitName = resort.unitTypes[prevIndex].name;
        unit.prevProject = resort.unitTypes[prevIndex].project;
        unit.nextUnitCode = resort.unitTypes[nextIndex].unit;
        unit.nextUnitName = resort.unitTypes[nextIndex].name;
        unit.nextProject = resort.unitTypes[nextIndex].project;
        self.ViewedUnitType(unit);
        var carouselArrow = window.carouselArrow;
        if ($("#unit-carousel").length > 0) {
            $("#unit-carousel").owlCarousel({
                items: 1,
                nav: true,
                loop: true,
                dots: false,
                navText: [carouselArrow, carouselArrow],
                onInitialized: function () {
                    // move the dots nav so its on top of the details
                    //this._plugins.navigation._controls.$absolute.appendTo(this.$element.parent());
                }
            });
        }
        var id = 'viewed-unit-type';
        if (unit != null && id != null && openModal) {
            HGV.Modal[id].open();
            //HGV.Carousel["unit-carousel"].init()
        }
    };
    Resort.prototype.toggleSRequest = function (resort, e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        var _self = this;
        var promise = $.ajax({
            url: '/api/availability/resort/' + resort.Id() + '?lang=' + _self.CurrentLanguageCode(),
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8',
        });
        promise.done(function (response) {
            var resortBookingWindowData = response;
            var bookingWindowData = {
                ResortId: resortBookingWindowData.id,
                ResortName: resortBookingWindowData.ResortName,
                CantBookOnlineMessage: resortBookingWindowData.CantBookOnlineMessage,
                BookableOnline: resortBookingWindowData.BookableOnline,
                MinNightsPoints: resortBookingWindowData.minNightsPoints,
                MinNightsCash: resortBookingWindowData.minNightsCash,
                UnitTypes: resortBookingWindowData.unitTypes
            };
            $(document).trigger("submitSearchRequestLinkClicked", resortBookingWindowData);
        });
    };
    Resort.prototype.viewBookingEligibilityClick = function (resort) {
        var minBookingWindowPointsDate = new Date();
        var maxBookingWindowPointsDate = new Date();
        var minBookingWindowCashDate = new Date();
        var maxBookingWindowCashDate = new Date();
        minBookingWindowPointsDate.setDate(minBookingWindowPointsDate.getDate() + resort.MinBookingWindowPoints());
        maxBookingWindowPointsDate.setDate(maxBookingWindowPointsDate.getDate() + resort.MaxBookingWindowPoints());
        minBookingWindowCashDate.setDate(minBookingWindowCashDate.getDate() + resort.MinBookingWindowCash());
        maxBookingWindowCashDate.setDate(maxBookingWindowCashDate.getDate() + resort.MaxBookingWindowCash());
        var bookingWindowData = {
            UserId: this.User.Id(),
            ResortId: resort.Id(),
            ResortName: resort.DisplayName(),
            ResortUrl: resort.Url(),
            MinBookingWindowPointsDate: minBookingWindowPointsDate,
            MaxBookingWindowPointsDate: maxBookingWindowPointsDate,
            MinBookingWindowCashDate: minBookingWindowCashDate,
            MaxBookingWindowCashDate: maxBookingWindowCashDate,
            MinBookingWindowPoints: resort.MinBookingWindowPoints(),
            MaxBookingWindowPoints: resort.MaxBookingWindowPoints(),
            MinBookingWindowCash: resort.MinBookingWindowCash(),
            MaxBookingWindowCash: resort.MaxBookingWindowCash(),
            RequiresOwnershipPoints: resort.RequiresOwnershipPoints(),
            RequiresOwnershipCash: resort.RequiresOwnershipCash(),
            IsOpenSeasonBlocked: resort.IsOpenSeasonBlocked(),
            CantBookOnlineMessage: resort.CantBookOnlineMessage(),
            BookableOnline: resort.BookOnline(),
            MinNightsPoints: resort.MinNightsPoints(),
            MinNightsCash: resort.MinNightsCash(),
            UnitTypes: (resort.UnitTypes) ? resort.UnitTypes() : []
        };
        $(document).trigger('bookingAppViewBookingEligibilityLinkClicked', bookingWindowData);
    };
    Resort.prototype.viewBookingEligibilityClickResort = function () {
        var minBookingWindowPointsDate = new Date();
        var maxBookingWindowPointsDate = new Date();
        var minBookingWindowCashDate = new Date();
        var maxBookingWindowCashDate = new Date();
        minBookingWindowPointsDate.setDate(minBookingWindowPointsDate.getDate() + this.ResortBookingWindowVM.MinBookingWindowPoints());
        maxBookingWindowPointsDate.setDate(maxBookingWindowPointsDate.getDate() + this.ResortBookingWindowVM.MaxBookingWindowPoints());
        minBookingWindowCashDate.setDate(minBookingWindowCashDate.getDate() + this.ResortBookingWindowVM.MinBookingWindowCash());
        maxBookingWindowCashDate.setDate(maxBookingWindowCashDate.getDate() + this.ResortBookingWindowVM.MaxBookingWindowCash());
        var bookingWindowData = {
            UserId: this.User.Id(),
            ResortId: this.ItemDetail.Id(),
            ResortName: this.ItemDetail.DisplayName(),
            MinBookingWindowPointsDate: minBookingWindowPointsDate,
            MaxBookingWindowPointsDate: maxBookingWindowPointsDate,
            MinBookingWindowCashDate: minBookingWindowCashDate,
            MaxBookingWindowCashDate: maxBookingWindowCashDate,
            MinBookingWindowPoints: this.ResortBookingWindowVM.MinBookingWindowPoints(),
            MaxBookingWindowPoints: this.ResortBookingWindowVM.MaxBookingWindowPoints(),
            MinBookingWindowCash: this.ResortBookingWindowVM.MinBookingWindowCash(),
            MaxBookingWindowCash: this.ResortBookingWindowVM.MaxBookingWindowCash(),
            RequiresOwnershipPoints: this.RequiresOwnershipPoints(),
            RequiresOwnershipCash: this.RequiresOwnershipCash(),
            IsOpenSeasonBlocked: this.ItemDetail.IsOpenSeasonBlocked(),
            CantBookOnlineMessage: this.ItemDetail.CantBookOnlineMessage()
        };
        $(document).trigger('bookingAppViewBookingEligibilityLinkClicked', bookingWindowData);
    };
    Resort.prototype.initializeImageGallery = function () {
        if (!this.IsDetail() && !this.IsHomeWeek()) {
            return;
        }
        var afterInit = function (popupGalleryId) {
            $('.masthead__view-photos').on('click', function (e) {
                e.preventDefault();
                var bg = $('#image-carousel .owl-item.active .masthead-carousel__item')
                    .css('background-image')
                    .replace('url(', '')
                    .replace(')', '')
                    .replace(/\"/gi, "");
                $('#' + popupGalleryId).find("a[href=\"" + bg + "\"]").click();
            });
        };
        ImageGallery.Initialize({ afterInit: afterInit });
    };
    Resort.prototype.forceMapRefresh = function () {
        // Update the map
        var mapEl = $('#map');
        var options = {
            skipAirportDistance: false,
            coords: this.mapPoints()
        };
        HGV.Constructors.Map.build(mapEl, options);
    };
    Resort.prototype.refreshHomeWeekDateSelection = function (checkinDate, checkoutDate) {
        this.Filters.CheckIn(checkinDate.format(this.dateFormat));
        this.Filters.CheckOut(checkoutDate.format(this.dateFormat));
        this.picker.refreshSelectedDate(this);
        this.refreshAllUnits();
        //set the HomeWeekBookingUnit based on what is available
        var unit = _.find(this.ItemDetail.Units(), function (u) { return u.AbleToBook(); });
        if (unit !== null && unit !== undefined) {
            var unitId = unit.UnitId();
            this.HomeWeekUnitPicked(unitId);
        }
        else {
            this.HomeWeekUnitPicked(null);
        }
    };
    Resort.prototype.redirectOldClientRoutes = function () {
        var hash = window.location.hash;
        if (!hash.length)
            return;
        var hashInfo = hash.replace("#/", "").split("/");
        if (hashInfo.length === 0)
            return;
        var viewType = hashInfo[0];
        var requestedId = hashInfo.length > 1 ? hashInfo[1] : "";
        if (viewType === "search") {
            window.location.href = "/" + this.CurrentLanguageCode() + "/resort/search";
        }
        if (viewType === 'index') {
            window.location.href = "/" + this.CurrentLanguageCode() + "/resort";
        }
        if (viewType === 'filter') {
            this.getOldFilterRedirectUrl(requestedId);
        }
        if (viewType === 'view') {
            this.getOldResortRedirectUrl(requestedId);
        }
        if (viewType === 'homeWeek') {
            if (hashInfo.length === 3) {
                var contractNum = hashInfo[1];
                var interval = hashInfo[2];
                window.location.href = "/" + this.CurrentLanguageCode() + "/resort/homeweek/" + contractNum + "/" + interval;
            }
        }
    };
    Resort.prototype.getOldFilterRedirectUrl = function (filterId) {
        if (filterId.length === 0)
            return;
        this.IsLoading(true);
        var promise = $.ajax({
            url: "/" + this.CurrentLanguageCode() + "/" + this.FrontEndRoute() + '/GetFilterRedirectUrl',
            data: { filterId: filterId },
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.done(function (data) {
            if (data.RedirectUrl)
                window.location.href = data.RedirectUrl;
        });
    };
    Resort.prototype.getOldResortRedirectUrl = function (resortId) {
        if (resortId.length === 0)
            return;
        this.IsLoading(true);
        var promise = $.ajax({
            url: "/" + this.CurrentLanguageCode() + "/" + this.FrontEndRoute() + '/GetResortRedirectUrl',
            data: { resortId: resortId },
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.done(function (data) {
            if (data.RedirectUrl)
                window.location.href = data.RedirectUrl;
        });
    };
    Resort.prototype.loadToSpecificDetailTab = function () {
        // Page is loading with intent to immediately view a tab
        if (this.SelectedTab() !== null) {
            var tabsSelector = '#resort-details-tabs';
            $.smoothScroll({ scrollTarget: tabsSelector });
            $(tabsSelector).find("a[href='#" + this.SelectedTab() + "']").trigger('click');
            $(tabsSelector).removeClass('is-open');
        }
        else if (window.location.hash) {
            var tabsSelector = '#resort-details-tabs';
            var hash = window.location.hash;
            setTimeout(function () {
                $(tabsSelector).find("a[href='" + hash + "']").trigger('click');
                $(tabsSelector).removeClass('is-open');
            }, 1000);
        }
        if (this.ShowReviewChoice() !== null) {
            var tmp = this.ReviewVM.ItemDetail;
            tmp.Recommended(this.ShowReviewChoice());
            $('#write-review').click();
        }
    };
    Resort.prototype.getAdaContains = function (list, value) {
        return _.some(list(), function (a) { return a === value; });
    };
    Resort.prototype.getAvailabilityCache = function (key) {
        var cache = this.Filters.ADAOnly() ? this.AvailabilityAdaCache : this.AvailabilityCache;
        return cache[key];
    };
    Resort.prototype.clickDatePickerDate = function (momentDate) {
        $("[data-date^='" + momentDate.toString() + "']:visible").click();
    };
    Resort.prototype.setupOverlays = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.HelpOverlaysEnabled()) {
                _this.initOverlay();
                _this.DisplayHelpOverlays();
            }
        }, 1000);
    };
    Resort.prototype.addToViewModel = function () {
        var _this = this;
        _super.prototype.addToViewModel.call(this);
        //if looking at item detail show that name else show the featured resort
        this.ResortDisplayName = ko.computed(function () {
            if (_this.ItemDetail)
                if (_this.ItemDetail.Name())
                    return _this.ItemDetail.DisplayName();
            return _this.data.FeaturedResort.DisplayName;
        });
        this.ResortCityStateCountry = ko.computed(function () {
            if (_this.ItemDetail && _this.ItemDetail.CityStateCountry())
                return _this.ItemDetail.CityStateCountry();
            return _this.FeaturedResort.CityStateCountry;
        });
        this.ResortShowRecommendedPercent = ko.computed(function () {
            if (_this.ItemDetail && _this.ItemDetail.ShowRecommendedPercent())
                return _this.ItemDetail.ShowRecommendedPercent();
            return _this.FeaturedResort.ShowRecommendedPercentage;
        });
        this.ResortRecommendedPercent = ko.computed(function () {
            if (_this.ItemDetail && _this.ItemDetail.RecommendedPercent())
                return _this.ItemDetail.RecommendedPercent();
            return _this.FeaturedResort.RecommendedPercent;
        });
        //compute the css style value offset for the sticky date picker 
        this.StickyDatePickerTop = ko.computed(function () {
            return +_this.StickyOffset().toString() + 'px';
        });
        //setup the toggling between the modes
        this.CurrentAvailabilityMode.subscribe(function (mode) {
            if (mode === _this.AvailabilityGridMode()) {
                setTimeout(function () {
                    _this.startAvailabilityApp();
                }, 75);
            }
            else {
                _this.stopAvailabilityApp();
                if (mode === _this.AvailabilityListMode()) {
                    _this.table.load(true);
                }
            }
        });
        this.isHccProperty = ko.computed(function () {
            if (_this.ReviewVM && _this.ReviewVM.ProjectIds()) {
                if (_this.ReviewVM.ProjectIds.indexOf(80) > -1 ||
                    _this.ReviewVM.ProjectIds.indexOf(81) > -1 ||
                    _this.ReviewVM.ProjectIds.indexOf(83) > -1) {
                    return true;
                }
            }
            return false;
        });
    };
    Resort.prototype.hasUnitsInRoomType = function (resort) {
        var units = resort.Units();
        var found = false;
        for (var i = 0; i < units.length; i++) {
            if (this.Filters.RoomSizes().includes(units[i].NumberOfRooms().toString())) {
                found = true;
                break;
            }
        }
        return found;
    };
    ;
    Resort.prototype.loadAvailabilityGridResortDetail = function () {
        if (window.bookingApp) {
            window.bookingApp.$data.resorts = ko.toJS(this.ItemDetail.AvailabilityGridInfo);
        }
        if (this.IsUpdatingReservation()) {
            var $existingResUnitDiv = $('.room.' + this.ExistingReservation.UnitTypeId());
            var hasUnitType = $existingResUnitDiv.length > 0;
            var scrollOffset = $('.booking').offset().top; //default to the top of the availability app;
            if (hasUnitType) {
                scrollOffset = $existingResUnitDiv.offset().top - 300;
            }
            $('HTML, body').animate({ scrollTop: scrollOffset }, 500); //scroll to the existing reservation unit type  
        }
    };
    Resort.prototype.isValidForAvailabilityView = function (checkIn, checkOut) {
        return this.isValidAvailabilityGridScreenSize() &&
            this.isValidDefaultAvailabilityGridStayLength(checkIn, checkOut) &&
            this.isValidAvailabilityViewResort();
    };
    Resort.prototype.isValidAvailabilityGridScreenSize = function () {
        return window.innerWidth >= 1024;
    };
    Resort.prototype.isValidDefaultAvailabilityGridStayLength = function (checkIn, checkOut) {
        if (checkIn.isValid() && checkOut.isValid()) {
            var totalStayLength = moment.duration(checkOut.diff(checkIn)).asDays();
            return Math.round(totalStayLength) < 20; //if they are at or over 20 days then default to the list view
        }
        return true;
    };
    Resort.prototype.isValidAvailabilityViewResort = function () {
        if (this.IsDetail()) {
            return this.ItemDetail.BookOnline();
        }
        return true;
    };
    Resort.prototype.startAvailabilityApp = function () {
        var _this = this;
        window.CHECKIN_DATE = this.Filters.CheckIn() != null ? moment(this.Filters.CheckIn(), 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
        window.CHECKOUT_DATE = this.Filters.CheckOut() != null ? moment(this.Filters.CheckOut(), 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
        window.switchToListView = function () { _this.CurrentAvailabilityMode('list'); };
        window.vueUp(); //initialize the availability grid application
        window.initVueEvents();
        this.refreshAvailabilityAppResorts(true);
    };
    Resort.prototype.refreshAvailabilityAppResorts = function (skipDateUpdate) {
        var numberOfGuests = this.Filters.NumberOfGuest() !== null ? this.Filters.NumberOfGuest() : 2;
        var guestsHaveChanged = numberOfGuests !== this.originalAvailabilityGuestNumber();
        this.originalAvailabilityGuestNumber(numberOfGuests);
        window.bookingApp.$data.guests = numberOfGuests;
        window.bookingApp.$data.totals = this.Filters.OpenSeasonMode() ? 'dollars' : 'points'; //needs to be validated
        window.bookingApp.$data.ada = this.Filters.ADAOnly();
        window.bookingApp.$data.existingResNumber = this.Filters.ReservationNumber();
        window.bookingApp.$data.bookingType = this.Filters.BookingType();
        window.bookingApp.$data.roomTypes = this.Filters.RoomSizes();
        var checkIn = this.Filters.CheckIn() != null ? moment(this.Filters.CheckIn(), 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
        var checkOut = this.Filters.CheckOut() != null ? moment(this.Filters.CheckOut(), 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
        if (!skipDateUpdate && checkIn !== null && checkOut !== null) {
            window.bookingApp.datePickerChanged([checkIn, checkOut], true);
        }
        if (this.IsSearch()) {
            this.table.loadAvailabilityGrid();
        }
        if (this.IsDetail()) { //if it's a detail that information already came down via the item detail
            if (guestsHaveChanged) {
                this.form.refreshResortData();
            }
            else {
                this.loadAvailabilityGridResortDetail();
            }
        }
    };
    Resort.prototype.unitIsVisible = function (unit, guests, roomTypes) {
        var isVisible = true;
        if (guests == null) {
            guests = 2;
        }
        if (isVisible && guests > unit.accomodates()) {
            isVisible = false;
        }
        if (isVisible && roomTypes.length > 0) {
            if (unit.numberOfRooms() >= 4) {
                if (roomTypes.includes(4)) {
                    isVisible = false;
                }
            }
            else {
                if (!roomTypes.includes(unit.numberOfRooms().toString())) {
                    isVisible = false;
                }
            }
        }
        return isVisible;
    };
    Resort.prototype.stopAvailabilityApp = function () {
        if (window.bookingApp) { //only kill the app if its actually started
            window.vueDestroy();
            window.killVueEvents();
        }
        //set the check-in / check-out dates to that
        var checkin = moment(window.CHECKIN_DATE, 'YYYY-MM-DD');
        var checkOut = moment(window.CHECKOUT_DATE, 'YYYY-MM-DD');
        if (checkin.isValid() && checkOut.isValid()) {
            this.updateStickySideBarCalendar(checkin, checkOut);
        }
        if (this.IsSearch()) {
            this.table.load(); //load the resort list based on the set filters    
        }
    };
    Resort.prototype.updateStickySideBarCalendar = function (checkin, checkOut) {
        this.Filters.CheckIn(checkin.format(this.dateFormat));
        this.Filters.CheckOut(checkOut.format(this.dateFormat));
        this.Filters.CheckIn.valueHasMutated(); //Refresh the dates first so all the correct observables fire if updating to the same values
        this.Filters.CheckOut.valueHasMutated();
        this.picker.setMonthOffset(this.Filters.CheckIn(), true); //reset the calendar
    };
    Resort.prototype.setHomeWeekCalendarDates = function () {
        //attempt to default to their first available homeweek if there is any availability
        var firstBookingStart = moment.tz(this.ItemDetail.HomeweekFirstAvailableStartDay(), this.UserTimeZone);
        var firstBookingEnd = moment.tz(this.ItemDetail.HomeweekFirstAvailableEndDay(), this.UserTimeZone);
        if (firstBookingStart.isValid() && firstBookingEnd.isValid()) {
            this.refreshHomeWeekDateSelection(firstBookingStart, firstBookingEnd);
            this.picker.setMonthOffset(firstBookingStart.endOf('month').clone().format(this.dateFormat));
        }
        else {
            //if there is no availability for their selected year
            var bookingYear = this.ItemDetail.HomeWeekBookingYear() != null
                ? this.ItemDetail.HomeWeekBookingYear()
                : this.UserCurrentTime().year();
            //if there are no dates that are available and they are trying to book next year
            if (bookingYear !== moment().year()) {
                this.picker.setMonthOffset(this.UserCurrentTime().year(bookingYear).startOf('year').format(this.dateFormat));
            }
        }
    };
    Resort.prototype.refreshStickySidebar = function () {
        if (this.timeout)
            clearTimeout(this.timeout);
        //this.timeout = setTimeout(() => { HGV.StickySidebar['StickyDatePicker'].reset(); }, 10);
    };
    Resort.prototype.refreshAllUnits = function (initialLoad) {
        //skip recalculating all the resort data if on the list view
        var _this = this;
        var checkIn = this.Filters.CheckIn();
        var checkOut = this.Filters.CheckOut();
        if (checkIn && checkOut) {
            this.ResortBookingWindowVM.SelectedStartDate(moment(this.Filters.CheckIn()));
            this.ResortBookingWindowVM.SelectedEndDate(moment(this.Filters.CheckOut()));
            this.ResortBookingWindowVM.RequiresOwnershipPoints(this.ItemDetail.RequiresOwnershipPoints());
            this.ResortBookingWindowVM.RequiresOwnershipCash(this.ItemDetail.RequiresOwnershipCash());
            this.ResortBookingWindowVM.IsOpenSeasonBlocked(this.ItemDetail.IsOpenSeasonBlocked());
        }
        if (this.IsSearch())
            return;
        this.addDisplayed = false;
        if (this.IsDetail() || this.IsHomeWeek()) {
            var resort = this.ItemDetail;
            //refresh the units
            _.each(resort.Units(), function (unit) { return _this.refreshUnit(unit, resort); });
            //refresh the resort 
            if (resort != undefined)
                this.setResortMinimums(resort);
        }
        var hasAnyEliteDiscounts = _.some(this.ItemDetail.Units(), function (unit) {
            return unit.ShowEliteDiscountDisclaimer();
        });
        this.ItemDetail.ShowEliteCashDiscounts(hasAnyEliteDiscounts);
        if (this.IsHomeWeek()) //skip the sidebar refresh below
            return;
        var shouldDisableSidebar = this.ItemDetail.Units() == null || this.ItemDetail.Units().length === 0;
        //this.form.refreshStickyDatePicker(initialLoad, shouldDisableSidebar);
    };
    Resort.prototype.setResortMinimums = function (resort) {
        var tempPoints = Number.MAX_VALUE;
        var tempPointsDiscounted = Number.MAX_VALUE;
        var tempCost = Number.MAX_VALUE;
        _.each(resort.Units, function (u) {
            if (resort.StartingAt != null && resort.StartingAt() < tempPoints && u.StartingAt != null && u.StartingAt() > 0) {
                tempPoints = u.StartingAt();
                resort.StartingAt(tempPoints);
            }
            if (resort.StartingAtDiscountPromo != null && resort.StartingAtDiscountPromo() < tempPointsDiscounted && u.StartingAtDiscountPromo != null && u.StartingAtDiscountPromo() > 0) {
                tempPointsDiscounted = u.StartingAtDiscountPromo();
                resort.StartingAtDiscountPromo(tempPointsDiscounted);
            }
            if (resort.OpenSeasonCost != null && resort.OpenSeasonCost() < tempCost && u.OpenSeasonCost != null && u.OpenSeasonCost() > 0) {
                tempCost = u.OpenSeasonCost();
                resort.OpenSeasonCost(tempCost);
            }
        });
        if (tempPoints === Number.MAX_VALUE) {
            resort.StartingAt(null);
        }
        if (tempPointsDiscounted === Number.MAX_VALUE) {
            resort.StartingAtDiscountPromo(null);
        }
        if (tempCost === Number.MAX_VALUE) {
            resort.OpenSeasonCost(null);
        }
    };
    Resort.prototype.isFunction = function (functionToCheck) {
        var getType = {};
        return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
    };
    Resort.prototype.safeSet = function (property, value) {
        if (this.isFunction(property)) {
            property(value);
        }
        else {
            property = value;
        }
    };
    Resort.prototype.refreshUnit = function (unit, resort) {
        var _this = this;
        if (this.DatesSelected()) {
            var startDate = this.Filters.CheckIn();
            var endDate = this.Filters.CheckOut();
            var pointCost = 0;
            var isAnyDayPointCostIsZero = false;
            var pointCostDiscounted = 0;
            var rateCost = 0;
            var previousDay = null;
            var possibleFunctionCodes = [];
            this.enumerateDaysBetweenDateStrings(startDate, endDate, function (date) {
                var unitList = _this.isFunction(resort.Units) ? resort.Units() : resort.Units;
                var unitInList = _.find(unitList, function (u) {
                    return ko.unwrap(u.UnitId) === ko.unwrap(unit.UnitId)
                        && ko.unwrap(u.AvailabilityProjectId) === ko.unwrap(unit.AvailabilityProjectId)
                        && ko.unwrap(u.UnitNumberDisplay) === ko.unwrap(unit.UnitNumberDisplay);
                });
                var availbilityDay = _this.getAvailabilityFromCache(unit, date);
                if (availbilityDay /*&& parseInt(availbilityDay.cn) > 0*/) {
                    //the function code must be available for each day in the sequence
                    //this filters out a function code if it wasnt available the previous day
                    if (previousDay) {
                        possibleFunctionCodes = _.intersection(availbilityDay.f, previousDay.f);
                    }
                    else {
                        possibleFunctionCodes = availbilityDay.f;
                    }
                    previousDay = availbilityDay;
                    unitInList.UnitAvailable(true);
                    if (availbilityDay.c == 0 || isAnyDayPointCostIsZero) {
                        pointCost = 0;
                        pointCostDiscounted = 0;
                        isAnyDayPointCostIsZero = true;
                        rateCost = rateCost + availbilityDay.r;
                        return false;
                    }
                    pointCost = pointCost + availbilityDay.c;
                    pointCostDiscounted = pointCostDiscounted + ((availbilityDay.cd > 0) ? availbilityDay.cd : availbilityDay.c);
                    rateCost = rateCost + availbilityDay.r;
                    return false;
                }
                else {
                    unitInList.UnitAvailable(false);
                    pointCost = 0;
                    pointCostDiscounted = 0;
                    rateCost = 0;
                    return true;
                }
            });
            //if the discounted cost is the same, there's actually no discount.
            if (pointCostDiscounted === pointCost) {
                pointCostDiscounted = 0;
            }
            //possibleFunctionCodes now contains only FC available for all days
            this.setUnitFunctionCodes(unit);
            this.setStartingAt(unit, pointCost, pointCostDiscounted, rateCost);
        }
        else {
            this.makeAllAvailable();
        }
    };
    Resort.prototype.setUnitFunctionCodes = function (unit) {
        var functionCodes = this.ItemDetail.FunctionCodes();
        var cashFunctionCode = this.calculateFunctionCodeForSelection(unit, 'OpenSeason', this.Filters.CheckIn(), this.Filters.CheckOut(), moment().format(this.dateFormat), functionCodes);
        unit.SelectedCashFunctionCode(cashFunctionCode);
        var pointFunctionCode = this.calculateFunctionCodeForSelection(unit, 'Points', this.Filters.CheckIn(), this.Filters.CheckOut(), moment().format(this.dateFormat), functionCodes);
        unit.SelectedPointFunctionCode(pointFunctionCode);
        var bonusFunctionCode = this.calculateFunctionCodeForSelection(unit, 'Bonus', this.Filters.CheckIn(), this.Filters.CheckOut(), moment().format(this.dateFormat), functionCodes);
        unit.SelectedBonusFunctionCode(bonusFunctionCode);
    };
    //return an array of common ids
    Resort.prototype.getCommon = function (aIds, bIds) {
        return _.intersection(aIds, bIds);
    };
    Resort.prototype.setStartingAt = function (u, startingAt, startingAtDiscounted, openSeason) {
        //store the original server values so we can reset
        if (u.OriginalStartingAt === undefined) {
            u.OriginalStartingAt = u.StartingAt();
        }
        if (u.OriginalStartingAtDiscountPromo === undefined) {
            u.OriginalStartingAtDiscountPromo = u.StartingAtDiscountPromo();
        }
        if (u.OriginalOpenSeasonCost === undefined) {
            u.OriginalOpenSeasonCost = u.OpenSeasonCost();
        }
        u.StartingAt(startingAt);
        u.StartingAtDiscountPromo(startingAtDiscounted);
        u.OpenSeasonCost(openSeason);
    };
    Resort.prototype.resetUnit = function (u) {
        //reset to original server values
        if (u.OriginalStartingAt !== undefined) {
            u.StartingAt(u.OriginalStartingAt);
        }
        if (u.OriginalStartingAtDiscountPromo !== undefined) {
            u.StartingAt(u.OriginalStartingAtDiscountPromo);
        }
        if (u.OriginalOpenSeasonCost !== undefined) {
            u.OpenSeasonCost(u.OriginalOpenSeasonCost);
        }
        if (this.IsDetail()) {
            if (u.StartingAt != undefined && u.OpenSeasonCost != undefined) {
                var unitAvailable = this.Filters.OpenSeasonMode() ? u.OpenSeasonCost() > 0 : u.StartingAt() > 0;
                u.UnitAvailable(unitAvailable);
            }
        }
    };
    Resort.prototype.makeAllAvailable = function () {
        var _this = this;
        _.each(this.ItemDetail.Units(), function (u) {
            _this.resetUnit(u);
        });
        _.each(this.List(), function (r) {
            _.each(r.Units, function (u) {
                _this.resetUnit(u);
            });
        });
    };
    Resort.prototype.addUnitNumberToCacheKey = function (cacheKey, unit) {
        var unitNumber = unit.UnitNumberDisplay && unit.UnitNumberDisplay();
        if (unitNumber && unitNumber.length > 0) {
            return cacheKey + "-" + unitNumber;
        }
        return cacheKey;
    };
    Resort.prototype.getAvailabilityFromCache = function (unit, date) {
        var cacheKey = ko.unwrap(unit.AvailabilityProjectId) + "-" + ko.unwrap(unit.UnitId) + "-" + date.format('YYMMDD');
        cacheKey = this.addUnitNumberToCacheKey(cacheKey, unit);
        var availability = this.getAvailabilityCache(cacheKey);
        if (availability == undefined) {
            return null;
        }
        return availability;
    };
    //this adds Availability to the cache
    Resort.prototype.addToIndex = function (resort, dates) {
        //add to proper cache
        var cache = this.Filters.ADAOnly() ? this.AvailabilityAdaCache : this.AvailabilityCache;
        _.each(resort.Availability, function (a) {
            cache[a.k] = a;
            var noUnitKey = a.k.split('-')[0] + '-' + a.k.split('-')[2];
            cache[noUnitKey] = a;
        });
        var projectNumber = ko.unwrap(resort.ProjectNumber);
        //add to cache index that lets us know if dates have been loaded as cal pages
        var cacheIndex = this.Filters.ADAOnly() ? this.AvailabilityAdaCacheIndex : this.AvailabilityCacheIndex;
        if (cacheIndex[projectNumber.toString()] === undefined) {
            cacheIndex[projectNumber.toString()] = ko.toJS(ko.mapping.fromJS(dates));
        }
        else {
            cacheIndex[projectNumber.toString()].End = ko.toJS(ko.mapping.fromJS(dates.End));
        }
    };
    Resort.prototype.datesOk = function (date, start, end) {
        var maxOk = moment(end).diff(moment(date, this.dateFormat)) >= 0;
        var minOk = moment(date, this.dateFormat).diff(moment(start)) >= 0;
        return minOk && maxOk;
    };
    //set computed on unit
    Resort.prototype.SetCosts = function (resort) {
        var _this = this;
        resort.PointReqMet = ko.computed(function () {
            var enoughPoints = true;
            if (!_this.Filters.OpenSeasonMode() && _this.DatesSelected()) {
                var points = _this.getMaxPoints(null, resort.FunctionCodes());
                enoughPoints = resort.StartingAt() <= points;
            }
            return enoughPoints;
        });
        resort.UnitsAvailable = ko.computed(function () {
            if (_this.ShowOpenSeason()) {
                return resort.OpenSeasonCost() !== null && resort.OpenSeasonCost() > 0;
            }
            else {
                return resort.StartingAt() !== null && resort.Units().length;
            }
        });
        resort.CostDisplay = ko.computed(function () {
            if (resort.UnitsAvailable()) {
                if (resort.IsYenAccepted() && _this.Filters.OpenSeasonMode()) {
                    return _this.ShowOpenSeason() ? new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(resort.OpenSeasonCost()) : numeral(resort.StartingAt()).format("(0,0)");
                }
                else {
                    return _this.ShowOpenSeason() ? numeral(resort.OpenSeasonCost()).format('$0,0') : numeral(resort.StartingAt()).format("(0,0)");
                }
            }
            else {
                return null;
            }
        });
        resort.UnitsDiscountPromoAvailable = ko.computed(function () {
            var discountPromoAvailable = false;
            resort.Units().forEach(function (unit) {
                if (unit.HasDiscountPromo()) {
                    discountPromoAvailable = true;
                }
            });
            return discountPromoAvailable;
        });
        resort.UnitTypes = ko.observable([]);
        resort.CashCostLabel = ko.observable(numeral(resort.OpenSeasonCost()).format('$0,0'));
        resort.PointsCostLabel = ko.observable(numeral(resort.StartingAt() != null ? resort.StartingAt() : 0).format('0,0'));
        resort.BookingWindowMet = ko.computed(function () {
            if (resort.BookingWindowStart() == null)
                return false;
            if (resort.BookingWindowEnd() == null)
                return false;
            if (!_this.DatesSelected())
                return true;
            var windowStart = moment(resort.BookingWindowStart()).startOf('day');
            var windowEnd = moment(resort.BookingWindowEnd()).endOf('day');
            var checkInDate = _this.Filters.CheckIn();
            var checkOutDate = _this.Filters.CheckOut();
            var lookupMethod = resort.BoookingWindowCalcMethod();
            var isValidWindow = _this.verifyBookingWindow(checkInDate, checkOutDate, windowStart, windowEnd, lookupMethod);
            return isValidWindow;
        });
        resort.BookingWindowDisplay = ko.computed(function () {
            return moment(resort.BookingWindowStart()).format(_this.dateFormat) + ' - ' + moment(resort.BookingWindowEnd()).format(_this.dateFormat);
        });
        _.each(resort.Units(), function (unit) {
            _this.setUnitCosts(unit);
        });
    };
    Resort.prototype.setUnitCosts = function (unit) {
        //console.log("setUnitCost: ", unit.AvailabilityProjectId(), unit.UnitId(), unit.UnitNumberGenerated(), unit.UnitNumberDisplay()/*, unit.UnitNumberEncrypted()*/);
        var _this = this;
        unit.SelectedCashFunctionCode = ko.observable(null);
        unit.SelectedPointFunctionCode = ko.observable(null);
        unit.SelectedBonusFunctionCode = ko.observable(null);
        unit.MinNightPoints = ko.pureComputed(function () {
            var pointFunctionCodes = _this.filterPoints(_this.ItemDetail.FunctionCodes());
            var bonusFunctionCodes = _this.filterBonus(_this.ItemDetail.FunctionCodes());
            var functionCodes = pointFunctionCodes.concat(bonusFunctionCodes);
            if (_.isEmpty(functionCodes))
                return 0;
            var functionCodesInRange = _this.filterFcOutOfRange(_this.Filters.CheckIn(), _this.Filters.CheckOut(), moment().format(_this.dateFormat), functionCodes);
            if (_.isEmpty(functionCodesInRange))
                return 0;
            var minNights = _.min(_.map(ko.toJS(functionCodesInRange), "MinimumNumberOfNights"));
            return minNights;
        });
        unit.MinNightOpen = ko.pureComputed(function () {
            var functionCodes = _this.filterOpenSeason(_this.ItemDetail.FunctionCodes());
            if (_.isEmpty(functionCodes))
                return 0;
            var functionCodesInRange = _this.filterFcOutOfRange(_this.Filters.CheckIn(), _this.Filters.CheckOut(), moment().format(_this.dateFormat), functionCodes);
            if (_.isEmpty(functionCodesInRange))
                return 0;
            var minRange = _.min(functionCodesInRange, function (fc) { return fc.MinimumNumberOfNights(); });
            var minNights = _.min(_.map(ko.toJS(functionCodesInRange), "MinimumNumberOfNights"));
            return minRange.MinimumNumberOfNights();
        });
        //window start
        unit.ResWindowPointsStart = ko.pureComputed(function () {
            var pointFunctionCodes = _this.filterPoints(_this.ItemDetail.FunctionCodes());
            var bonusFunctionCodes = _this.filterBonus(_this.ItemDetail.FunctionCodes());
            var functionCodes = pointFunctionCodes.concat(bonusFunctionCodes);
            if (_.isEmpty(functionCodes))
                return null;
            var minEndRangeForReservation = _.min(functionCodes, function (fc) { return fc.EndRangeForReservation(); });
            return moment().add(minEndRangeForReservation.EndRangeForReservation(), 'day').endOf('day'); //make sure that the window goes all the way to the end of the day
        });
        unit.IsPriorityExchangeWindow = ko.pureComputed(function () {
            return unit.ShowTotalPointsLabel() &&
                unit.SelectedPointFunctionCode() != null &&
                unit.SelectedPointFunctionCode().IsPriorityExchange();
        });
        unit.ResWindowCashStart = ko.pureComputed(function () {
            var functionCodes = _this.filterOpenSeason(_this.ItemDetail.FunctionCodes());
            if (_.isEmpty(functionCodes))
                return null;
            var minEndRangeForReservation = _.min(functionCodes, function (fc) { return fc.EndRangeForReservation(); });
            return moment().add(minEndRangeForReservation.EndRangeForReservation(), 'day').endOf('day'); //make sure that the window goes all the way to the end of the day
        });
        //window end
        unit.ResWindowPointsEnd = ko.pureComputed(function () {
            var pointFunctionCodes = _this.filterPoints(_this.ItemDetail.FunctionCodes());
            var bonusFunctionCodes = _this.filterBonus(_this.ItemDetail.FunctionCodes());
            var functionCodes = pointFunctionCodes.concat(bonusFunctionCodes);
            if (_.isEmpty(functionCodes))
                return null;
            var maxBeginRangeForReservation = _.max(functionCodes, function (fc) { return fc.BeginRangeForReservation(); });
            return moment().add(maxBeginRangeForReservation.BeginRangeForReservation(), 'day').endOf('day'); //make sure that the window goes all the way to the end of the day
        });
        unit.ResWindowCashEnd = ko.pureComputed(function () {
            var functionCodes = _this.filterOpenSeason(_this.ItemDetail.FunctionCodes());
            if (_.isEmpty(functionCodes))
                return null;
            var maxBeginRangeForReservation = _.max(functionCodes, function (fc) { return fc.BeginRangeForReservation(); });
            return moment().add(maxBeginRangeForReservation.BeginRangeForReservation(), 'day').endOf('day'); //make sure that the window goes all the way to the end of the day
        });
        unit.ShowNightStayLabel = ko.pureComputed(function () {
            if (!unit.PointWindowMet() && _this.Filters.OpenSeasonMode() === false)
                return false;
            if (!unit.CashWindowMet() && _this.Filters.OpenSeasonMode()) {
                return false;
            }
            return unit.PointReqMet() && unit.MinNightMet() && _this.Filters.OpenSeasonMode() === true;
        });
        unit.ShowTotalPointsLabel = ko.pureComputed(function () {
            if (!_this.Filters.OpenSeasonMode() && !_this.DatesSelected())
                return true;
            if (!unit.PointWindowMet() && _this.Filters.OpenSeasonMode() === false)
                return false;
            if (!unit.CashWindowMet() && _this.Filters.OpenSeasonMode()) {
                return false;
            }
            return unit.PointReqMet() && unit.MinNightMet() && unit.ValidReservationChange() && _this.Filters.OpenSeasonMode() === false;
        });
        unit.ShowEliteDiscountDisclaimer = ko.pureComputed(function () {
            return unit.HasEliteCashDiscount() && unit.CostDisplay() !== '' && _this.Filters.OpenSeasonMode();
        });
        //cost display
        unit.CostDisplay = ko.pureComputed(function () {
            //dont show costs for things outside windows
            if (_this.DatesSelected() && (!unit.CashWindowMet() || !unit.MinNightMet()) && _this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.DatesSelected() && (!unit.PointWindowMet() || !unit.MinNightMet()) && !_this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.ItemDetail.IsYenAccepted() && _this.Filters.OpenSeasonMode()) {
                return _this.ShowOpenSeason() ? new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(unit.OpenSeasonCost()) : numeral(unit.OpenSeasonCost()).format("$(0,0)");
            }
            else if (_this.Filters.OpenSeasonMode()) {
                return unit.OpenSeasonCost() ? numeral(unit.OpenSeasonCost()).format("$(0,0)") : null;
            }
            else {
                return numeral(unit.StartingAt()).format("(0,0)");
            }
        });
        unit.CostDiscountedDisplay = ko.pureComputed(function () {
            //dont show costs for things outside windows
            if (_this.DatesSelected() && (!unit.CashWindowMet() || !unit.MinNightMet()) && _this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.DatesSelected() && (!unit.PointWindowMet() || !unit.MinNightMet()) && !_this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.Filters.OpenSeasonMode()) {
                return null;
            }
            else {
                return numeral(unit.StartingAtDiscountPromo()).format("(0,0)");
            }
        });
        unit.CostDiscountedDecreaseDisplay = ko.pureComputed(function () {
            if (_this.DatesSelected() && (!unit.CashWindowMet() || !unit.MinNightMet()) && _this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.DatesSelected() && (!unit.PointWindowMet() || !unit.MinNightMet()) && !_this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.Filters.OpenSeasonMode()) {
                return null;
            }
            var startingAt = unit.StartingAt();
            var startingAtDiscounted = unit.StartingAtDiscountPromo();
            var decrease = startingAt - startingAtDiscounted;
            return decrease;
        });
        unit.CostDiscountedPercentageDisplay = ko.pureComputed(function () {
            //dont show costs for things outside windows
            if (_this.DatesSelected() && (!unit.CashWindowMet() || !unit.MinNightMet()) && _this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.DatesSelected() && (!unit.PointWindowMet() || !unit.MinNightMet()) && !_this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.Filters.OpenSeasonMode()) {
                return null;
            }
            else {
                var startingAt = unit.StartingAt();
                var startingAtDiscounted = unit.StartingAtDiscountPromo();
                if (startingAtDiscounted == null || startingAtDiscounted === 0) {
                    return null;
                }
                else {
                    var decrease = startingAt - startingAtDiscounted;
                    var percentage = (decrease / startingAt) * 100;
                    return "(-" + percentage.toFixed(1).replace(".0", "") + "%)";
                }
            }
        });
        unit.IsCostDiscounted = ko.pureComputed(function () {
            //dont show costs for things outside windows
            if (_this.DatesSelected() && (!unit.CashWindowMet() || !unit.MinNightMet()) && _this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.DatesSelected() && (!unit.PointWindowMet() || !unit.MinNightMet()) && !_this.Filters.OpenSeasonMode()) {
                return '';
            }
            if (_this.Filters.OpenSeasonMode()) {
                return false;
            }
            else {
                var startingAtDiscounted = unit.StartingAtDiscountPromo();
                var hasStartingAtDiscounted = startingAtDiscounted != null && startingAtDiscounted > 0;
                return hasStartingAtDiscounted;
            }
        });
        //number of guest filter
        unit.SizeReqMet = ko.pureComputed(function () {
            if (_this.Filters.NumberOfGuest() !== null) {
                return unit.Occupancy() >= _this.Filters.NumberOfGuest();
            }
            return true;
        });
        //number of guest filter
        unit.ValidReservationChange = ko.pureComputed(function () {
            if (_this.IsUpdatingReservation()) {
                //Verify if they are required to spend >= the current amount of points that they are
                if (_this.ExistingReservation.ChangeCannotReducePoints()) {
                    return unit.StartingAt() >= _this.ExistingReservation.TotalPointsCost();
                }
                //Not allowed to move an existing reservation to another year
                var checkInDaySameAsArrivalYear = moment(_this.ExistingReservation.DateCheckIn()).year() === moment(_this.Filters.CheckIn()).year();
                //Can not add days onto an existing reservation to push it into another year. If the reservation already straddles years they are not allowed to change the checkout day
                var checkOutDaySameAsArrivalYear = moment(_this.Filters.CheckOut()).year() === moment(_this.ExistingReservation.DateCheckIn()).year();
                var checkOutDayHasntChanged = moment(_this.ExistingReservation.DateCheckOut()).format('YYYY-MM-DD') === moment(_this.Filters.CheckOut()).format('YYYY-MM-DD');
                return checkInDaySameAsArrivalYear && (checkOutDaySameAsArrivalYear || checkOutDayHasntChanged);
            }
            return true;
        });
        //minimum nights
        unit.MinNightMet = ko.pureComputed(function () {
            var minNightsMet = true;
            var points = unit.MinNightPoints();
            var open = unit.MinNightOpen();
            if (_this.DatesSelected()) {
                if (_this.Filters.OpenSeasonMode()) {
                    minNightsMet = _this.getNumberOfNights() >= open;
                }
                else {
                    minNightsMet = _this.getNumberOfNights() >= points;
                }
            }
            return minNightsMet;
        });
        unit.PointWindowDisplay = ko.pureComputed(function () {
            return moment(unit.ResWindowPointsStart()).format(_this.dateFormat) + ' - ' + moment(unit.ResWindowPointsEnd()).format(_this.dateFormat);
        });
        unit.CashWindowDisplay = ko.pureComputed(function () {
            return moment(unit.ResWindowCashStart()).format(_this.dateFormat) + ' - ' + moment(unit.ResWindowCashEnd()).format(_this.dateFormat);
        });
        unit.AvailableForPaymentType = ko.pureComputed(function () {
            if (_this.Filters.OpenSeasonMode()) {
                var openSeasonFc = _this.filterOpenSeason(_this.ItemDetail.FunctionCodes());
                return _this.DatesSelected() ? openSeasonFc.length > 0 : (unit.OpenSeasonCost() != null && unit.OpenSeasonCost() > 0);
            }
            if (_this.DatesSelected()) {
                var pointFunctionCodes = _this.filterPoints(_this.ItemDetail.FunctionCodes());
                var bonusFunctionCodes = _this.filterBonus(_this.ItemDetail.FunctionCodes());
                var hasPointFunctionCode = pointFunctionCodes.length > 0;
                var hasBonusFunctionCode = bonusFunctionCodes.length > 0;
                return hasPointFunctionCode || hasBonusFunctionCode;
            }
            return unit.StartingAt() != null && unit.StartingAt() > 0;
        });
        unit.PointWindowMet = ko.pureComputed(function () {
            var pointFunctionCode = unit.SelectedPointFunctionCode();
            var bonusFunctionCode = unit.SelectedBonusFunctionCode();
            var functionCode = pointFunctionCode != null ? pointFunctionCode : bonusFunctionCode;
            if (functionCode != null)
                return true;
            var checkInDate = _this.Filters.CheckIn();
            var checkOutDate = _this.Filters.CheckOut();
            var todaysDate = moment().format(_this.dateFormat);
            var functionCodes = _this.ItemDetail.FunctionCodes();
            var bonusFunctionCodes = _this.filterBonus(functionCodes);
            var pointFunctionCodes = _this.filterPoints(functionCodes);
            var allFunctionCodes = bonusFunctionCodes.concat(pointFunctionCodes);
            var fcRange = _this.filterFcOutOfRange(checkInDate, checkOutDate, todaysDate, allFunctionCodes);
            return (fcRange.length > 0);
        });
        unit.CashWindowMet = ko.pureComputed(function () {
            var functionCode = unit.SelectedCashFunctionCode();
            if (functionCode != null)
                return true;
            var checkInDate = _this.Filters.CheckIn();
            var checkOutDate = _this.Filters.CheckOut();
            var todaysDate = moment().format(_this.dateFormat);
            var functionCodes = _this.ItemDetail.FunctionCodes();
            var cashFunctionCodes = _this.filterOpenSeason(functionCodes);
            var fcRange = _this.filterFcOutOfRange(checkInDate, checkOutDate, todaysDate, cashFunctionCodes);
            return (fcRange.length > 0);
        });
        unit.PointReqMet = ko.pureComputed(function () {
            var enoughPoints = true;
            //CostDisplay
            if (!_this.Filters.OpenSeasonMode() && _this.DatesSelected()) {
                var functionCodes = _this.ItemDetail.FunctionCodes();
                var bonusFunctionCodes = _this.filterBonus(functionCodes);
                var pointFunctionCodes = _this.filterPoints(functionCodes);
                var allFunctionCodes = bonusFunctionCodes.concat(pointFunctionCodes);
                var fcsCanAfford = _this.filterFcForCanAffordPointType(allFunctionCodes, unit.StartingAt());
                return (fcsCanAfford.length > 0);
            }
            return enoughPoints;
        });
        unit.IsExistingReservationsUnit = ko.pureComputed(function () {
            return _this.IsUpdatingReservation() && unit.UnitId() === _this.ExistingReservation.UnitTypeId() && unit.UnitNumberDisplay() === _this.ExistingReservation.UnitNumber();
        });
        unit.AvailableGreaterThanZero = ko.pureComputed(function () {
            var available = unit.UnitAvailable();
            var reqsMets = true;
            if (unit.StartingAt() === 0 && !_this.Filters.OpenSeasonMode())
                reqsMets = false;
            if ((unit.OpenSeasonCost() === 0 || unit.OpenSeasonCost() == null) && _this.Filters.OpenSeasonMode())
                reqsMets = false;
            return available && reqsMets;
        });
        unit.AbleToBook = ko.pureComputed(function () {
            return _this.DatesSelected()
                && unit.UnitAvailable()
                && unit.PointReqMet()
                && unit.MinNightMet()
                && unit.ValidReservationChange()
                && unit.AvailableGreaterThanZero()
                && ((_this.Filters.OpenSeasonMode() && unit.CashWindowMet()) ||
                    (!_this.Filters.OpenSeasonMode() && unit.PointWindowMet()));
        });
        unit.DisplayTotals = ko.computed(function () {
            return (unit.AvailableGreaterThanZero() && unit.UnitAvailable()) && unit.AvailableForPaymentType() && unit.SizeReqMet();
        });
        unit.DisplayNotAvailableForPayment = ko.computed(function () {
            return (unit.AvailableGreaterThanZero() && unit.UnitAvailable()) && !unit.AvailableForPaymentType();
        });
        unit.DisplayNoAvailability = ko.computed(function () {
            return !unit.AvailableGreaterThanZero() || !unit.UnitAvailable();
        });
        unit.DisplaySizeRequirementNotMet = ko.computed(function () {
            return !unit.SizeReqMet() && unit.UnitAvailable();
        });
        unit.BookUrl =
            ko.pureComputed(function () {
                var resortVersionId = "&resortVersionId=" + _this.ItemDetail.VersionId();
                var projectNum = "&projectNum=" + unit.AvailabilityProjectId();
                var checkIn = "&checkIn=" + _this.Filters.CheckIn();
                var checkOut = "&checkOut=" + _this.Filters.CheckOut();
                var unitId = "&unitId=" + unit.UnitId();
                var numberOfGuest = "&guests=" + _this.Filters.NumberOfGuest();
                var homeWeekContract = "&contractNum=" + _this.ItemDetail.HomeWeekContract();
                var homeWeekInterval = "&interval=" + _this.ItemDetail.HomeWeekInterval();
                //Bonus Points OpenSeason
                var functionCodes = _this.ItemDetail.FunctionCodes();
                var pointFC = _this.calculateFunctionCodeForSelection(unit, 'Points', _this.Filters.CheckIn(), _this.Filters.CheckOut(), moment().format(_this.dateFormat), functionCodes);
                var cashFC = _this.calculateFunctionCodeForSelection(unit, 'OpenSeason', _this.Filters.CheckIn(), _this.Filters.CheckOut(), moment().format(_this.dateFormat), functionCodes);
                var bonusFC = _this.calculateFunctionCodeForSelection(unit, 'Bonus', _this.Filters.CheckIn(), _this.Filters.CheckOut(), moment().format(_this.dateFormat), functionCodes);
                //pass selected function code 
                var pointFunctionCode = "&pointFunctionCode=" + (pointFC ? pointFC.FunctionType() : null);
                var cashFunctionCode = "&cashFunctionCode=" + (cashFC ? cashFC.FunctionType() : null);
                var bonusFunctionCode = "&bonusFunctionCode=" + (bonusFC ? bonusFC.FunctionType() : null);
                var openSeason = "&openSeason=" + _this.Filters.OpenSeasonMode();
                var unitNumber = unit.UnitNumberEncrypted() !== null ? "&unitNumber=" + unit.UnitNumberEncrypted() : "";
                var bonus = _this.Filters.BookingType() == 'bonus' ? "&bonus=true" : "";
                if (_this.Filters.NumberOfGuest() == null)
                    numberOfGuest = "&guests=2";
                var lang = _this.CurrentLanguageCode();
                if (_this.IsUpdatingReservation()) {
                    var existingReservation = "resNum=" + _this.ExistingReservation.Id();
                    return "/" + lang + "/reservation/change/?" + existingReservation + resortVersionId + projectNum + checkIn + checkOut + unitId + numberOfGuest + cashFunctionCode + pointFunctionCode + bonusFunctionCode + openSeason + unitNumber + "#/new";
                }
                else if (_this.IsHomeWeek()) {
                    return "/" + lang + "/reservation?" + homeWeekContract + homeWeekInterval + checkIn + checkOut + numberOfGuest + "&unitId=" + _this.ItemDetail.HomeWeekUnitType.UnitId() + pointFunctionCode + "#/new";
                }
                else {
                    return "/" + lang + "/reservation?" + resortVersionId + projectNum + checkIn + checkOut + unitId + numberOfGuest + cashFunctionCode + pointFunctionCode + bonusFunctionCode + openSeason + unitNumber + bonus + "#/new";
                }
            });
        unit.DropDownDisplayName = ko.pureComputed(function () {
            var ddName = unit.Name() + " (" + unit.UnitId() + ")";
            if (unit.UnitNumberGenerated()) {
                ddName += " - " + unit.UnitNumberGenerated();
            }
            return ddName;
        });
    };
    Resort.prototype.getMaxPoints = function (unitSelectedPointFunctionCode, resortFunctionCodes) {
        var _this = this;
        var bookingYear = moment(this.Filters.CheckIn(), this.dateFormat).year(); //based on checkin date
        //If there is already a selected function code get the max and skip searching all existing function codes.
        if (unitSelectedPointFunctionCode) {
            var isValidSelectedPointFunctionCode = this.filterFcOutOfRange(this.Filters.CheckIn(), this.Filters.CheckOut(), moment().format(this.dateFormat), [unitSelectedPointFunctionCode]).length === 1;
            unitSelectedPointFunctionCode = isValidSelectedPointFunctionCode ? unitSelectedPointFunctionCode : null;
            return this.getMaxFunctionCodePoints(unitSelectedPointFunctionCode, bookingYear);
        }
        //only take function codes into account that have a valid booking window as different types of FCs have different points rules
        var resortFunctionCodesInRange = this.filterFcOutOfRange(this.Filters.CheckIn(), this.Filters.CheckOut(), moment().format(this.dateFormat), this.filterPointsOrBonus(resortFunctionCodes));
        var allMaxPoints = ko.utils.arrayMap(resortFunctionCodesInRange, function (fc) {
            return _this.getMaxFunctionCodePoints(fc, bookingYear);
        });
        var maxPoints = allMaxPoints != null && allMaxPoints.length > 0 ? _.max(allMaxPoints) : 0;
        return maxPoints;
    };
    Resort.prototype.getMaxFunctionCodePoints = function (functionCode, bookingYear) {
        if (!functionCode)
            return 0;
        var bookingYearPoints = ko.utils.arrayFirst(functionCode.YearlyBookablePoints(), function (pointsType) {
            return pointsType.Year() === bookingYear;
        });
        if (bookingYearPoints == null)
            return 0;
        return bookingYearPoints.MaxAvailable();
    };
    Resort.prototype.verifyBookingWindow = function (checkInDate, checkOutDate, windowStart, windowEnd, calcMethodLookup) {
        //'From Arrival Date'  
        if (calcMethodLookup === 0) {
            return this.datesOk(checkInDate, windowStart, windowEnd);
        }
        //'From Use Year Begin'
        if (calcMethodLookup === 1) {
            //todo
        }
        //'From Season Begin' 
        if (calcMethodLookup === 2) {
            //TODO need to send down season info? what if reservation spans multiple seasons?
        }
        //'From Departure Date' 
        if (calcMethodLookup === 3) {
            return this.datesOk(checkOutDate, windowStart, windowEnd);
        }
        return false;
    };
    Resort.prototype.getDates = function () {
        return {
            Start: this.CalendarWeek1()[0].Date,
            End: this.CalendarWeek12()[6].Date
        };
    };
    Resort.prototype.getMoreDates = function (ids) {
        var _this = this;
        if (this.IsGetMoreDatesLoading()) {
            return;
        }
        //make overlay loading div full height
        // $('#loading').height($(window).height());
        this.IsGetMoreDatesLoading(true);
        var postData = this.form.getPostDate();
        postData.PageResortIds = ids;
        var dates = this.getDates();
        postData.Filters.CalendarStart = moment(dates.Start).format('YYYY-MM-DD');
        postData.Filters.CalendarEnd = moment(dates.End).format('YYYY-MM-DD');
        postData.Filters.HomeWeekContractNumber = this.ItemDetail.HomeWeekContract();
        postData.Filters.HomeWeekContractInterval = this.ItemDetail.HomeWeekInterval();
        postData.Filters.HomeWeekSeasonsOwned = this.ItemDetail.HomeWeekSeasonsOwned();
        postData.Filters.HomeWeekNumberOfNights = this.ItemDetail.HomeWeekNumberOfNights();
        postData.Filters.HomeWeekStartDayIndex = this.ItemDetail.HomeWeekStartDayIndex();
        postData.Filters.HomeWeekBookableYears = postData.ItemDetail.HomeWeekBookableYears;
        var simplePostData = {
            PageResortIds: postData.PageResortIds,
            Filters: postData.Filters
        };
        var promise = $.ajax({
            url: this.form.postUrl.replace('Edit', 'GetMoreDates'),
            data: JSON.stringify(simplePostData),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8',
        });
        promise.done(function (data) {
            _.each(data.List, function (resort) {
                _this.addToIndex(resort, dates);
            });
            //TODO: Make sure this doesn't need to be done on the list
            //Add back the function codes for the new dates in looking at a particular resort
            if ((_this.IsDetail() || _this.IsHomeWeek()) && data.List && data.List.length === 1) {
                var resort = data.List[0];
                var currentCodes = ko.toJS(_this.ItemDetail.FunctionCodes);
                var allFunctionCodes = currentCodes.concat(resort.FunctionCodes);
                var uniqueFunctionCodes = _.uniq(allFunctionCodes, function (item, key, a) {
                    return item.ClubFunctionPK;
                });
                ko.mapping.fromJS(uniqueFunctionCodes, {}, _this.ItemDetail.FunctionCodes);
                var currentUnits = ko.toJS(_this.ItemDetail.Units);
                var currentUnitsCount = currentUnits.length;
                var newUnits = resort.Units.filter(function (unit) {
                    var exists = currentUnits.some(function (cu) {
                        return cu.AvailabilityProjectId == unit.AvailabilityProjectId && cu.UnitId == unit.UnitId && cu.UnitNumberDisplay == unit.UnitNumberDisplay;
                    });
                    return !exists;
                });
                var newUnitsMapped = _.map(newUnits, function (unit) {
                    unit.UnitNumberGenerated = ++currentUnitsCount;
                    var mappedUnit = ko.mapping.fromJS(unit);
                    _this.setUnitCosts(mappedUnit);
                    return mappedUnit;
                });
                for (var _i = 0, newUnitsMapped_1 = newUnitsMapped; _i < newUnitsMapped_1.length; _i++) {
                    var unit = newUnitsMapped_1[_i];
                    _this.ItemDetail.Units.push(unit);
                }
                _this.refreshAllUnits();
            }
            _this.AvailabilityCacheUpdate(new Date());
            _this.IsGetMoreDatesLoading(false);
        });
        return promise;
    };
    Resort.prototype.getPointsChartsDayRangeDisplay = function (days) {
        var startDayName = this.getContentBlock('AbbreviationHeaderDay' + days.StartDay(), 'PointsChart');
        var endDayName = this.getContentBlock('AbbreviationHeaderDay' + days.EndDay(), 'PointsChart');
        var fullWeekName = this.getContentBlock('SevenNightStayHeader', 'PointsChart');
        if (days.IsWeekly()) {
            return fullWeekName;
        }
        else {
            return startDayName + ' - ' + endDayName;
        }
    };
    Resort.prototype.stepsJson = function () {
        var overlay = window['hgv']['Resort'].HelpOverlay;
        return JSON.parse(ko.unwrap(overlay.Content));
    };
    Resort.prototype.overlayClosed = function () {
        var resort = window['hgv']['Resort'];
        resort.UpdateClosedOverlays(resort.Title() + '-availability');
    };
    Resort.prototype.DisplayHelpOverlays = function () {
        if (ko.unwrap(this.HasHelpOverlay) && this.ShouldDisplayOverlay(this.Title() + '-availability')) {
            HGV.HelpOverlay['help-overlay'].toggle(true);
        }
    };
    Resort.prototype.ShouldDisplayOverlay = function (titleOverride) {
        var display = _super.prototype.ShouldDisplayOverlay.call(this, titleOverride);
        return display && (this.IsSearch() || this.IsDetail());
    };
    /* Begin Function Code Calculator Functions
    USE Jasmine\ResortTest.ts when modifying these methods
    */
    Resort.prototype.filterFcForUnit = function (checkin, checkout, functionCodes, unit) {
        var _this = this;
        var fcIds = null;
        var missing = false;
        //iterate between dates and see if we are missing availability
        //also get the intersection of all days functions codes so if we are not missing a day
        //we can filter the function codes to only the intersection
        this.enumerateDaysBetweenDateStrings(checkin, checkout, function (date) {
            var availbilityDay = _this.getAvailabilityFromCache(unit, date);
            if (availbilityDay === null || availbilityDay === undefined) {
                missing = true;
                return true;
            }
            if (fcIds == null) {
                fcIds = availbilityDay.f;
            }
            else {
                fcIds = _.intersection(fcIds, availbilityDay.f);
            }
            return false; //return false to keep iterating
        });
        if (missing) {
            return []; //missing a day so there is no function code
        }
        else {
            //we need to return function codes that are only in the intersection of availability days
            var filteredFc = _.filter(functionCodes, function (item) {
                return _.includes(fcIds, item.ClubFunctionPK());
            });
            return filteredFc;
        }
    };
    Resort.prototype.filterFcForCanAffordPointType = function (functionCodes, unitCost) {
        var _this = this;
        if (this.IsUpdatingReservation()) { //If updating a reservation get the difference between the original and new reservation
            unitCost = unitCost - this.ExistingReservation.TotalPointsCost();
        }
        var bookablePoints = ko.utils.arrayFilter(functionCodes, function (functionCode) {
            var maxBookablePoints = _this.getMaxPoints(functionCode);
            return maxBookablePoints >= unitCost;
        });
        return bookablePoints;
    };
    Resort.prototype.filterFcForOpenSeason = function (checkin, checkout, functionCodes, unit) {
        var _this = this;
        var fcIds = null;
        var missing = false;
        //iterate between dates and see if we are missing availability
        //also get the intersection of all days functions codes so if we are not missing a day
        //we can filter the function codes to only the intersection
        this.enumerateDaysBetweenDateStrings(checkin, checkout, function (date) {
            var availbilityDay = _this.getAvailabilityFromCache(unit, date);
            if (availbilityDay === null || availbilityDay === undefined || availbilityDay.r <= 0) {
                missing = true;
                return true;
            }
            return false; //return false to keep iterating
        });
        if (missing) {
            return []; //missing a day so there is no function code
        }
        else {
            return functionCodes;
        }
    };
    Resort.prototype.filterOpenSeason = function (list) {
        return _.filter(list, function (fc) {
            return fc.OpenSeason() && fc.BonusPoint() === false;
        });
    };
    Resort.prototype.filterPointsOrBonus = function (list) {
        return _.filter(list, function (fc) {
            return fc.OpenSeason() === false;
        });
    };
    Resort.prototype.filterPoints = function (list) {
        return _.filter(list, function (fc) {
            return fc.OpenSeason() === false; //VSTS 2311 && fc.BonusPoint() === false;
        });
    };
    Resort.prototype.filterBonus = function (list) {
        return _.filter(list, function (fc) {
            return fc.BonusPoint();
        });
    };
    Resort.prototype.filterMinimumServiceFee = function (list) {
        var result = _.min(list, function (fc) {
            return fc.ServiceFee();
        });
        if (result === Infinity) {
            return null;
        }
        return result;
    };
    Resort.prototype.filterFcOutOfRange = function (checkin, checkout, todaysDate, functionCodes) {
        var filteredFc = functionCodes;
        var startDate = moment(checkin, this.dateFormat);
        var endDate = moment(checkout, this.dateFormat);
        var now = moment(todaysDate, this.dateFormat);
        var endDateDiff = endDate.diff(now, 'days');
        var startDateDiff = startDate.diff(now, 'days');
        filteredFc = _.filter(filteredFc, function (fc) {
            if (fc.ResvPeriodCalcMethod() === 0)
                return fc.EndRangeForReservation() <= startDateDiff && startDateDiff <= fc.BeginRangeForReservation();
            if (fc.ResvPeriodCalcMethod() === 3)
                return fc.EndRangeForReservation() <= endDateDiff && endDateDiff <= fc.BeginRangeForReservation();
            return false;
        });
        return filteredFc;
    };
    Resort.prototype.filterNumberOfNightFunctionCodes = function (checkin, checkout, functionCodes, sevenNightFunctionCodes) {
        var checkinDate = moment(checkin, this.dateFormat);
        var checkoutDate = moment(checkout, this.dateFormat);
        var filteredFc = functionCodes;
        if (checkinDate.isValid() && checkoutDate.isValid()) {
            var numberOfNight = checkoutDate.diff(checkinDate, 'days');
            //remove 7 night elite if not staying 7 nights
            var sevenNightMultiple = numberOfNight % 7 === 0;
            if (!sevenNightMultiple) {
                filteredFc = _.filter(functionCodes, function (item) {
                    return !_.includes(sevenNightFunctionCodes, item.FunctionType());
                });
            }
            //remove minimum nights not met
            filteredFc = _.filter(filteredFc, function (item) {
                return item.MinimumNumberOfNights() <= numberOfNight;
            });
        }
        return filteredFc;
    };
    Resort.prototype.filterFunctionCodesTypes = function (bookingType, functionCodes) {
        if (bookingType === "Bonus") {
            return this.filterBonus(functionCodes);
        }
        else if (bookingType === "Points") {
            return this.filterPoints(functionCodes);
        }
        else if (bookingType === "OpenSeason") {
            return this.filterOpenSeason(functionCodes);
        }
        throw "bookingType:" + bookingType + " is invalid.";
    };
    Resort.prototype.triggerDateChange = function () {
        console.log('trigger date change');
    };
    Resort.prototype.closeSRModal = function () {
        var self = this;
        if (HGV.Modal) {
            $.each(HGV.Modal, function (key, value) {
                if ($(value.$target).attr("id") != undefined && $(value.$target).attr("id").indexOf('submit-request-modal') != -1) {
                    $("#txt-minpoints").val("");
                    $("#txt-maxpoints").val("");
                    $("#txt-borrowpoints").val("");
                    self.ShowSRValidation(false);
                    value.close();
                }
            });
        }
    };
    Resort.prototype.saveSubmitRequest = function () {
        var _this = this;
        var self = this;
        self.IsLoading(true);
        var uTypes = [];
        var project = Number($('body').find('.project').val());
        $(".checkedUnits").each(function (i, dat) {
            if ($(dat).is(":checked")) {
                uTypes.push($(dat).attr("data-unit"));
            }
        });
        if (uTypes.length == 0) {
            self.ShowSRValidation(true);
            self.IsLoading(false);
            return;
        }
        else {
            self.ShowSRValidation(false);
        }
        var data = {
            //Region : self.re,
            Property: project,
            Arrival_date: $("#checkInDate").val(),
            Number_of_nights: $("#nights-number").val(),
            Low_points: $("#txt-minpoints").val(),
            Max_points: $("#txt-maxpoints").val(),
            Number_of_guests: $("#txt-guest").val(),
            Borrow_points: $("#txt-borrowpoints").val(),
            Club_database: "",
            Reservation_note: "",
            Unit_type: uTypes,
            BookingType: self.Filters.BookingType()
        };
        var promise = $.ajax({
            url: "/" + this.CurrentLanguageCode() + "/resort/savesubmitrequest",
            data: JSON.stringify(data),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.done(function (data) {
            self.IsLoading(false);
            _this.closeSRModal();
            $("body, html").animate({ scrollTop: $("#availability").offset().top - 200 }, 0);
            if (data) {
                self.ShowSuccessMessage(true);
                setTimeout(function () {
                    self.ShowSuccessMessage(false);
                    self.IsLoading(true);
                    window.location.reload();
                }, 10000);
            }
            else {
                self.IsLoading(false);
                self.ShowFailedMessage(true);
                $("body, html").animate({ scrollTop: $("#availability").offset().top - 200 }, 0);
                setTimeout(function () {
                    self.ShowFailedMessage(false);
                }, 10000);
            }
        });
        promise.fail(function (data) {
            self.IsLoading(false);
            self.ShowFailedMessage(true);
            $("body, html").animate({ scrollTop: $("#availability").offset().top - 200 }, 0);
            setTimeout(function () {
                self.ShowFailedMessage(false);
            }, 10000);
        });
        promise.always(function () {
            $("#txt-minpoints").val("");
            $("#txt-maxpoints").val("");
            $("#txt-borrowpoints").val("");
            _this.closeSRModal();
        });
    };
    Resort.prototype.calculateFunctionCodeForSelection = function (unit, bookingType, checkin, checkout, todaysDate, functionCodes, sevenNightFunctionCodes) {
        if (sevenNightFunctionCodes === void 0) { sevenNightFunctionCodes = ['7NBL', '7NEL', '7NEM', 'ELFE', 'HMHK']; }
        var fcForBooking = this.filterFunctionCodesTypes(bookingType, functionCodes);
        var fcNightNumber = this.filterNumberOfNightFunctionCodes(checkin, checkout, fcForBooking, sevenNightFunctionCodes);
        var fcRange = this.filterFcOutOfRange(checkin, checkout, todaysDate, fcNightNumber);
        var fcForUnit = this.filterFcForUnit(checkin, checkout, fcRange, unit);
        var fcCanAfford;
        if (bookingType !== "OpenSeason") {
            fcCanAfford = this.filterFcForCanAffordPointType(fcForUnit, unit.StartingAt());
        }
        else {
            fcCanAfford = this.filterFcForOpenSeason(checkin, checkout, fcForUnit, unit);
        }
        var result = this.filterMinimumServiceFee(fcCanAfford);
        if (result && bookingType === "Points") {
            result = !result.OpenSeason() && !result.BonusPoint() ? result : null;
        }
        return result;
    };
    Resort.prototype.getAnalytics = function () {
        return window.analytics || [];
    };
    return Resort;
}(base.ResortSearchVmBase));
module.exports = Resort;

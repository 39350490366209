"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Form = require("../Base/FrontEndForm");
var PurchaseRequestForm = /** @class */ (function (_super) {
    __extends(PurchaseRequestForm, _super);
    function PurchaseRequestForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    PurchaseRequestForm.prototype.post = function () {
        var _this = this;
        this.vm.HasScrolledToError(false);
        //prevent double post
        if (this.vm.IsLoading()) {
            return;
        }
        //make overlay loading div full height
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        var postData = this.getPostDate();
        var url = this.postUrl.replace('PurchaseRequest', 'purchase-request');
        var promise = $.ajax({
            url: url,
            data: JSON.stringify(postData),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8',
        });
        promise.fail(function (error) { return _this.postPromiseFail(error); });
        promise.done(function (data) { return _this.postPromiseDone(data); });
        return promise;
    };
    PurchaseRequestForm.prototype.postPromiseDone = function (data) {
        this.vm.IsLoading(false);
        this.vm.ShowConfirmation(true);
        //console.log(data);
        //window.location.hash = data.DefaultSammyRoute;
    };
    return PurchaseRequestForm;
}(Form));
module.exports = PurchaseRequestForm;

"use strict";
var WeatherApi = /** @class */ (function () {
    function WeatherApi(apiUrl) {
        this._apiUrl = apiUrl;
    }
    WeatherApi.prototype.getCurrentTemperature = function (zipCode, shortCountryCode) {
        var _this = this;
        var formattedUrl = this._apiUrl.replace('{0}', zipCode).replace('{1}', shortCountryCode);
        var promise = $.ajax({
            url: formattedUrl,
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        return promise.then(function (data) {
            var tempInKelvin = data.Temp;
            var tempInFahrenheit = _this.convertKelvinToFahrenheit(tempInKelvin);
            var tempInCelcius = _this.convertKelvinToCelcius(tempInKelvin);
            if (!data.Temp) {
                return null;
            }
            return { Fahrenheit: tempInFahrenheit, Celcius: tempInCelcius, CityName: data.name };
        });
    };
    WeatherApi.prototype.convertKelvinToFahrenheit = function (tempInKelvin) {
        //T(K) × 9/5 - 459.67
        var rawTemp = ((tempInKelvin * 9) / 5) - 459.67;
        return Math.round(rawTemp);
    };
    WeatherApi.prototype.convertKelvinToCelcius = function (tempInKelvin) {
        //T(K) - 273.15
        var rawTemp = tempInKelvin - 273.15;
        return Math.round(rawTemp);
    };
    return WeatherApi;
}());
module.exports = WeatherApi;

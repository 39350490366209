"use strict";
var ko = require("knockout");
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var KoBindingDoughnutChart = /** @class */ (function () {
    function KoBindingDoughnutChart() {
    }
    KoBindingDoughnutChart.prototype.init = function () {
        ko.bindingHandlers.DoughnutChart = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var boundObservable = valueAccessor();
                var selectedValue = boundObservable();
                var dataObservable = allBindings.get('DoughnutChart_data');
                var dataValue = dataObservable();
                var contentBlocks = allBindings.get('DoughnutChart_contentBlocks');
                var draw = function () {
                    //filter out points that have 0 unless that removes them all
                    var data = ko.toJS(dataValue);
                    var dataGreaterThan0 = _.filter(ko.toJS(dataValue), function (p) { return (p.Points > 0); });
                    if (dataGreaterThan0.length)
                        data = dataGreaterThan0;
                    var chartData = _.map(data, function (p) {
                        return { "value": p.Points, "color": p.Color };
                    });
                    var label = _.find(ko.toJS(contentBlocks), function (p) { return (p.Slug === 'DashboardPointSummaryPoints'); }).Content;
                    var title = selectedValue + " " + label;
                    $(element).drawDoughnutChart(chartData, {
                        animationSteps: 50,
                        showLabel: false,
                        showTip: false,
                        segmentShowStroke: false,
                        segmentStrokeWidth: 0,
                        baseOffset: 0,
                        percentageInnerCutout: 80,
                        baseColor: 'rgba(0,0,0,0.05)',
                        summaryClass: 'donut-chart__summary',
                        summaryTitle: title,
                        summaryTitleClass: 'donut-chart__title',
                        summaryNumberClass: 'donut-chart__total',
                        afterDrawed: function () {
                            var $total = $(this).find('.donut-chart__total');
                            var formattedTotal = $total.text().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                            $total.text(formattedTotal);
                            $(element).addClass('is-loaded');
                        }
                    });
                };
                draw();
                var reDraw = function () {
                    boundObservable = valueAccessor();
                    selectedValue = boundObservable();
                    dataValue = dataObservable();
                    contentBlocks = allBindings.get('DoughnutChart_contentBlocks');
                    $(element).html('');
                    draw();
                };
                dataObservable.subscribe(function (newValue) {
                    reDraw();
                });
            }
        };
    };
    return KoBindingDoughnutChart;
}());
module.exports = KoBindingDoughnutChart;

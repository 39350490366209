"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var moment = require("moment");
var ResortForm = /** @class */ (function (_super) {
    __extends(ResortForm, _super);
    function ResortForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        _this.dateFormat = "MM/DD/YYYY";
        return _this;
    }
    ResortForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('body').on('click', '.browse-dates', function () {
            var unit = ko.dataFor(this);
            self.vm.SelectedUnit(unit);
            //
            //if (!(<any>window).enquire.queries['(min-width: ' + HGV._.bp.l + ')'].mql.matches) {
            if ($(window).width() < 1088) {
                $('#modify-search-button').click();
            }
            else {
                $('#resort-details-tabs').find("a[href='#availability']").trigger('click');
                if (self.vm.CurrentAvailabilityMode() == self.vm.AvailabilityListMode()) {
                    $('#checkInDate', '#ResortListDatePicker').click();
                }
                else {
                    $('#checkInDate', '#StickyDatePicker').click();
                }
            }
        });
        $('.search-mode-toggle').on('click', 'a', function (e) {
            e.preventDefault();
            if ($(this).hasClass('is-active')) { //don't do anything if it's already selected
                return false;
            }
            var mode = $(this).data('search-mode');
            var confirmWarning = $(this).hasClass('confirm-availability-switch');
            if (confirmWarning) {
                HGV.Modal['availability-stay-warning'].close();
            }
            var isValidAvailabilityStay = self.vm.isValidDefaultAvailabilityGridStayLength(moment(self.vm.Filters.CheckIn(), self.vm.dateFormat), moment(self.vm.Filters.CheckOut(), self.vm.dateFormat));
            if (mode === self.vm.AvailabilityGridMode() && !isValidAvailabilityStay && !confirmWarning) {
                HGV.Modal['availability-stay-warning'].open(); //open the warning modal if they have more than 20 day stay selcted and try and switch to availability view
                return false;
            }
            self.vm.CurrentAvailabilityMode(mode);
            return false;
        });
        $('body').on('click', '#btnBookHomeWeek', function (e) {
            if ($(e.target).hasClass('is-disabled')) {
                e.preventDefault();
                return false;
            }
        });
        $(document).on('adjustGuests', function (e, val) {
            var guestsNumber = parseInt(val);
            if (isNaN(guestsNumber) || guestsNumber <= 0)
                return;
            self.vm.Filters.NumberOfGuest(guestsNumber);
        });
        $('.availability-guests').on('change', function (e, val) {
            e.stopImmediatePropagation();
            if (!(document.readyState === 'loading')) { // HACK ALERT!! There seems to be a race condition between toolkit and KO; this seems to fix it.
                var guestsNumber = parseInt($(this).val());
                if (isNaN(guestsNumber) || guestsNumber <= 0 || guestsNumber === undefined)
                    return;
                if (guestsNumber != self.vm.Filters.NumberOfGuest())
                    self.vm.Filters.NumberOfGuest(guestsNumber);
            }
        });
        $('body').on('click', '#submit-review', function () {
            self.vm.RemoveAlerts();
            self.vm.ReviewVM.form.post();
        });
        $('.resort-detail').on('click', '.floorplan-link', function (e) {
            e.preventDefault();
            var imageSource = $(this).attr('href');
            self.vm.ItemDetail.SelectedUnitFloorplanSrc(imageSource);
            HGV.Modal['floorplan-image-modal'].open();
            return false;
        });
        $('.currency-toggle').on('click', 'a', function (e) {
            e.preventDefault();
            var currentSelection = self.vm.Filters.OpenSeasonMode();
            self.vm.Filters.OpenSeasonMode(!currentSelection);
            return false;
        });
        $('body').on('click', '#btnAddMoreAttractions', function () {
            var visLen = self.vm.ItemDetail.VisibleAttractions().length;
            var availLen = self.vm.ItemDetail.Attractions().length;
            for (var i = visLen; i < Math.min(availLen, visLen + 3); i++) {
                self.vm.ItemDetail.VisibleAttractions.push(self.vm.ItemDetail.Attractions()[i]);
            }
            return false;
        });
        try {
            HGV.Dropdown['ddlNumGuests'].on('change', function (event) {
                var num = ko.dataFor(event.currentTarget);
                if (num != null)
                    _this.vm.Filters.NumberOfGuest(num);
                return false;
            });
        }
        catch (ex) { }
    };
    ResortForm.prototype.missingDates = function () {
        var projectNumber = this.vm.ItemDetail.ProjectNumber().toString();
        if (this.vm.AvailabilityCacheIndex[projectNumber] === undefined)
            return true;
        var startMissing = moment(this.vm.AvailabilityCacheIndex[projectNumber].Start) > moment(this.vm.CalendarWeek1()[0].Date);
        var endMissing = moment(this.vm.AvailabilityCacheIndex[projectNumber].End) < moment(this.vm.CalendarWeek12()[6].Date);
        return (startMissing || endMissing);
    };
    //we need add the 'BookUrl' computed to each Unit on the ItemDetail
    ResortForm.prototype.komappingfromdata = function (data, id) {
        var _this = this;
        var self = this;
        var copyMapping = {
            'copy': this.data.KOMapping.allNonPostBack,
            'Units': {
                create: function (options) {
                    var unit = mapper.fromJs(options.data);
                    _this.vm.setUnitCosts(unit);
                    return unit;
                }
            },
            'DayRangeHeaders': {
                create: function (options) {
                    var model = mapper.fromJs(options.data);
                    model.DayRangeContentLang = ko.pureComputed(function () {
                        return self.vm.getPointsChartsDayRangeDisplay(model);
                    });
                    return model;
                }
            }
        };
        mapper.fromJsWithOptions(data.ItemDetail, copyMapping, this.vm.ItemDetail);
        // PurchaseRequest
        var ignorePageInfoMapping = {
            'ignore': ["PageInfo"]
        };
        mapper.fromJsWithOptions(data.PurchaseRequest, ignorePageInfoMapping, this.vm.PurchaseRequestVM);
    };
    ResortForm.prototype.loadDetail = function () {
        this.vm.addToIndex(ko.toJS(this.vm.ItemDetail), ko.toJS(this.vm.DatesLoaded));
        this.vm.SetCosts(this.vm.ItemDetail);
        //maintain state of unavailable
        this.vm.ItemDetail.VisibleAttractions(this.vm.ItemDetail.Attractions.slice(0, 3));
        this.vm.ItemDetail.StartingAtLabel(this.vm.ItemDetail.CostDisplay());
        this.vm.HeaderClass(null);
        this.vm.AvailabilityCacheUpdate(new Date());
        this.vm.SelectedUnit(null);
        // Paulb:  Removed - no longer doing anything with airport distances (per Deamons)
        // And Map.build is called after the DOM loads (found in Resort.ts)
        // Leaving commented-out in case we're wrong. :)
        //var $map = $('#map-directions').find('.map');
        //$map.data('center', this.vm.ItemDetail.Location().trim());
        // separate pre-existing tookit code builds map with airport distances
        //var skipAirportDistance = !this.vm.IsHoa();
        //HGV.Constructors.Map.build($map, skipAirportDistance);
        if (this.vm.Filters.CheckIn())
            this.vm.picker.setMonthOffset(this.vm.Filters.CheckIn());
        this.vm.detailLoading = false;
        this.scrollTop();
    };
    ResortForm.prototype.refreshResortData = function () {
        var _this = this;
        if (this.vm.IsDetail()) {
            var id = this.vm.ItemDetail.Id();
            var promise = this.getDetail(id);
            promise.done(function () {
                _this.vm.loadAvailabilityGridResortDetail();
            });
        }
        if (this.vm.IsSearch() && this.vm.CurrentAvailabilityMode() == this.vm.AvailabilityGridMode()) { //refresh the resort list with ada unit types
            this.vm.table.loadAvailabilityGrid();
        }
    };
    ResortForm.prototype.getDetail = function (id, reservationNumber) {
        var _this = this;
        //this.resetDetail();
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        this.vm.detailLoading = true;
        $('body').addClass('form-shown');
        //this is the start date of availability to include
        var url = '/' + this.vm.CurrentLanguageCode() + '/resort/Item';
        var ada = this.vm.Filters.ADAOnly() ? true : false;
        var dates = this.vm.getDates();
        var start = moment(dates.Start).format('YYYY-MM-DD');
        var end = moment(dates.End).format('YYYY-MM-DD');
        var guests = this.vm.Filters.NumberOfGuest();
        if (reservationNumber == undefined) {
            if (this.vm.Filters.ReservationNumber() !== undefined) {
                reservationNumber = this.vm.Filters.ReservationNumber();
            }
            else if (this.vm.ExistingReservation !== null && this.vm.ExistingReservation.Id() > 0) {
                reservationNumber = this.vm.ExistingReservation.Id();
            }
        }
        var resNumber = null;
        if (reservationNumber !== undefined) {
            resNumber = reservationNumber;
        }
        this.vm.Filters.ReservationNumber(resNumber);
        var promise = $.ajax({
            url: url,
            data: { id: id, Start: start, End: end, ada: ada, guests: guests, reservationNumber: resNumber },
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.fail(function (error) { return _this.getPromiseFail(error, id); });
        promise.done(function (data) { return _this.getPromiseDone(data, id); });
        return promise;
    };
    ResortForm.prototype.getPromiseDone = function (data, id) {
        this.vm.addToIndex(data.ItemDetail, data.DatesLoaded);
        //maintain state of unavailable
        data.ItemDetail.HideUnavailable = this.vm.ItemDetail.HideUnavailable();
        _super.prototype.getPromiseDone.call(this, data, id);
        this.vm.ItemDetail.VisibleAttractions(this.vm.ItemDetail.Attractions.slice(0, 3));
        this.vm.refreshAllUnits();
        this.vm.ItemDetail.StartingAtLabel(this.vm.ItemDetail.CostDisplay());
        this.vm.AvailabilityCacheUpdate(new Date());
        this.vm.SelectedUnit(null);
        var reservationNumber = ko.unwrap(data.Filters.ReservationNumber);
        var isChange = reservationNumber !== null && reservationNumber > 0;
        if (isChange) {
            this.vm.picker.setMonthOffset(data.DatesLoaded.Start);
        }
        this.vm.detailLoading = false;
    };
    ResortForm.prototype.getReservationToChange = function (reservationNumber) {
        var _this = this;
        var promise = $.ajax({
            url: "/" + this.vm.CurrentLanguageCode() + "/" + this.vm.FrontEndRoute() + '/GetExistingReservation',
            data: { reservationNumber: reservationNumber },
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.done(function (data) {
            if (data.ExistingReservation === undefined) {
                return; // skip doing anything if it's an invalid change
            }
            mapper.fromJsWithOptions(data.ExistingReservation, {}, _this.vm.ExistingReservation);
            _this.vm.IsUpdatingReservation(true);
            $("body > .container").addClass("changing-reservation"); // adjusting padding for the change reservation overlay
            if (_this.vm.Filters.CheckIn() == null && _this.vm.Filters.CheckOut() == null) {
                var formattedCheckin = moment.tz(_this.vm.ExistingReservation.DateCheckIn(), _this.vm.UserTimeZone).format(_this.dateFormat); //make sure to use the user's timezone and don't convert to EST
                var formattedCheckout = moment.tz(_this.vm.ExistingReservation.DateCheckOut(), _this.vm.UserTimeZone).format(_this.dateFormat);
                _this.vm.Filters.CheckIn(formattedCheckin);
                _this.vm.Filters.CheckOut(formattedCheckout);
            }
            _this.vm.Filters.NumberOfGuest(_this.vm.ExistingReservation.NumberOfGuests());
            _this.vm.skipADARefresh = true;
            _this.vm.Filters.ADAOnly(_this.vm.ExistingReservation.HasAccessibleOptions());
            _this.vm.skipADARefresh = false;
            _this.vm.Filters.OpenSeasonMode(false); //override any open season filters as you can't change cash bookings
            _this.vm.picker.setMonthOffset(_this.vm.Filters.CheckIn());
            _this.vm.refreshAllUnits(); //refresh the available units
            _this.vm.picker.refreshSelectedDate(_this.vm); //refresh the date picker with selected dates
            var hasExistingUnit = $('.existing-reservation').length > 0;
            if (hasExistingUnit && _this.vm.CurrentAvailabilityMode() === _this.vm.AvailabilityListMode()) {
                $('HTML, body').animate({ scrollTop: $('.existing-reservation').offset().top - 100 }, 500); //scroll to the existing reservation unit type    
            }
            if (_this.vm.CurrentAvailabilityMode() === _this.vm.AvailabilityGridMode() && _this.vm.IsDetail()) {
                _this.vm.startAvailabilityApp();
            }
        });
        promise.fail(function () {
            //TODO: Add a global alert that failed to find reservation?
            _this.vm.IsUpdatingReservation(false);
        });
        return promise;
    };
    ResortForm.prototype.loadHomeWeekDetail = function () {
        this.vm.addToIndex(ko.toJS(this.vm.ItemDetail), ko.toJS(this.vm.DatesLoaded));
        this.vm.SetCosts(this.vm.ItemDetail);
        this.vm.SelectedUnit(this.vm.ItemDetail.HomeWeekUnitType);
        this.vm.refreshAllUnits();
        this.vm.AvailabilityCacheUpdate(new Date());
        this.scrollTop();
    };
    ResortForm.prototype.setTab = function (tabId) {
        this.vm.SelectedTab(tabId);
    };
    ResortForm.prototype.refreshStickyDatePicker = function (shouldReset, shouldDisable) {
        this.refreshStickySidebar('StickyDatePicker', shouldReset, shouldDisable);
    };
    ResortForm.prototype.refreshHomeWeekStickySidebar = function (shouldReset) {
        this.refreshStickySidebar('StickyHomeWeekDetails', shouldReset);
    };
    return ResortForm;
}(FrontEndForm));
module.exports = ResortForm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _ = require("lodash");
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var HGV = (typeof window !== "undefined" ? window['HGV'] : typeof global !== "undefined" ? global['HGV'] : null);
var Cookies = require("js-cookie");
var AccountForm = /** @class */ (function (_super) {
    __extends(AccountForm, _super);
    function AccountForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    AccountForm.prototype.mapEditMembershipInfo = function (data) {
        mapper.fromJsWithOptions(data.EditMembershipInfo, {}, this.vm.EditMembershipInfo);
    };
    AccountForm.prototype.resetUpdateUser = function () {
        this.vm.EditAccountInfo.LastName(this.vm.EditAccountInfo.OldLastName());
        this.vm.EditAccountInfo.FirstName(this.vm.EditAccountInfo.OldFirstName());
    };
    AccountForm.prototype.resetEditMembership = function () {
        this.vm.EditMembershipInfo.Address1(this.vm.ItemDetail.MembershipAddress.Address1());
        this.vm.EditMembershipInfo.Address2(this.vm.ItemDetail.MembershipAddress.Address2());
        this.vm.EditMembershipInfo.Address3(this.vm.ItemDetail.MembershipAddress.Address3());
        this.vm.EditMembershipInfo.City(this.vm.ItemDetail.MembershipAddress.City());
        this.vm.EditMembershipInfo.State(this.vm.ItemDetail.MembershipAddress.State());
        this.vm.EditMembershipInfo.County(this.vm.ItemDetail.MembershipAddress.County());
        this.vm.EditMembershipInfo.ZipCode(this.vm.ItemDetail.MembershipAddress.ZipCode());
        this.vm.EditMembershipInfo.CountryId(this.vm.ItemDetail.MembershipAddress.CountryId());
        this.vm.EditMembershipInfo.PrimaryPhoneNumber(this.vm.ItemDetail.PrimaryPhoneNumber());
        this.vm.EditMembershipInfo.SecondaryPhoneNumber(this.vm.ItemDetail.SecondaryPhoneNumber());
        this.vm.EditMembershipInfo.ShowVerifyAddress(false);
        this.vm.EditMembershipInfo.ShowAddressNotFound(false);
        this.vm.EditMembershipInfo.AddressVerified(false);
        this.vm.EditMembershipInfo.SelectedAddress(null);
    };
    AccountForm.prototype.resetUpdatePassword = function () {
        this.vm.UpdatePassword.CurrentPassword("");
        this.vm.UpdatePassword.NewPassword("");
        this.vm.UpdatePassword.ConfirmPassword("");
    };
    AccountForm.prototype.resetUpdateUserName = function () {
        this.vm.UpdateUsername.CurrentUsername(this.vm.ItemDetail.CurrentUsername());
        this.vm.UpdateUsername.NewUserName("");
    };
    AccountForm.prototype.resetUpdateLanguage = function () {
        this.vm.UpdateLanguage.LanguageCode(this.vm.ItemDetail.CurrentLeadLanguageCode());
        this.vm.UpdateLanguage.LanguageName(this.vm.ItemDetail.LanguageName());
    };
    AccountForm.prototype.resetUpdateEmail = function () {
        this.vm.UpdateEmailAddress.UpdatedEmail(this.vm.ItemDetail.PrimaryAccountEmail());
        this.vm.UpdateEmailAddress.UpdatedSecondaryEmail(this.vm.ItemDetail.SecondaryAccountEmail());
        this.vm.UpdateEmailAddress.Password("");
    };
    AccountForm.prototype.resetInviteUser = function () {
        this.vm.InviteNewUser.Email("");
        this.vm.InvitedUserToRemove(null);
    };
    AccountForm.prototype.resetQuestions = function () {
        this.vm.EditSecurityQuestions.SecurityAnswer1.QuestionId(this.vm.EditSecurityQuestions.SecurityAnswer1.OldQuestionId());
        this.vm.EditSecurityQuestions.SecurityAnswer2.QuestionId(this.vm.EditSecurityQuestions.SecurityAnswer2.OldQuestionId());
        this.vm.EditSecurityQuestions.SecurityAnswer3.QuestionId(this.vm.EditSecurityQuestions.SecurityAnswer3.OldQuestionId());
        this.vm.EditSecurityQuestions.SecurityAnswer4.QuestionId(this.vm.EditSecurityQuestions.SecurityAnswer4.OldQuestionId());
        this.vm.EditSecurityQuestions.SecurityAnswer5.QuestionId(this.vm.EditSecurityQuestions.SecurityAnswer5.OldQuestionId());
        this.vm.EditSecurityQuestions.SecurityAnswer1.Answer(this.vm.EditSecurityQuestions.SecurityAnswer1.OldAnswer());
        this.vm.EditSecurityQuestions.SecurityAnswer2.Answer(this.vm.EditSecurityQuestions.SecurityAnswer2.OldAnswer());
        this.vm.EditSecurityQuestions.SecurityAnswer3.Answer(this.vm.EditSecurityQuestions.SecurityAnswer3.OldAnswer());
        this.vm.EditSecurityQuestions.SecurityAnswer4.Answer(this.vm.EditSecurityQuestions.SecurityAnswer4.OldAnswer());
        this.vm.EditSecurityQuestions.SecurityAnswer5.Answer(this.vm.EditSecurityQuestions.SecurityAnswer5.OldAnswer());
    };
    AccountForm.prototype.resetModals = function (id) {
        if (id === "#change-password-modal") {
            this.resetUpdatePassword();
        }
        if (id === "#edit-account-modal") {
            this.resetUpdateUser();
        }
        if (id === "#add-invited-modal") {
            this.resetInviteUser();
        }
        if (id === "#edit-email-modal") {
            this.resetUpdateEmail();
        }
        if (id === "#edit-membershipinfo-modal") {
            this.resetEditMembership();
        }
        if (id === "#edit-questions-modal") {
            this.resetQuestions();
        }
        if (id === "#change-username-modal") {
            this.resetUpdateUserName();
        }
    };
    AccountForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        var AuthType;
        (function (AuthType) {
            AuthType[AuthType["App"] = 0] = "App";
            AuthType[AuthType["Email"] = 1] = "Email";
            AuthType[AuthType["SMS"] = 2] = "SMS";
        })(AuthType || (AuthType = {}));
        var AuthContactType;
        (function (AuthContactType) {
            AuthContactType[AuthContactType["Default"] = 0] = "Default";
            AuthContactType[AuthContactType["Primary"] = 1] = "Primary";
            AuthContactType[AuthContactType["Secondary"] = 2] = "Secondary";
        })(AuthContactType || (AuthContactType = {}));
        //Clicking on validate or save membership information
        $('#edit-address-validate, #edit-address-save').on('click', function (e) {
            e.preventDefault();
            _this.editAction = "EditMembershipInfo";
            if (_this.vm.EditMembershipInfo.State() == null) {
                _this.vm.EditMembershipInfo.State(_this.vm.EditMembershipInfo.County() == null ? _this.vm.EditMembershipInfo.Province() : _this.vm.EditMembershipInfo.County());
            }
            var editMembership = _this.vm.EditMembershipInfo;
            var postData = mapper.toJs(editMembership);
            var promise = _this.post(postData);
            promise.done(function (data) {
                HGV.Modal['edit-membership-info'].close();
                _this.vm.UpdatedSuccessfully();
                _this.vm.ItemDetail.MembershipAddress.Address1(data.EditMembershipInfo.SelectedAddress.Address1);
                _this.vm.ItemDetail.MembershipAddress.Address2(data.EditMembershipInfo.SelectedAddress.Address2);
                _this.vm.ItemDetail.MembershipAddress.Address3(data.EditMembershipInfo.SelectedAddress.Address3);
                _this.vm.ItemDetail.MembershipAddress.City(data.EditMembershipInfo.SelectedAddress.City);
                _this.vm.ItemDetail.MembershipAddress.State(data.EditMembershipInfo.SelectedAddress.State);
                _this.vm.ItemDetail.MembershipAddress.ZipCode(data.EditMembershipInfo.SelectedAddress.ZipCode);
                _this.vm.ItemDetail.MembershipAddress.CountryId(data.EditMembershipInfo.SelectedAddress.CountryFK);
                _this.vm.ItemDetail.MembershipAddress.Country(data.EditMembershipInfo.SelectedAddress.Country);
                _this.vm.ItemDetail.PrimaryPhoneNumber(_this.vm.EditMembershipInfo.PrimaryPhoneNumber());
                _this.vm.ItemDetail.SecondaryPhoneNumber(_this.vm.EditMembershipInfo.SecondaryPhoneNumber());
                if (_this.vm.EditMembershipInfo.AllStates().length > 0) {
                    var state = _this.vm.EditMembershipInfo.AllStates().filter(function (s) { return s.Id() == data.EditMembershipInfo.SelectedAddress.State; });
                    _this.vm.ItemDetail.MembershipAddress.CityStateZip(data.EditMembershipInfo.SelectedAddress.City + ", " + state[0].Name() + " " + data.EditMembershipInfo.SelectedAddress.ZipCode);
                }
                else if (_this.vm.EditMembershipInfo.AllCounties().length > 0) {
                    var county = _this.vm.EditMembershipInfo.AllCounties().filter(function (s) { return s.Id == _this.vm.EditMembershipInfo.County(); });
                    _this.vm.ItemDetail.MembershipAddress.CityStateZip(data.EditMembershipInfo.SelectedAddress.City + ", " + county[0].Name + " " + data.EditMembershipInfo.SelectedAddress.ZipCode);
                }
                else if (_this.vm.EditMembershipInfo.AllProvinces().length > 0) {
                    var province = _this.vm.EditMembershipInfo.AllProvinces().filter(function (s) { return s.Id() == _this.vm.EditMembershipInfo.Province(); });
                    _this.vm.ItemDetail.MembershipAddress.CityStateZip(data.EditMembershipInfo.SelectedAddress.City + ", " + province[0].Name() + " " + data.EditMembershipInfo.SelectedAddress.ZipCode);
                }
                else {
                    //Take the server-side formatting for display
                    _this.vm.ItemDetail.MembershipAddress.CityStateZip(data.EditMembershipInfo.CityStateZip);
                }
                _this.resetEditMembership(); //so if they click edit again it goes back to the edit screen
                //this.getDetail(null);  //refresh the details with updated information;
            });
            promise.fail(function (data) {
                _this.mapEditMembershipInfo(data.responseJSON);
                _super.prototype.postPromiseFail.call(_this, data);
                if (!editMembership.ShowAddressNotFound()) { //If the address was found then default the verified
                    editMembership.SelectedAddress(editMembership.VerifiedAddress());
                }
                else {
                    editMembership.SelectedAddress(editMembership.EnteredAddress());
                }
            });
        });
        //Clicking on the edit address on validate address screen
        $('#return-edit-address').on('click', function (e) {
            e.preventDefault();
            //this.resetEditMembership();
            _this.vm.EditMembershipInfo.SelectedAddress(null);
            _this.vm.EditMembershipInfo.ShowVerifyAddress(false);
            _this.vm.EditMembershipInfo.ShowAddressNotFound(false);
            _this.vm.EditMembershipInfo.AddressVerified(false);
        });
        $('#close-modal-btn').on('click', function (e) {
            e.preventDefault();
            HGV.Modal['change-two-factor'].close();
        });
        $('#two-factor-back-to-selection').on('click', function (e) {
            e.preventDefault();
            self.vm.ItemDetail.TwoFactorStep(1);
        });
        $('.2fa-email-add').on('click', function (e) {
            e.preventDefault();
            document.getElementById("twoFactorEmailErrorMessage").innerHTML = "";
            if (e.target.id == '2fa-email-primary-add-btn')
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Primary;
            else
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Secondary;
            var textbox = document.getElementById("2fa-email-AuthCode");
            if (textbox) {
                textbox.value = ""; // Using plain JavaScript to clear the textbox
            }
            //call the create authenticator to generate the email
            self.editAction = "Generate2FAEmailAuthCode";
            var postData = mapper.toJs(self.vm.EditAuthenticatorInfo);
            var promise = self.post(postData);
            promise.done(function (data) {
                self.vm.EditAuthenticatorInfo.Id(data.Id);
            });
            promise.fail(function (failData) {
                self.vm.IsLoading(false);
                var data = failData.responseJSON;
            });
        });
        $('.2fa-email-resend').on('click', function (e) {
            //set the action
            e.preventDefault();
            self.editAction = "ReGenerate2FAEmailAuthCode";
            var postData = mapper.toJs(self.vm.EditAuthenticatorInfo);
            var promise = self.post(postData);
            promise.done(function (data) {
                document.getElementById("twoFactorEmailErrorMessage").innerHTML = data.ItemDetail.ErrorMessage;
            });
        });
        $('#2fa-email-submit-btn').on('click', function (e) {
            //set the action
            e.preventDefault();
            self.editAction = "Verify2FAEmailAuthCode";
            document.getElementById("twoFactorEmailErrorMessage").innerHTML = "";
            //set the type            
            self.vm.EditAuthenticatorInfo.Type = AuthType.Email;
            //read the value enterd in textbox
            var authCode = null;
            var textbox = document.getElementById('2fa-email-AuthCode');
            // Ensure the textbox exists and then get its value
            if (textbox) {
                authCode = textbox.value;
            }
            //prepare the data
            self.vm.EditAuthenticatorInfo.AuthCode(authCode);
            var postData = mapper.toJs(self.vm.EditAuthenticatorInfo);
            //execute
            var promise = self.post(postData);
            promise.done(function (data) {
                if (self.vm.EditAuthenticatorInfo.ContactType == AuthContactType.Primary)
                    self.vm.ItemDetail.TwoFactorPrimaryEmail(data.EmailAddress);
                else
                    self.vm.ItemDetail.TwoFactorSecondaryEmail(data.EmailAddress);
                document.getElementById("twoFactorEmailErrorMessage").innerHTML = "";
                //clean up
                self.vm.EditAuthenticatorInfo.AuthCode("");
                HGV.Modal['change-two-factor-email'].close();
                HGV.Modal['view-two-factor'].close();
            });
            promise.fail(function (failData) {
                self.vm.EditAuthenticatorInfo.AuthCode("");
                self.vm.IsLoading(false);
                document.getElementById("twoFactorEmailErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
                var data = failData.responseJSON;
            });
        });
        $('#2fa-email-AuthCode').on('keydown', function (e) {
            if (self.vm.EditAuthenticatorInfo.AuthCode() != "" && document.getElementById("twoFactorEmailErrorMessage").innerHTML != "") {
                document.getElementById("twoFactorEmailErrorMessage").innerHTML = "";
            }
        });
        $('.2fa-email-remove').on('click', function (e) {
            //set the action
            e.preventDefault();
            self.editAction = "RemoveAuthenticator";
            //set the auth type
            self.vm.EditAuthenticatorInfo.Type = AuthType.Email;
            //set the contact type
            if (e.target.id == '2fa-email-primary-remove-btn')
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Primary;
            else
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Secondary;
            //prepare
            var postData = mapper.toJs(self.vm.EditAuthenticatorInfo);
            //execute
            var promise = self.post(postData);
            //validate
            promise.done(function (data) {
                if (e.target.id == '2fa-email-primary-remove-btn') {
                    self.vm.ItemDetail.TwoFactorPrimaryEmail('');
                }
                else {
                    self.vm.ItemDetail.TwoFactorSecondaryEmail('');
                }
            });
        });
        $('#2fa-email-back-btn').on('click', function (e) {
            e.preventDefault();
            self.vm.EditAuthenticatorInfo.Id = 0;
            HGV.Modal['change-two-factor-email'].close();
        });
        $('#2fa-sms-back1-btn').on('click', function (e) {
            e.preventDefault();
            HGV.Modal['change-two-factor-sms'].close();
        });
        $('#2fa-sms-back2-btn').on('click', function (e) {
            e.preventDefault();
            //clear any error message if required
            document.getElementById("twoFactorSMSErrorMessage").innerHTML = "";
            self.vm.ItemDetail.TwoFactorStep(1);
        });
        $('.2fa-sms-add').on('click', function (e) {
            self.vm.ItemDetail.TwoFactorStep(1);
            document.getElementById("twoFactorSMSErrorMessage").innerHTML = "";
            if (e.target.id == '2fa-sms-primary-add-btn')
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Primary;
            else
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Secondary;
        });
        $('.2fa-sms-remove').on('click', function (e) {
            //set the action
            e.preventDefault();
            self.editAction = "RemoveAuthenticator";
            //set the auth type
            self.vm.EditAuthenticatorInfo.Type = AuthType.SMS;
            //set the contact type
            if (e.target.id == '2fa-sms-primary-remove-btn')
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Primary;
            else
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Secondary;
            //prepare
            var postData = mapper.toJs(self.vm.EditAuthenticatorInfo);
            //execute
            var promise = self.post(postData);
            //validate
            promise.done(function (data) {
                if (e.target.id == '2fa-sms-primary-remove-btn') {
                    self.vm.ItemDetail.TwoFactorPrimaryPhoneNumber('');
                }
                else {
                    self.vm.ItemDetail.TwoFactorSecondaryPhoneNumber('');
                }
            });
        });
        //Clicking on continue to add two factor for SMS
        $('.2fa-sms-continue').on('click', function (e) {
            //set the action
            e.preventDefault();
            _this.editAction = "Generate2FASMSAuthNumber";
            //clear any error message if required
            document.getElementById("twoFactorSMSErrorMessage").innerHTML = "";
            if (_this.vm.ItemDetail.EditTwoFactorPhoneNumber() != null && _this.vm.ItemDetail.EditTwoFactorPhoneNumber().length > 0) {
                //set the phone number
                _this.vm.EditAuthenticatorInfo.SMSPhoneNumber(_this.vm.TwoFactorCountryCode() + ' ' + _this.vm.ItemDetail.EditTwoFactorPhoneNumber());
            }
            //contact type should have been already set
            //prepare
            var postData = mapper.toJs(_this.vm.EditAuthenticatorInfo);
            //execute
            var promise = _this.post(postData);
            //validate
            promise.done(function (data) {
                _this.vm.ItemDetail.TwoFactorStep(2);
                document.getElementById("twoFactorSMSErrorMessage").innerHTML = "";
                HGV.Modal['change-two-factor'].open();
            });
            promise.fail(function (failData) {
                _this.vm.IsLoading(false);
                document.getElementById("twoFactorSMSErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
                HGV.Modal['change-two-factor'].open();
            });
        });
        $('.2fa-app-add').on('click', function (e) {
            self.vm.EditAuthenticatorInfo.Name("");
            self.vm.ItemDetail.TwoFactorStep(1);
            if (e.target.id == '2fa-app-primary-add-btn')
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Primary;
            else
                self.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Secondary;
        });
        //clicking on saveing the two factor for SMS
        $('#2fa-sms-submit-btn').on('click', function (e) {
            //set the action
            e.preventDefault();
            _this.editAction = "Save2FASMSAuthenticator";
            //set the type            
            _this.vm.EditAuthenticatorInfo.Type = AuthType.SMS;
            //values like AuthContactType & AuthCode should be already available in EditAuthenticatorInfo
            //prepare the data
            var postData = mapper.toJs(_this.vm.EditAuthenticatorInfo);
            //execute
            var promise = _this.post(postData);
            promise.done(function (data) {
                //display sucess message                
                if (_this.vm.EditAuthenticatorInfo.ContactType == AuthContactType.Primary) {
                    _this.vm.ItemDetail.TwoFactorPrimaryPhoneNumber(_this.vm.EditAuthenticatorInfo.SMSPhoneNumber());
                }
                else {
                    _this.vm.ItemDetail.TwoFactorSecondaryPhoneNumber(_this.vm.EditAuthenticatorInfo.SMSPhoneNumber());
                }
                //clean up
                document.getElementById("twoFactorSMSErrorMessage").innerHTML = "";
                _this.vm.ItemDetail.EditTwoFactorPhoneNumber("");
                _this.vm.EditAuthenticatorInfo.SMSPhoneNumber("");
                HGV.Modal['change-two-factor-sms'].close();
                HGV.Modal['view-two-factor'].close();
            });
            promise.fail(function (failData) {
                _this.vm.IsLoading(false);
                document.getElementById("twoFactorSMSErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            });
            _this.vm.EditAuthenticatorInfo.AuthCode("");
        });
        $('#2fa-app-back1-btn').on('click', function (e) {
            e.preventDefault();
            document.getElementById("twoFactorAppErrorMessage").innerHTML = "";
            HGV.Modal['change-two-factor-app'].close();
        });
        $('#2fa-app-back3-btn').on('click', function (e) {
            e.preventDefault();
            document.getElementById("twoFactorAppErrorMessage").innerHTML = "";
            self.vm.ItemDetail.TwoFactorStep(2);
        });
        $('#2fa-app-continue2-btn').on('click', function (e) {
            e.preventDefault();
            document.getElementById("twoFactorAppErrorMessage").innerHTML = "";
            self.vm.EditAuthenticatorInfo.AuthCode("");
            self.vm.ItemDetail.TwoFactorStep(3);
        });
        $('.2fa-app-activate').on('click', function (e) {
            e.preventDefault();
            document.getElementById("authimage").innerHTML = "";
            document.getElementById("twoFactorAppErrorMessage").innerHTML = "";
            $("#sharedkey").text('');
            var authdata = [];
            if (e.target.id == '2fa-app-primary-activate-btn' && self.vm.ItemDetail.InActivePrimaryAuthData() != null) {
                authdata = self.vm.ItemDetail.InActivePrimaryAuthData().split('|');
            }
            else if (self.vm.ItemDetail.InActiveSecondaryAuthData() != null) {
                authdata = self.vm.ItemDetail.InActiveSecondaryAuthData().split('|');
            }
            if (authdata != null && authdata.length > 0) {
                self.vm.EditAuthenticatorInfo.Id(authdata[0]);
                $("#authimage").append("<img src='" + authdata[3] + "?size=120x120&data=" + authdata[2] + "' alt='qr' />");
                $("#sharedkey").text(authdata[1]);
                self.vm.ItemDetail.TwoFactorStep(2);
            }
        });
        $('.2fa-app-remove').on('click', function (e) {
            //set the action
            e.preventDefault();
            _this.editAction = "RemoveAuthenticator";
            //set the auth type
            _this.vm.EditAuthenticatorInfo.Type = AuthType.App;
            //set the contact type
            if (e.target.id == '2fa-app-primary-remove-btn')
                _this.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Primary;
            else
                _this.vm.EditAuthenticatorInfo.ContactType = AuthContactType.Secondary;
            //prepare
            var postData = mapper.toJs(_this.vm.EditAuthenticatorInfo);
            //execute
            var promise = _this.post(postData);
            //validate
            promise.done(function (data) {
                if (_this.vm.EditAuthenticatorInfo.ContactType == AuthContactType.Primary) {
                    _this.vm.ItemDetail.TwoFactorPrimaryAuthApp('');
                    _this.vm.ItemDetail.InActivePrimaryAuthData('');
                }
                else {
                    _this.vm.ItemDetail.TwoFactorSecondaryAuthApp('');
                    _this.vm.ItemDetail.InActiveSecondaryAuthData('');
                }
                HGV.Modal['change-two-factor'].open();
            });
        });
        $('#2fa-app-continue-btn').on('click', function (e) {
            e.preventDefault();
            //empty before fetching
            document.getElementById("authimage").innerHTML = "";
            document.getElementById("twoFactorAppErrorMessage").innerHTML = "";
            $("#sharedkey").text('');
            var postData = mapper.toJs(_this.vm);
            _this.editAction = "Generate2FAAppAuthCode";
            var promise = _this.post(postData);
            promise.done(function (data) {
                _this.vm.ItemDetail.TwoFactorStep(2);
                _this.vm.EditAuthenticatorInfo.Id(data.Id);
                _this.vm.EditAuthenticatorInfo.Name(data.Name);
                if (_this.vm.EditAuthenticatorInfo.ContactType == AuthContactType.Primary)
                    _this.vm.ItemDetail.InActivePrimaryAuthData(data.Id + '|' + data.SharedKey + '|' + data.AuthenticatorUri + '|' + data.QRCodeURL);
                else
                    _this.vm.ItemDetail.InActiveSecondaryAuthData(data.Id + '|' + data.SharedKey + '|' + data.AuthenticatorUri + '|' + data.QRCodeURL);
                $("#authimage").append("<img src='" + data.QRCodeURL + "?size=120x120&data=" + data.AuthenticatorUri + "' alt='qr' />");
                $("#sharedkey").text(data.SharedKey);
                HGV.Modal['change-two-factor'].open();
            });
            promise.fail(function (failData) {
                _this.vm.IsLoading(false);
                var data = failData.responseJSON;
                document.getElementById("twoFactorAppErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            });
        });
        $('#copySSK').on('click', function (e) {
            var copyText = document.getElementById("sharedkey");
            var textArea = document.createElement("textarea");
            textArea.value = copyText.textContent;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("Copy");
            textArea.remove();
        });
        $('#2fa-app-submit-btn').on('click', function (e) {
            //set the action
            e.preventDefault();
            _this.editAction = "Save2FAAuthenticatorCode";
            document.getElementById("twoFactorAppErrorMessage").innerHTML = "";
            //set the type            
            _this.vm.EditAuthenticatorInfo.Type = AuthType.App;
            //values like AuthContactType & AuthCode should be already available in EditAuthenticatorInfo
            //prepare the data
            var postData = mapper.toJs(_this.vm.EditAuthenticatorInfo);
            //execute
            var promise = _this.post(postData);
            promise.done(function (data) {
                if (_this.vm.EditAuthenticatorInfo.ContactType == AuthContactType.Primary) {
                    _this.vm.ItemDetail.TwoFactorPrimaryAuthApp(_this.vm.EditAuthenticatorInfo.Name());
                    _this.vm.ItemDetail.InActivePrimaryAuthData('');
                }
                else {
                    _this.vm.ItemDetail.TwoFactorSecondaryAuthApp(_this.vm.EditAuthenticatorInfo.Name());
                    _this.vm.ItemDetail.InActiveSecondaryAuthData('');
                }
                HGV.Modal['change-two-factor-app'].close();
                HGV.Modal['view-two-factor'].close();
            });
            promise.fail(function (failData) {
                _this.vm.IsLoading(false);
                var data = failData.responseJSON;
                document.getElementById("twoFactorAppErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            });
            //clean up
            self.vm.EditAuthenticatorInfo.AuthCode("");
        });
        $('#two-factor-back-to-phone').on('click', function (e) {
            e.preventDefault();
            self.vm.ItemDetail.TwoFactorStep(2);
        });
        $('#two-factor-agree-btn').on('click', function (e) {
            e.preventDefault();
            self.putTwoFactorAuth(true);
        });
        $('#two-factor-decline-btn').on('click', function (e) {
            e.preventDefault();
            self.putTwoFactorAuth(false);
        });
        //Clicking on save primary account info
        $('#edit-account-save').on('click', function (e) {
            e.preventDefault();
            _this.editAction = "EditAccountInfo";
            var editAccount = _this.vm.EditAccountInfo;
            var postData = mapper.toJs(editAccount);
            var promise = _this.post(postData);
            promise.done(function (data) {
                HGV.Modal['edit-account-info'].close();
                _this.vm.UpdatedSuccessfully();
                //refresh the details with updated information;
                var primaryAccountName = _this.vm.EditAccountInfo.FirstName() + " " + _this.vm.EditAccountInfo.LastName();
                _this.vm.ItemDetail.PrimaryAccountName(primaryAccountName.trim());
                $('#GlobalUserFirstName').text(_this.vm.EditAccountInfo.FirstName());
                //this.getDetail(null);  //refresh the details with updated information;
            });
        });
        //Clicking on update password
        $('#save-new-password').on('click', function (e) {
            e.preventDefault();
            _this.editAction = "UpdatePassword";
            var updatePassword = _this.vm.UpdatePassword;
            var postData = mapper.toJs(updatePassword);
            var promise = _this.post(postData);
            promise.done(function (data) {
                HGV.Modal['change-password'].close();
                _this.resetUpdatePassword();
                _this.vm.UpdatedSuccessfully();
            });
        });
        $('#update-new-username').on('click', function (e) {
            e.preventDefault();
            _this.editAction = "UpdateUserName";
            var updateUserName = _this.vm.UpdateUsername;
            var postData = mapper.toJs(updateUserName);
            var promise = _this.post(postData);
            promise.done(function (data) {
                _this.vm.UpdatedSuccessfully();
                _this.vm.ItemDetail.CurrentUsername(data.UpdateUsername.NewUserName);
                HGV.Modal['change-username'].close();
                _this.resetUpdateUserName();
                _this.vm.UpdateUsername.ShowUserNameError(false);
                _this.vm.UpdateUsername.ShowUserNameLengthError(false);
                _this.vm.UpdateUsername.ShowUserNameRequiredError(false);
                _this.vm.UpdateUsername.ShowUserNameAlphaRequiredError(false);
                _this.vm.UpdateUsername.ShowUserNameExistsError(false);
                _this.vm.UpdateUsername.UserNameExistsErrorMessage('');
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                self.vm.IsLoading(false);
                _this.vm.UpdateUsername.ShowUserNameError(data.UpdateUsername.ShowUserNameError);
                _this.vm.UpdateUsername.ShowUserNameLengthError(data.UpdateUsername.ShowUserNameLengthError);
                _this.vm.UpdateUsername.ShowUserNameRequiredError(data.UpdateUsername.ShowUserNameRequiredError);
                _this.vm.UpdateUsername.ShowUserNameAlphaRequiredError(data.UpdateUsername.ShowUserNameAlphaRequiredError);
                _this.vm.UpdateUsername.ShowUserNameExistsError(data.UpdateUsername.ShowUserNameExistsError);
                _this.vm.UpdateUsername.UserNameExistsErrorMessage(data.UpdateUsername.UserNameExistsErrorMessage);
            });
        });
        $('#twoFactorEnabledCheck')
            .on('click', 'input[type=checkbox]', function (e) {
            e.preventDefault();
            if ($(this).prop("checked")) {
                self.vm.ItemDetail.TwoFactorElect(true);
            }
            else {
                self.vm.ItemDetail.TwoFactorElect(false);
            }
        });
        $('#save-two-factor-auth-selection').on('click', function (e) {
            e.preventDefault();
            document.getElementById("twoFactor_auth_Selection_ErrorMessage").innerHTML = "";
            _this.editAction = "UpdateTwoFactor";
            var accountDetail = _this.vm.ItemDetail;
            accountDetail.TwoFactorElect(_this.vm.ItemDetail.EditTwoFactorEnabled());
            var postData = mapper.toJs(accountDetail);
            var promise = _this.post(postData);
            promise.done(function (data) {
                HGV.Modal['update-two-factor-selection'].close();
                _this.vm.ItemDetail.TwoFactorAuthIsEnabled(_this.vm.ItemDetail.EditTwoFactorEnabled());
                _this.vm.UpdatedSuccessfully();
            });
            promise.fail(function (failData) {
                self.vm.IsLoading(false);
                var data = failData.responseJSON;
                document.getElementById("twoFactor_auth_Selection_ErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
                // HGV.Modal['change-two-factor'].open();//$('#change-two-factor-modal').show();
                HGV.Modal['update-two-factor-selection'].open();
            });
        });
        $('#2fa-app-device-name').on('keydown', function (e) {
            if (self.vm.EditAuthenticatorInfo.Name() != "" && document.getElementById("twoFactorAppErrorMessage").innerHTML != "") {
                document.getElementById("twoFactorAppErrorMessage").innerHTML = "";
            }
        });
        $('#2fa-app-code').on('keydown', function (e) {
            if (self.vm.EditAuthenticatorInfo.AuthCode() != "" && document.getElementById("twoFactorAppErrorMessage").innerHTML != "") {
                document.getElementById("twoFactorErrorMessage").innerHTML = "";
            }
        });
        //Clicking on update two factortwoFactorErrorMessage
        $('#save-two-factor-auth-settings').on('click', function (e) {
            document.getElementById("twoFactorErrorMessage").innerHTML = "";
            e.preventDefault();
            _this.editAction = "TwoFactorPhoneNumber";
            var accountDetail = _this.vm.ItemDetail;
            var postData = mapper.toJs(accountDetail);
            postData.TwoFactorPhoneNumber = _this.vm.ItemDetail.EditTwoFactorPhoneNumber();
            postData.TwoFactorCountryId = _this.vm.ItemDetail.EditTwoFactorPhoneCountryId();
            var promise = _this.post(postData);
            promise.done(function (data) {
                HGV.Modal['update-two-factor'].open();
                _this.vm.RequiresValidationToUpdate(true);
            });
            promise.fail(function (failData) {
                self.vm.IsLoading(false);
                var data = failData.responseJSON;
                document.getElementById("twoFactorErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
                //$('#change-two-factor-modal').show();
                HGV.Modal['update-two-factor'].open();
            });
        });
        $('#two-factor-edit-validate-code')
            .on('click', function (e) {
            e.preventDefault();
            _this.editAction = "EditTwoFactorSettings";
            document.getElementById("twoFactorErrorMessage").innerHTML = "";
            var accountDetail = _this.vm.ItemDetail;
            var postData = mapper.toJs(accountDetail);
            var promise = _this.post(postData);
            promise.done(function (data) {
                HGV.Modal['update-two-factor'].close();
                _this.vm.UpdatedSuccessfully();
                _this.vm.RequiresValidationToUpdate(false);
                _this.vm.ItemDetail.TwoFactorCountryId(_this.vm.ItemDetail.EditTwoFactorPhoneCountryId());
                _this.vm.ItemDetail.TwoFactorPhoneNumber(_this.vm.ItemDetail.EditTwoFactorPhoneNumber());
            });
            promise.fail(function (failData) {
                self.vm.IsLoading(false);
                var data = failData.responseJSON;
                document.getElementById("twoFactorErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
                //$('#change-two-factor-modal').show();
                HGV.Modal['update-two-factor'].open();
            });
        });
        $('#two-factor-phone-submit').on('click', function (e) {
            e.preventDefault();
            self.putTwoFactorPhoneNumber();
        });
        $('#two-factor-resend-code-account-initial-signup-modal')
            .on('click', function (e) {
            e.preventDefault();
            self.resendTwoFactorCodeInitialSignUpAccount();
        });
        $('#two-factor-resend-code-account-modal')
            .on('click', function (e) {
            e.preventDefault();
            self.resendTwoFactorCodeAccount();
        });
        $('#two-factor-sign-in').on('click', function (e) {
            e.preventDefault();
            self.validateTwoFactorCode();
        });
        $('.link-to-edit-lang-preferance').on('click', function (e) {
            _this.vm.UpdateLanguage.UpdatedLanguageCode(_this.vm.ItemDetail.CurrentLeadLanguageCode());
            _this.vm.UpdateLanguage.UpdatedLanguageName(_this.vm.ItemDetail.LanguageName());
        });
        $('#lang-dropdown').on('change', function (event, selectedItem) {
            var langCode = $(selectedItem.prevObject).attr('data-lang-id');
            var langName = $(selectedItem.prevObject).attr('data-lang-name');
            var cultCode = $(selectedItem.prevObject).attr('data-culture-code');
            self.vm.UpdateLanguage.UpdatedLanguageCode = cultCode;
            self.vm.UpdateLanguage.UpdatedLanguageName = langName;
            self.vm.UpdateLanguage.UpdatedLangid = langCode;
        });
        //Clicking on update langaguage
        $('.update-lang-pref').on('click', function (e) {
            e.preventDefault();
            _this.editAction = "UpdateLanguage";
            var updateLanguage = _this.vm.UpdateLanguage;
            var postData = mapper.toJs(updateLanguage);
            var promise = _this.post(postData);
            var lang = updateLanguage.UpdatedLanguageCode;
            var validLanguageCodes = _.map(ko.toJS(_this.vm.SiteLanguages), 'Id');
            var reloadPage = false;
            //replace current language with new from valid top language selector list
            if (validLanguageCodes.indexOf(lang) > -1) {
                var urlParts = window.location.pathname.split('/');
                var firstPart = urlParts[1];
                var host = window.location.hostname.split('.').reverse();
                var domain = (host[1] ? (host[1] + '.') : '') + host[0];
                urlParts[1] = lang;
                Cookies.remove('_culture');
                Cookies.set('_culture', firstPart, { expires: 3650, domain: domain });
                reloadPage = true;
            }
            promise.done(function (data) {
                HGV.Modal['edit-Language-preference'].close();
                _this.vm.ItemDetail.LanguageCode(data.UpdateLanguage.UpdatedLangid);
                _this.vm.ItemDetail.LanguageName(data.UpdateLanguage.UpdatedLanguageName);
                _this.vm.ItemDetail.CurrentLeadLanguageCode(data.UpdateLanguage.UpdatedLanguageCode);
                if (reloadPage) {
                    var newUrl = urlParts.join('/').replace(/\/$/, '');
                    window.location.pathname = newUrl;
                }
                _this.vm.UpdatedSuccessfully();
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                _super.prototype.postPromiseFail.call(_this, data);
            });
        });
        $('#invite-new-user').on('click', function (e) {
            e.preventDefault();
            _this.editAction = "InviteNewUser";
            var inviteNewUser = _this.vm.InviteNewUser;
            inviteNewUser.CurrentLanguage(_this.vm.CurrentLanguageCode());
            var postData = mapper.toJs(inviteNewUser);
            var promise = _this.post(postData);
            promise.done(function (data) {
                _this.vm.UpdatedSuccessfully();
                _this.resetInviteUser();
                _this.getDetail(null); //refresh the details with updated information;
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                _this.vm.UpdateFailed(data.ItemDetail.ErrorMessage);
            });
            promise.always(function () { return HGV.Modal['invite-user'].close(); });
        });
        //pop up the remove invited user modal
        $('.invited-users-list').on('click', '.remove-invited-user', function (e) {
            e.preventDefault();
            var userToRemove = ko.dataFor(e.currentTarget);
            _this.vm.InvitedUserToRemove(userToRemove);
            HGV.Modal['remove-invited-user'].open();
        });
        //clicking confirm on the remove invited user modal
        $('#remove-invited-modal').on('click', '.confirm-remove-invited', function (e) {
            e.preventDefault();
            _this.editAction = "RemoveInvitedUser";
            if (_this.vm.InvitedUserToRemove() == null) {
                HGV.Modal['remove-invited-user'].close();
                return;
            }
            var postData = mapper.toJs(_this.vm.InvitedUserToRemove);
            var promise = _this.post(postData);
            promise.done(function (data) {
                _this.vm.UpdatedSuccessfully();
                _this.resetInviteUser();
                _this.getDetail(null); //refresh the details with updated information;
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                _this.vm.UpdateFailed(data.ItemDetail.ErrorMessage);
            });
            promise.always(function () { return HGV.Modal['remove-invited-user'].close(); });
        });
        //Saving updated security questions
        $('#save-security-questions').on('click', function (e) {
            e.preventDefault();
            _this.editAction = "UpdateSecurityQuestions";
            var editSecurityQuestions = _this.vm.EditSecurityQuestions;
            var postData = mapper.toJs(editSecurityQuestions);
            var promise = _this.post(postData);
            promise.done(function (data) {
                HGV.Modal['edit-security-questions'].close();
                editSecurityQuestions.MoreAnswersRequiredError(data.EditSecurityQuestions.MoreAnswersRequiredError);
                _this.getDetail(null); //refresh the details with updated information;
                _this.vm.UpdatedSuccessfully();
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                editSecurityQuestions.MoreAnswersRequiredError(data.EditSecurityQuestions.MoreAnswersRequiredError);
                _this.scrollTop('.side-modal', '.side-modal__heading');
            });
        });
        $('.link-to-edit-email-address').on('click', function (e) {
            if (e.target.id == "edit-email-address") {
                _this.vm.UpdateEmailAddress.UpdatedSecondaryEmail("");
                _this.vm.UpdateEmailAddress.IsUpdateSecondary(false);
                _this.vm.UpdateEmailAddress.CurrentEmailAddress(_this.vm.ItemDetail.PrimaryAccountEmail());
            }
            else {
                _this.vm.UpdateEmailAddress.UpdatedSecondaryEmail(_this.vm.ItemDetail.SecondaryAccountEmail());
                _this.vm.UpdateEmailAddress.IsUpdateSecondary(true);
                _this.vm.UpdateEmailAddress.CurrentEmailAddress(_this.vm.ItemDetail.SecondaryAccountEmail());
            }
        });
        //Saving updated email address
        $('.save-updated-email').on('click', function (e) {
            e.preventDefault();
            _this.editAction = "UpdateEmailAddress";
            _this.vm.UpdateEmailAddress.CurrentLanguageCode(_this.vm.CurrentLanguageCode());
            var updateEmailAddress = _this.vm.UpdateEmailAddress;
            if (_this.vm.UpdateEmailAddress.IsUpdateSecondary()) {
                updateEmailAddress.CurrentEmailAddress(_this.vm.ItemDetail.SecondaryAccountEmail());
                updateEmailAddress.ColumnName = 'EMAIL_ADDRESS1';
            }
            else {
                updateEmailAddress.CurrentEmailAddress(_this.vm.ItemDetail.PrimaryAccountEmail());
                updateEmailAddress.ColumnName = 'EMAIL_ADDRESS';
            }
            var postData = mapper.toJs(updateEmailAddress);
            var promise = _this.post(postData);
            promise.done(function (data) {
                //Don't know if we want to do this as the emails goes into there system!
                if (_this.vm.UpdateEmailAddress.IsUpdateSecondary()) {
                    //this.vm.ItemDetail.SecondaryAccountEmail(this.vm.UpdateEmailAddress.UpdatedSecondaryEmail());
                    if (_this.vm.UpdateEmailAddress.UpdatedSecondaryEmail() !== null && _this.vm.UpdateEmailAddress.UpdatedSecondaryEmail() !== "") {
                        _this.vm.ItemDetail.IsSecondaryEmailExists(true);
                    }
                    else {
                        _this.vm.ItemDetail.IsSecondaryEmailExists(false);
                        _this.vm.ItemDetail.TwoFactorSecondaryEmail('');
                    }
                    //this.vm.ItemDetail.SecondaryAccountEmail(this.vm.UpdateEmailAddress.UpdatedSecondaryEmail());
                    // this.vm.UpdateEmailAddress.ShowEmailAddressExistsError(false);
                    //this.vm.UpdateEmailAddress.EmailAddressExistsErrorMessage('');
                    HGV.Modal['edit-secondary-email-address'].close();
                }
                else {
                    // this.vm.ItemDetail.PrimaryAccountEmail(this.vm.UpdateEmailAddress.UpdatedEmail());
                    HGV.Modal['edit-email-address'].close();
                }
                _this.vm.UpdatedEmailSuccessfully();
                _this.resetUpdateEmail();
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                //this.vm.UpdateEmailAddress.ShowEmailAddressExistsError(data.UpdateEmailAddress.ShowEmailAddressExistsError);
                //this.vm.UpdateEmailAddress.EmailAddressExistsErrorMessage(data.UpdateEmailAddress.EmailAddressExistsErrorMessage);
            });
        });
        $('#edit-profile-picture, #add-profile-picture').on('click', function (e) {
            e.preventDefault();
            $("input[type=file]:hidden").trigger('click'); //trigger the click of the hidden input element
        });
        $('#remove-profile-picture').on('click', function (e) {
            e.preventDefault();
            _this.vm.ItemDetail.ImageUpload(null);
            _this.editAction = "RemoveProfilePicture";
            //
            var promise = _this.post(null);
            promise.done(function (data) {
                _this.vm.UpdatedSuccessfully();
                //refresh the details with updated information;
                $("#GlobalProfilePicture").attr("src", $("#DefaultProfilePicture").attr("src"));
            });
        });
        $("#Account_ReadPolicies").click(function () {
            if ($("#Account_ReadPolicies").is(":checked") && !$("#checkboxError").prop('hidden')) {
                $("#checkboxError").prop('hidden', true);
            }
        });
        $("#chkContainer").click(function () {
            if ($("#Account_ReadPolicies").prop('disabled')) {
                $("#readAllErrorTxt").prop('hidden', false);
            }
        });
        $("#scrollDetails").scroll(function (event) {
            var elmnt = document.getElementById("scrollDetails");
            if (elmnt.offsetHeight + elmnt.scrollTop >= elmnt.scrollHeight) {
                $("#readAllErrorTxt").prop('hidden', true);
                $("#Account_ReadPolicies").prop('disabled', false);
            }
        });
        $('.save-communication-prefs').on('click', function (e) {
            e.preventDefault();
            if ($("#Account_ReadPolicies").is(":visible")) {
                if (!$("#Account_ReadPolicies").is(":checked")) {
                    $("#checkboxError").prop('hidden', false);
                    var object = {
                        Key: "Account.ReadPolicies",
                        ErrorMessage: "< !--The field ReadPolicies must be between True and True. -->"
                    };
                    self.addPropertyError(object);
                    return false;
                }
            }
            if (!$("#checkboxError").prop('hidden')) {
                $("#checkboxError").prop('hidden', true);
            }
            var postData = mapper.toJs(_this.vm.ManageCommunications);
            _this.editAction = "UpdateHoaCommunicationPreferences";
            var promise = _this.post(postData);
            promise.done(function (data) {
                _this.vm.UpdatedCommPreferencesSuccessfully();
            });
        });
        //Resetting the screens when the links are clicked again?
        $(".side-modal__close").on("click", function (e) {
            //e
            var id = $(this).data('target');
            self.resetModals(id);
        });
        $("#change-username").on("click", function (e) {
            self.resetUpdateUserName();
        });
        $(".side-modal-container").on("click", function (e) {
            //e
            if ($(this).hasClass("is-open")) {
                return;
            }
            var id = $(this).attr('id');
            self.resetModals("#" + id);
        });
        $('#county-list li').on('click', function () {
            alert($(this).val());
            this.vm.EditMembershipInfo.County($(this).val());
        });
        //User Story 650681: One Console R1.1 (Production): Add SMS opt-in to Primary phone number
        $('#allowTextCall').on('click', function () {
            if ($("#allowTextCall").is(":checked")) {
                $("#SMSConsentScrollbox").prop('hidden', false); // Show the scrollbox
            }
            else {
                $("#ReadnAgreeConsent").prop('checked', false); // Reset Consentbox
                self.vm.ItemDetail.SmsConsent1(false);
                self.vm.EditMembershipInfo.SmsConsent1(false);
            }
        });
        $('#LearnMoreLink').on('click', function (e) {
            if ($("#SMSConsentScrollbox").prop('hidden')) {
                $("#SMSConsentScrollbox").prop('hidden', false);
            }
            else {
                $("#SMSConsentScrollbox").prop('hidden', true); // Hide the scrollbox
            }
        });
        $("#consentScrollBox").scroll(function (event) {
            var elmnt = document.getElementById("consentScrollBox");
            if (elmnt.offsetHeight + elmnt.scrollTop >= elmnt.scrollHeight) {
                $("#ReadnAgreeConsent").prop('disabled', false);
            }
        });
        //User Story 650681: One Console R1.1 (Production): Add SMS opt-in to secondary phone number
        $('#allowTextCallSecond').on('click', function () {
            if ($("#allowTextCallSecond").is(":checked")) {
                $("#SMSConsentScrollbox2").prop('hidden', false); // Show the scrollbox
            }
            else {
                $("#ReadnAgreeConsent2").prop('checked', false); // Reset Consentbox
                self.vm.ItemDetail.SmsConsent2(false);
                self.vm.EditMembershipInfo.SmsConsent2(false);
            }
        });
        $('#LearnMoreLink2').on('click', function (e) {
            if ($("#SMSConsentScrollbox2").prop('hidden')) {
                $("#SMSConsentScrollbox2").prop('hidden', false);
            }
            else {
                $("#SMSConsentScrollbox2").prop('hidden', true); // Hide the scrollbox
            }
        });
        $("#consentScrollBox2").scroll(function (event) {
            var elmnt = document.getElementById("consentScrollBox2");
            if (elmnt.offsetHeight + elmnt.scrollTop >= elmnt.scrollHeight) {
                $("#ReadnAgreeConsent2").prop('disabled', false);
            }
        });
        $('#edit-membership-info').on('click', function (e) {
            if (_this.vm.EditMembershipInfo.SmsConsent1()) {
                $("#allowTextCall").prop('checked', true);
            }
            else {
                $("#allowTextCall").prop('checked', false);
            }
            if (_this.vm.EditMembershipInfo.SmsConsent2()) {
                $("#allowTextCallSecond").prop('checked', true);
            }
            else {
                $("#allowTextCallSecond").prop('checked', false);
            }
        });
        //Update the state list when the country changes
        this.vm.EditMembershipInfo.CountryId.subscribe(function (newValue) {
            var country = ko.utils.arrayFirst(_this.vm.Meta.AllCountries, function (country) {
                return country.Id === newValue;
            });
            _this.vm.EditMembershipInfo.County(null);
            _this.vm.EditMembershipInfo.State(null);
            _this.vm.EditMembershipInfo.Province(null);
            _this.vm.EditMembershipInfo.AllCounties.removeAll();
            _this.vm.EditMembershipInfo.AllStates.removeAll();
            _this.vm.EditMembershipInfo.AllProvinces.removeAll();
            //if (country == null || !country.HasStateDropdown) {
            //    this.vm.EditMembershipInfo.AllStates.removeAll();
            // this.vm.EditMembershipInfo.State(null);
            //}
            var promise = _this.getAllRegions(country.IsoAlpha3);
            promise.done(function (allRegions) {
                if (allRegions.length > 0) {
                    if (allRegions[0].Type == "State") {
                        _this.vm.EditMembershipInfo.County(null);
                        _this.vm.EditMembershipInfo.Province(null);
                        _this.vm.EditMembershipInfo.AllCounties.removeAll();
                        _this.vm.EditMembershipInfo.AllProvinces.removeAll();
                        _this.vm.EditMembershipInfo.AllStates(allRegions);
                        var state = ko.utils.arrayFirst(allRegions, function (state) {
                            return state.Id === _this.vm.ItemDetail.MembershipAddress.State();
                        });
                        if (state == null) {
                            _this.vm.EditMembershipInfo.State(allRegions[0].Id); //set it to the first Province if no valid current Province is selected
                            //$("#province-list .dropdown__menu li a")[0].click();
                        }
                        else {
                            _this.vm.EditMembershipInfo.State(state.Id); //trigger a change so the Province is selected from the toolkit dropdown
                            var regionName = _this.vm.ItemDetail.MembershipAddress.CityStateZip().split(",")[1].split(" ")[1].replace(_this.vm.EditMembershipInfo.State(), state.Name);
                            _this.vm.ItemDetail.MembershipAddress.CityStateZip(_this.vm.ItemDetail.MembershipAddress.City() + ", " + regionName + " " + _this.vm.ItemDetail.MembershipAddress.ZipCode());
                        }
                    }
                    if (allRegions[0].Type == "Province") {
                        _this.vm.EditMembershipInfo.AllCounties.removeAll();
                        _this.vm.EditMembershipInfo.AllStates.removeAll();
                        _this.vm.EditMembershipInfo.AllProvinces(allRegions);
                        _this.vm.EditMembershipInfo.County(null);
                        _this.vm.EditMembershipInfo.State(null);
                        var province = ko.utils.arrayFirst(allRegions, function (province) {
                            return province.Id === _this.vm.ItemDetail.MembershipAddress.State();
                        });
                        if (province == null) {
                            _this.vm.EditMembershipInfo.Province(allRegions[0].Id); //set it to the first Province if no valid current Province is selected
                            //$("#province-list .dropdown__menu li a")[0].click();
                        }
                        else {
                            _this.vm.EditMembershipInfo.Province(province.Id); //trigger a change so the Province is selected from the toolkit dropdown
                            var regionName = _this.vm.ItemDetail.MembershipAddress.CityStateZip().split(",")[1].split(" ")[1].replace(_this.vm.EditMembershipInfo.Province(), province.Name);
                            _this.vm.ItemDetail.MembershipAddress.CityStateZip(_this.vm.ItemDetail.MembershipAddress.City() + ", " + regionName + " " + _this.vm.ItemDetail.MembershipAddress.ZipCode());
                            //  $("#province-list .dropdown__menu li a[data-val=" + province.Id + "]")[0].click();
                        }
                    }
                    if (allRegions[0].Type == "County") {
                        _this.vm.EditMembershipInfo.AllProvinces.removeAll();
                        _this.vm.EditMembershipInfo.AllStates.removeAll();
                        _this.vm.EditMembershipInfo.State(null);
                        _this.vm.EditMembershipInfo.Province(null);
                        _this.vm.EditMembershipInfo.AllCounties(allRegions);
                        var county = ko.utils.arrayFirst(allRegions, function (county) {
                            return county.Id === _this.vm.ItemDetail.MembershipAddress.State();
                        });
                        if (county == null) {
                            _this.vm.EditMembershipInfo.County(allRegions[0].Id); //set it to the first Province if no valid current Province is selected
                            //$("#province-list .dropdown__menu li a")[0].click();
                        }
                        else {
                            _this.vm.EditMembershipInfo.County(county.Id); //trigger a change so the Province is selected from the toolkit dropdown
                            var regionName = _this.vm.ItemDetail.MembershipAddress.CityStateZip().split(",")[1].split(" ")[1].replace(_this.vm.EditMembershipInfo.County(), county.Name);
                            _this.vm.ItemDetail.MembershipAddress.CityStateZip(_this.vm.ItemDetail.MembershipAddress.City() + ", " + regionName + " " + _this.vm.ItemDetail.MembershipAddress.ZipCode());
                        }
                    }
                }
            });
        });
    };
    AccountForm.prototype.saveMemberProfilePicture = function (element, result) {
        var _this = this;
        //update the image to the new result
        var url = result.ImageSizes[0].Url;
        this.vm.ItemDetail.ImageUpload(url);
        //save the new dto back to the server to save it for the current user
        this.editAction = "UpdateProfilePicture";
        var postData = mapper.toJs(result);
        var promise = this.post(postData);
        promise.done(function (data) {
            _this.vm.UpdatedSuccessfully();
            //refresh the details with updated information;
            $('#GlobalProfilePicture').attr("src", url);
        });
    };
    AccountForm.prototype.getAllStates = function (countryId) {
        var countryIdDto = { 'countryId': countryId };
        var promise = $.ajax({
            url: this.area + "/" + this.vm.CurrentLanguageCode() + "/address/GetStates",
            data: JSON.stringify(countryIdDto),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        return promise;
    };
    AccountForm.prototype.getAllRegions = function (countryName) {
        var countryIdDto = { 'countryName': countryName };
        var promise = $.ajax({
            url: this.area + "/" + this.vm.CurrentLanguageCode() + "/address/GetPreferencesRegionLocalized",
            data: JSON.stringify(countryIdDto),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        return promise;
    };
    AccountForm.prototype.resendTwoFactorCodeAccount = function () {
        var _this = this;
        this.editAction = "TwoFactorResendCode";
        document.getElementById("twoFactorErrorMessage").innerHTML = "";
        this.vm.ItemDetail.TwoFactorCountryId(this.vm.ItemDetail.EditTwoFactorPhoneCountryId());
        this.vm.ItemDetail.EditTwoFactorPhoneNumber(this.vm.ItemDetail.EditTwoFactorPhoneNumber());
        var accountDetail = this.vm.ItemDetail;
        var postData = mapper.toJs(accountDetail);
        var promise = this.post(postData);
        promise.done(function (data) {
            _this.vm.IsLoading(false);
        });
        promise.fail(function (failData) {
            _this.vm.IsLoading(false);
            var data = failData.responseJSON;
            document.getElementById("twoFactorErrorMessage").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            HGV.Modal['update-two-factor'].open();
        });
    };
    AccountForm.prototype.resendTwoFactorCodeInitialSignUpAccount = function () {
        var _this = this;
        this.editAction = "TwoFactorResendCode";
        document.getElementById("twoFactorErrorMessage_Initial_Signup").innerHTML = "";
        this.vm.ItemDetail.TwoFactorCountryId(this.vm.ItemDetail.TwoFactorPhoneNumber());
        this.vm.ItemDetail.EditTwoFactorPhoneNumber(this.vm.ItemDetail.EditTwoFactorPhoneNumber());
        var accountDetail = this.vm.ItemDetail;
        var postData = mapper.toJs(accountDetail);
        var promise = this.post(postData);
        promise.done(function (data) {
            _this.vm.IsLoading(false);
        });
        promise.fail(function (failData) {
            //self.vm.IsLoading(false);
            var data = failData.responseJSON;
            document.getElementById("twoFactorErrorMessage_Initial_Signup").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            HGV.Modal['change-two-factor'].open();
        });
    };
    AccountForm.prototype.validateTwoFactorCode = function () {
        var _this = this;
        this.editAction = "ValidateCode";
        document.getElementById("twoFactorErrorMessage_Initial_Signup").innerHTML = "";
        var accountDetail = this.vm.ItemDetail;
        var postData = mapper.toJs(accountDetail);
        var promise = this.post(postData);
        promise.done(function (data) {
            HGV.Modal['change-two-factor'].close();
            _this.vm.ItemDetail.TwoFactorStep(1);
            _this.vm.ItemDetail.TwoFactorAuthIsEnabled(true);
            _this.vm.ItemDetail.EditTwoFactorEnabled(true);
            _this.vm.ItemDetail.TwoFactorPhoneNumber(_this.vm.ItemDetail.TwoFactorPhoneNumber());
        });
        promise.fail(function (failData) {
            _this.vm.IsLoading(false);
            var data = failData.responseJSON;
            document.getElementById("twoFactorErrorMessage_Initial_Signup").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            HGV.Modal['change-two-factor'].open();
        });
    };
    AccountForm.prototype.putTwoFactorPhoneNumber = function () {
        var _this = this;
        this.editAction = "TwoFactorPhoneNumber";
        document.getElementById("twoFactorErrorMessage_Initial_Signup").innerHTML = "";
        var accountDetail = this.vm.ItemDetail;
        var postData = mapper.toJs(accountDetail);
        var promise = this.post(postData);
        promise.done(function (data) {
            HGV.Modal['change-two-factor'].open();
            _this.vm.ItemDetail.TwoFactorStep(3);
        });
        promise.fail(function (failData) {
            _this.vm.IsLoading(false);
            var data = failData.responseJSON;
            document.getElementById("twoFactorErrorMessage_Initial_Signup").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            HGV.Modal['change-two-factor'].open();
        });
    };
    AccountForm.prototype.putTwoFactorAuth = function (election) {
        var _this = this;
        this.vm.ItemDetail.TwoFactorElect(election);
        this.editAction = "UpdateTwoFactor";
        var accountDetail = this.vm.ItemDetail;
        var postData = mapper.toJs(accountDetail);
        var promise = this.post(postData);
        promise.done(function (data) {
            if (election == false) {
                HGV.Modal['change-two-factor'].close();
            }
            else if (election = true) {
                HGV.Modal['change-two-factor'].open();
                _this.vm.ItemDetail.TwoFactorStep(2);
            }
        });
        promise.fail(function (error) {
        });
    };
    AccountForm.prototype.postPromiseDone = function (data) {
        this.vm.IsLoading(false);
    };
    AccountForm.prototype.getPromiseDone = function (data, id) {
        this.vm.ItemDetailIsNew(false);
        this.komappingfromdata(data, id);
        ;
        this.vm.IsLoading(false);
        this.vm.FormVisible(true);
    };
    AccountForm.prototype.komappingfromdata = function (data, id) {
        var copyMapping = {
            'copy': this.data.KOMapping.allNonPostBack
        };
        mapper.fromJsWithOptions(data.ItemDetail, copyMapping, this.vm.ItemDetail);
        mapper.fromJsWithOptions(data.EditMembershipInfo, copyMapping, this.vm.EditMembershipInfo);
        mapper.fromJsWithOptions(data.UpdateEmailAddress, copyMapping, this.vm.UpdateEmailAddress);
        mapper.fromJsWithOptions(data.EditAccountInfo, copyMapping, this.vm.EditAccountInfo);
        mapper.fromJsWithOptions(data.EditSecurityQuestions, copyMapping, this.vm.EditSecurityQuestions);
        mapper.fromJsWithOptions(data.ManageCommunications, copyMapping, this.vm.ManageCommunications);
        mapper.fromJsWithOptions(data.GuestCertificates, copyMapping, this.vm.GuestCertificates);
        mapper.fromJsWithOptions(data.MyResortCredits, copyMapping, this.vm.MyResortCredits);
        mapper.fromJsWithOptions(data.MyOpenSeasonCredits, copyMapping, this.vm.MyOpenSeasonCredits);
    };
    AccountForm.prototype.missingDates = function () { };
    AccountForm.prototype.refreshResortData = function () { };
    return AccountForm;
}(FrontEndForm));
module.exports = AccountForm;

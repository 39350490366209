"use strict";
var ko = require("knockout");
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var numeral = require("numeral");
var moment = require("moment");
require("moment/locale/ja");
var HGV = (typeof window !== "undefined" ? window['HGV'] : typeof global !== "undefined" ? global['HGV'] : null);
var mask = (typeof window !== "undefined" ? window['$']['mask'] : typeof global !== "undefined" ? global['$']['mask'] : null);
moment;
mask;
var KoBindingExtensions = /** @class */ (function () {
    function KoBindingExtensions() {
        this.isJapanProperty = false;
    }
    KoBindingExtensions.prototype.register = function () {
        ko.bindingHandlers.hidden = {
            update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                ko.bindingHandlers.visible.update(element, function () { return (!value); }, allBindingsAccessor, viewModel, bindingContext);
            }
        };
        ko.bindingHandlers.fadeVisible = {
            init: function (element, valueAccessor) {
                // Initially set the element to be instantly visible/hidden depending on the value
                var value = valueAccessor();
                $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
            },
            update: function (element, valueAccessor) {
                // Whenever the value subsequently changes, slowly fade the element in or out
                var value = valueAccessor();
                ko.unwrap(value) ? $(element).fadeIn() : $(element).fadeOut();
            }
        };
        ko.subscribable.fn.subscribeChanged = function (callback) {
            var oldValue;
            this.subscribe(function (_oldValue) {
                oldValue = _oldValue;
            }, this, 'beforeChange');
            this.subscribe(function (newValue) {
                callback(newValue, oldValue);
            });
        };
        ko.bindingHandlers.hiddenEx = (function () {
            function setVisibility(element, valueAccessor) {
                var hidden = ko.unwrap(valueAccessor());
                $(element).css('visibility', hidden ? 'hidden' : 'visible');
            }
            return { init: setVisibility, update: setVisibility };
        })();
        ko.bindingHandlers.slideInVisible = {
            init: function (element, valueAccessor) {
                // Initially set the element to be instantly visible/hidden depending on the value
                var value = valueAccessor();
                $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
            },
            update: function (element, valueAccessor) {
                // Whenever the value subsequently changes, slowly fade the element in or out
                var value = valueAccessor();
                var jqueryElement = $(element);
                if (ko.unwrap(value)) {
                    //scroll browser to top to make sure form is in view
                    $('body')
                        .animate({ scrollTop: 0 }, '250', 'swing');
                    //hide
                    //position off screen 
                    //show
                    //slide into position
                    jqueryElement
                        .hide()
                        .animate({ left: -3000 }, 1, 'linear', function () {
                        $(this).show(); //local this
                    })
                        .animate({ left: 0 }, 250);
                }
                else {
                    //slide off screen
                    //hide
                    //reset to 0 position
                    jqueryElement
                        .animate({ left: -3000 }, 500, 'linear', function () {
                        $(this).hide().css('left', '0'); //local this
                    });
                    ;
                }
            }
        };
        ko.bindingHandlers.dateMask = {
            init: function (element, valueAccessor, allBindings, viewModel) {
                var value = valueAccessor();
                var $el = $(element);
                var dateFormat = $el.attr('data-date-format') || "MM/DD/YYYY";
                var maskFormat = dateFormat.replace(/[a-z]/gi, '9'); //format it correctly for the date mask as it has to be in 9's to allow all numbers
                $el.mask(maskFormat);
                ko.utils.registerEventHandler(element, "change", function () {
                    var observable = valueAccessor();
                    observable($el.val());
                });
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                var value = valueAccessor();
                var $el = $(element);
                var valueUnwrapped = ko.utils.unwrapObservable(value);
                var dateFormat = $el.attr('data-date-format') || "MM/DD/YYYY";
                var isValidDate = moment(valueUnwrapped, dateFormat).isValid();
                if (!isValidDate) { //if not a valid date blank it out
                    value(null);
                    $el.val('');
                }
            }
        };
        ko.bindingHandlers.currencyInput = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
                ko.bindingHandlers.value.init(element, valueAccessor, allBindingsAccessor, viewModel, context);
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                var value = valueAccessor();
                var $el = $(element);
                var valueUnwrapped = ko.utils.unwrapObservable(value);
                valueUnwrapped = valueUnwrapped.toString().replace(',', ''); //remove any commas as it breaks parseFloat
                valueUnwrapped = valueUnwrapped.slice(0, 10); //anything longer than 21 digits will return back scientific notation
                var currenyAmount = parseFloat(valueUnwrapped);
                var currencyFormat = viewModel.hasOwnProperty('IsJapanProperty') && viewModel.IsJapanProperty() ? '0,0' :
                    '0,0.00';
                var maxAmount = parseFloat($el.attr('data-max-amount')) || 99999.99;
                if (isNaN(currenyAmount)) {
                    value(0);
                    $el.val('');
                    return;
                }
                if (currenyAmount > maxAmount) { //default to the max amount if they enter in more than it
                    var formattedMax = numeral(maxAmount).format(currencyFormat);
                    value(maxAmount);
                    $el.val(formattedMax);
                    return;
                }
                var formattedUpdated = numeral(currenyAmount).format(currencyFormat);
                value(currenyAmount);
                $el.val(formattedUpdated);
            }
        };
        ko.bindingHandlers.executeOnEnter = {
            init: function (element, valueAccessor, allBindings, viewModel) {
                var callback = valueAccessor();
                $(element).keypress(function (event) {
                    var keyCode = (event.which ? event.which : event.keyCode);
                    if (keyCode === 13) {
                        callback.call(viewModel);
                    }
                    return true;
                });
            }
        };
        ko.bindingHandlers.tkToggle = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var binding = valueAccessor.toString().replace('function (){return ', '').replace('function(){return ', '').replace(' }', ''); //Needs to be the string form of the list (e.g. '$root.Filters.OpenSeason' )
                var trueLabel = allBindings.get('tkToggle_TrueLabel');
                var falseLabel = allBindings.get('tkToggle_FalseLabel');
                var displayTrueFirst = allBindings.get('tkToggle_DisplayTrueFirst') || false;
                var boundObservable = valueAccessor();
                var trueToggle = '<div class="toggle" data-bind="css : { \'is-active\' : ' + binding + '() }">' + trueLabel + '</div>';
                var falseToggle = '<div class="toggle" data-bind="css : { \'is-active\' : !' + binding + '() }">' + falseLabel + '</div>';
                var toggleOrder;
                if (displayTrueFirst) {
                    toggleOrder = trueToggle + falseToggle;
                }
                else {
                    toggleOrder = falseToggle + trueToggle;
                }
                var fullHtml = '    <div class="toggle-group">' +
                    toggleOrder +
                    '    </div>';
                $(element).html(fullHtml);
                //Wire up the click events on drop down as init only wires up those that are present before the foreach binding is rendered
                $(element).on('click', '.toggle', function (e) {
                    boundObservable(!boundObservable());
                });
            }
        };
        var getBindingContextFromString = function (o, s) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, ''); // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in o) {
                    o = o[k];
                }
                else {
                    return;
                }
            }
            return o;
        };
        ko.bindingHandlers.tkDropDown_options = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                try {
                    var optionListBindingText = ko.utils.unwrapObservable(valueAccessor());
                    var optionsList = getBindingContextFromString(bindingContext, optionListBindingText);
                    var selectObject = allBindings.has('tkDropDown_setSelectedObject') && allBindings.get('tkDropDown_setSelectedObject') == true; //Sets if the selected value should return the whole selected object and not the selected objects value
                    var useDefaultValue = !allBindings.has('tkDropDown_optionsValue');
                    var useDefaultText = !allBindings.has('tkDropDown_optionsText');
                    var allowClearProperty = 'tkDropDown_allowClear'; //allows the dropdown to be cleared by clicking on the caption again
                    var optionCaptionProperty = 'tkDropDown_optionsCaption';
                    var optionValue = useDefaultValue ? "$data" : allBindings.get('tkDropDown_optionsValue'); //the property of object in the options list to use for the selected value
                    var optionCaption = allBindings.get(optionCaptionProperty); //The default text of the dropdown with no value
                    var valueForAttr = useDefaultValue ? "$data" : allBindings.get('tkDropDown_optionsValue'); //the property of object in the options list to use for the selected value
                    var optionText = useDefaultText ? "$data" : allBindings.get('tkDropDown_optionsText'); //the property of object to display in the list
                    var selectedValue = allBindings.get('tkDropDown_selectedValue');
                    var allowClear = allBindings.has(allowClearProperty) ? allBindings.get(allowClearProperty) : true; //default to allow the dropdown to be cleared if not set
                    var formControl = $(element).hasClass('dropdown--stretch') ? "form-control" : ""; //adds the correct border for the dropdowns
                    //Sets the dropdown label to the intial value if set otherwise the specified
                    var unwrappedInitialValue = ko.utils.unwrapObservable(selectedValue);
                    var unwrappedOptionsList = ko.utils.unwrapObservable(optionsList);
                    var defaultLabel = optionCaption;
                    var hasInitialValue = unwrappedInitialValue !== undefined && unwrappedInitialValue !== null;
                    if (hasInitialValue) {
                        if (!useDefaultValue) {
                            //make sure the selected option exists in the list
                            var initialSelected = ko.utils.arrayFirst(unwrappedOptionsList, function (option) {
                                var listOption = ko.utils.unwrapObservable(option[optionValue]);
                                return listOption == unwrappedInitialValue;
                            });
                            if (initialSelected !== undefined && initialSelected !== null) {
                                var selectedOption = ko.utils.unwrapObservable(initialSelected[optionText]);
                                defaultLabel = selectedOption;
                            }
                        }
                        else {
                            defaultLabel = unwrappedInitialValue;
                        }
                    }
                    //add the html. Knockout will recompute this as if the html to a bound element changes it will revalulate. Just adding this html after the element won't work
                    var htmlTemplate_button = "<div data-toggle=\"dropdown\" class=\"dropdown__button " + formControl + " is-touched\">" + defaultLabel + "</div>";
                    var htmlTemplate_ul = '<ul class="dropdown__menu" role="menu">';
                    var htmlTemplate_clearSelection = allowClear ? "<li role=\"presentation\">\n                            <a class=\"clear_dropdown\" data-val=null href=\"#\" role=\"menuitem\" tabindex=\"-1\">" + optionCaption + "</a>\n                        </li>" : "";
                    var htmlTemplateForEachStart = "<!-- ko foreach: " + optionListBindingText + " -->";
                    var htmlTemplate_li = "<li role=\"presentation\">\n                            <a class=\"select_option\" href=\"#\" role=\"menuitem\" tabindex=\"-1\"  data-bind=\"text: " + optionText + ", attr: { 'data-val' : " + valueForAttr + " }\"></a>\n                        </li>";
                    var htmlTemplateForEachEnd = "<!-- /ko -->";
                    var htmlTemplate_ulclose = '</ul>';
                    var fullHtml = htmlTemplate_button + htmlTemplate_ul +
                        htmlTemplate_clearSelection +
                        htmlTemplateForEachStart +
                        htmlTemplate_li +
                        htmlTemplateForEachEnd +
                        htmlTemplate_ulclose;
                    $(element).html(fullHtml);
                    //init the dropdown control   
                    HGV.Dropdown[element.id] = new HGV.Constructors.Dropdown(element);
                    var dropDown = HGV.Dropdown[element.id];
                    //Wire up the click events on drop down as init only wires up those that are present before the foreach binding is rendered
                    $(element).on('click touchstart', '.dropdown__menu a.select_option', function (e) {
                        var selected = ko.dataFor(e.currentTarget);
                        var dataValue = useDefaultValue || selectObject ? selected : selected[optionValue];
                        dataValue = ko.utils.unwrapObservable(dataValue);
                        if (selected != null)
                            selectedValue(dataValue);
                    });
                    $(element).on('click touchstart', '.dropdown__menu a.clear_dropdown', function (e) {
                        selectedValue(null);
                    });
                    //subscribe to value binding to set selected when observable changes
                    selectedValue.subscribe(function (value) {
                        var id = value;
                        if (!useDefaultValue) {
                            if (value) {
                                if (value[optionValue])
                                    id = ko.utils.unwrapObservable(value[optionValue]);
                            }
                            else {
                                id = null;
                            }
                        }
                        var selectedLi = $(element).find('[data-val="' + id + '"]')[0]; //this needs to allow for null as if they clear the list we need to display the caption again
                        if (selectedLi != undefined) {
                            dropDown.select(selectedLi);
                        }
                    });
                }
                catch (e) {
                    //  console.log('error binding', element, valueAccessor, allBindings, viewModel, bindingContext);
                }
            }
            /*end binding*/
        };
        ko.bindingHandlers.slideAlert = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                $(element).slideDown();
                //auto remove success messages after 3 seconds
                if (viewModel.Type === 'alert-success') {
                    setTimeout(function () {
                        $(element).slideUp();
                        bindingContext.$root.Alerts.remove(viewModel);
                    }, 3000);
                }
            }
        };
        ko.bindingHandlers.currentLanguageLink = {
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                ko.bindingHandlers.attr.update(element, function () {
                    var lang = '';
                    try {
                        var langObservable = bindingContext.$root['CurrentLanguageCode'];
                        lang = langObservable !== undefined ? '/' + langObservable() : '';
                    }
                    catch (e) { }
                    return { href: lang + valueAccessor() };
                }, allBindings, viewModel, bindingContext);
            }
        };
        ko.bindingHandlers.currentLanguageResortLink = {
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var unwrapped = ko.utils.unwrapObservable(valueAccessor());
                var regionSlug = unwrapped.regionSlug;
                var slug = unwrapped.slug;
                var query = unwrapped.queryParams;
                var queryParams = '';
                if (query !== undefined) {
                    queryParams = "" + query();
                }
                ko.bindingHandlers.attr.update(element, function () {
                    var lang = '';
                    try {
                        var langObservable = bindingContext.$root['CurrentLanguageCode'];
                        lang = langObservable !== undefined ? '/' + langObservable() : '';
                    }
                    catch (e) { }
                    var url = lang + "/resort/" + regionSlug() + "/" + slug() + "/" + queryParams;
                    return { href: url };
                }, allBindings, viewModel, bindingContext);
            }
        };
        ko.bindingHandlers.href = {
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                ko.bindingHandlers.attr.update(element, function () {
                    return { href: valueAccessor() };
                }, allBindings, viewModel, bindingContext);
            }
        };
        ko.bindingHandlers.html = {
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());
                if (valueUnwrapped !== null && valueUnwrapped !== undefined && valueUnwrapped.length > 0) {
                    //Trying to decode html string (security fix encodes this during a round trip)
                    if (valueUnwrapped.indexOf('&lt;') >= 0 && valueUnwrapped.indexOf('&gt;') >= 0) {
                        valueUnwrapped = $('<div/>').html(valueUnwrapped).text();
                    }
                }
                $(element).html(valueUnwrapped);
            }
        };
        ko.bindingHandlers.backGroundImage = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var value = valueAccessor();
                value = ko.utils.unwrapObservable(value);
                if (!value || value.match(/^ *$/) !== null) {
                    $(element).css("backgroundImage", "none"); //if the value is null or blank remove the background image
                }
                else {
                    $(element).css("backgroundImage", "url(\'" + value + "\')");
                }
            },
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var value = valueAccessor();
                value = ko.utils.unwrapObservable(value);
                if (!value || value.match(/^ *$/) !== null) { //if the value is null or blank remove the background image
                    $(element).css("backgroundImage", "none");
                }
                else {
                    $(element).css("backgroundImage", "url(\'" + value + "\')");
                }
            }
        };
        ko.bindingHandlers.datetime = {
            update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                var value = valueAccessor();
                var allBindings = allBindingsAccessor();
                var valueUnwrapped = ko.utils.unwrapObservable(value);
                // Date formats: http://momentjs.com/docs/#/displaying/format/
                var elementFormat = $(element).attr('data-format');
                var pattern = elementFormat || allBindings.format || 'MM/DD/YYYY';
                var culture = allBindings.culture || 'en';
                var output = "";
                if (valueUnwrapped !== null && valueUnwrapped !== undefined && valueUnwrapped.length > 0) {
                    var momentObject;
                    if (valueUnwrapped.indexOf('Z') == valueUnwrapped.length - 1) { //by default momentjs converts UTC to local and we want it to stay UTC if selected
                        momentObject = moment.utc(valueUnwrapped);
                    }
                    else {
                        momentObject = moment(valueUnwrapped);
                    }
                    momentObject.locale(culture);
                    output = momentObject.format(pattern);
                }
                if ($(element).is("input") === true) {
                    $(element).val(output);
                }
                else {
                    $(element).text(output);
                }
            }
        };
        var formatNumber = function (element, valueAccessor, allBindingsAccessor, format, isAbs) {
            if (isAbs === void 0) { isAbs = false; }
            // Provide a custom text value
            var value = valueAccessor(), allBindings = allBindingsAccessor();
            value = isAbs ? Math.abs(value) : value;
            var numeralFormat = allBindingsAccessor.numeralFormat || format;
            var strNumber = ko.utils.unwrapObservable(value);
            if (typeof strNumber === 'undefined' || strNumber === null) {
                return '';
            }
            return numeral(strNumber).format(numeralFormat);
        };
        var formatCurrency = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext, format, isAbs, isNegative) {
            if (isAbs === void 0) { isAbs = false; }
            if (isNegative === void 0) { isNegative = false; }
            var value = valueAccessor(), allBindings = allBindingsAccessor();
            value = isAbs ? Math.abs(value) : value;
            var numeralFormat = allBindingsAccessor.numeralFormat || format;
            var strNumber = ko.utils.unwrapObservable(value);
            if (typeof strNumber === 'undefined' || strNumber === null) {
                return '';
            }
            if (viewModel.hasOwnProperty('IsJapanProperty') && viewModel.IsJapanProperty() || bindingContext.$root.ItemDetail.hasOwnProperty('IsJapanProperty') && bindingContext.$root.ItemDetail.IsJapanProperty()) {
                strNumber = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(strNumber);
            }
            else {
                strNumber = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(strNumber);
            }
            if (isNegative) {
                strNumber = "-" + strNumber;
            }
            return strNumber;
        };
        ko.bindingHandlers.numeralText = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                $(element).text(formatNumber(element, valueAccessor, allBindingsAccessor, "0,0"));
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                $(element).text(formatNumber(element, valueAccessor, allBindingsAccessor, "0,0"));
            }
        };
        ko.bindingHandlers.numeralTextAbs = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                $(element).text(formatNumber(element, valueAccessor, allBindingsAccessor, "0,0", true));
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                $(element).text(formatNumber(element, valueAccessor, allBindingsAccessor, "0,0", true));
            }
        };
        ko.bindingHandlers.currencyText = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                $(element).text(formatCurrency(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext, "$0,0.00"));
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                $(element).text(formatCurrency(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext, "$0,0.00"));
            }
        };
        ko.bindingHandlers.currencyTextNegative = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                $(element).text(formatCurrency(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext, "$0,0.00", false, true));
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                $(element).text(formatCurrency(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext, "$0,0.00", false, true));
            }
        };
        ko.bindingHandlers.currencyTextNoDollar = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                $(element).text(formatNumber(element, valueAccessor, allBindingsAccessor, "0,0.00"));
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                $(element).text(formatNumber(element, valueAccessor, allBindingsAccessor, "0,0.00"));
            }
        };
        ko.bindingHandlers.creditCardEntry = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
                ko.bindingHandlers.value.init(element, valueAccessor, allBindingsAccessor, viewModel, context);
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                var value = valueAccessor();
                var $el = $(element);
                var valueUnwrapped = ko.utils.unwrapObservable(value);
                if (valueUnwrapped == null || valueUnwrapped.indexOf('HTS') === 0) { //if it's already tokenized don't do anything
                    return;
                }
                var validDisplay = valueUnwrapped.replace(/  +/g, ' ') //get rid of more than 1 space
                    .replace(/-+/g, '-') //get rid of more than 1 dash
                    .replace(/[^0-9\- ]/g, ""); //get rid of anything but a number, dash or space
                var validDigitsOnly = valueUnwrapped.replace(/\D/g, ""); //get rid of anything that is not a numeric digit
                value(validDigitsOnly);
                $el.val(validDisplay);
            }
        };
        ko.bindingHandlers.characterCount = {
            update: function (element, valueAccessor, allBindings, bindingContext) {
                var length = 0;
                if (allBindings().value()) {
                    var text = ko.unwrap(allBindings().value());
                    var maxLength = ko.unwrap(valueAccessor());
                    if (text !== undefined && text !== null) {
                        length = text.length;
                        // add in new-line characters
                        length += (text.match(/\n/g) || []).length;
                        if (length > maxLength) {
                            // check if the last character is a new-line
                            var trim = text.match(/\n$/) ? 1 : 0;
                            text = text.substring(0, maxLength - trim);
                            allBindings().value(text);
                            length = text.length;
                        }
                    }
                }
                $(element).siblings('.character-count-wrapper').first().children('.characters-used').html(length.toString());
            }
        };
        ko.bindingHandlers.authorByline = {
            update: function (element, valueAccessor, allBindings, bindingContext) {
                var review = bindingContext;
                var format = ko.unwrap(valueAccessor());
                var byline = format.replace(/\{0\}/g, review.ReviewerName).replace(/\{1\}/g, formatReviewDate(review.CreatedDate));
                $(element).text(byline);
            }
        };
        ko.bindingHandlers.numericoscamount = {
            init: function (element, valueAccessor) {
                $(element).on("input", function () {
                    var value = $(this).val();
                    value = value.replace(/[^0-9.]/g, "");
                    valueAccessor()(value);
                });
            },
            update: function (element, valueAccessor) {
                var value = ko.unwrap(valueAccessor());
                $(element).val(value);
            }
        };
        ko.bindingHandlers.responseByline = {
            update: function (element, valueAccessor, allBindings, bindingContext) {
                var review = bindingContext;
                if (review.PublishResponse) {
                    var format = ko.unwrap(valueAccessor());
                    var byline = format.replace(/\{0\}/g, formatReviewDate(review.ResponseDateTime));
                    $(element).text(byline);
                }
            }
        };
        function formatReviewDate(date) {
            var pattern = 'MM/DD/YYYY';
            if (date.indexOf('Z') == date.length - 1) { //by default momentjs converts UTC to local and we want it to stay UTC if selected
                return moment.utc(date).format(pattern);
            }
            else {
                return moment(date).format(pattern);
            }
        }
        ko.bindingHandlers.textOrDefault = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                $(element).text(textOrDefault(element, valueAccessor, allBindingsAccessor));
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                $(element).text(textOrDefault(element, valueAccessor, allBindingsAccessor));
            }
        };
        var textOrDefault = function (element, valueAccessor, allBindingsAccessor) {
            var value = valueAccessor();
            var valueUnwrapped = ko.utils.unwrapObservable(value);
            var returnValue = (!valueUnwrapped || valueUnwrapped === " ") ? "--" : valueUnwrapped;
            return returnValue;
        };
    };
    return KoBindingExtensions;
}());
module.exports = KoBindingExtensions;

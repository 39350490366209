"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: UpdateYourInformation
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/VMBase");
var Table = require("../Base/Table");
var UpdateYourInformationForm = require("./UpdateYourInformationForm");
var UpdateYourInformation = /** @class */ (function (_super) {
    __extends(UpdateYourInformation, _super);
    function UpdateYourInformation(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, UpdateYourInformationForm, Table, null) || this;
        _this.data = data;
        _this.form.getDetail(null);
        ko.applyBindings(_this);
        $('.hideUnbound').removeClass('hideUnbound');
        return _this;
    }
    return UpdateYourInformation;
}(base.VmBase));
module.exports = UpdateYourInformation;

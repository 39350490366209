"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: Account
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVmBase");
var koupload = require("../Base/KoBindingFileUpload");
new koupload().init();
var AccountRouter = require("./AccountRouter");
var AccountForm = require("./AccountForm");
var AccountTable = require("./AccountTable");
var PasswordComplexity = require("../Base/PasswordComplexity");
var UserNameComplexity = require("../Base/UserNameComplexity");
var Account = /** @class */ (function (_super) {
    __extends(Account, _super);
    //ShowEmailErrorExistsError: KnockoutObservable<boolean>;
    //EmailAddressExistsErrorMessage: KnockoutObservable<string>;
    function Account(data) {
        var _this_1 = 
        //call base class constructor
        _super.call(this, data, AccountForm, AccountTable, AccountRouter) || this;
        _this_1.data = data;
        _this_1.RequiresValidationToUpdate = ko.observable(false);
        _this_1.InvitedUserToRemove = ko.observable(null);
        _this_1.ShowManageCommunication = ko.observable(false);
        _this_1.ShowMyResortCredits = ko.observable(false);
        _this_1.ShowMyOpenSeasonCredits = ko.observable(false);
        _this_1.ShowGuestCertificates = ko.observable(false);
        _this_1.ShowSuccessMessage = ko.observable(false);
        _this_1.ShowEmailSuccessMessage = ko.observable(false);
        _this_1.ShowSuccessUpdatedHOAPreferences = ko.observable(false);
        _this_1.setupChangePasswordBindings();
        _this_1.setupChangeUserNameBindings();
        _this_1.setupSecurityQuestionsBindings();
        _this_1.TwoFactorCountryCode = ko.observable('');
        _this_1.ShowUserNameError = ko.observable(false);
        _this_1.ShowUserNameLengthError = ko.observable(false);
        _this_1.ShowUserNameRequiredError = ko.observable(false);
        _this_1.ShowUserNameExistsError = ko.observable(false);
        _this_1.ShowUserNameAlphaRequiredError = ko.observable(false);
        _this_1.ShowErrorMessage = ko.observable(false);
        //this.ShowEmailErrorExistsError = ko.observable(false);
        _this_1.ErrorMessage = ko.observable('');
        _this_1.UserNameExistsErrorMessage = ko.observable('');
        //this.EmailAddressExistsErrorMessage = ko.observable('');
        _this_1.CurrentUsername = ko.observable('');
        _this_1.CurrentSelectedCreditYear = ko.observable(new Date().getFullYear());
        _this_1.MyOpenSeasonCreditYears = ko.computed(function () {
            var self = _this_1;
            var distinctY = [];
            for (var i = 0; i < self.MyOpenSeasonCredits().length; i++) {
                var year = new Date(self.MyOpenSeasonCredits()[i].EffectiveDateStart()).getFullYear();
                if (distinctY.indexOf(year) == -1) {
                    distinctY.push(year);
                }
            }
            return distinctY;
        });
        _this_1.FilteredOpenSeasonCreditsGroupBy = ko.computed(function () {
            var self = _this_1;
            return ko.utils.arrayFilter(self.MyOpenSeasonCredits(), function (item) {
                return new Date(item.EffectiveDateStart()).getFullYear() == self.CurrentSelectedCreditYear();
            });
        });
        _this_1.picker.setupCalendarBindings(_this_1, 'TopDatePicker');
        $('.hideUnbound').removeClass('hideUnbound');
        var _this = _this_1;
        $("#openseasoncredits-list").on('click touchstart', '.dropdown__menu a.select_option', function (e) {
            _this.CurrentSelectedCreditYear($(e.currentTarget).val());
        });
        _this_1.form.getDetail(null);
        _this_1.ItemDetail.TwoFactorRequiresSetup = ko.computed(function () {
            if (_this_1.ItemDetail.TwoFactorPrimaryEmail() !== null && _this_1.ItemDetail.TwoFactorPrimaryEmail().length > 0)
                return false;
            if (_this_1.ItemDetail.TwoFactorSecondaryEmail() !== null && _this_1.ItemDetail.TwoFactorSecondaryEmail().length > 0)
                return false;
            if (_this_1.ItemDetail.TwoFactorPrimaryPhoneNumber() !== null && _this_1.ItemDetail.TwoFactorPrimaryPhoneNumber().length > 0)
                return false;
            if (_this_1.ItemDetail.TwoFactorSecondaryPhoneNumber() !== null && _this_1.ItemDetail.TwoFactorSecondaryPhoneNumber().length > 0)
                return false;
            if (_this_1.ItemDetail.TwoFactorPrimaryAuthApp() !== null && _this_1.ItemDetail.TwoFactorPrimaryAuthApp().length > 0)
                return false;
            if (_this_1.ItemDetail.TwoFactorSecondaryAuthApp() !== null && _this_1.ItemDetail.TwoFactorSecondaryAuthApp().length > 0)
                return false;
            return true;
        });
        ko.applyBindings(_this_1);
        return _this_1;
    }
    Account.prototype.setupChangePasswordBindings = function () {
        var _this_1 = this;
        this.UpdatePassword.PasswordMeetsMinimumLength = ko.computed(function () {
            return PasswordComplexity.MeetsMinimumLength(_this_1.UpdatePassword.NewPassword());
        });
        this.UpdatePassword.PasswordMeetsMaximumLength = ko.computed(function () {
            return PasswordComplexity.MeetsMaximumLength(_this_1.UpdatePassword.NewPassword());
        });
        this.UpdatePassword.PasswordHasUppercaseLetter = ko.computed(function () {
            return PasswordComplexity.HasUppercaseLetter(_this_1.UpdatePassword.NewPassword());
        });
        this.UpdatePassword.PasswordHasNumberOrSpecialChar = ko.computed(function () {
            return PasswordComplexity.HasNumberOrSpecialChar(_this_1.UpdatePassword.NewPassword());
        });
    };
    Account.prototype.setupChangeUserNameBindings = function () {
        var _this_1 = this;
        this.UpdateUsername.UserNameMeetsMinimumLength = ko.computed(function () {
            return UserNameComplexity.MeetsMinimumLength(_this_1.UpdateUsername.NewUserName());
        });
        this.UpdateUsername.UserNameHasUppercaseLetter = ko.computed(function () {
            return UserNameComplexity.HasUppercaseLetter(_this_1.UpdateUsername.NewUserName());
        });
        this.UpdateUsername.UserNameHasNumberOrSpecialChar = ko.computed(function () {
            return UserNameComplexity.HasNumberOrSpecialChar(_this_1.UpdateUsername.NewUserName());
        });
    };
    Account.prototype.setupSecurityQuestionsBindings = function () {
        var editQuestions = this.EditSecurityQuestions;
        editQuestions.SelectedQuestionIds = ko.computed(function () {
            var qs = [];
            for (var i = 1; i <= 5; i++) {
                var questionId = editQuestions['SecurityAnswer' + i].QuestionId();
                if (questionId !== 0) {
                    qs.push(questionId); //get list of all selected question Ids    
                }
            }
            return qs;
        });
        this.WireupSecurityQuestionLists();
        this.WireupClearingAnswersOnQuestionChange();
    };
    //Clears the entered answer if the security question is changed
    Account.prototype.WireupClearingAnswersOnQuestionChange = function () {
        var editQuestions = this.EditSecurityQuestions;
        editQuestions.SecurityAnswer1.QuestionId.subscribe(function () {
            editQuestions.SecurityAnswer1.Answer("");
        });
        editQuestions.SecurityAnswer1.QuestionId.subscribe(function () {
            editQuestions.SecurityAnswer1.Answer("");
        });
        editQuestions.SecurityAnswer2.QuestionId.subscribe(function () {
            editQuestions.SecurityAnswer2.Answer("");
        });
        editQuestions.SecurityAnswer3.QuestionId.subscribe(function () {
            editQuestions.SecurityAnswer3.Answer("");
        });
        editQuestions.SecurityAnswer1.QuestionId.subscribe(function () {
            editQuestions.SecurityAnswer1.Answer("");
        });
        editQuestions.SecurityAnswer1.QuestionId.subscribe(function () {
            editQuestions.SecurityAnswer1.Answer("");
        });
    };
    //Wire up the disctint question lists for each security question. Selecting one question will remove it from all the other lists.
    Account.prototype.WireupSecurityQuestionLists = function () {
        var _this_1 = this;
        var editQuestions = this.EditSecurityQuestions;
        editQuestions.SecurityAnswer1.QuestionList = ko.computed(function () {
            return _this_1.GetDistinctSecurityQuestions(editQuestions.SecurityAnswer1);
        });
        editQuestions.SecurityAnswer2.QuestionList = ko.computed(function () {
            return _this_1.GetDistinctSecurityQuestions(editQuestions.SecurityAnswer2);
        });
        editQuestions.SecurityAnswer3.QuestionList = ko.computed(function () {
            return _this_1.GetDistinctSecurityQuestions(editQuestions.SecurityAnswer3);
        });
        editQuestions.SecurityAnswer4.QuestionList = ko.computed(function () {
            return _this_1.GetDistinctSecurityQuestions(editQuestions.SecurityAnswer4);
        });
        editQuestions.SecurityAnswer5.QuestionList = ko.computed(function () {
            return _this_1.GetDistinctSecurityQuestions(editQuestions.SecurityAnswer5);
        });
    };
    Account.prototype.GetDistinctSecurityQuestions = function (currentQuestion) {
        var editQuestions = this.EditSecurityQuestions;
        return ko.utils.arrayFilter(editQuestions.AllQuestions(), function (question) {
            return editQuestions.SelectedQuestionIds().indexOf(question.Id()) === -1
                || question.Id() === currentQuestion.QuestionId();
        });
    };
    Account.prototype.UpdateFailed = function (errorMessage) {
        var _this_1 = this;
        this.form.scrollTop();
        this.ErrorMessage(errorMessage);
        this.ShowErrorMessage(true);
        setTimeout(function () {
            _this_1.ShowErrorMessage(false);
        }, 4000);
    };
    Account.prototype.UpdatedSuccessfully = function () {
        var _this_1 = this;
        this.form.scrollTop();
        this.ShowSuccessMessage(true);
        setTimeout(function () {
            _this_1.ShowSuccessMessage(false);
        }, 4000);
    };
    Account.prototype.UpdatedEmailSuccessfully = function () {
        var _this_1 = this;
        this.form.scrollTop();
        this.ShowEmailSuccessMessage(true);
        setTimeout(function () {
            _this_1.ShowEmailSuccessMessage(false);
        }, 10000);
    };
    Account.prototype.UpdatedCommPreferencesSuccessfully = function () {
        var _this_1 = this;
        this.form.scrollTop();
        this.ShowSuccessUpdatedHOAPreferences(true);
        setTimeout(function () {
            _this_1.ShowSuccessUpdatedHOAPreferences(false);
        }, 4000);
    };
    Account.prototype.GetYearFromDate = function (date) {
        return new Date(date()).getFullYear();
    };
    return Account;
}(base.ResortSearchVmBase));
module.exports = Account;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var LoginForm = /** @class */ (function (_super) {
    __extends(LoginForm, _super);
    function LoginForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    LoginForm.prototype.mapForgotEmailDetail = function (data) {
        mapper.fromJsWithOptions(data.ForgotEmailDetail, {}, this.vm.ForgotEmailDetail);
    };
    LoginForm.prototype.mapForgotPasswordDetail = function (data) {
        mapper.fromJsWithOptions(data.ForgotPasswordDetail, {}, this.vm.ForgotPasswordDetail);
    };
    LoginForm.prototype.mapResetPasswordDetail = function (data) {
        mapper.fromJsWithOptions(data.ResetPasswordDetail, {}, this.vm.ResetPasswordDetail);
    };
    LoginForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('.login_form').on('click', '#login_wrapper #log-in', function (e) {
            e.preventDefault();
            var enteredEmailAddress = $('#ItemDetail_EmailAddress').val();
            var enteredPassword = $('#ItemDetail_Password').val();
            if (enteredEmailAddress.length > 0 && enteredEmailAddress !== self.vm.ItemDetail.EmailAddress()) // Manually trigger a change for KO to get the updated values as some password managers won't trigger the change event
                self.vm.ItemDetail.EmailAddress(enteredEmailAddress);
            if (enteredPassword.length > 0 && enteredPassword !== self.vm.ItemDetail.Password())
                self.vm.ItemDetail.Password(enteredPassword);
            self.editAction = "Authenticate";
            var promise = _this.post();
            promise.done(function (data) {
                $('#loading').height($(window).height());
                self.vm.IsLoading(true);
                self.vm.IsLoadingTransaction(true);
                window.location.href = data.ReturnUrl;
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                _this.mapPostData(data);
                if (data.ItemDetail.TwoFactorAuthRequired) {
                    var loginUrl = '#/two-factor?{{EMAIL}}';
                    var url = loginUrl.replace('{{EMAIL}}', self.vm.ItemDetail.EmailAddress() == null ? '' : 'email=' + self.vm.ItemDetail.EmailAddress());
                    parent.location.hash = url;
                }
                else if (data.ItemDetail.TwoFactorAuthRequiresSetup) {
                    HGV.Modal['change-two-factor'].open();
                }
                if (typeof (window.grecaptcha) !== "undefined") {
                    window.grecaptcha.reset(); //reset the captcha
                }
            });
            return false;
        });
        $('.login_form').on('keyup', '#ItemDetail_Password', function (e) {
            if (e.keyCode === 13) {
                $('#log-in').click();
            }
        });
        $('.login_form').on('keyup', '#forgot_email_wrapper .input-forgot-email', function (e) {
            if (e.keyCode === 13) {
                $('#forgot_email_wrapper #find-account').click();
            }
        });
        $('.login_form').on('click', '#two-factor-phone-submit', function (e) {
            e.preventDefault();
        });
        $('.login_form').on('click', '#two-factor-resend-code', function (e) {
            e.preventDefault();
            document.getElementById("twoFactor_Login_Error").innerHTML = "";
            self.editAction = "TwoFactorResendCode";
            var promise = self.post();
            // var forgotEmailDetail = self.vm.ForgotEmailDetail;
            promise.done(function (data) {
                self.mapForgotEmailDetail(data);
                self.vm.IsLoading(false);
            });
            promise.fail(function (failData) {
                self.vm.IsLoading(false);
                var data = failData.responseJSON;
                document.getElementById("twoFactor_Login_Error").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            });
            /* if (forgotEmailDetail.AccountFound() && !forgotEmailDetail.NoAccountFoundError()) {
                 var $headerText = $('#forgot_email_wrapper #account-found-header-template');
                 $("#forgot_email_wrapper #account-lookup-header").text($headerText.text());

                 var $message = $('#forgot_email_wrapper #account-found-template'); //replace the masked email address in the error message
                 var messageText = $message.text().replace('{0}', forgotEmailDetail.UserMaskedEmail());
                 forgotEmailDetail.AccountFoundMessage(messageText);
             } else {
                 var $headerText = $('#forgot_email_wrapper #account-notfound-header-template');
                 $("#forgot_email_wrapper #account-lookup-header").text($headerText.text());

                 var $msg = $('#forgot_email_wrapper #account-notfound-template');
                 forgotEmailDetail.AccountFoundMessage($msg.text());
                 forgotEmailDetail.AccountFound(true);
                 forgotEmailDetail.NoAccountFoundError(true);
                 forgotEmailDetail.EmailAssociated(true);
             }*/
        });
        $('.login_form').on('click', '#two_factor_wrapper #two-factor-sign-in', function (e) {
            e.preventDefault();
            document.getElementById("twoFactor_Login_Error").innerHTML = "";
            self.editAction = "TwoFactor";
            var promise = self.post();
            // var forgotEmailDetail = self.vm.ForgotEmailDetail;
            promise.done(function (data) {
                self.mapForgotEmailDetail(data);
                self.vm.IsLoading(false);
                self.vm.DataLayer.push({
                    'event': 'twoFactor'
                });
                self.editAction = "Authenticate";
                var promiseCont = self.post();
                promiseCont.done(function (data) {
                    $('#loading').height($(window).height());
                    self.vm.IsLoading(true);
                    self.vm.IsLoadingTransaction(true);
                    window.location.href = data.ReturnUrl;
                });
                /* if (forgotEmailDetail.AccountFound() && !forgotEmailDetail.NoAccountFoundError()) {
                     var $headerText = $('#forgot_email_wrapper #account-found-header-template');
                     $("#forgot_email_wrapper #account-lookup-header").text($headerText.text());

                     var $message = $('#forgot_email_wrapper #account-found-template'); //replace the masked email address in the error message
                     var messageText = $message.text().replace('{0}', forgotEmailDetail.UserMaskedEmail());
                     forgotEmailDetail.AccountFoundMessage(messageText);
                 } else {
                     var $headerText = $('#forgot_email_wrapper #account-notfound-header-template');
                     $("#forgot_email_wrapper #account-lookup-header").text($headerText.text());

                     var $msg = $('#forgot_email_wrapper #account-notfound-template');
                     forgotEmailDetail.AccountFoundMessage($msg.text());
                     forgotEmailDetail.AccountFound(true);
                     forgotEmailDetail.NoAccountFoundError(true);
                     forgotEmailDetail.EmailAssociated(true);
                 }*/
            });
            promise.fail(function (failData) {
                self.vm.IsLoading(false);
                var data = failData.responseJSON;
                document.getElementById("twoFactor_Login_Error").innerHTML = failData.responseJSON.ItemDetail.ErrorMessage;
            });
        });
        $('.login_form').on('click', '#forgot_email_wrapper #find-account', function (e) {
            e.preventDefault();
            self.editAction = "ForgotEmail";
            var promise = self.post();
            var forgotEmailDetail = self.vm.ForgotEmailDetail;
            promise.done(function (data) {
                self.mapForgotEmailDetail(data);
                self.vm.DataLayer.push({
                    'event': 'forgotEmail'
                });
                if (forgotEmailDetail.AccountFound() && !forgotEmailDetail.NoAccountFoundError()) {
                    var $headerText = $('#forgot_email_wrapper #account-found-header-template');
                    $("#forgot_email_wrapper #account-lookup-header").text($headerText.text());
                    var $message = $('#forgot_email_wrapper #account-found-template'); //replace the masked email address in the error message
                    var messageText = $message.text().replace('{0}', forgotEmailDetail.UserMaskedEmail());
                    forgotEmailDetail.AccountFoundMessage(messageText);
                }
                else {
                    var $headerText = $('#forgot_email_wrapper #account-notfound-header-template');
                    $("#forgot_email_wrapper #account-lookup-header").text($headerText.text());
                    var $msg = $('#forgot_email_wrapper #account-notfound-template');
                    forgotEmailDetail.AccountFoundMessage($msg.text());
                    forgotEmailDetail.AccountFound(true);
                    forgotEmailDetail.NoAccountFoundError(true);
                    forgotEmailDetail.EmailAssociated(true);
                }
            });
            promise.fail(function (data) {
                self.mapForgotEmailDetail(data.responseJSON);
            });
            return false;
        });
        $('.login_form').on('keyup', '#forgot_password_wrapper .input-enter-email', function (e) {
            if (e.keyCode === 13) {
                $('#forgot_password_wrapper #forgot-password').click();
            }
        });
        $('.login_form').on('keyup', '#forgot_password_wrapper .input-enter-identifier', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                $('#forgot_password_wrapper #identify-membership').click();
            }
        });
        $('.login_form').on('keyup', '#forgot_password_wrapper .input-enter-identifier', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                $('#forgot_password_wrapper #identify-membership').click();
            }
        });
        $('.login_form').on('click', '#forgot_password_wrapper #forgot-password', function (e) {
            e.preventDefault();
            self.editAction = "ForgotPassword";
            var promise = self.post();
            promise.done(function (data) {
                self.vm.DataLayer.push({
                    'event': 'forgotPassword'
                });
                self.mapForgotPasswordDetail(data);
                self.displaySentMessageWithMaskedEmail();
            });
            promise.fail(function (data) {
                self.mapForgotPasswordDetail(data.responseJSON);
            });
            return false;
        });
        $('.login_form').on('click', '#forgot_password_wrapper #identify-membership', function (e) {
            e.preventDefault();
            self.editAction = "ForgotPaswordIdentifyMembership";
            var promise = self.post();
            promise.done(function (data) {
                self.mapForgotPasswordDetail(data);
                self.displaySentMessageWithMaskedEmail();
            });
            promise.fail(function (data) {
                self.mapForgotPasswordDetail(data.responseJSON);
            });
            return false;
        });
        $('#close-modal-btn').on('click', function (e) {
            e.preventDefault();
            HGV.Modal['change-two-factor'].close();
        });
        $('.login_form').on('keyup', '#reset_password_wrapper .input-confirm-password', function (e) {
            if (e.keyCode === 13) {
                $('#reset_password_wrapper #save-new-password').click();
            }
        });
        $('.login_form').on('click', '#reset_password_wrapper #save-new-password', function (e) {
            e.preventDefault();
            self.editAction = "ResetPassword";
            var promise = self.post();
            promise.done(function (data) {
                self.mapResetPasswordDetail(data);
                window.location = data.ReturnUrl;
            });
            promise.fail(function (data) {
                self.mapResetPasswordDetail(data.responseJSON);
            });
            return false;
        });
    };
    LoginForm.prototype.displaySentMessageWithMaskedEmail = function () {
        var forgotPasswordDetail = this.vm.ForgotPasswordDetail;
        if (forgotPasswordDetail.ResetEmailSent()) {
            var $message = $('#forgot_password_wrapper #email-sent-template'); //replace the masked email address in the error message
            var messageText = $message.text().replace('{0}', forgotPasswordDetail.MaskedEmailAddress());
            forgotPasswordDetail.ResetEmailSentMessage(messageText);
        }
    };
    LoginForm.prototype.missingDates = function () { };
    LoginForm.prototype.refreshResortData = function () { };
    return LoginForm;
}(FrontEndForm));
module.exports = LoginForm;

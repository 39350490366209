"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var ResortReviewFrontEndForm = /** @class */ (function (_super) {
    __extends(ResortReviewFrontEndForm, _super);
    function ResortReviewFrontEndForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        _this.editAction = "submit";
        return _this;
    }
    ResortReviewFrontEndForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $("body").on('click', '.btn-open-review-form', function () {
            _this.add();
        });
        $("body").on('click', '#cancel-review', function () {
            _this.vm.FormVisible(false);
        });
    };
    ResortReviewFrontEndForm.prototype.add = function () {
        this.vm.ItemDetailIsNew(true);
        this.vm.FormVisible(true);
    };
    Object.defineProperty(ResortReviewFrontEndForm.prototype, "postUrl", {
        get: function () {
            return "/" + this.vm.CurrentLanguageCode() + "/" + this.vm.FrontEndRoute() + "/" + this.editAction;
        },
        enumerable: false,
        configurable: true
    });
    ResortReviewFrontEndForm.prototype.postPromiseFail = function (error) {
        if (error.status === 400) {
            _super.prototype.mapValidationErrors.call(this, error);
        }
        else {
            this.vm.AddAlert(this.submissionErrorKey, 'error', false);
        }
        this.vm.IsLoading(false);
    };
    ResortReviewFrontEndForm.prototype.postPromiseDone = function (data) {
        this.vm.AddAlert(this.submissionSuccessKey, 'success', false);
        this.vm.UserEligibleToReview(false);
        this.vm.PageInfo.PageNumber(1);
        this.vm.table.load();
        this.vm.IsLoading(false);
        this.vm.FormVisible(false);
    };
    return ResortReviewFrontEndForm;
}(FrontEndForm));
module.exports = ResortReviewFrontEndForm;

"use strict";
var UserNameComplexity = /** @class */ (function () {
    function UserNameComplexity() {
    }
    UserNameComplexity.MeetsMinimumLength = function (username) {
        return username !== null && username !== undefined && username.length >= 8;
    };
    UserNameComplexity.HasUppercaseLetter = function (username) {
        return username !== null && username !== undefined && /[A-Z]/.test(username);
    };
    UserNameComplexity.HasNumberOrSpecialChar = function (username) {
        return (/[0-9]/.test(username)) || /[~`!#@$%\^&*+=\-\[\]\\';,\/{}|\\":<>\?]/.test(username); //special chars to test
    };
    return UserNameComplexity;
}());
module.exports = UserNameComplexity;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVMBase");
var PublicContentPageForm = require("./PublicContentPageForm");
var PublicContentPageTable = require("./PublicContentPageTable");
var HGV = (typeof window !== "undefined" ? window['HGV'] : typeof global !== "undefined" ? global['HGV'] : null);
var ImageGallery = require("../Base/ImageGallery");
var Cookies = require("js-cookie");
var PublicContentPage = /** @class */ (function (_super) {
    __extends(PublicContentPage, _super);
    function PublicContentPage(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, PublicContentPageForm, PublicContentPageTable, null) || this;
        _this.data = data;
        ko.applyBindings(_this);
        _this.initPopupGalleries();
        _this.picker.setupCalendarBindings(_this, 'TopDatePicker');
        $('.hideUnbound').removeClass('hideUnbound');
        HGV.Animation.reset(); //reset the fade-in animations to display correctly
        _this.fillUserFormInfo();
        _this.fillPromoFormInfo();
        _this.initArticleCardAnalytics();
        return _this;
    }
    PublicContentPage.prototype.initPopupGalleries = function () {
        var beforeInit = function () {
            // transform html first to DOM structure the Image Gallery expects
            $('.popup-gallery > img').wrap(function () {
                var $img = $(this);
                return $("<a>")
                    .attr("href", $img.attr("src").replace("/200x200/", "/original/"))
                    .attr("title", $img.attr("title"));
            });
        };
        ImageGallery.Initialize({ beforeInit: beforeInit });
    };
    PublicContentPage.prototype.fillUserFormInfo = function () {
        var cookie = Cookies.get("data_user_sf");
        if (cookie == null) {
            return;
        }
        var cookieValues = jQuery.parseJSON(cookie);
        this.setInputValue("data-user-member-id", cookieValues.MemberId, true);
        this.setInputValue("data-user-member-type", cookieValues.MemberType, true);
        this.setInputValue("data-user-contact-name", cookieValues.ContactName, false);
        this.setInputValue("data-user-first-name", cookieValues.FirstName, false);
        this.setInputValue("data-user-last-name", cookieValues.LastName, false);
        this.setInputValue("data-user-phone-number", cookieValues.PhoneNumber, true);
        this.setInputValue("data-user-email-address", cookieValues.EmailAddress, true);
        this.setInputValue("data-user-hhonors-number", cookieValues.HHonorsNumber, true);
        this.setDropDownValues("data-user-contract-numbers", cookieValues.ContractNumbers);
        this.setVisibility("data-user-visible-if-not-delinquent", cookieValues.InGoodStanding);
        this.setVisibility("data-user-visible-if-delinquent", !cookieValues.InGoodStanding);
    };
    PublicContentPage.prototype.fillPromoFormInfo = function () {
        var cookie = Cookies.get("data_promotion_sf");
        if (cookie == null) {
            return;
        }
        var cookieValues = jQuery.parseJSON(cookie);
        this.setInputValue("data-promotion-member-id", cookieValues.MemberId, true);
        this.setInputValue("data-promotion-member-type", cookieValues.MemberType, true);
        this.setInputValue("data-promotion-confirmation-number", cookieValues.ConfirmationNumber, true);
        this.setInputValue("data-promotion-arrival-date", cookieValues.ArrivalDate, true);
        this.setInputValue("data-promotion-departure-date", cookieValues.DepartureDate, true);
        this.setInputValue("data-promotion-contact-name", cookieValues.ContactName, false);
        this.setInputValue("data-promotion-first-name", cookieValues.FirstName, false);
        this.setInputValue("data-promotion-last-name", cookieValues.LastName, false);
        this.setInputValue("data-promotion-phone-number", cookieValues.PhoneNumber, true);
        this.setInputValue("data-promotion-email-address", cookieValues.EmailAddress, true);
        this.setInputValue("data-promotion-property-number", cookieValues.PropertyNumber, true);
        this.setInputValue("data-promotion-incentive-type", cookieValues.IncentiveType, true);
    };
    PublicContentPage.prototype.initArticleCardAnalytics = function () {
        var _this = this;
        document.querySelectorAll('.article-card > .article-card__content > .button').forEach(function (card) {
            card.addEventListener('click', function (event) {
                var cardContent = event.target.parentElement;
                var articleTitle = cardContent.querySelector('h3').innerText;
                var articleUrl = cardContent.querySelector('a').href;
                _this.Analytics.track("Article Clicked", {
                    event: "article_clicked",
                    url: articleUrl,
                    title: articleTitle,
                    lead_id: _this.User.LeadId(),
                    u_lead_id: _this.User.ULeadId()
                });
            });
        });
    };
    PublicContentPage.prototype.setInputValue = function (dataAttrName, value, isDisabled) {
        if (!dataAttrName || !value) {
            return;
        }
        var $input = this.getElement(dataAttrName);
        if ($input.length) {
            $input.val(value);
            if ($input.val() != null && $input.val().length) {
                if (isDisabled) {
                    this.disableInput($input);
                }
            }
        }
    };
    PublicContentPage.prototype.setDropDownValues = function (dataAttrName, values) {
        if (!dataAttrName || !values) {
            return;
        }
        var $dropDown = this.getElement(dataAttrName);
        if ($dropDown.length) {
            $.each(values, function (index, value) {
                var option = $("<option value=" + value + ">" + value + "</option>");
                $dropDown.append(option);
            });
            $dropDown.val(values);
            // commented out to make sure values get posted to salesforce servers.
            //if ($dropDown.val() != null && $dropDown.val().length) {
            //    const numberOfOptions = $dropDown.find("option").length;
            //    // disabled the dropdown if there's only one option aside from the default
            //    if (numberOfOptions === 2) {
            //        this.disableInput($dropDown);
            //    }
            //}
        }
    };
    PublicContentPage.prototype.setVisibility = function (dataAttrName, visible) {
        if (!dataAttrName) {
            return;
        }
        var $div = this.getElement(dataAttrName);
        if ($div.length) {
            $div.toggle(visible);
        }
    };
    PublicContentPage.prototype.disableInput = function ($input) {
        if ($input.is("input")) {
            $input.prop("readonly", true);
        }
        if ($input.is("select")) {
            $input.prop("disabled", true);
            // when submitting them form, remove the disabled property
            $("form").bind("submit", function () {
                $input.prop("disabled", false);
            });
        }
    };
    PublicContentPage.prototype.getElement = function (dataAttrName) {
        return $("[" + dataAttrName + "]");
    };
    return PublicContentPage;
}(base.ResortSearchVmBase));
module.exports = PublicContentPage;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var PartnerPerkForm = /** @class */ (function (_super) {
    __extends(PartnerPerkForm, _super);
    function PartnerPerkForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    PartnerPerkForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        $('#btnSubmit').on('click', function (e) {
            e.preventDefault();
            var self = _this;
            if (!_this.vm.PurchaseButtonEnabled())
                return;
            _this.vm.RemoveAlerts();
            var postData = _this.getPostData();
            _this.editAction = 'Purchase';
            if (_this.vm.TotalAmountDue() > 0) {
                if (!self.validateCC(_this.vm.ItemDetail.CreditCardForm))
                    return false;
            }
            if (_this.vm.TotalAmountDue() > 0) {
                _this.GetFuseboxToken(self.vm.ItemDetail.CreditCardForm, window.location.href)
                    .then(function (data) {
                    if (data.CardToken().length > 0) {
                        self.vm.IsLoading(false);
                        var postData = self.getPostDate();
                        self.post(postData);
                    }
                    else {
                        self.postCCError();
                        self.logFuseError("token length is zero");
                    }
                });
            }
            else {
                _this.post(postData);
            }
            return false;
        });
        $('body').on('click', '.btnPrint', function (event) {
            event.preventDefault();
            window.print();
        });
    };
    PartnerPerkForm.prototype.getPostData = function () {
        var ignoreMapping = {
            'ignore': this.data.KOMapping.allNonPostBack
        };
        this.vm.ItemDetail.CreditCardForm.Amount = this.vm.TotalAmountDue;
        var postData = mapper.toJsWithOptions(this.vm, ignoreMapping);
        this.removeContentBlocks(postData);
        if (this.vm.TotalAmountDue() === 0 && !this.vm.ItemDetail.CreditCardForm.ShowWhenAmountIsZero()) {
            delete postData.ItemDetail.CreditCardForm;
        }
        return postData;
    };
    ;
    PartnerPerkForm.prototype.mapPostData = function (data) {
        // Don't overwrite the line items
        delete data.ItemDetail.PaymentLineItems;
        _super.prototype.mapPostData.call(this, data);
    };
    PartnerPerkForm.prototype.postPromiseDone = function (data) {
        this.mapPostData(data);
        if (this.vm.StatusCode() === 200) {
            this.vm.ReceiptVisible(true);
        }
        else {
            this.vm.AddAlert(data.Message, 'error');
        }
        this.vm.IsLoading(false);
        this.scrollTop();
    };
    PartnerPerkForm.prototype.postPromiseFail = function (error) {
        _super.prototype.postPromiseFail.call(this, error);
    };
    PartnerPerkForm.prototype.missingDates = function () { };
    PartnerPerkForm.prototype.refreshResortData = function () { };
    return PartnerPerkForm;
}(FrontEndForm));
module.exports = PartnerPerkForm;

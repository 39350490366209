"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var ManagePointsForm = /** @class */ (function (_super) {
    __extends(ManagePointsForm, _super);
    function ManagePointsForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    ManagePointsForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('body').on('click', '#changeEnrollment', function (e) {
            e.preventDefault();
            self.vm.isEnrolled = self.vm.ItemDetail.IsAlreadyEnrolled();
            self.vm.protectionAction = self.vm.NewProtection.ProtectionAction();
            self.vm.protectionFee = self.vm.NewProtection.CurrentFee();
            self.vm.ItemDetail.IsAlreadyEnrolled(false);
        });
        $('body').on('click', '#cancelEnrollment', function (e) {
            //Fire ajax request
            e.preventDefault();
            self.vm.LeaveOpenOnAjaxSuccess(true);
            self.editAction = "Cancel";
            var promise = self.post();
            promise.done(function (data) {
                //Move back to index
                self.editAction = "Edit";
                self.vm.isEnrolled = false;
                self.vm.protectionAction = 'Deposit';
                window.location.hash = data.ReturnUrl;
            });
            return false;
        });
        $('body').on('click', '.btnPrint', function (event) {
            event.preventDefault();
            window.print();
        });
        $('body').on('click', '#view-fee-modal', function (e) {
            e.preventDefault();
            HGV.Modal['view-fee-schedule'].open();
            return false;
        });
        $('body').on('click', '.btn-continue-points-transaction', function (e) {
            e.preventDefault();
            var disabled = $(e.target).hasClass('is-disabled');
            if (_this.vm.DisablePointsTransactions() || disabled)
                return false;
            if (_this.vm.NewForm() !== 'protection') {
                _this.showConfirm();
            }
        });
        if (this.vm.HTSIsEnabled()) {
            this.vm.ItemDetail.CreditCardForm.DisplayNumber.subscribe(function (newValue) {
                if (newValue != null) {
                    _this.vm.ItemDetail.CreditCardForm.Number(newValue);
                    _this.vm.ItemDetail.CreditCardForm.LastFourCardNumber(_this.vm.ItemDetail.CreditCardForm.DisplayNumber().substr(_this.vm.ItemDetail.CreditCardForm.DisplayNumber().length - 4));
                }
            });
        }
        this.vm.ItemDetail.CreditCardForm.CardType.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_CardType').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_CardType').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.CreditCardForm.Month.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Month').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Month').addClass('creditForm');
            }
        });
        this.vm.ItemDetail.CreditCardForm.Year.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Year').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Year').addClass('creditForm');
            }
        });
    };
    ManagePointsForm.prototype.post = function (dataToPost) {
        var _this = this;
        var self = this;
        this.vm.HasScrolledToError(false);
        //prevent double post
        if (this.vm.IsLoading()) {
            return;
        }
        this.vm.RemoveAlerts();
        if ($("#ItemDetail_AgreePayment").is(":visible")) {
            if (!$("#ItemDetail_AgreePayment").is(":checked")) {
                var object = { Key: "ItemDetail.AgreePayment", ErrorMessage: "< !--The field AgreePayment must be between True and True. -->" };
                self.addPropertyError(object);
                return;
            }
        }
        //make overlay loading div full height
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        var postData = dataToPost == undefined ? this.getPostDate() : dataToPost;
        this.removeContentBlocks(postData);
        //must delete credit card if nothing is due
        if (this.vm.NewForm() !== 'protection') {
            if (this.vm.TotalAmountDue() === 0 && !this.vm.ItemDetail.CreditCardForm.ShowWhenAmountIsZero()) {
                delete postData.ItemDetail.CreditCardForm;
            }
        }
        var isValidAmount = (this.vm.NewForm() === 'protection') ? true : this.vm.TotalAmountDue() > 0;
        if (isValidAmount) {
            if (this.validateCC(this.vm.ItemDetail.CreditCardForm)) {
                this.vm.IsLoading(true);
                if (this.vm.NewForm() === 'protection') {
                    self.vm.ItemDetail.AgreePayment(true);
                }
                this.GetFuseboxToken(self.vm.ItemDetail.CreditCardForm, window.location.href)
                    .then(function (data) {
                    if (data.CardToken().length > 0) {
                        var postTo = self.postUrl.replace('/null', '');
                        postData = self.getPostDate();
                        self.vm.IsLoading(true);
                        var promise = $.ajax({
                            url: postTo,
                            data: JSON.stringify(postData),
                            type: 'POST',
                            cache: false,
                            contentType: 'application/json;charset=utf-8',
                        });
                        promise.fail(function (error) {
                            self.postPromiseFail(error);
                            self.vm.IsLoading(false);
                        });
                        promise.done(function (data) {
                            var pointsUsed = 0;
                            if (self.vm.NewForm() === 'rescue') {
                                pointsUsed = data.NewRescue.ClubPointsToConvert + data.NewRescue.BhcPointsToConvert;
                            }
                            else if (self.vm.NewForm() === 'rci') {
                                pointsUsed = data.NewRci.ClubPointsToConvert + data.NewRci.BhcPointsToConvert;
                            }
                            else if (self.vm.NewForm() === 'hhonors') {
                                pointsUsed = data.NewHhonors.ClubPointsToConvert + data.NewHhonors.BhcPointsToConvert;
                            }
                            self.vm.DataLayer.push({
                                'event': 'PointTransaction',
                                'transactionType': self.vm.NewForm(),
                                'pointsUsed': pointsUsed,
                                'memberID': self.vm.User.MemberId(),
                                'transactionDate': (new Date()).toLocaleDateString()
                            });
                            self.postPromiseDone(data);
                            self.vm.IsLoading(false);
                        });
                        return promise;
                    }
                    else {
                        self.postCCError();
                        self.logFuseError("token length is zero");
                    }
                }).catch(function (e) {
                    self.vm.IsLoading(false);
                });
            }
            else {
                self.vm.IsLoading(false);
                self.postCCError();
            }
        }
        else {
            var postTo = this.postUrl.replace('/null', '');
            var promise = $.ajax({
                url: postTo,
                data: JSON.stringify(postData),
                type: 'POST',
                cache: false,
                contentType: 'application/json;charset=utf-8',
            });
            promise.fail(function (error) { return _this.postPromiseFail(error); });
            promise.done(function (data) { return _this.postPromiseDone(data); });
            return promise;
        }
    };
    ManagePointsForm.prototype.postPromiseDone = function (data) {
        var _this = this;
        this.vm.IsLoading(false);
        mapper.fromJsToModel(data.ItemDetail, this.vm.ItemDetail);
        _.each(data.ModelState.ModelErrors, function (v) {
            _this.vm.AddAlert(v.Key, 'error', true);
        });
        if (this.vm.NewForm() === 'rci') {
            mapper.fromJsToModel(data.NewRci.ClubRescuedPoints, this.vm.NewRci.ClubRescuedPoints);
            mapper.fromJsToModel(data.NewRci.BhcRescuedPoints, this.vm.NewRci.BhcRescuedPoints);
            mapper.fromJsToModel(data.NewRci.BhcPoints, this.vm.NewRci.BhcPoints);
            mapper.fromJsToModel(data.NewRci.ClubPoints, this.vm.NewRci.ClubPoints);
        }
        if (this.vm.NewForm() === 'protection') {
            //mapper.fromJsToModel(data.NewProtection, this.vm.NewProtection);
            this.vm.ItemDetail.IsAlreadyEnrolled(data.ItemDetail.IsAlreadyEnrolled);
            mapper.fromJsToModel(data.NewProtection, this.vm.NewProtection);
        }
        if (this.vm.NewForm() === 'hhonors') {
            //mapper.fromJsToModel(data.NewHhonors, this.vm.NewHhonors);
            _.each(this.vm.NewHhonors.SpecialContracts(), function (elem, i, ctx) {
                if (elem.PointsToConvert() > 0) {
                    $.each(data.NewHhonors.SpecialContracts, function (key, value) {
                        if (elem.ContractNumber() === value.ContractNumber) {
                            elem.ErrorMessage(value.ErrorMessage);
                            elem.ConfirmationNumber(value.ConfirmationNumber);
                            elem.TransactionDate(value.TransactionDate);
                        }
                    });
                }
            });
            if (this.vm.NewHhonors.ClubPoints.PointsToConvert() > 0) {
                this.vm.NewHhonors.ClubPoints.ErrorMessage(data.NewHhonors.ClubPoints.ErrorMessage);
                this.vm.NewHhonors.ClubPoints.ConfirmationNumber(data.NewHhonors.ClubPoints.ConfirmationNumber);
                this.vm.NewHhonors.ClubPoints.TransactionDate(data.NewHhonors.ClubPoints.TransactionDate);
            }
            if (this.vm.NewHhonors.ClubNextYearsPoints.PointsToConvert() > 0) {
                this.vm.NewHhonors.ClubNextYearsPoints.ErrorMessage(data.NewHhonors.ClubNextYearsPoints.ErrorMessage);
                this.vm.NewHhonors.ClubNextYearsPoints.ConfirmationNumber(data.NewHhonors.ClubNextYearsPoints.ConfirmationNumber);
                this.vm.NewHhonors.ClubNextYearsPoints.TransactionDate(data.NewHhonors.ClubNextYearsPoints.TransactionDate);
            }
            if (this.vm.NewHhonors.BhcPoints.PointsToConvert() > 0) {
                this.vm.NewHhonors.BhcPoints.ErrorMessage(data.NewHhonors.BhcPoints.ErrorMessage);
                this.vm.NewHhonors.BhcPoints.ConfirmationNumber(data.NewHhonors.BhcPoints.ConfirmationNumber);
                this.vm.NewHhonors.BhcPoints.TransactionDate(data.NewHhonors.BhcPoints.TransactionDate);
            }
            if (this.vm.NewHhonors.BhcNextYearsPoints.PointsToConvert() > 0) {
                this.vm.NewHhonors.BhcNextYearsPoints.ErrorMessage(data.NewHhonors.BhcNextYearsPoints.ErrorMessage);
                this.vm.NewHhonors.BhcNextYearsPoints.ConfirmationNumber(data.NewHhonors.BhcNextYearsPoints.ConfirmationNumber);
                this.vm.NewHhonors.BhcNextYearsPoints.TransactionDate(data.NewHhonors.BhcNextYearsPoints.TransactionDate);
            }
            if (this.vm.NewHhonors.BonusPoints.PointsToConvert() > 0) {
                this.vm.NewHhonors.BonusPoints.ErrorMessage(data.NewHhonors.BonusPoints.ErrorMessage);
                this.vm.NewHhonors.BonusPoints.ConfirmationNumber(data.NewHhonors.BonusPoints.ConfirmationNumber);
                this.vm.NewHhonors.BonusPoints.TransactionDate(data.NewHhonors.BonusPoints.TransactionDate);
            }
        }
        if (this.vm.NewForm() === 'deposit') {
            //mapper.fromJsToModel(data.NewDeposit, this.vm.NewDeposit);
        }
        if (this.vm.NewForm() === 'rescue') {
            //mapper.fromJsToModel(data.NewRescue, this.vm.NewRescue);
        }
        //window.location.hash = data.ReturnUrl;
        this.scrollTop();
    };
    ManagePointsForm.prototype.showConfirm = function () {
        var self = this;
        self.vm.FormVisible(true);
        self.vm.ItemDetail.Step(2);
        if (self.vm.NewForm() === "none") {
            window.location.href = "manage-points#/index";
        }
        this.scrollTop();
    };
    ManagePointsForm.prototype.mapPostData = function (data) {
        try {
            data.ItemDetail.CreditCardForm.Number = this.vm.ItemDetail.CreditCardForm.Number();
        }
        catch (e) {
        }
        var copyMapping = {
            'copy': this.data.KOMapping.copy
        };
        mapper.fromJsWithOptions(data.ItemDetail, copyMapping, this.vm.ItemDetail);
    };
    ManagePointsForm.prototype.missingDates = function () { };
    ManagePointsForm.prototype.refreshResortData = function () { };
    return ManagePointsForm;
}(FrontEndForm));
module.exports = ManagePointsForm;

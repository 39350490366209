"use strict";
var ko = require("knockout");
var komapping = require("knockout.mapping");
ko.mapping = komapping;
var Mapper = /** @class */ (function () {
    function Mapper() {
    }
    Mapper.deepCopy = function (vm) {
        return ko.mapping.fromJS(ko.toJS(vm));
    };
    Mapper.fromJs = function (vm) {
        return ko.mapping.fromJS(vm);
    };
    Mapper.toJsWithOptions = function (vm, options) {
        return ko.mapping.toJS(vm, options);
    };
    Mapper.toJs = function (vm) {
        return ko.mapping.toJS(vm);
    };
    Mapper.fromJsExisting = function (data, vm) {
        ko.mapping.fromJS(data, {}, vm);
    };
    Mapper.fromJsWithOptions = function (data, option, vm) {
        ko.mapping.fromJS(data, option, vm);
    };
    Mapper.fromJsToModel = function (data, vm) {
        ko.mapping.fromJS(data, data.KOMapping, vm);
    };
    return Mapper;
}());
module.exports = Mapper;

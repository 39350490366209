"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVMBase");
var Router = require("../Base/Router");
var PartnerPerkForm = require("./PartnerPerkForm");
var PartnerPerksTable = require("./PartnerPerksTable");
var PartnerPerk = /** @class */ (function (_super) {
    __extends(PartnerPerk, _super);
    // ReSharper restore InconsistentNaming
    function PartnerPerk(data) {
        var _this = _super.call(this, data, PartnerPerkForm, PartnerPerksTable, Router) || this;
        _this.data = data;
        _this.ReceiptVisible = ko.observable(false);
        var self = _this;
        var itemDetail = self.ItemDetail;
        var selectedPoints = itemDetail.PointsSelected;
        self.TotalDollarSum = ko.pureComputed(function () { return self.TotalPointsSelected() * itemDetail.PointDollarRatio(); });
        self.MinimumTotalPointsMet = ko.pureComputed(function () { return self.TotalPointsSelected() >= itemDetail.MinimumTotalPoints(); });
        self.BhcPointsAvailable = ko.pureComputed(function () { return itemDetail.AvailablePoints.BhcPoints() + itemDetail.AvailablePoints.BorrowableBhcPoints(); });
        self.BhcPointsBorrowWarningVisible = ko.pureComputed(function () { return self.BhcPointsSelected() > itemDetail.AvailablePoints.BhcPoints(); });
        self.BhcPointsChecked = ko.observable(true);
        self.BhcPointsSelected = ko.pureComputed(function () { return parseInt(selectedPoints.BhcPoints().toString()); });
        self.BhcPointsVisible = ko.pureComputed(function () { return self.BhcPointsAvailable() > 0; });
        self.BonusPointsAvailable = ko.pureComputed(function () { return itemDetail.AvailablePoints.BonusPoints(); });
        self.BonusPointsChecked = ko.observable(true);
        self.BonusPointsSelected = ko.pureComputed(function () { return parseInt(selectedPoints.BonusPoints().toString()); });
        self.BonusPointsVisible = ko.pureComputed(function () { return _this.BonusPointsAvailable() > 0; });
        self.ClubPointsAvailable = ko.pureComputed(function () { return itemDetail.AvailablePoints.ClubPoints() + itemDetail.AvailablePoints.BorrowableClubPoints(); });
        self.ClubPointsBorrowWarningVisible = ko.pureComputed(function () { return self.ClubPointsSelected() > itemDetail.AvailablePoints.ClubPoints(); });
        self.ClubPointsChecked = ko.observable(true);
        self.ClubPointsSelected = ko.pureComputed(function () { return parseInt(selectedPoints.ClubPoints().toString()); });
        self.ClubPointsVisible = ko.pureComputed(function () { return self.ClubPointsAvailable() > 0; });
        self.CertificateFeeApplies = ko.pureComputed(function () { return itemDetail.PointsSelected.BonusPoints() < self.TotalPointsSelected(); });
        self.CertificateFeeApplies = ko.pureComputed(function () { return true; });
        self.PaymentSummaryVisible = ko.pureComputed(function () { return itemDetail.PaymentLineItems().length > 0; });
        self.PurchaseButtonEnabled = ko.pureComputed(function () { return self.InGoodStanding() && self.MinimumTotalPointsMet(); });
        self.TotalAmountDue = ko.pureComputed(function () { return itemDetail.PaymentLineItems().reduce(function (accum, curr) { return accum + (ko.unwrap(curr.AmountDue) || 0); }, 0); });
        self.TotalPointsAvailable = ko.pureComputed(function () { return parseInt(self.BonusPointsAvailable().toString()) + parseInt(self.ClubPointsAvailable().toString()) + parseInt(self.BhcPointsAvailable().toString()); });
        // Subscribe to changes now that all the basic bindings are in place
        selectedPoints.BhcPoints.subscribe(function (newValue) { return self.selectedPointChanged(newValue, itemDetail.AvailablePoints.MaxBhcPoints(), selectedPoints.BhcPoints); });
        selectedPoints.BonusPoints.subscribe(function (newValue) { return self.selectedPointChanged(newValue, itemDetail.AvailablePoints.MaxBonusPoints(), selectedPoints.BonusPoints); });
        selectedPoints.ClubPoints.subscribe(function (newValue) { return self.selectedPointChanged(newValue, itemDetail.AvailablePoints.MaxClubPoints(), selectedPoints.ClubPoints); });
        self.BhcPointsChecked.subscribe(function (value) { self.checkboxChanged(value, selectedPoints.BhcPoints); });
        self.BonusPointsChecked.subscribe(function (value) { self.checkboxChanged(value, selectedPoints.BonusPoints); });
        self.ClubPointsChecked.subscribe(function (value) { self.checkboxChanged(value, selectedPoints.ClubPoints); });
        self.TotalPointsSelected = ko.pureComputed(function () { return self.BhcPointsSelected() + self.BonusPointsSelected() + self.ClubPointsSelected(); });
        var certificateFee = itemDetail.PaymentLineItems()[0];
        certificateFee.AmountDue = ko.pureComputed(function () { return self.CertificateFeeApplies() ? certificateFee.Amount() : 0; });
        itemDetail.CreditCardForm.CardType.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_CardType').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_CardType').addClass('creditForm');
            }
        });
        itemDetail.CreditCardForm.Month.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Month').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Month').addClass('creditForm');
            }
        });
        itemDetail.CreditCardForm.Year.subscribe(function (data) {
            if (data != null && data !== '') {
                $('#ItemDetail_CreditCardForm_Year').removeClass('creditForm');
            }
            else {
                $('#ItemDetail_CreditCardForm_Year').addClass('creditForm');
            }
        });
        ko.applyBindings(_this);
        $('.hideUnbound').removeClass('hideUnbound');
        self.triggerCheckboxChange();
        self.triggerInputRangesChange();
        return _this;
    }
    PartnerPerk.prototype.selectedPointChanged = function (newValue, maxValue, selectedPoint) {
        var newValueNumber = Number(newValue);
        if (!$.isNumeric(newValue) || newValueNumber < 0) {
            selectedPoint(0);
        }
        else if (newValueNumber > maxValue) {
            selectedPoint(maxValue);
        }
        else {
            this.triggerInputRangesChange();
        }
    };
    PartnerPerk.prototype.checkboxChanged = function (isChecked, selectedPoint) {
        if (isChecked)
            return;
        selectedPoint(0);
        this.triggerInputRangesChange();
    };
    PartnerPerk.prototype.triggerCheckboxChange = function () {
        setTimeout(function () { $('input[type=checkbox]').trigger('change.toolkit'); }, 0);
    };
    PartnerPerk.prototype.triggerInputRangesChange = function () {
        setTimeout(function () { $('input[type=range]').trigger('change.toolkit'); }, 0);
    };
    return PartnerPerk;
}(base.ResortSearchVmBase));
module.exports = PartnerPerk;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var ContactUsEntryForm = /** @class */ (function (_super) {
    __extends(ContactUsEntryForm, _super);
    function ContactUsEntryForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    ContactUsEntryForm.prototype.setupBindings = function () {
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('.toggle-group .hhonors > .toggle').on('click', function () {
            $('#00N50000003Ahbt').val($(this).html());
        });
        $('.toggle-group .timeshare-owner > .toggle').on('click', function () {
            $('#00N50000003Ahbt').val($(this).html());
        });
    };
    ContactUsEntryForm.prototype.getAllStates = function (countryId) {
        var countryIdDto = { 'countryId': countryId };
        var promise = $.ajax({
            url: this.area + "/" + this.vm.CurrentLanguageCode() + "/address/GetStates",
            data: JSON.stringify(countryIdDto),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        return promise;
    };
    ContactUsEntryForm.prototype.getPostDate = function () {
        var ignoreMapping = {
            'ignore': this.data.KOMapping.allNonPostBack
        };
        var postData = mapper.toJsWithOptions(this.vm, ignoreMapping);
        delete postData.Alerts;
        delete postData.AllCountries;
        delete postData.AllStates;
        if (this.vm.SelectedActivities().length > 0)
            postData.ItemDetail.Activities = this.vm.SelectedActivities().join(',');
        postData.ItemDetail.BestTimeToCall = this.vm.ItemDetail.Hour() + " " + this.vm.ItemDetail.Meridiem() + " " + this.vm.ItemDetail.Day() + " " + this.vm.ItemDetail.TimeZone();
        if (this.vm.ItemDetail.IsRequestingOnlinePresentation()) {
            if (this.vm.ItemDetail.PreferredDate() !== '') {
                var hour = this.vm.ItemDetail.PreferredHour() === '' ? '00:00:00' : this.vm.ItemDetail.PreferredHour() + ':00:00';
                var meridiem = this.vm.ItemDetail.PreferredMeridiem() || 'PM';
                postData.ItemDetail.PresentationDateTime = this.vm.ItemDetail.PreferredDate() + " " + hour + " " + meridiem;
            }
            if (this.vm.ItemDetail.AlternateDate() !== '') {
                var hour = this.vm.ItemDetail.AlternateHour() === '' ? '00:00:00' : this.vm.ItemDetail.AlternateHour() + ':00:00';
                var meridiem = this.vm.ItemDetail.AlternateMeridiem() || 'PM';
                postData.ItemDetail.PresentationAltDateTime = this.vm.ItemDetail.AlternateDate() + " " + hour + " " + meridiem;
            }
        }
        return postData;
    };
    return ContactUsEntryForm;
}(FrontEndForm));
module.exports = ContactUsEntryForm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ResortSearchTable = require("../Base/ResortSearchTable");
var _ = require("lodash");
var ReservationTable = /** @class */ (function (_super) {
    __extends(ReservationTable, _super);
    function ReservationTable(data, vm, upComing, pageInfoOverride, titleOverride) {
        if (upComing === void 0) { upComing = true; }
        if (pageInfoOverride === void 0) { pageInfoOverride = undefined; }
        if (titleOverride === void 0) { titleOverride = undefined; }
        var _this = _super.call(this, data, vm, pageInfoOverride, titleOverride) || this;
        _this.data = data;
        _this.vm = vm;
        _this.upComing = upComing;
        _this.pageInfoOverride = pageInfoOverride;
        _this.titleOverride = titleOverride;
        return _this;
    }
    //loads ajax data into table
    ReservationTable.prototype.loadResponse = function (responsedata) {
        var _this = this;
        this.reloadPageInfo(responsedata);
        if (this.upComing) {
            this.vm.ReservationsList(ko.mapping.fromJS(responsedata.List)());
            this.vm.UpcomingComplete(true);
        }
        else {
            //clear the list
            this.vm.List.removeAll();
            //add the new items
            _.each(responsedata.List, function (v) {
                _this.pushToList(v);
            });
            this.vm.HistoryComplete(true);
        }
        var complete = this.vm.UpcomingComplete() && this.vm.HistoryComplete();
        this.vm.IsLoading(!complete);
    };
    ReservationTable.prototype.getIdsMissingDates = function () {
        return [];
    };
    return ReservationTable;
}(ResortSearchTable));
module.exports = ReservationTable;

"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: ManagePoints
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _ = require("lodash");
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/ResortSearchVMBase");
var ManagePointsTable = require("./ManagePointsTable");
var ManagePointsRouter = require("./ManagePointsRouter");
var ManagePointsForm = require("./ManagePointsForm");
var Mapper = require("../Base/Mapper");
var numeral = require("numeral");
var ManagePoints = /** @class */ (function (_super) {
    __extends(ManagePoints, _super);
    function ManagePoints(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, ManagePointsForm, ManagePointsTable, ManagePointsRouter) || this;
        _this.data = data;
        _this.applyValidRange = function (max, input, ko) {
            if (input > max) {
                ko(max);
            }
            else if (input < 0) {
                ko(0);
                return;
            }
            else if (isNaN(input)) {
                ko(0);
            }
            else if (input === "") {
                ko(0);
            }
            else {
                //Make sure it is always a number
                var value = parseInt(input.toString());
                ko(value);
            }
        };
        _this.NewHhonors.User = _this.User;
        _this.initHtsTokenizer();
        _this.isEnrolled = _this.ItemDetail.IsAlreadyEnrolled();
        _this.protectionAction = _this.NewProtection.ProtectionAction();
        _this.protectionFee = _this.NewProtection.CurrentFee();
        _this.rescueChecked = ko.observable(false);
        _this.DisablePointsTransactions = ko.computed(function () {
            return !_this.InGoodStanding() || _this.ItemDetail.HasBalanceDue();
        });
        _this.AbleToRescue = ko.computed(function () {
            return _this.NewRescue.ClubRemainingAvailablePoints() > 0 || _this.NewRescue.BhcRemainingAvailablePoints() > 0;
        });
        _this.AbleToConvertToHhonors = ko.computed(function () {
            return _this.NewHhonors.ClubNextYearsPoints.AvailablePoints() > 0 || _this.NewHhonors.BhcNextYearsPoints.AvailablePoints() > 0 || _this.NewHhonors.ClubPoints.AvailablePoints() > 0 || _this.NewHhonors.BhcPoints.AvailablePoints() > 0 || _this.NewHhonors.BonusPoints.AvailablePoints() > 0;
        });
        _this.AbleToConvertToRci = ko.computed(function () {
            return (_this.NewRci.ClubRescuedPoints.AvailablePoints() + _this.NewRci.ClubPoints.AvailablePoints() + _this.NewRci.BhcPoints.AvailablePoints() + _this.NewRci.BhcRescuedPoints.AvailablePoints()) > 0;
        });
        ko.applyBindings(_this);
        _this.picker.setupCalendarBindings(_this, 'TopDatePicker');
        _this.RouteDisplayed(true);
        $('.hideUnbound').removeClass('hideUnbound');
        _this.SubmitForm = function () {
            var url = '/en/manage-points/ProcessPointsConversion';
            var mapping = {
                'ignore': _this.data.KOMapping.allNonPostBack
            };
            var postData = Mapper.toJsWithOptions(_this, mapping);
            $('#loading').height($(window).height());
            _this.IsLoading(true);
            var promise = $.ajax({
                url: url,
                data: JSON.stringify(postData),
                type: 'POST',
                cache: false,
                contentType: 'application/json;charset=utf-8'
            });
            promise.fail(function (error) {
                //something went wrong display error
                var html = error.error().responseText;
                var element = $('<div/>').html(html);
                $('style', element).remove();
                var htmlText = element.html();
                this.AddAlert(htmlText, 'error');
                this.IsLoading(false);
            });
            promise.done(function (data) {
                window.location.hash = 'confirmation';
                _this.updateUserPoints();
                _this.IsLoading(false);
            });
            return promise;
        };
        _this.setupSavePoints();
        _this.setupRci();
        _this.setupHHonors();
        _this.Fees.subscribe(function (newValue) {
            var cost = 0;
            _.each(_this.Fees(), function (x) { cost = cost + x.CostDecimal(); });
            _this.TotalAmountDue(cost);
            _this.ItemDetail.CreditCardForm.Amount(cost);
        });
        // #/protection
        _this.NewProtection.ProtectionAction.subscribe(function (newvalue) {
            _this.refreshProtectionFees(newvalue);
        });
        return _this;
    }
    ManagePoints.prototype.computePointPercentage = function (pointsType, basePoints, totalPoints) {
        var result = (basePoints / totalPoints) * 100;
        if (isNaN(result))
            result = 0;
        return result;
    };
    //#region Rescue
    ManagePoints.prototype.setupSavePoints = function () {
        var _this = this;
        var self = this;
        // #/rescue computeds
        self.NewRescue.ClubCurrentYearRemainPercentage = ko.computed(function () {
            var self = _this.NewRescue;
            var remainingPoints = self.ClubRemainingAvailablePoints() - parseInt(self.ClubPointsToConvert().toString());
            var totalPoints = _this.getSaveTotalPossiblePoints();
            return _this.computePointPercentage(self, remainingPoints, totalPoints);
        });
        self.NewRescue.BhcCurrentYearRemainPercentage = ko.computed(function () {
            var self = _this.NewRescue;
            var remainingPoints = self.BhcRemainingAvailablePoints() - parseInt(self.BhcPointsToConvert().toString());
            var totalPoints = _this.getSaveTotalPossiblePoints();
            return _this.computePointPercentage(self, remainingPoints, totalPoints);
        });
        self.NewRescue.ClubTotalConvertedPercentage = ko.computed(function () {
            var self = _this.NewRescue;
            var newPoints = self.ClubNextYearAvailablePoints() + parseInt(self.ClubPointsToConvert().toString());
            var totalPoints = _this.getSaveTotalPossiblePoints();
            return _this.computePointPercentage(self, newPoints, totalPoints);
        });
        self.NewRescue.BhcTotalConvertedPercentage = ko.computed(function () {
            var self = _this.NewRescue;
            var newPoints = self.BhcNextYearAvailablePoints() + parseInt(self.BhcPointsToConvert().toString());
            var totalPoints = _this.getSaveTotalPossiblePoints();
            return _this.computePointPercentage(self, newPoints, totalPoints);
        });
        self.NewRescue.TotalRemainingPointsDisplay = ko.computed(function () {
            var self = _this.NewRescue;
            var bhcRemaining = self.BhcRemainingAvailablePoints() - parseInt(self.BhcPointsToConvert().toString());
            var clubRemaining = self.ClubRemainingAvailablePoints() - parseInt(self.ClubPointsToConvert().toString());
            return numeral(bhcRemaining + clubRemaining).format('0,0');
        });
        self.NewRescue.TotalEstimatedConversionDisplay = ko.computed(function () {
            var self = _this.NewRescue;
            var clubNextYearPoints = self.ClubNextYearAvailablePoints() + parseInt(self.ClubPointsToConvert().toString());
            var bhcNextYearPoints = self.BhcNextYearAvailablePoints() + parseInt(self.BhcPointsToConvert().toString());
            return numeral(clubNextYearPoints + bhcNextYearPoints).format('0,0');
        });
        self.NewRescue.ClubPointsToConvert.subscribe(function (newValue) {
            var self = _this.NewRescue;
            _this.applyValidRange(self.ClubRemainingAvailablePoints(), newValue, self.ClubPointsToConvert);
        });
        self.NewRescue.BhcPointsToConvert.subscribe(function (newValue) {
            var self = _this.NewRescue;
            _this.applyValidRange(self.BhcRemainingAvailablePoints(), newValue, self.BhcPointsToConvert);
        });
        $("body").on("click", ".ts-rescuepoints-selected", function () {
            var type = $(this).data("type");
            var isClubChecked = $('#clubRescued').prop('checked');
            var isBhcChecked = $('#bhcRescued').prop('checked');
            switch (type) {
                case "rescuedBhcPoints":
                    self.addrescuedSelectedPointsBucket(isBhcChecked, isClubChecked);
                    break;
                case "rescuedClubPoints":
                    self.addrescuedSelectedPointsBucket(isBhcChecked, isClubChecked);
                    break;
            }
        });
    };
    ManagePoints.prototype.addrescuedSelectedPointsBucket = function (isBhcChecked, isClubChecked) {
        var self = this;
        if (!isBhcChecked) {
            self.NewRescue.BhcPointsToConvert(0);
        }
        else {
            self.NewRescue.BhcPointsToConvert(self.NewRescue.BhcRemainingAvailablePoints());
        }
        if (!isClubChecked) {
            self.NewRescue.ClubPointsToConvert(0);
        }
        else {
            self.NewRescue.ClubPointsToConvert(self.NewRescue.ClubRemainingAvailablePoints());
        }
    };
    ManagePoints.prototype.getSaveTotalPossiblePoints = function () {
        var self = this.NewRescue;
        var totalPoints = self.BhcRemainingAvailablePoints() + self.BhcNextYearAvailablePoints() + self.ClubRemainingAvailablePoints() + self.ClubNextYearAvailablePoints();
        return totalPoints;
    };
    //#endregion
    //#region RCI
    ManagePoints.prototype.setupRci = function () {
        var _this = this;
        var self = this;
        self.NewRci.BhcRescuedPoints.PointsToConvert(0);
        self.NewRci.BhcPoints.PointsToConvert(0);
        self.NewRci.ClubPoints.PointsToConvert(0);
        self.NewRci.ClubRescuedPoints.PointsToConvert(0);
        // #/rci computeds
        self.NewRci.ClubCurrentYearRemainPercentage = ko.computed(function () {
            var self = _this.NewRci;
            var remainingClubPoints = _this.getRciRemainingClubCurrentPoints();
            var totalPoints = _this.getRciTotalAvailablePoints();
            return _this.computePointPercentage(self, remainingClubPoints, totalPoints);
        });
        self.NewRci.BhcCurrentYearRemainPercentage = ko.computed(function () {
            var self = _this.NewRci;
            var remainingBhcPoints = _this.getRciRemainingBhcCurrentPoints();
            var totalPoints = _this.getRciTotalAvailablePoints();
            return _this.computePointPercentage(self, remainingBhcPoints, totalPoints);
        });
        self.NewRci.ClubTotalConvertedPercentage = ko.computed(function () {
            var self = _this.NewRci;
            var newClubPoints = parseInt(self.ClubRescuedPoints.PointsToConvert().toString()) +
                parseInt(self.ClubPoints.PointsToConvert().toString());
            var totalPoints = _this.getRciTotalAvailablePoints();
            return _this.computePointPercentage(self, newClubPoints, totalPoints);
        });
        self.NewRci.BhcTotalConvertedPercentage = ko.computed(function () {
            var self = _this.NewRci;
            var newBhcPoints = parseInt(self.BhcRescuedPoints.PointsToConvert().toString()) +
                parseInt(self.BhcPoints.PointsToConvert().toString());
            var totalPoints = _this.getRciTotalAvailablePoints();
            return _this.computePointPercentage(self, newBhcPoints, totalPoints);
        });
        // Limit PointsToConvert to max possible
        self.NewRci.ClubRescuedPoints.PointsToConvert.subscribe(function (newValue) {
            var clbRescuePoints = self.NewRci.ClubRescuedPoints;
            self.applyValidRange(clbRescuePoints.AvailablePoints(), newValue, clbRescuePoints.PointsToConvert);
            self.checkIsChecked(clbRescuePoints);
        });
        // Limit PointsToConvert to max possible
        self.NewRci.ClubPoints.PointsToConvert.subscribe(function (newValue) {
            var rciPoints = self.NewRci.ClubPoints;
            self.applyValidRange(rciPoints.AvailablePoints(), newValue, rciPoints.PointsToConvert);
            self.checkIsChecked(rciPoints);
        });
        // Limit PointsToConvert to max possible
        self.NewRci.BhcRescuedPoints.PointsToConvert.subscribe(function (newValue) {
            var rciPoints = self.NewRci.BhcRescuedPoints;
            self.applyValidRange(rciPoints.AvailablePoints(), newValue, rciPoints.PointsToConvert);
            self.checkIsChecked(rciPoints);
        });
        // Limit PointsToConvert to max possible
        self.NewRci.BhcPoints.PointsToConvert.subscribe(function (newValue) {
            var rciPoints = self.NewRci.BhcPoints;
            self.applyValidRange(rciPoints.AvailablePoints(), newValue, rciPoints.PointsToConvert);
            self.checkIsChecked(rciPoints);
        });
        self.NewRci.TotalRemainingPointsDisplay = ko.computed(function () {
            var bhcRemaining = _this.getRciRemainingBhcCurrentPoints();
            var clubRemaining = _this.getRciRemainingClubCurrentPoints();
            return numeral(bhcRemaining + clubRemaining).format('0,0');
        });
        self.NewRci.TotalEstimatedConversionDisplay = ko.computed(function () {
            var totalCoversion = _this.getRciTotalPointsToConvert();
            return numeral(totalCoversion).format('0,0');
        });
        self.NewRci.TotalEstimatedConversion = ko.computed(function () {
            _this.refreshRciFees();
            var total = _this.getRciTotalPointsToConvert();
            return total;
        });
        $("body").on("click", ".ts-rcipoints-selected", function () {
            var type = $(this).data("type");
            switch (type) {
                case "clbRescuePoints":
                    var clbRescuePoints = self.NewRci.ClubRescuedPoints;
                    self.addrciSelectedPointsBucket(clbRescuePoints);
                    break;
                case "rciBhcRescuedPoints":
                    var rciBhcRescuedPoints = self.NewRci.BhcRescuedPoints;
                    self.addrciSelectedPointsBucket(rciBhcRescuedPoints);
                    break;
                case "rciBhcPoints":
                    var rciBhcPoints = self.NewRci.BhcPoints;
                    self.addrciSelectedPointsBucket(rciBhcPoints);
                    break;
                case "clbRciAvailablePoints":
                    var clbRciAvailablePoints = self.NewRci.ClubPoints;
                    self.addrciSelectedPointsBucket(clbRciAvailablePoints);
                    break;
            }
        });
    };
    ManagePoints.prototype.getRciRemainingBhcCurrentPoints = function () {
        var pointsType = this.NewRci;
        var remainingBhcPoints = (pointsType.BhcRescuedPoints.AvailablePoints() - pointsType.BhcRescuedPoints.PointsToConvert()) +
            (pointsType.BhcPoints.AvailablePoints() - pointsType.BhcPoints.PointsToConvert());
        return remainingBhcPoints;
    };
    ManagePoints.prototype.getRciRemainingClubCurrentPoints = function () {
        var pointsType = this.NewRci;
        var remainingClubPoints = (pointsType.ClubRescuedPoints.AvailablePoints() - pointsType.ClubRescuedPoints.PointsToConvert()) +
            (pointsType.ClubPoints.AvailablePoints() - pointsType.ClubPoints.PointsToConvert());
        return remainingClubPoints;
    };
    ManagePoints.prototype.getRciTotalAvailablePoints = function () {
        var totalPoints = this.NewRci.ClubRescuedPoints.AvailablePoints() +
            this.NewRci.ClubPoints.AvailablePoints() +
            this.NewRci.BhcRescuedPoints.AvailablePoints() +
            this.NewRci.BhcPoints.AvailablePoints();
        return totalPoints;
    };
    ManagePoints.prototype.getRciTotalPointsToConvert = function () {
        var pointsType = this.NewRci;
        var total = parseInt(pointsType.ClubRescuedPoints.PointsToConvert().toString()) + parseInt(pointsType.ClubPoints.PointsToConvert().toString()) +
            parseInt(pointsType.BhcRescuedPoints.PointsToConvert().toString()) + parseInt(pointsType.BhcPoints.PointsToConvert().toString());
        return total;
    };
    ManagePoints.prototype.addrciSelectedPointsBucket = function (rciPointBucket) {
        if (rciPointBucket.IsChecked()) {
            var baseValue = parseInt(rciPointBucket.PointsToConvert().toString());
            if (baseValue === 0) {
                rciPointBucket.PointsToConvert(rciPointBucket.AvailablePoints());
            }
        }
        else {
            rciPointBucket.PointsToConvert(0);
            rciPointBucket.PointsToConvert(0);
            rciPointBucket.PointsToConvert(0);
            rciPointBucket.PointsToConvert(0);
        }
    };
    //#endregion
    //#region hhonors
    ManagePoints.prototype.setupHHonors = function () {
        var _this = this;
        var self = this;
        // #/hhonors
        // Computeds
        self.NewHhonors.TotalEstimatedConversion = ko.computed(function () {
            var self = _this.NewHhonors;
            var estimate = 0;
            // Add special
            _.each(self.SpecialContracts(), function (elem, i, ctx) {
                if (elem.IsChecked()) {
                    estimate += elem.CalculatedConversion();
                }
            });
            // Add Bhc
            if (self.BhcNextYearsPoints.IsChecked())
                estimate += self.BhcNextYearsPoints.CalculatedConversion();
            if (self.BhcPoints.IsChecked())
                estimate += self.BhcPoints.CalculatedConversion();
            // Add Club
            if (self.ClubNextYearsPoints.IsChecked())
                estimate += self.ClubNextYearsPoints.CalculatedConversion();
            if (self.ClubPoints.IsChecked())
                estimate += self.ClubPoints.CalculatedConversion();
            // Add Bonud
            if (self.BonusPoints.IsChecked())
                estimate += self.BonusPoints.CalculatedConversion();
            _this.refreshHonorsFees();
            return estimate;
        });
        self.NewHhonors.BhcNextYearsPoints.CalculatedConversion = ko.computed(function () {
            var self = _this.NewHhonors.BhcNextYearsPoints;
            return _this.getTotalPointsConverted(self);
        });
        self.NewHhonors.BhcPoints.CalculatedConversion = ko.computed(function () {
            var self = _this.NewHhonors.BhcPoints;
            return _this.getTotalPointsConverted(self);
        });
        self.NewHhonors.ClubNextYearsPoints.CalculatedConversion = ko.computed(function () {
            var self = _this.NewHhonors.ClubNextYearsPoints;
            return _this.getTotalPointsConverted(self);
        });
        self.NewHhonors.ClubPoints.CalculatedConversion = ko.computed(function () {
            var self = _this.NewHhonors.ClubPoints;
            return _this.getTotalPointsConverted(self);
        });
        self.NewHhonors.BonusPoints.CalculatedConversion = ko.computed(function () {
            var bonusPoints = self.NewHhonors.BonusPoints;
            return _this.getTotalPointsConverted(bonusPoints);
        });
        //we need to remove points used from special contracts from current year points
        self.NewHhonors.NextYearsBhcPointsOffset = ko.computed(function () {
            var pointsLeft = self.getSpecialContractPointOffset(self.NewHhonors.SpecialContracts, self.NewHhonors.BhcNextYearsPoints, 1); //1 == Bhc points
            return pointsLeft;
        });
        self.NewHhonors.NextYearsClubPointsOffset = ko.computed(function () {
            var pointsLeft = self.getSpecialContractPointOffset(self.NewHhonors.SpecialContracts, self.NewHhonors.ClubNextYearsPoints, 0); //0 == club points
            return pointsLeft;
        });
        // For each of our special contracts...
        _.each(ko.utils.unwrapObservable(self.NewHhonors.SpecialContracts()), function (element, index, context) {
            // Calculated Conversion...
            element.CalculatedConversion = ko.computed(function () {
                var baseValue = parseInt(element.PointsToConvert().toString());
                if (element.IsChecked()) {
                    var multiplier = (element.RatioRight() / element.RatioLeft());
                    var newValue = baseValue * multiplier;
                    return newValue;
                }
                return 0;
            });
            // Points to convert... 
            element.PointsToConvert = ko.computed(function () {
                var points = element.AvailablePoints();
                if (element.IsChecked()) {
                    return points;
                }
                return 0;
            });
        });
        self.NewHhonors.ConvertingCurrentYearPoints.subscribe(function (isCurrentYear) {
            //Need to clear the selected points if toggling between current and next year
            if (isCurrentYear) {
                self.NewHhonors.BhcNextYearsPoints.PointsToConvert(0);
                self.NewHhonors.ClubNextYearsPoints.PointsToConvert(0);
                ko.utils.arrayForEach(self.NewHhonors.SpecialContracts(), function (contract) {
                    contract.IsChecked(false);
                });
            }
            else {
                self.NewHhonors.BhcPoints.PointsToConvert(0);
                self.NewHhonors.ClubPoints.PointsToConvert(0);
                self.NewHhonors.BonusPoints.PointsToConvert(0);
            }
        });
        // Reset bonus points to zero when unchecked
        self.NewHhonors.BonusPoints.IsChecked.subscribe(function (isChecked) {
            _this.togglePointsSelected(self.NewHhonors.BonusPoints, isChecked);
        });
        // Reset next year's points to zero when unchecked
        self.NewHhonors.ClubNextYearsPoints.IsChecked.subscribe(function (isChecked) {
            _this.togglePointsSelected(self.NewHhonors.ClubNextYearsPoints, isChecked);
        });
        // Reset current year's points to zero when unchecked
        self.NewHhonors.ClubPoints.IsChecked.subscribe(function (isChecked) {
            _this.togglePointsSelected(self.NewHhonors.ClubPoints, isChecked);
        });
        // Reset bhc next year's points to zero when unchecked
        self.NewHhonors.BhcNextYearsPoints.IsChecked.subscribe(function (isChecked) {
            _this.togglePointsSelected(self.NewHhonors.BhcNextYearsPoints, isChecked);
        });
        // Reset bhc current year's points to zero when unchecked
        self.NewHhonors.BhcPoints.IsChecked.subscribe(function (isChecked) {
            _this.togglePointsSelected(self.NewHhonors.BhcPoints, isChecked);
        });
        // Limit PointsToConvert to max possible
        self.NewHhonors.BonusPoints.PointsToConvert.subscribe(function (newValue) {
            var bonusPoints = self.NewHhonors.BonusPoints;
            self.applyValidRange(bonusPoints.AvailablePoints(), newValue, bonusPoints.PointsToConvert);
            self.checkIsChecked(bonusPoints);
        });
        // Limit PointsToConvert to max possible
        self.NewHhonors.ClubPoints.PointsToConvert.subscribe(function (newValue) {
            var clubPoints = self.NewHhonors.ClubPoints;
            self.applyValidRange(clubPoints.AvailablePoints(), newValue, clubPoints.PointsToConvert);
            self.checkIsChecked(clubPoints);
        });
        // Limit PointsToConvert to max possible
        self.NewHhonors.ClubNextYearsPoints.PointsToConvert.subscribe(function (newValue) {
            var nextYearPoints = self.NewHhonors.ClubNextYearsPoints;
            var appliedSpecials = self.NewHhonors.NextYearsClubPointsOffset();
            var maxConvert = (nextYearPoints.AvailablePoints() - appliedSpecials);
            self.applyValidRange(maxConvert, newValue, nextYearPoints.PointsToConvert);
            self.checkIsChecked(nextYearPoints);
        });
        // Limit PointsToConvert to max possible
        self.NewHhonors.BhcPoints.PointsToConvert.subscribe(function (newValue) {
            var bhcPoints = self.NewHhonors.BhcPoints;
            self.applyValidRange(bhcPoints.AvailablePoints(), newValue, bhcPoints.PointsToConvert);
            self.checkIsChecked(bhcPoints);
        });
        // Limit PointsToConvert to max possible
        self.NewHhonors.BhcNextYearsPoints.PointsToConvert.subscribe(function (newValue) {
            var bhcNextYearPoints = self.NewHhonors.BhcNextYearsPoints;
            var appliedSpecials = self.NewHhonors.NextYearsBhcPointsOffset();
            var maxConvert = (bhcNextYearPoints.AvailablePoints() - appliedSpecials);
            self.applyValidRange(maxConvert, newValue, bhcNextYearPoints.PointsToConvert);
            self.checkIsChecked(bhcNextYearPoints);
        });
        self.NewHhonors.NextYearsBhcPointsOffset.subscribe(function (newValue) {
            var nextYearPoints = self.NewHhonors.BhcNextYearsPoints;
            var maxConvert = (nextYearPoints.AvailablePoints() - newValue);
            if (nextYearPoints.PointsToConvert() > maxConvert) {
                nextYearPoints.PointsToConvert(maxConvert);
            }
            self.checkIsChecked(nextYearPoints);
        });
        self.NewHhonors.NextYearsClubPointsOffset.subscribe(function (newValue) {
            var nextYearPoints = self.NewHhonors.ClubNextYearsPoints;
            var maxConvert = (nextYearPoints.AvailablePoints() - newValue);
            if (nextYearPoints.PointsToConvert() > maxConvert) {
                nextYearPoints.PointsToConvert(maxConvert);
            }
            self.checkIsChecked(nextYearPoints);
        });
        $("body").on("click", ".ts-points-selected", function () {
            var type = $(this).data("type");
            switch (type) {
                case "bhcpoints":
                    var bhcPoints = self.NewHhonors.BhcPoints;
                    self.addAllSelectedPointsBucket(bhcPoints);
                    break;
                case "bhc-nextyearpoints":
                    var bhcNextYearPoints = self.NewHhonors.BhcNextYearsPoints;
                    self.addAllSelectedPointsBucket(bhcNextYearPoints);
                    break;
                case "clubpoints":
                    var clubPoints = self.NewHhonors.ClubPoints;
                    self.addAllSelectedPointsBucket(clubPoints);
                    break;
                case "club-nextyearpoints":
                    var nextYearPoints = self.NewHhonors.ClubNextYearsPoints;
                    self.addAllSelectedPointsBucket(nextYearPoints);
                    break;
                case "bonuspoints":
                    var bonusPoints = self.NewHhonors.BonusPoints;
                    self.addAllSelectedPointsBucket(bonusPoints);
                    break;
            }
        });
    };
    ManagePoints.prototype.togglePointsSelected = function (hhrsPointBucket, isChecked) {
        if (!isChecked) {
            hhrsPointBucket.PointsToConvert(0);
        }
        ;
    };
    ManagePoints.prototype.getTotalPointsConverted = function (hhrsPointBucket) {
        if (hhrsPointBucket.IsChecked()) {
            var baseValue = parseInt(hhrsPointBucket.PointsToConvert().toString());
            var multiplier = (hhrsPointBucket.RatioRight() / hhrsPointBucket.RatioLeft());
            var newValue = baseValue * multiplier;
            return newValue;
        }
        return 0;
    };
    ManagePoints.prototype.getSpecialContractPointOffset = function (specialContracts, nextYearPointsBucket, pointsType) {
        if (specialContracts().length === 0) {
            return 0;
        }
        var appliedSpecials = 0;
        //Get total of selected special
        _.each(specialContracts(), function (elem, i, ctx) {
            if (elem.IsChecked() && elem.PointsBucketType() === pointsType) {
                appliedSpecials += elem.AvailablePoints();
            }
            elem.DisableCheck(false);
        });
        var available = nextYearPointsBucket.AvailablePoints() - appliedSpecials;
        //See if we can select any other special!
        _.each(specialContracts(), function (elem, i, ctx) {
            if (!elem.IsChecked() && elem.PointsBucketType() === pointsType) {
                if (elem.AvailablePoints() > available) {
                    elem.DisableCheck(true);
                }
            }
        });
        return appliedSpecials;
    };
    ManagePoints.prototype.addAllSelectedPointsBucket = function (hhrsPointBucket) {
        if (hhrsPointBucket.IsChecked()) {
            var baseValue = parseInt(hhrsPointBucket.PointsToConvert().toString());
            if (baseValue === 0) {
                hhrsPointBucket.PointsToConvert(hhrsPointBucket.AvailablePoints());
            }
        }
    };
    //#endregion
    ManagePoints.prototype.checkIsChecked = function (self) {
        if (!self.IsChecked() && self.PointsToConvert() > 0) {
            self.IsChecked(true);
        }
        if (self.PointsToConvert() === 0 && self.IsChecked()) {
            self.IsChecked(false);
        }
    };
    ManagePoints.prototype.refreshHonorsFees = function () {
        var _this = this;
        this.Fees.removeAll();
        var convertingClubThisYearPoints = this.NewHhonors.ClubPoints.PointsToConvert()
            && this.NewHhonors.ClubPoints.PointsToConvert() !== '0' && this.NewHhonors.ClubPoints.IsChecked();
        var convertingClubNextYearPoints = this.NewHhonors.ClubNextYearsPoints.PointsToConvert()
            && this.NewHhonors.ClubNextYearsPoints.PointsToConvert() !== '0' && this.NewHhonors.ClubNextYearsPoints.IsChecked();
        var convertingBhcThisYearPoints = this.NewHhonors.BhcPoints.PointsToConvert()
            && this.NewHhonors.BhcPoints.PointsToConvert() !== '0' && this.NewHhonors.BhcPoints.IsChecked();
        var convertingBhcNextYearPoints = this.NewHhonors.BhcNextYearsPoints.PointsToConvert()
            && this.NewHhonors.BhcNextYearsPoints.PointsToConvert() !== '0' && this.NewHhonors.BhcNextYearsPoints.IsChecked();
        var convertingBonusPoints = this.NewHhonors.BonusPoints.PointsToConvert() &&
            this.NewHhonors.BonusPoints.PointsToConvert() !== '0' && this.NewHhonors.BonusPoints.IsChecked();
        var convertingSpecialContractPoints = ko.utils.arrayFilter(this.NewHhonors.SpecialContracts(), function (contract) {
            return contract.PointsToConvert() > 0;
        });
        //Add in the fees. There is only 1 per day per point year bucket regardless of the number of contracts they are converting and if they are doing both BHC/Club
        var needsToPayCurrentYearFee = !this.NewHhonors.HasPaidHhrCurrentYearFee();
        if (needsToPayCurrentYearFee && convertingClubThisYearPoints) {
            _.each(this.NewHhonors.ClubPoints.Fees(), function (fee) { _this.Fees.push(fee); });
        }
        else if (needsToPayCurrentYearFee && convertingBhcThisYearPoints) {
            _.each(this.NewHhonors.BhcPoints.Fees(), function (fee) { _this.Fees.push(fee); });
        }
        var needsToPayNextYearFee = !this.NewHhonors.HasPaidHhrNextYearFee();
        if (needsToPayNextYearFee && (convertingClubNextYearPoints || convertingSpecialContractPoints.length > 0)) {
            _.each(this.NewHhonors.ClubNextYearsPoints.Fees(), function (fee) { _this.Fees.push(fee); });
        }
        else if (needsToPayNextYearFee && convertingBhcNextYearPoints) {
            _.each(this.NewHhonors.BhcNextYearsPoints.Fees(), function (fee) { _this.Fees.push(fee); });
        }
        if (!this.NewHhonors.HasPaidHhrBonusToday() && (convertingBonusPoints)) {
            _.each(this.NewHhonors.BonusPoints.Fees(), function (fee) { _this.Fees.push(fee); });
        }
    };
    ManagePoints.prototype.refreshRciFees = function () {
        var _this = this;
        this.Fees.removeAll();
        var hasConvertedClubPoints = this.NewRci.ClubPoints.PointsToConvert() && this.NewRci.ClubPoints.PointsToConvert() !== '0';
        var hasConvertedClubRescuedPoints = this.NewRci.ClubRescuedPoints.PointsToConvert() && this.NewRci.ClubRescuedPoints.PointsToConvert() !== '0';
        var hasConvertedBhcPoints = this.NewRci.BhcPoints.PointsToConvert() && this.NewRci.BhcPoints.PointsToConvert() !== '0';
        var hasConvertedBhcRescuedPoints = this.NewRci.BhcRescuedPoints.PointsToConvert() && this.NewRci.BhcRescuedPoints.PointsToConvert() !== '0';
        //Just push 1 fee for any RCI transaction
        if (hasConvertedClubPoints || hasConvertedClubRescuedPoints || hasConvertedBhcPoints || hasConvertedBhcRescuedPoints) {
            _.each(this.NewRci.ClubPoints.Fees(), function (fee) { _this.Fees.push(fee); });
        }
    };
    ManagePoints.prototype.refreshProtectionFees = function (newvalue) {
        var _this = this;
        this.Fees.removeAll();
        if (newvalue === "Deposit") {
            _.each(this.NewProtection.ClubPoints.Fees(), function (fee) {
                _this.Fees.push(fee);
            });
        }
        else {
            _.each(this.NewProtection.ClubRescuedPoints.Fees(), function (fee) {
                _this.Fees.push(fee);
            });
        }
        this.protectionAction = newvalue;
    };
    ManagePoints.prototype.setupCreditCardForm = function (showWhenIsZero) {
        this.ItemDetail.CreditCardForm.CardType(null);
        this.ItemDetail.CreditCardForm.NameOnCard(null);
        this.ItemDetail.CreditCardForm.CardToken(null);
        this.ItemDetail.CreditCardForm.ExpDate(null);
        this.ItemDetail.CreditCardForm.ShowWhenAmountIsZero(showWhenIsZero);
    };
    return ManagePoints;
}(base.ResortSearchVmBase));
module.exports = ManagePoints;

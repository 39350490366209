"use strict";
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var _ = require("lodash");
var moment = require("moment");
var ClubGuideResponseSummary = require("./ClubGuideResponseSummary");
var ClubGuideQuestionResponsesDto = /** @class */ (function () {
    function ClubGuideQuestionResponsesDto(vm, applyBindings) {
        if (applyBindings === void 0) { applyBindings = true; }
        this.applyBindings = applyBindings;
        if (applyBindings) {
            ko.applyBindings(this);
            $('.hideUnbound').removeClass('hideUnbound');
        }
        this.vm = vm;
        this.QuestionImageUrl = ko.observable("");
        this.QuestionText = ko.observable("");
        this.QuestionId = ko.observable("");
        this.Responses = ko.observableArray([]);
        this.IsRespondView = ko.observable(false);
        this.IsDeletingAnswer = ko.observable(false);
        this.AnswerToDelete = ko.observable("");
        var self = this;
        $('body').on('click', 'a.load-club-guide-add-response', function (evt) {
            evt.stopImmediatePropagation();
            var elem = $(this);
            var id = elem.data('id');
            self.loadQuestionResponses(id);
            self.IsRespondView(true);
            $('textarea.guide-modal__add').val("");
            $('.add-response__count').html('');
            $('.guide-modal__add').focus();
        });
        $('body').on('click', 'a.load-club-guide-questions', function (evt) {
            evt.stopImmediatePropagation();
            self.IsRespondView(false);
            var elem = $(this);
            var id = elem.data('id');
            self.loadQuestionResponses(id);
        });
        $('body').on('click', 'a.response-sort-order', function (evt) {
            evt.stopImmediatePropagation();
            var elem = $(this);
            var parent = elem.closest('.side-modal__categories');
            parent.children('.active').removeClass('active');
            elem.parent().addClass('active');
            var sort_order = parseInt(elem.data('order'));
            self.applySorting(sort_order);
        });
        $('body').on('click', 'a.response-like', function (evt) {
            evt.preventDefault();
            var elem = $(this);
            var data = ko.toJS(ko.dataFor(evt.currentTarget));
            var userLikes = !ko.unwrap(data.CurrentUserLiked);
            var likeCount = ko.unwrap(data.Likes);
            var newLikeCount = (userLikes) ? ++likeCount : --likeCount;
            var answer = _.find(self.Responses(), function (i) { return i.AnswerId() == data.AnswerId; });
            var url = '/' + self.vm.CurrentLanguageCode() + '/clubguide/' + (userLikes ? 'like' : 'unlike');
            $.post(url, { ResponseId: data.AnswerId })
                .done(function (response) {
                if (response.responseCode == 1) {
                    answer.CurrentUserLiked(userLikes);
                    answer.Likes(newLikeCount);
                }
            });
        });
        $('body').on('click', 'a.response-change-translation', function (evt) {
            evt.stopImmediatePropagation();
            var data = ko.toJS(ko.dataFor(evt.currentTarget));
            var answer = _.find(self.Responses(), function (i) { return i.AnswerId() == data.AnswerId; });
            var newLanguage = (answer.CurrentViewLanguage() === self.vm.CurrentLanguageCode()) ? data.OriginalLanguage : self.vm.CurrentLanguageCode();
            var url = '/' + self.vm.CurrentLanguageCode() + '/clubguide/original';
            $.get(url, { ResponseId: data.AnswerId, LanguageCode: newLanguage })
                .done(function (response) {
                answer.AnswerDescription(response.Answer);
                answer.CurrentViewLanguage(newLanguage);
            });
        });
        $('body').on('click', 'a.report-post', function (evt) {
            evt.stopImmediatePropagation();
            var elem = $(this);
            var data = ko.toJS(ko.dataFor(evt.currentTarget));
            var url = '/' + self.vm.CurrentLanguageCode() + '/clubguide/report';
            $.post(url, { ResponseId: data.AnswerId })
                .done(function (response) {
                if (response.responseCode == 1) {
                    // TODO:: Anything to do?
                }
            });
        });
        $('body').on('click', 'a.add-answer', function (evt) {
            evt.stopImmediatePropagation();
            self.IsRespondView(!self.IsRespondView());
            $('.guide-modal__add').focus();
        });
        $('body').on('click', 'a.submit-answer', function (evt) {
            evt.stopImmediatePropagation();
            var userResponse = $('textarea.guide-modal__add').val();
            var url = '/' + self.vm.CurrentLanguageCode() + '/clubguide/submitanswer';
            $.post(url, {
                QuestionId: self.QuestionId(),
                ResortId: self.vm.ItemDetail.Id(),
                Title: '',
                Description: userResponse
            })
                .done(function (response) {
                if (response.responseCode == 1) {
                    self.IsRespondView(!self.IsRespondView());
                    self.loadQuestionResponses(self.QuestionId());
                }
                else if (response.responseCode == 4) {
                    $('#ClubGuide_Response').parent().addClass('is-error');
                    $('#ClubGuide_Response').parent().after('<div class="form-control-error">' + response.errorMessage + '</div>');
                }
            });
        });
        $('body').on('click', 'a.delete-my-post', function (evt) {
            evt.stopImmediatePropagation();
            var elem = $(this);
            elem.parent().css('display', 'none');
            var confirmElem = $('.confirm-delete');
            elem.closest('.side-modal__response').after(confirmElem);
            self.IsDeletingAnswer(true);
            var data = ko.toJS(ko.dataFor(evt.currentTarget));
            self.AnswerToDelete(data.AnswerId);
        });
        $('body').on('click', 'a.delete-post-yes', function (evt) {
            evt.stopImmediatePropagation();
            var url = '/' + self.vm.CurrentLanguageCode() + '/clubguide/delete';
            $.post(url, { ResponseId: self.AnswerToDelete() })
                .done(function (response) {
                if (response.responseCode == 1) {
                    self.IsDeletingAnswer(false);
                    self.AnswerToDelete("");
                    self.loadQuestionResponses(self.QuestionId());
                }
            });
        });
        $('body').on('click', 'a.delete-post-no', function (evt) {
            evt.stopImmediatePropagation();
            self.IsDeletingAnswer(false);
            self.AnswerToDelete("");
        });
    }
    ClubGuideQuestionResponsesDto.prototype.buildResponseArray = function (responseArray) {
        var self = this;
        var newArray = [];
        responseArray.forEach(function (v) {
            var newItem = new ClubGuideResponseSummary(v, self.vm.CurrentLanguageCode());
            newArray.push(newItem);
        });
        return newArray;
    };
    ClubGuideQuestionResponsesDto.prototype.applySorting = function (sortId) {
        if (sortId === void 0) { sortId = 0; }
        var self = this;
        switch (sortId) {
            case 1:
                this.Responses.sort(function (l, r) {
                    if (l.Likes() < r.Likes())
                        return 1;
                    if (l.Likes() > r.Likes())
                        return -1;
                    return 0;
                });
                break;
            case 2:
                this.Responses.sort(function (l, r) {
                    var lDate = moment.tz(l.SubmittedDate(), self.vm.UserTimeZone);
                    var rDate = moment.tz(r.SubmittedDate(), self.vm.UserTimeZone);
                    if (lDate < rDate)
                        return 1;
                    if (lDate > rDate)
                        return -1;
                    return 0;
                });
                break;
            default:
                this.Responses.sort(function (l, r) {
                    if (l.TrendingOrder() < r.TrendingOrder())
                        return -1;
                    if (l.TrendingOrder() > r.TrendingOrder())
                        return 1;
                    return 0;
                });
                break;
        }
    };
    ClubGuideQuestionResponsesDto.prototype.loadQuestionResponses = function (id) {
        var _this = this;
        var url = '/' + this.vm.CurrentLanguageCode() + '/clubguide/responses';
        var promise = $.ajax({
            url: url,
            data: { questionId: id, resortId: this.vm.ItemDetail.Id },
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.done(function (response) {
            _this.QuestionImageUrl(response.ImageUrl);
            _this.QuestionId(response.QuestionId);
            _this.QuestionText(response.Question);
            _this.Responses(_this.buildResponseArray(response.Responses));
            _this.IsDeletingAnswer(false);
            _this.AnswerToDelete("");
            $('.response-sort-order[data-order=0]').click();
        });
        promise.fail(function (error) {
            var html = error.error().responseText;
        });
    };
    return ClubGuideQuestionResponsesDto;
}());
module.exports = ClubGuideQuestionResponsesDto;

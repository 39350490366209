"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: ClubGuideResponse
var ko = require("knockout");
var ClubGuideResponseSummary = /** @class */ (function () {
    function ClubGuideResponseSummary(data, currentLanguageCode) {
        this.data = data;
        this.AnswerDescription = ko.observable(data.AnswerDescription);
        this.AnswerId = ko.observable(data.AnswerId);
        this.CurrentUserLiked = ko.observable(data.CurrentUserLiked);
        this.Likes = ko.observable(data.Likes);
        this.OriginalLanguage = ko.observable(data.OriginalLanguage);
        this.SubmittedDate = ko.observable(data.SubmittedDate);
        this.Title = ko.observable(data.Title);
        this.TrendingOrder = ko.observable(data.TrendingOrder);
        this.User = ko.observable(data.User);
        this.CurrentViewLanguage = ko.observable(currentLanguageCode);
    }
    return ClubGuideResponseSummary;
}());
module.exports = ClubGuideResponseSummary;

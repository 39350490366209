"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var mapper = require("../Base/Mapper");
var OwnershipForm = /** @class */ (function (_super) {
    __extends(OwnershipForm, _super);
    function OwnershipForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    OwnershipForm.prototype.getFeesDetail = function () {
        var _this = this;
        $('#loading').height($(window).height());
        this.vm.IsLoading(true);
        //        $('body').addClass('form-shown');
        var promise = $.ajax({
            url: this.getUrl,
            type: 'GET',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        promise.fail(function (error) { return _this.getPromiseFail(error, null); });
        promise.done(function (data) { return _this.getPromiseDone(data, null); });
        return promise;
    };
    OwnershipForm.prototype.komappingfromdata = function (data, id) {
        mapper.fromJsToModel(data.ItemDetail.FeeDueAmount, this.vm.ItemDetail.FeeDueAmount);
        mapper.fromJsToModel(data.ItemDetail.FeeDueWord, this.vm.ItemDetail.FeeDueWord);
        if (this.vm.ItemDetail.FeeDueAmount() > 0) {
            this.vm.FormVisible(true);
        }
    };
    OwnershipForm.prototype.getPromiseDone = function (data, id) {
        this.komappingfromdata(data, id);
        this.vm.IsLoading(false);
    };
    OwnershipForm.prototype.missingDates = function () { };
    OwnershipForm.prototype.refreshResortData = function () { };
    return OwnershipForm;
}(FrontEndForm));
module.exports = OwnershipForm;

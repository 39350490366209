"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validate = void 0;
var creditCardType = require("credit-card-type");
var validationConst = require("../Constants/ValidationConstants");
function validate(ccNumber) {
    var result = '';
    var type = creditCardType(ccNumber)[0].type;
    switch (type) {
        case validationConst.visa: {
            result = validationConst.visaTypeId;
            break;
        }
        case validationConst.jcb: {
            result = validationConst.jcbTypeId;
            break;
        }
        case validationConst.discover: {
            result = validationConst.discoverTypeId;
            break;
        }
        case validationConst.americanExpress: {
            result = validationConst.americanExpressTypeId;
            break;
        }
        case validationConst.mastercard: {
            result = validationConst.mastercardTypeId;
            break;
        }
        case validationConst.dinersclub: {
            result = validationConst.dinersclubTypeId;
            break;
        }
        default: {
            result = '';
        }
    }
    return result;
}
exports.validate = validate;

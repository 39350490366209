"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Router = require("../Base/Router");
var ReservationRouter = /** @class */ (function (_super) {
    __extends(ReservationRouter, _super);
    function ReservationRouter(data, vm, alternateroutes) {
        var _this = _super.call(this, data, vm, alternateroutes) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    ReservationRouter.prototype.getRoutes = function () {
        var self = this;
        return [
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/list",
                func: function () {
                    self.vm['ReceiptVisible'] && self.vm['ReceiptVisible'](false);
                    self.vm['CancelReservation'] && self.vm['CancelReservation'](false);
                    self.vm['CancelReservationReceipt'] && self.vm['CancelReservationReceipt'](false);
                    self.vm['ReservationNotFound'] && self.vm['ReservationNotFound'] && self.vm['ReservationRequestIsInValid'](false);
                    self.vm['ReservationResortNotFound'] && self.vm['ReservationResortNotFound'](false);
                    self.vm['AvailErrorVisible'] && self.vm['AvailErrorVisible'](false);
                    self.vm.form && self.vm.form.cancel();
                    self.vm.Filters.CurrentLanguageCode = self.vm.CurrentLanguageCode();
                    //  self.vm.form.getUpcomingReservations();
                    self.vm.table.load();
                    self.vm["HistoryReservationTable"].load();
                    //HGV['Modal']['btnOpenChangeGuest'].close();
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + '/' + self.data.FrontEndRoute + '(.*)#/new',
                func: function () {
                    if (self.vm.StatusCode() !== 200) {
                        window.location.hash = '#/avail-error';
                    }
                    else {
                        self.vm.form.add();
                        self.vm['ReceiptVisible'](false);
                        self.vm['AvailErrorVisible'](false);
                        self.vm.RouteDisplayed(true);
                        self.vm.form.refreshReservationStickySidebar(true);
                        self.vm.form.setupPaymentLine(self.vm.ItemDetail.PaymentMethod().toString());
                        sessionStorage.setItem("ReservationStarted", "Yes");
                    }
                }
            },
            {
                name: self.getArea() + '/' + self.data.FrontEndRoute + '(.*)#/change',
                func: function () {
                    if (self.vm.StatusCode() !== 200) {
                        window.location.hash = '#/avail-error';
                    }
                    else {
                        self.vm.form.add();
                        self.vm['ReceiptVisible'](false);
                        self.vm['AvailErrorVisible'](false);
                        self.vm.form.refreshReservationStickySidebar(true);
                    }
                }
            },
            {
                name: self.getArea() + '/' + self.data.FrontEndRoute + '.*#/change-guest/:id',
                func: function () {
                    self.vm.form.changeGuest(this.params.id);
                    self.vm['ReceiptVisible'](false);
                    self.vm.FormVisible(true);
                    self.vm.form.refreshReservationStickySidebar(true);
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/view/:id",
                func: function () {
                    self.vm['ReservationNotFound'](false);
                    self.vm['ReservationResortNotFound'](false);
                    self.vm['ReservationRequestIsInValid'](false);
                    self.vm['AcceptOrRejectReservationVisible'](false);
                    var id = this.params.id;
                    var promise = self.vm.form.getDetail(id);
                    promise.done(function () {
                        self.vm.RouteDisplayed(true);
                        self.vm.form.refreshReservationStickySidebar(true);
                    });
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/index",
                func: function () {
                    self.vm['ReceiptVisible'](false);
                    self.vm['ReservationNotFound'](false);
                    self.vm['ReservationResortNotFound'](false);
                    self.vm['ReservationRequestIsInValid'](false);
                    self.vm['AvailErrorVisible'](false);
                    self.vm.form.cancel();
                    self.vm.table.load();
                }
            },
            //{
            //    name: `${self.getArea() }/${self.data.FrontEndRoute}#/delete/:id`,
            //    func() {
            //        var id = this.params['id'];
            //        self.vm.form.delete(id);
            //    }
            //},
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "(.*)#/avail-error",
                func: function () {
                    self.vm.FormVisible(false);
                    self.vm['AvailErrorVisible'](true);
                    //self.vm.form.refreshReservationStickySidebar(true);
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/cancel/:id",
                func: function () {
                    var id = this.params.id;
                    self.vm['ReservationConfirmCancel']['CancelationError'](false);
                    //When coming from reservation and going directly yo cancel reservation PaymentLineItems still contains data so make sure they are empty
                    self.vm.ItemDetail.PaymentLineItems([]);
                    self.vm.form.getConfirmCancellationSummary(id);
                    self.vm['ReceiptVisible'](false);
                    self.vm['CancelReservation'](true);
                    self.vm['ReservationNotFound'](false);
                    self.vm['ReservationResortNotFound'](false);
                    self.vm['ReservationRequestIsInValid'](false);
                    //club-payment
                    //   $('#club-payment').remove();
                    window.HGV.init();
                }
            },
            {
                name: self.getArea() + "/" + self.data.FrontEndRoute + "#/acceptorreject/:id",
                func: function () {
                    self.vm['ReservationNotFound'](false);
                    self.vm['ReservationResortNotFound'](false);
                    self.vm['ReservationRequestIsInValid'](false);
                    self.vm['AvailErrorVisible'](false);
                    self.vm['FormVisible'](false);
                    var id = this.params.id;
                    var promise = self.vm.form.getAcceptOrRejectDetail(id);
                    promise.done(function () {
                        self.vm.RouteDisplayed(true);
                        //self.vm.form.refreshReservationStickySidebar(true);
                        self.vm['ReceiptVisible'](false);
                        self.vm['AcceptOrRejectReservationVisible'](true);
                    });
                }
            },
        ];
    };
    ReservationRouter.prototype.showAvailabilityError = function () {
        window.location.hash = '#/avail-error';
    };
    return ReservationRouter;
}(Router));
module.exports = ReservationRouter;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var UpdateYourInformationForm = /** @class */ (function (_super) {
    __extends(UpdateYourInformationForm, _super);
    function UpdateYourInformationForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    UpdateYourInformationForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        $('#updateyourinformation-wrapper, #verify-address-wrapper').on('click', '#continue, #verify-continue', function (e) {
            e.preventDefault();
            var promise = _this.post();
            promise.done(function (data) {
                _this.vm.ShowVerifyAddress(data.ShowVerifyAddress);
                _this.vm.ShowAddressNotFound(data.ShowAddressNotFound);
                _this.vm.ShowUpdatedSuccessfully(data.ShowUpdatedSuccessfully);
                _this.vm.ContinueUrl(data.ContinueUrl);
                _this.scrollTop();
            });
            promise.fail(function (failData) {
                _this.vm.IsLoading(false);
                var data = failData.responseJSON;
                _this.vm.ShowVerifyAddress(data.ShowVerifyAddress);
                _this.vm.ShowAddressNotFound(data.ShowAddressNotFound);
                _this.vm.ShowUpdatedSuccessfully(data.ShowUpdatedSuccessfully);
                _this.vm.VerifiedAddress(data.VerifiedAddress);
                _this.vm.EnteredAddress(data.EnteredAddress);
                _this.vm.SelectedAddress(data.SelectedAddress);
                if (!_this.vm.ShowAddressNotFound()) { //If the address was found then default the verified addt
                    _this.vm.SelectedAddress(_this.vm.VerifiedAddress());
                }
                else {
                    _this.vm.SelectedAddress(_this.vm.EnteredAddress());
                }
                _this.scrollTop();
            });
            return false;
        });
        $('#verify-address-wrapper').on('click', '#edit-address', function (e) {
            e.preventDefault();
            _this.vm.ShowVerifyAddress(false);
            _this.vm.ShowAddressNotFound(false);
            _this.vm.ShowUpdatedSuccessfully(false);
            _this.vm.SelectedAddress(null);
            return false;
        });
        this.vm.ItemDetail.CountryId.subscribe(function (newValue) {
            var country = ko.utils.arrayFirst(_this.vm.Meta.AllCountries, function (country) {
                return country.Id === newValue;
            });
            if (country == null || !country.HasStateDropdown) {
                _this.vm.AllStates.removeAll();
                _this.vm.ItemDetail.State(null);
                return;
            }
            var promise = _this.getAllStates(_this.vm.ItemDetail.CountryId());
            promise.done(function (allStates) {
                _this.vm.AllStates(allStates);
                var state = ko.utils.arrayFirst(allStates, function (state) {
                    return state.Id === _this.vm.ItemDetail.State();
                });
                if (state == null) {
                    _this.vm.ItemDetail.State(allStates[0].Id);
                }
                else {
                    _this.vm.ItemDetail.State.valueHasMutated(); //trigger a change so the state is selected from the toolkit dropdown
                }
            });
        });
    };
    UpdateYourInformationForm.prototype.getAllStates = function (countryId) {
        var countryIdDto = { 'countryId': countryId };
        var promise = $.ajax({
            url: this.area + "/" + this.vm.CurrentLanguageCode() + "/address/GetStates",
            data: JSON.stringify(countryIdDto),
            type: 'POST',
            cache: false,
            contentType: 'application/json;charset=utf-8'
        });
        return promise;
    };
    UpdateYourInformationForm.prototype.postPromiseDone = function (data) {
        this.mapPostData(data);
        if (!this.vm.LeaveOpenOnAjaxSuccess()) {
            this.resetDetail();
        }
        this.vm.IsLoading(false);
    };
    UpdateYourInformationForm.prototype.getPromiseDone = function (data, id) {
        this.vm.ItemDetailIsNew(false);
        this.komappingfromdata(data, id);
        ;
        this.vm.IsLoading(false);
        this.vm.FormVisible(true);
    };
    return UpdateYourInformationForm;
}(FrontEndForm));
module.exports = UpdateYourInformationForm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FrontEndForm = require("../Base/FrontEndForm");
var SelectMembershipForm = /** @class */ (function (_super) {
    __extends(SelectMembershipForm, _super);
    function SelectMembershipForm(data, vm) {
        var _this = _super.call(this, data, vm) || this;
        _this.data = data;
        _this.vm = vm;
        return _this;
    }
    SelectMembershipForm.prototype.komappingfromdata = function (data, id) {
        _super.prototype.komappingfromdata.call(this, data, id);
        this.vm.HasUnlinkedAccounts(data.HasUnlinkedAccounts);
        this.vm.IsTrustMember(data.IsTrustMember);
    };
    SelectMembershipForm.prototype.setupBindings = function () {
        var _this = this;
        _super.prototype.setupBindings.call(this);
        var self = this;
        $('#select_account_wrapper').on('click', '.select-account', function (e) {
            e.preventDefault();
            var membership = ko.dataFor(e.target);
            var memberNumber = "";
            //assign memberNumber based on how it is displayed in Select Account screen.
            if (membership.IsTrustMembership()) {
                self.vm.SelectedTrustMembership(membership.TrustMembershipDto);
                if (membership.TrustMembershipDto.ContractNumber()) {
                    memberNumber = membership.TrustMembershipDto.ContractNumber();
                }
                else {
                    memberNumber = membership.TrustMembershipDto.ParentMembershipContractId();
                }
            }
            else {
                self.vm.SelectedMembership(membership);
                self.vm.SelectedTrustMembership(null);
                if (membership.IsContractOnly()) {
                    memberNumber = membership.ContractNumbers();
                }
                else {
                    memberNumber = membership.ClubNumber();
                }
            }
            _this.pushSegmentAccountSelectedAnalyticsEvent(memberNumber);
            self.editAction = "Select";
            var promise = _this.post();
            promise.done(function (data) {
                $('#loading').height($(window).height());
                self.vm.IsLoading(true);
                self.vm.IsLoadingTransaction(true);
                window.location.href = data.ReturnUrl;
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                //tried to select an account that the user. TODO: Should we display an error? Shouldn't happen unless a malicious user
            });
            return false;
        });
        $('#select_account_wrapper').on('click', '.link-account', function (e) {
            e.preventDefault();
            var membership = ko.dataFor(e.target);
            self.vm.SelectedMembership(membership);
            self.editAction = "Authenticate";
            var promise = _this.post();
            promise.done(function (data) {
                membership.IsLinked(data.AccountLinkedSuccess);
                self.vm.AccountLinkedSuccess(data.AccountLinkedSuccess);
                if (data.AccountLinkedSuccess) { //scroll to the top to see the sucess message
                    self.scrollTop();
                }
            });
            promise.fail(function (failData) {
                var data = failData.responseJSON;
                self.vm.AccountLinkedSuccess(data.AccountLinkedSuccess);
                membership.PasswordRequiredError(data.PasswordRequiredError);
                membership.InvalidPasswordError(data.InvalidPasswordError);
            });
            return false;
        });
        $('#select_account_wrapper').on('click', '.reset-password', function (e) {
            e.preventDefault();
            var membership = ko.dataFor(e.target);
            self.vm.SelectedMembership(membership);
            self.vm.PasswordResetSent(false);
            self.editAction = "ResetPassword";
            var promise = _this.post();
            promise.done(function (data) {
                self.vm.PasswordResetSent(data.PasswordResetSent);
                if (data.PasswordResetSent) { //scroll to the top to see the sucess message
                    self.scrollTop();
                }
            });
            promise.fail(function (data) {
                //self.vm.SelectedMembership
            });
            return false;
        });
    };
    SelectMembershipForm.prototype.postPromiseDone = function (data) {
        //this.mapPostData(data);
        this.vm.IsLoading(false);
    };
    SelectMembershipForm.prototype.pushSegmentAccountSelectedAnalyticsEvent = function (memberNumber) {
        this.vm.Analytics.track("Account Selected", {
            event: "account_selected",
            interaction_type: "click",
            member_number: memberNumber,
            u_lead_id: this.vm.User.ULeadId(),
        });
    };
    SelectMembershipForm.prototype.getPromiseDone = function (data, id) {
        //this.vm.ItemDetailIsNew(false);
        this.komappingfromdata(data, id);
        ;
        this.vm.IsLoading(false);
        this.vm.FormVisible(true);
    };
    SelectMembershipForm.prototype.missingDates = function () { };
    SelectMembershipForm.prototype.refreshResortData = function () { };
    return SelectMembershipForm;
}(FrontEndForm));
module.exports = SelectMembershipForm;

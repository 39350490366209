"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validate = void 0;
var creditCardType = require("credit-card-type");
var validationConst = require("../Constants/ValidationConstants");
function validate(ccType, ccNumber) {
    if (ccNumber === '') {
        return false;
    }
    var type = creditCardType(ccNumber);
    if (type != null && type.length > 0) {
        if (validationConst.allCardTypes.some(function (x) { return x === type[0].type; })) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}
exports.validate = validate;

"use strict";
/// This code was generated by Orange Onion Scaffolder.
// template: MVC.JSModule.cs
// class: HoaPayment
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var ko = require("knockout");
var base = require("../Base/VMBase");
var Router = require("../Base/Router");
var Table = require("../Base/Table");
var ContactUsEntryForm = require("./ContactUsEntryForm");
var HoaPayment = /** @class */ (function (_super) {
    __extends(HoaPayment, _super);
    function HoaPayment(data) {
        var _this = 
        //call base class constructor
        _super.call(this, data, ContactUsEntryForm, Table, Router) || this;
        _this.data = data;
        _this.SelectedActivities = ko.observableArray();
        _this.ItemDetail.Hour = ko.observable('');
        _this.ItemDetail.Meridiem = ko.observable('');
        _this.ItemDetail.Day = ko.observable('');
        _this.ItemDetail.TimeZone = ko.observable('');
        _this.Meta.AllMeridiems = ko.observableArray(['AM', 'PM']); // Fairly certain this can be static ;-)
        _this.ItemDetail.PreferredDate = ko.observable('');
        _this.ItemDetail.PreferredHour = ko.observable('');
        _this.ItemDetail.PreferredMeridiem = ko.observable('');
        _this.ItemDetail.AlternateDate = ko.observable('');
        _this.ItemDetail.AlternateHour = ko.observable('');
        _this.ItemDetail.AlternateMeridiem = ko.observable('');
        ko.applyBindings(_this);
        $('.hideUnbound').removeClass('hideUnbound');
        return _this;
    }
    return HoaPayment;
}(base.VmBase));
module.exports = HoaPayment;
